import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { externalJs } from 'src/app/services/external-js.service';
import { UserServiceService } from 'src/app/services/user-service.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(private xj: externalJs, private Fb: FormBuilder, private userService: UserServiceService, private router: Router, private toastr: NotificationService) { }
  settingsForm:any;
  isLoading:boolean = true;
  isSubmit:boolean = false;
  showError: boolean = false;
  errorMessages: any = [];
  defOpDistrict:string='Select District';
  districtList: any [];
  submitBtn: any;
  ngOnInit(): void {
    this.xj.extraInit();
    this.settingsForm = this.Fb.group({
      district: [''],
      one_roaster_upload: ['1'],
      upload_csv: ['1'],
      host: [''],
      username:[''],
      password: [''],
      port:[''],
      root:[''],
    })
    this.getDistrict();
    this.onLoadData();
  }

  getDistrict(){
    // this.isLoading = true;
    this.userService.fetchDistrict().subscribe((res:any) => {
      if(res.status){
        this.districtList = res['data'];
        this.defOpDistrict= 'Select District';
      } else{
        this.defOpDistrict= 'No District Found';
        this.districtList = [];        
      }
      this.isLoading = false;
    }, (err:any)=>{
        this.toastr.showError('Something went wrong. Please try later','');
        this.router.navigate(['/home/dashboard']);
        this.isLoading = false;
    })
  }

  onLoadData(){
    this.isLoading = true;
    this.userService.getSetting().then((res:any) =>{
      if(res.status){
        this.settingsForm.patchValue({
          district: res?.data?.district,
          one_roaster_upload: res?.data?.one_roaster_upload,
          upload_csv: res?.data?.upload_csv,
          host: res?.data?.host,
          username: res?.data?.username,
          password: res?.data?.password,
          port: res?.data?.port,
          root: res?.data?.root,
        })
      }
    }).catch((err:any) =>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
      this.isLoading = false;
    })
  }
  updateUser(e:any) {
    this.isSubmit = true;
    this.submitBtn = e.currentTarget;
    this.submitBtn.disabled = true;
    this.showError = false;
    this.errorMessages = [];

    const data = this.settingsForm.value;
    this.userService.updateSetting(data).then((res:any) => {
      if(res.status){
        this.toastr.showSuccess(res?.message);
        this.onLoadData();
        setTimeout(() => {
          this.router.navigate(['/home/dashboard']);
        }, 1000);
      } else {
        if(res?.message){
          this.errorMessages.push(res?.message);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }   
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }
}
