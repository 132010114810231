<div id="main-wrapper">
    <app-sidebar [addLink]="currentAddLnk" [addLinkTxt]="currAddLnkTxt"></app-sidebar>
        <!-- content-body -->
        <div class="content-body">
            <div class="container-fluid">
                <div class="card-table {{ (isLoading)? 'loading' : ''}}">
                    <div class="apploader {{ (isLoading)? 'show' : ''}}">
                        <div class="loader">
                            <div class="spinner-border" role="status"></div>
                            <span class="d-block mt-3">Loading...</span>
                        </div>
                    </div>
                    <div class="table-head">
                        <form class="row align-items-md-end">
                            <div class="col align-self-md-center">
                                <div class="form-inline span-5">
                                    <div class="form-group mb-1">
                                        <label for="table_title" class="fw-500 text-muted small w-100 justify-content-start mb-1">Title : </label>
                                        <input type="text" id="table_title" class="form-input" placeholder="Title" name="currentName"  [(ngModel)]="currentName">                                                  
                                    </div>
                                    <div class="form-group mb-1" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>
                                        <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">School : </label>
                                        <select class="form-input" id="table_organization" name="currentSchool"  [(ngModel)]="currentSchool" (change)="filterSchool($event.target.value)" >
                                            <option value="" selected>All</option>
                                            <option value="{{ school.id }}" *ngFor="let school of orgData">{{ school.name }}</option>
                                       </select>                                   
                                    </div>   
                                    <div class="form-group mb-1">
                                        <label for="table_course" class="fw-500 text-muted small w-100 justify-content-start mb-1">Course : </label>
                                        <select id="table_course" class="form-input" name="currentCourse"  [(ngModel)]="currentCourse">
                                            <option value="">All</option>
                                            <option *ngFor="let item of courseData" [value]="item.id">{{ item.title }}</option>
                                        </select>                                              
                                    </div>
                                                               
                                </div>
                            </div>
                            <div class="col-auto text-md-right">                                
                                <div class="addMore d-md-inline-block">
                                    <button class="btn-add" type="submit" (click)="filterData()">                                       
                                       Search
                                    </button>
                                    <button class="btn-reset" type="reset" (click)="clearFilter()">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>                   
                    <div class="table-responsive">
                        <p class="text-center not-found"  *ngIf="message && !isLoading">
                            <i class="bi bi-card-heading"></i>
                            {{message}}
                        </p>
                        <table class="table table-sm table-bordered sortable datatable nowraptable" *ngIf="!message && !isLoading">
                            <thead>
                            <tr class="table-header">
                                    <th class="text-dark">S.No.</th>
                                    <th class="text-dark">Title</th>
                                    <th class="text-dark">Course</th>
                                    <th class="text-dark">Code</th>
                                    <!-- <th class="text-dark">Location</th> -->
                                    <th class="text-dark" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>School</th>
                                    <!-- <th class="text-dark">Session</th> -->
                                    <th class="text-dark">Status</th>
                                    <th class="text-dark"  *ngIf="editButton || deleteButton">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor = "let data of classList;index as i">
                                    <td>{{(i + 1) + (currentPage - 1) * tableDataPerPage}}</td>
                                    <td>{{data.title}}</td>
                                    <td>{{data.course}}</td>
                                    <td>{{data.classCode}}</td>
                                    <!-- <td>{{data.location}}</td> -->
                                    <td *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>{{data.schoolName}}</td>
                                    <!-- <td>
                                       
                                        <ng-container *ngIf="data.academic_session?.length <= 2">
                                            <ul class="grid-list">
                                                <li *ngIf="data.academic_session[0]?.schoolYear || data.academic_session[0]?.title || data.academic_session[0]?.type">{{ data.academic_session[0]?.schoolYear }} - {{data.academic_session[0]?.title}}</li>
                                                <li *ngIf="data.academic_session[1]?.schoolYear || data.academic_session[1]?.title || data.academic_session[1]?.type">{{ data.academic_session[1]?.schoolYear }} - {{data.academic_session[1]?.title}}</li>
                                            </ul>  
                                        </ng-container>
                                        <ng-container *ngIf="data.academic_session?.length > 2">
                                            <ul class="grid-list">
                                                <li *ngIf="data.academic_session[0]?.schoolYear || data.academic_session[0]?.title || data.academic_session[0]?.type">{{ data.academic_session[0]?.schoolYear }} - {{data.academic_session[0]?.title}}</li>
                                                <li *ngIf="data.academic_session[1]?.schoolYear || data.academic_session[1]?.title || data.academic_session[1]?.type">{{ data.academic_session[1]?.schoolYear }} - {{data.academic_session[1]?.title}}</li>                                           
                                                                                      
                                            </ul>  
                                            <a tabindex="1" data-trigger="focus" class="fw-500 small text-primary" (click)="openPopup($event.target, data.academic_session)" title="Session">View More</a>
                                        </ng-container>
                                    </td> -->
                                    <td *ngIf="data.isActive == '1'"><span class="badge light badge-success">Active</span></td>
                                    <td *ngIf="data.isActive == '0'"><span class="badge light badge-danger">In-active</span></td>
                                    <td  *ngIf="editButton || deleteButton">
                                    
                                        <a *ngIf="editButton === true" (click)="goToEditPage(data.id)" class="btn btn-sm btn-success light">
                                            <i class="bi bi-pencil-fill"></i>
                                        </a>
                                        <a  *ngIf="deleteButton === true" href="#deletePopup" (click)="getCourseId(data.id)" data-toggle="modal" class="btn btn-sm btn-danger light">
                                            <i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </tr>                               
                            </tbody>
                        </table>
                    </div>
                    <div class="table-footer" *ngIf="!message && !isLoading">
                        <div class="row">
                            <div class="col-md-auto align-self-center ml-auto">
                                <div class="justify-content-md-end">
                                   
                                </div>
                            </div>
                            <div class="col-md-auto col-12 align-self-center ml-auto">
                                <div class="justify-content-md-end">
                                    <div class="text-md-right">
                                        <div class="dropdown-select">
                                            <!-- <div class="dataTables_length" id="sortable_length">
                                                <label>
                                                    <span class="mb-0">Rows per page:</span>
                                                    <select name="sortable_length">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </label>
                                            </div> -->
                                            <div>
                                                <div class="dataTables_info" id="sortable_info" role="status" aria-live="polite">{{ tableDataFrom }} - {{ tableDataTo }} of
                                                    {{tableDataTotal}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-auto col-12">
                                <div class="pagination dataTables_paginate" id="sortable_paginate" *ngIf="pageList?.length > 1">
                                    <a class="paginate_button previous" [ngClass]="1 == currentPage ? 'disabled':''"  (click)="changePage(currentPage-1)">
                                        <i class="bi bi-chevron-left"></i>
                                    </a>
                                    <span>
                                        <ng-container *ngFor="let page of pageList; let i = index;">
                                            <a *ngIf="page !== '...'" (click)="changePage(page)" [ngClass]="page == currentPage ? 'current paginate_button':'paginate_button'"   >{{ page }}</a>
                                            <span *ngIf="page === '...'"   class="paginate_button"   >{{ page }}</span>
                                        </ng-container>                                        
                                    </span>
                                    <a class="paginate_button next" [ngClass]="lastPage == currentPage ? 'disabled':''"  (click)="changePage(currentPage+1)">
                                        <i class="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- content-body -->
    </div>

    

    <!-- delete popup start -->
    <div id="deletePopup" class="modal fade" aria-hidden="true" #delete>
        <div class="modal-dialog modal-confirm modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header flex-column">
                    <div class="icon-box">
                        <i class="bi bi-x"></i>
                    </div>						
                    <h4 class="modal-title w-100">Are you sure?</h4>	
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="bi bi-x"></i></button>
                </div>
                <div class="modal-body">
                    <p>Do you really want to delete these record?</p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" (click)= "deleteCourse()">Delete</button>
                </div>
            </div>
        </div>
    </div>
    <!-- delete popup end -->
    
