import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env} from '../../environments/environment';
import { Injectable } from '@angular/core';
import { LoginServiceService } from './login-service.service';


@Injectable({
  providedIn: 'root'
})
export class MasterService {
  token: string;
  constructor(private http: HttpClient,private loginSrv:LoginServiceService) {

    this.token = localStorage.getItem('token')
  }

  getHeader = () =>{
    return {
      headers : new HttpHeaders({
        "Authorization" :'Bearer ' + this.loginSrv.getUserToken()
      })
    }
  }

  // get grade list
  getGradeList(page:any=1,school_id:any='',name:any=''){

    return this.http.get(env.apiurl + 'grade?page='+page+'&school_id='+school_id+'&grade='+name, this.getHeader());

  }
  allGradeList(school_id:any=''){
    return this.http.get(env.apiurl + 'grade?school_id='+school_id+'&allrecords=yes'+name, this.getHeader());
  }
  // view single grade
  viewGradeItem(id:any){
    return this.http.get(env.apiurl + 'grade/' + id, this.getHeader())
  }

  // add grade
  addGrade(formData:any){
    return this.http.post(env.apiurl+ 'grade', formData, this.getHeader()).toPromise();
  }

  // edit grade
  editGrade(id:any, formData:any){
    return this.http.put(env.apiurl+ 'grade/'+id, formData, this.getHeader()).toPromise();
  }

  // delete grade
  deleteGrade(formData:any) {
    return this.http.post(env.apiurl + 'delete-grade', formData, this.getHeader()).toPromise();
  }

  // document type list 
  getDocTypeList(page:any=1,school_id:any='',name:any=''){
    return this.http.get(env.apiurl + 'document-type?page='+page+'&schoolId='+school_id+'&name='+name, this.getHeader());
  }

   // view single document type
   viewDocTypeItem(id:any){
    return this.http.get(env.apiurl + 'document-type/' + id + '/edit', this.getHeader())
  }

  // add document type 
  addDocType(formdata:any){
    return this.http.post(env.apiurl+ 'document-type', formdata, this.getHeader()).toPromise();
  }

  // edit document tyoe 
  editDoctype(id:any, formData: any){
    return this.http.put(env.apiurl+ 'document-type/'+id, formData, this.getHeader()).toPromise();
  }

  // delete grade
  deleteDocType(id:any) {
    return this.http.delete(env.apiurl + 'document-type/'+id, this.getHeader()).toPromise();
  }
  // ftp list
  getFTPist(page:any=1,school_id:any='',name:any=''){
      return this.http.get(env.apiurl + 'ftp?page='+page+'&schoolId='+school_id+'&name='+name, this.getHeader())
  }

  // view single ftp
  viewFTPItem(id:any){
    return this.http.get(env.apiurl + 'ftp/' + id + '/edit', this.getHeader())
  }
   // delete grade
   deleteFTPItem(id:any) {
    return this.http.delete(env.apiurl + 'ftp/'+id, this.getHeader()).toPromise();
  }
  // add ftp list
  addFTP(formData:any){
    return this.http.post(env.apiurl+ 'ftp', formData, this.getHeader()).toPromise();
  }

  // edit ftp list

  editFTP(id:any, formData: any){
    return this.http.put(env.apiurl+ 'ftp/'+id, formData, this.getHeader()).toPromise();
  }




}
