   <!-- main-wrapper -->
   <div id="main-wrapper">
    <!-- sidebar menu  -->
  <app-sidebar (exportEvent)="exportUser()" [exportText]="'Export Users'"></app-sidebar>
    <!-- sidebar menu -->
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-table {{ (isLoading)? 'loading' : ''}}">
                <div class="apploader {{ (isLoading)? 'show' : ''}}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Loading...</span>
                    </div>
                </div>
               
                
                <div class="table-responsive">
                    <p class="text-center not-found" *ngIf="message && !isLoading">
                        <i class="bi bi-person-circle"></i>
                       {{ message }}
                    </p>
                    <table class="table table-sm table-bordered sortable datatable nowraptable" *ngIf="!message && !isLoading">
                        <thead>
                            <tr class="table-header">
                                <th class="text-dark">S.No.</th>
                                <th class="text-dark">Name</th>
                                <th class="text-dark" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>School</th>
                                <th class="text-dark">Email</th>
                                <th class="text-dark">Role</th>                           
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of faileduser; index as i">
                                <td>{{(i + 1) + (currentPage - 1) * tableDataPerPage}}</td>
                                <td>{{ item?.givenName }} {{ item?.middleName }} {{ item?.familyName }}</td>
                                <td *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>{{ item?.organisaitonName }}</td>
                                <td>NA</td>
                                <td>{{ (item?.roleName) ? item?.roleName : 'NA' }}</td>
                            </tr>
                            
                        </tbody>
                    
                    </table>
                </div>
                <div class="table-footer" *ngIf="!message && !isLoading">
                    <div class="row">
                        <div class="col-md-auto align-self-center ml-auto">
                            <div class="justify-content-md-end">
                               
                            </div>
                        </div>
                        <div class="col-md-auto col-12 align-self-center ml-auto">
                            <div class="justify-content-md-end">
                                <div class="text-md-right">
                                    <div class="dropdown-select">
                                        <!-- <div class="dataTables_length" id="sortable_length">
                                            <label>
                                                <span class="mb-0">Rows per page:</span>
                                                <select name="sortable_length">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </label>
                                        </div> -->
                                        <div>
                                            <div class="dataTables_info" id="sortable_info" role="status" aria-live="polite">{{ tableDataFrom }} - {{ tableDataTo }} of
                                                {{tableDataTotal}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-auto">                            
                            <div class="pagination dataTables_paginate" id="sortable_paginate" *ngIf="totalPages.length > 1">
                                <a class="paginate_button previous" [ngClass]="1 == currentPage ? 'disabled':''"  (click)="changeCurrentPage(currentPage-1)">
                                    <i class="bi bi-chevron-left"></i>
                                </a>
                                <span>
                                    <ng-container *ngFor="let page of totalPages; let i = index;">
                                        <a *ngIf="page !== '...'" (click)="changeCurrentPage(page)" [ngClass]="page == currentPage ? 'current paginate_button':'paginate_button'"   >{{ page }}</a>
                                        <span *ngIf="page === '...'"   class="paginate_button"   >{{ page }}</span>
                                    </ng-container>                                    
                                </span>
                                <a class="paginate_button next" [ngClass]="totalPages.at(-1) == currentPage ? 'disabled':''"  (click)="changeCurrentPage(currentPage+1)">
                                    <i class="bi bi-chevron-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- content-body -->
</div>
<!-- main-wrapper end -->
