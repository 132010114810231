<div class="login-container h-100">
    <div class="login-inner h-100">
        <div class="login-two-pane">
            <div>
                <div class="login-form">
                    <div class="mb-10 login-logo">
                        <img src="./assets/images/logo.png" alt="logo" class="img-fluid">
                    </div>
                    <div>
                        <h4> Forgot Password </h4>
                        <p class="mb-10"> Enter your Registered email address to forgot password.</p>
                    </div>
                
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16"
                                            viewBox="0 0 24 16">
                                            <g transform="translate(0)">
                                                <path
                                                    d="M23.983,101.792a1.3,1.3,0,0,0-1.229-1.347h0l-21.525.032a1.169,1.169,0,0,0-.869.4,1.41,1.41,0,0,0-.359.954L.017,115.1a1.408,1.408,0,0,0,.361.953,1.169,1.169,0,0,0,.868.394h0l21.525-.032A1.3,1.3,0,0,0,24,115.062Zm-2.58,0L12,108.967,2.58,101.824Zm-5.427,8.525,5.577,4.745-19.124.029,5.611-4.774a.719.719,0,0,0,.109-.946.579.579,0,0,0-.862-.12L1.245,114.4,1.23,102.44l10.422,7.9a.57.57,0,0,0,.7,0l10.4-7.934.016,11.986-6.04-5.139a.579.579,0,0,0-.862.12A.719.719,0,0,0,15.977,110.321Z"
                                                    transform="translate(0 -100.445)"></path>
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                                <input type="email" id="email" name="email" class="form-control" placeholder="Enter your email" required #email>
                            </div>
                        </div>
                        <!-- email end -->
                        
                        
                        <div class="text-center">
                            <p class="text-danger" *ngIf="errMsg !=''">
                                {{errMsg}}
                            </p>
                          <button (click)="getEmail(email.value, $event)"  class="btn btn-primary btn-block"> Submit 
                            <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isLoading"></span>
                          </button>
                        </div>

                    
                </div>
            </div>
            <div class="right-pane">
                <div class="text-center login-right">
                    <div class="mb-10 login-logo">
                        <img src="./assets/images/logo.png" alt="logo" class="img-fluid">
                    </div>
                    <div class="mt-10">
                        <h4 class="mb-4">Welcome to Clinton County R-3.</h4>
                        <p>Clinton County is a place where kids can experience a wide range of activities and
                            experiences. A place where students, staff and community work together towards a common goal
                            of success in education.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>