<div id="main-wrapper">
    <app-sidebar></app-sidebar>
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form" [ngClass]="{'loading position-relative': isLoading}">
                <div class="apploader" [ngClass]="{'show': isLoading}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Getting your data...</span>
                    </div>
                </div>
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Add Academic Session</h5>
                        <a routerLink="/home/academic-session"><i class="bi bi-chevron-left mr-1"></i>Back to list</a>
                    </div>
                </div>
                <form [formGroup]="addSessionForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="school_code" class="text-dark fw-500 label-text">Title<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group ">
                                            <input type="text" formControlName="title" id="school_code"
                                                class="form-control" placeholder="Title">
                                        </div>

                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="term" class="text-dark fw-500 label-text">Type<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <select name="" id="term" class="form-control" formControlName="term">
                                                <option value="">Select Type</option>
                                                <option value="term" >Term</option>
                                                <option value="gradingPeriod " >Grading Period</option>
                                                <option value="schoolYear" >School Year</option>
                                                <option value="semester" >Semester</option>
                                            </select>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="startDate" class="text-dark fw-500 label-text">Start Date<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="startDate" id="startDate" (blur)="updateStartDate($event)" class="form-control datepicker" placeholder="Start Date" readonly>
                                        </div>

                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="endDate" class="text-dark fw-500 label-text">End Date<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="endDate" id="endDate" (blur)="updateEndDate($event)" class="form-control datepicker" placeholder="End Date" readonly>
                                        </div>

                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="schoolYear" class="text-dark fw-500 label-text">School Year<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="schoolYear" id="schoolYear"
                                                class="form-control" placeholder="School Year">
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="showError">
                            <div class="alert alert-danger" role="alert">
                                {{errorMessages[0]}}
                            </div>
                        </ng-container>
                    </div>
                    <div class="card-footer">
                        <div class="form-group mb-0">                           
                            <button type="submit" class="btn btn-success" (click)="addSession($event)">Save
                                <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isSubmit"></span>
                            </button>
                            <button type="reset" class="btn btn-secondary mr-3" routerLink="/home/academic-session">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>