import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './dashboard/login/login.component';
import { DashboardPageComponent } from './dashboard/dashboard-page/dashboard-page.component';
import { SidebarComponent } from './dashboard/sidebar/sidebar.component';
import { UserComponent } from './dashboard/users/user/user.component';
import { AddUserComponent } from './dashboard/users/add-user/add-user.component';
import { AuthDocumentComponent } from './dashboard/auth-document/auth-document.component';
import { externalJs } from './services/external-js.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UserRoleComponent } from './dashboard/users/user-role/user-role.component';
import { OrganisationViewComponent } from './dashboard/organisation/organisation-view/organisation-view.component';
import { AddOrganisationComponent } from './dashboard/organisation/add-organisation/add-organisation.component';
import { EditOrganisationComponent } from './dashboard/organisation/edit-organisation/edit-organisation.component';
import { ToastrModule } from 'ngx-toastr';
import { EditUserComponent } from './dashboard/users/edit-user/edit-user.component';
import { ProfileSettingComponent } from './dashboard/profile-setting/profile-setting.component';
import { AddRoleComponent } from './dashboard/users/add-role/add-role.component';
import { ChecklistModule } from 'angular-checklist';
import { TeacherComponent } from './dashboard/teacher/teacher.component';
import { StudentComponent } from './dashboard/student/student.component';
import { ChangePasswordComponent } from './dashboard/change-password/change-password.component';
import { EditRoleComponent } from './dashboard/users/edit-role/edit-role.component';
import { SessionListComponent } from './dashboard/acedmin-session/session-list/session-list.component';
import { AddSessionComponent } from './dashboard/acedmin-session/add-session/add-session.component';
import { EditSessionComponent } from './dashboard/acedmin-session/edit-session/edit-session.component';

import {AuthGuard} from './services/auth.guard';
import { CreateStudentComponent } from './dashboard/students/create-student/create-student.component';
// import { EditStudentComponent } from './dashboard/students/edit-student/edit-student.component';

import { CreateDocumentComponent } from './dashboard/documents/create-document/create-document.component';
import { ListDocumentComponent } from './dashboard/documents/list-document/list-document.component';
import { DocumentsComponent } from './dashboard/documents/documents.component';
import { StudentListComponent } from './dashboard/student/student-list/student-list.component';
import { AddStudentComponent } from './dashboard/student/add-student/add-student.component';
import { EditStudentComponent } from './dashboard/student/edit-student/edit-student.component';

import { TeacherListComponent } from './dashboard/teacher/teacher-list/teacher-list.component';
import { AddTeacherComponent } from './dashboard/teacher/add-teacher/add-teacher.component';
import { CoursesComponent } from './dashboard/courses/courses.component';
import { CoursesListComponent } from './dashboard/Courses/courses-list/courses-list.component';
import { CreateCourseComponent } from './dashboard/Courses/create-course/create-course.component';
import { ClassesComponent } from './dashboard/classes/classes.component';
import { ClassesListComponent } from './dashboard/Classes/classes-list/classes-list.component';
import { CreateClassComponent } from './dashboard/Classes/create-class/create-class.component';
import { EditCourseComponent } from './dashboard/courses/edit-course/edit-course.component';
import { EditClassComponent } from './dashboard/classes/edit-class/edit-class.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BulkuploadComponent } from './dashboard/bulkupload/bulkupload.component';
import { UploadbulkdocumentComponent } from './dashboard/bulkupload/uploadbulkdocument/uploadbulkdocument.component';
import { UploadbulkcsvComponent } from './dashboard/bulkupload/uploadbulkcsv/uploadbulkcsv.component';
import { UploadedDcoumentComponent } from './dashboard/bulkupload/uploaded-dcoument/uploaded-dcoument.component';
import { BulkUploadUserComponent } from './dashboard/bulkupload/bulk-upload-user/bulk-upload-user.component';
import { DndDirective } from './dashboard/dnd.directive';
import { ProgressComponent } from './dashboard/progress/progress.component';
import { BulkViewUploadComponent } from './dashboard/bulkupload/bulk-view-upload/bulk-view-upload.component';
import { EditTeacherComponent } from './dashboard/teacher/edit-teacher/edit-teacher.component';
import { ForgotPasswordComponent } from './dashboard/forgot-password/forgot-password.component';
import { VerifyOtpComponent } from './dashboard/verify-otp/verify-otp.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { ResetPasswordComponent } from './dashboard/reset-password/reset-password.component';
import { MasterComponent } from './dashboard/master/master.component';
import { GradeListComponent } from './dashboard/master/grade-list/grade-list.component';
import { AddGradeComponent } from './dashboard/master/add-grade/add-grade.component';
import { DocumentTypeListComponent } from './dashboard/master/document-type-list/document-type-list.component';
import { FtpSettingListComponent } from './dashboard/master/ftp-setting-list/ftp-setting-list.component';
import { AddFtpSettingComponent } from './dashboard/master/add-ftp-setting/add-ftp-setting.component';
import { AddDocumentTypeComponent } from './dashboard/master/add-document-type/add-document-type.component';
import { HomeControllerComponent } from './home-controller/home-controller.component';
import { ParentsComponent } from './dashboard/parents/parents.component';
import { ParentListComponent } from './dashboard/parents/parent-list/parent-list.component';
import { EditParentComponent } from './dashboard/parents/edit-parent/edit-parent.component';

import { MetismenuAngularModule } from "@metismenu/angular";
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { FailedUserComponent } from './dashboard/bulkupload/failed-user/failed-user.component';
import { TeacherChildDocComponent } from './dashboard/teacher-child-doc/teacher-child-doc.component';
import { DocListComponent } from './dashboard/teacher-child-doc/doc-list/doc-list.component';
import { CreateDocComponent } from './dashboard/teacher-child-doc/create-doc/create-doc.component';
import { ActualImageDirective } from './dashboard/actual-image.directive';
import { SettingsComponent } from './dashboard/settings/settings.component';


  

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    UserComponent,
    AddUserComponent,
    DashboardPageComponent,
    SidebarComponent,
    AuthDocumentComponent,
    UserRoleComponent,
    OrganisationViewComponent,
    AddOrganisationComponent,
    EditOrganisationComponent,
    EditUserComponent,
    ProfileSettingComponent,
    AddRoleComponent,
    TeacherComponent,
    StudentComponent,
    ChangePasswordComponent,
    EditRoleComponent,
    SessionListComponent,
    AddSessionComponent,
    EditSessionComponent,
    CreateStudentComponent,
    EditStudentComponent,
    CreateDocumentComponent,
    ListDocumentComponent,
    DocumentsComponent,
    StudentListComponent,
    AddStudentComponent,
    TeacherListComponent,
    AddTeacherComponent,
    CoursesComponent,
    CoursesListComponent,
    CreateCourseComponent,
    ClassesComponent,
    ClassesListComponent,
    CreateClassComponent,
    EditCourseComponent,
    EditClassComponent,
    BulkuploadComponent,
    UploadbulkdocumentComponent,
    UploadbulkcsvComponent,
    UploadedDcoumentComponent,
    BulkUploadUserComponent,
    DndDirective,
    ProgressComponent,
    BulkViewUploadComponent,
    EditTeacherComponent,
    ForgotPasswordComponent,
    VerifyOtpComponent,
    DocumentListComponent,
    ResetPasswordComponent,
    MasterComponent,
    GradeListComponent,
    AddGradeComponent,
    DocumentTypeListComponent,
    FtpSettingListComponent,
    AddFtpSettingComponent,
    AddDocumentTypeComponent,
    HomeControllerComponent,
    ParentsComponent,
    ParentListComponent,
    EditParentComponent,
    FailedUserComponent,
    TeacherChildDocComponent,
    DocListComponent,
    CreateDocComponent,
    ActualImageDirective,
    SettingsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChecklistModule,
    MetismenuAngularModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()

  ],
  providers: [externalJs,AuthGuard, Title,  {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
