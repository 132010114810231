<div class="login-container h-100">
    <div class="login-inner h-100">
        <div class="login-two-pane">
            <div>
                <div class="login-form">
                    <div class="mb-10 login-logo">
                        <img src="./assets/images/logo.png" alt="logo" class="img-fluid">
                    </div>
                    <div>
                        <h4> Verify OTP</h4>
                        <p class="mb-10"> Check your email for OTP</p>
                    </div>
                <form (ngSubmit)="otpFormSubmit(f)" #f="ngForm">
                        <div class="form-group">
                            <div class="input-group">
                               
                                <input type="text" class="form-control" name="otp" 
                        required ngModel minlength="6" maxlength="6" #otp="ngModel" placeholder="Enter OTP"> 
                           
                    
                    </div>
                         <span class="text-danger d-block mt-3" *ngIf="errmsg !='' ">{{errmsg}}</span>
                        <span class="text-success d-block mt-3" *ngIf="sucsmsg !=''">{{sucsmsg}}</span>

                    </div>
                        <!-- email end -->
                        <div class="mb-4">
                            <a  href="javascript:void(0)" (click)="resendOTP()"> Resend  OTP </a>
                        </div>

                        <div class="text-center">
                            <button type="submit" [disabled]="submitBtn" class="btn btn-primary btn-block"> Submit 
                                <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isLoading"></span>
                            </button>
                        </div>

                    </form>
                </div>
            </div>
            <div class="right-pane">
                <div class="text-center login-right">
                    <div class="mb-10 login-logo">
                        <img src="./assets/images/logo.png" alt="logo" class="img-fluid">
                    </div>
                    <div class="mt-10">
                        <h4 class="mb-4">Welcome to Clinton County R-3.</h4>
                        <p>Clinton County is a place where kids can experience a wide range of activities and
                            experiences. A place where students, staff and community work together towards a common goal
                            of success in education.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>