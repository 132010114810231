import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { externalJs } from 'src/app/services/external-js.service';
import { AcademicService } from 'src/app/services/academic.service';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { OrganisationService } from 'src/app/services/organisation.service';
import { MasterService } from 'src/app/services/master.service';
import { TeacherService } from 'src/app/services/teacher.service';
@Component({
  selector: 'app-create-doc',
  templateUrl: './create-doc.component.html',
  styleUrls: ['./create-doc.component.css']
})
export class CreateDocComponent implements OnInit {
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  sessionData: any = [];
  docTypes: any = [];
  courseItems:any =[];
  gradeItems:any = [];
  childs: any = [];
  submitted:boolean = false;
  creating:boolean = false;  
  viewPage: boolean = false;
  constructor(private xj:externalJs, private FB: FormBuilder, private SessionSer: AcademicService, private CourseSer: CourseService, private Toaster: NotificationService, private Router: Router, private MasterSer: MasterService, private SchoolSev: OrganisationService, private teacher: TeacherService ) { 
    let permission = JSON.parse(localStorage.getItem('userPermission'));
    if(permission?.isParents){
      this.viewPage = true;
    } else{
      this.viewPage = false;
    }
  }
  createDocForm:any;
  showError:boolean = false;
  errorMessages:any = [];

   // loader is
   gradeLoad:boolean = true;
   courseLoad:boolean = true;
   studLoad:boolean = true;
   isLoading:boolean = true;
   defOpType:any ='Select Type';
   defOpSess:any ='Select Session';
   defOpGrade:any = 'Select Grade';
   defOpCourse:any = 'Select Course';
   defOpStd:any = 'Select Student';
   defOpassignedTo:any = 'Select Teacher';
   assignedTo:any = [];
   assignedToLoading:boolean = false;
   isExp: boolean = false;
 
  ngOnInit(): void {
    if(!this.viewPage){
      this.Router.navigate(['/home/dashboard']);
     }
    this.xj.extraInit();
    this.getSessionList();
    this.getDocumentType();
    this.getCourseData();
    this.getGradeItems();
    this.getChildList();
    this.createDocForm = this.FB.group({
      organisation: [this.schoolId],
      grade: [''],
      course: [''],
      name: ['',],
      session: ['',],
      document_type: ['',],
      certificate: ['',],
      fileSource: null,
      comment: [''],
      year:[''],
      assignedTo: [''],
      isApproved: [0,]
    })
    this.createDocForm.controls['grade'].disable();
    this.createDocForm.controls['course'].disable();
    this.createDocForm.controls['name'].disable();
    this.createDocForm.controls['session'].disable();
    this.createDocForm.controls['document_type'].disable();
    this.createDocForm.get('year').setValue(new Date().getFullYear());
  }
  // 
  
  get f(){
    return this.createDocForm.controls;
  }
  // get session list
  getSessionList() {
    this.SessionSer.getAllSession().subscribe((res:any)=>{
      if(res['status']){
        this.sessionData = res['data'];
        this.defOpSess = 'Select Session';
        this.createDocForm.controls['session'].enable();
      } else{
        this.sessionData = [];
        this.defOpSess="No Session Found";
      }     
    },(err:any)=>{
      this.Toaster.showError('Something went wrong. Please try later','');
      this.Router.navigate(['/home/mychild']);
      this.isLoading = false;
    })
  }
  // get Document Type
  getDocumentType(){
    this.CourseSer.getdocTypes().subscribe(res => {
      if(res['status']){
        this.docTypes = res['data'];
        this.defOpType='Select Type';
        this.createDocForm.controls['document_type'].enable();
      } else{
        this.docTypes = [];
        this.defOpType='No Document Type Found';
      }      
    },(err:any)=>{
      this.Toaster.showError('Something went wrong. Please try later','');
      this.Router.navigate(['/home/mychild']);
    })
  }
  // get courses based on the org id
  getCourseData(){
    this.courseItems = [];
    this.CourseSer.getAllCoursebyOrgid(this.schoolId).subscribe(res => {
      if(res['status']){
        this.courseItems = res['data'];
        this.defOpCourse = 'Select Course';
        this.createDocForm.controls['course'].enable();
      } else{
        this.courseItems = []; 
        this.defOpCourse = 'No Course Found';      
      }      
      this.courseLoad = false;      
      this.isLoading = false;      
    },(err:any)=>{
      this.Toaster.showError('Something went wrong. Please try later','');
      this.Router.navigate(['/home/mychild']);
      this.isLoading = false;
      this.courseLoad = false;
    })
  }

  oncourseChange(courseId){  
    if (courseId) {
      const index = this.courseItems.findIndex(p => p.id == courseId);
      if(this.courseItems[index].isExp){  
        this.isExp = true;
        this.getAssignedTo(this.schoolId);
      } else{
        this.isExp = false;
        this.createDocForm.controls['assignedTo'].setValue('');
      }
    } else{
      this.isExp = false;
      this.createDocForm.controls['assignedTo'].setValue('');
    }
    // console.log(courseId);
  }

  getAssignedTo(orgId){
    this.assignedToLoading = true;
    this.assignedTo = [];
    this.teacher.getAllTeacher(orgId).subscribe(res => {
      if(res['status']){
        this.assignedTo = res['data'];
        this.defOpassignedTo = 'Select Teacher';
        this.createDocForm.controls['assignedTo'].enable();
      } else{
        this.assignedTo = [];
        this.defOpassignedTo = 'No Teacher Found';
      }
      this.assignedToLoading = false;
      if(this.isLoading){
        this.isLoading = false;
      }
    },(err:any)=>{
      this.Toaster.showError('Something went wrong. Please try later','');
      this.Router.navigate(['/home/mychild']);
      this.isLoading = false;
      this.assignedToLoading = false;
    })
  }

  // getGradeItems
  getGradeItems(){
    this.gradeItems = [];
    this.MasterSer.allGradeList(this.schoolId).subscribe((res:any)=>{
      if(res.status){
        this.gradeItems = res.data;
        this.defOpGrade = 'Select Grade';
        this.createDocForm.controls['grade'].enable();
      } else{
        this.gradeItems = [];
        this.defOpGrade = 'No Grade Found';
      }
      this.gradeLoad = false;
    },(err:any)=>{
      this.Toaster.showError('Something went wrong. Please try later','');
      this.Router.navigate(['/home/mychild']);
      this.isLoading = false;
    })
  }

  // getChildList
  getChildList(){
    this.childs = [];
    this.CourseSer.getAllStudetnsbyParent().subscribe((res:any)=>{
      if(res.status){
        this.childs = res.data;
        this.defOpStd = 'Select Student';
        this.createDocForm.controls['name'].enable();
      } else{
        this.childs = [];
        this.defOpStd = 'No Student Found';
      }
      this.studLoad = false;
    },(err:any)=>{
      this.Toaster.showError('Something went wrong. Please try later','');
      this.Router.navigate(['/home/mychild']);
      this.isLoading = false;
    })
  }
  // 
  onFileChange(event) {  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSize = file.size / 1024 / 1024; 
      if(fileSize > 2){
        if(fileSize > 2){
          this.Toaster
          .showWarning('You have attached a large file so it will take time to upload. please be patient', 'Large File');       
        }
      }
      this.createDocForm.patchValue({
        fileSource: file
      });
    }
  }
  
  // form submi
  submit(){
    this.submitted = true;
    this.creating = true;
    this.showError = false;
    this.errorMessages = [];
    const formData = new FormData();
    formData.append('certificate', this.createDocForm.get('fileSource').value);
    formData.append('userSourcedId', this.createDocForm.get('name').value);
    formData.append('orgSourcedId', this.createDocForm.get('organisation').value);
    formData.append('courseSourcedId', this.createDocForm.get('course').value);
    formData.append('sessionSourcedId', this.createDocForm.get('session').value);
    formData.append('documentType', this.createDocForm.get('document_type').value);
    formData.append('grade', this.createDocForm.get('grade').value);    
    if(this.isExp){
      formData.append('isApproved', '0');
    } else{
      formData.append('isApproved', this.createDocForm.get('isApproved').value);
    }
    formData.append('year', this.createDocForm.get('year').value);
    formData.append('remarks', this.createDocForm.get('comment').value);
    formData.append('assignedTo', this.createDocForm.get('assignedTo').value);
    formData.append('child', 'yes');
    console.log(this.createDocForm.get('certificate').value)
  
    this.SchoolSev.upLoadDocument(formData).then(res => {
      if(res['status']){
        this.Toaster.showSuccess("Document uploaded successfully !!");
        this.Router.navigate(['/home/mychild']);
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }         
        this.showError = true; 
      }
      this.creating = false;
    }) .catch((err:any)=>{
      let errData = err.error.errors;      
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.creating = false;
    })
  }
}
