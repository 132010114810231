<div id="main-wrapper">
    <app-sidebar [addLink]="currentAddLnk" [addLinkTxt]="currAddLnkTxt"></app-sidebar>
        <!-- content-body -->
        <div class="content-body">
            <div class="container-fluid">
                <ul class="nav nav-tabs" *ngIf="loginService.getCurrentUserRole().slug == 'student' || loginService.getCurrentUserRole().slug == 'parent'">
                    <li class="nav-item">
                        <a class="nav-link {{ (activeTab == 'yes')? 'active' : ''}}" (click)="toggleTab('yes')">Approved</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link {{ (activeTab == 'no')? 'active' : ''}}" (click)="toggleTab('no')">Unapprove</a>
                    </li>
                    <li class="nav-item" *ngIf="loginService.getCurrentUserRole().slug !== 'super-admin'">
                        <a class="nav-link {{ (isArchived == 'yes')? 'active' : ''}}" (click)="showArchive('yes')">Archived</a>
                    </li>
                </ul>

                <ul class="nav nav-tabs" *ngIf="loginService.getCurrentUserRole().slug !== 'student' && loginService.getCurrentUserRole().slug !== 'parent'">
                    <li class="nav-item">
                        <a class="nav-link {{ (activeTab == 'no')? 'active' : ''}}" (click)="toggleTab('no')">Unapprove</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link {{ (activeTab == 'yes')? 'active' : ''}}" (click)="toggleTab('yes')">Approved</a>
                    </li>
                   
                </ul>
                <div class="card-table border-top-0 {{ (isLoading)? 'loading' : ''}}">
                    <div class="apploader {{ (isLoading)? 'show' : ''}}">
                        <div class="loader">
                            <div class="spinner-border" role="status"></div>
                            <span class="d-block mt-3">Loading...</span>
                        </div>
                    </div>
                   
                    <div class="tab-content">
                        <div class="table-head border-top-0">
                            <form class="row align-items-md-end" method="post">
                                <div class="col align-self-md-center">
                                    <div class="form-inline span-5">
                                        <div class="form-group mb-1" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>
                                            <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">School : </label>
                                            <select class="form-input" name="schoolId" [(ngModel)]="currentSchoolId" (change)="onSchool($event.target.value)">
                                                <option value="" selected>All</option>
                                                <option value="{{ school.id }}" *ngFor="let school of orgData">{{ school.name }}</option>
                                           </select>                                                
                                        </div>
                                        <!-- <div class="form-group mb-1" >
                                            <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">Documents Type : </label>
                                            <select class="form-input" name="currentDocType" [(ngModel)]="currentDocType">
                                                <option value="" selected>All</option>
                                                <option value="{{ doc.id }}" *ngFor="let doc of docTypes">{{ doc.name }}</option>
                                           </select>                                               
                                        </div> -->
                                        <div class="form-group mb-1" >
                                            <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">Course : </label>
                                            <select class="form-input" name="currentCourseId" [(ngModel)]="currentCourseId">
                                                <option value="">All</option>
                                                <option value="{{ course.id }}" *ngFor="let course of courseData">{{ course.title }}</option>
                                           </select>                                                
                                        </div>
    
                                        <div class="form-group mb-1" *ngIf="loginService.getCurrentUserRole().slug != 'student'">
                                            <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1" >Student Name : </label>
                                            <input type="text"  class="form-input" name="name" [(ngModel)]="currentStudentName">                                            
                                        </div>
                                        
                                        <!-- <div class="form-group mb-1" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident"'>
                                            <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">School : </label>
                                            <select class="form-input" name="" id="organisation">
                                                <option value="">Select School</option>
                                                <option *ngFor="let i of orgData;" value="{{i.id}}">{{ i.name }}</option>
                                           </select>                                                
                                        </div>    
                                                                     -->
                                       
                                    </div>
                                </div>
                                <div class="col-auto text-md-right">                                
                                    <div class="addMore d-md-inline-block">
                                        <a class="btn-add" (click)="filterDocuments()">Filter</a>
                                        <button class="btn-reset" type="reset" (click)="clearFilter()">
                                            Clear
                                        </button>
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
                        <div class="table-responsive">
                            <p class="text-center not-found" *ngIf="docItems.length < 1 && !isLoading">
                                <i class="bi bi-file-earmark-text"></i>
                                {{ message }}
                            </p>
                            <table class="table table-sm table-bordered sortable datatable nowraptable" *ngIf="docItems.length > 0">
                                <thead>
                                <tr class="table-header">
                                        <th class="checkbox" *ngIf='activeTab === "yes"'>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="MasterCheckbox" [(ngModel)]="masterSelected" (change)="checkUncheckAll()">
                                                <label class="custom-control-label" for="MasterCheckbox"> </label>
                                            </div>
                                        </th>
                                        <th class="text-dark">S.No.</th>
                                        <th class="text-dark no-sort">Document</th>
                                        <!-- <th class="text-dark">Document Type</th> -->
                                        <th class="text-dark" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" || loginService.getCurrentUserRole().slug == "principal" || loginService.getCurrentUserRole().slug == "secreatry" || loginService.getCurrentUserRole().slug == "teacher" || loginService.getCurrentUserRole().slug == "parent"'>Student Name</th>
                                        <th class="text-dark" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>School</th>
                                        <th class="text-dark">Course</th>
                                        <th class="text-dark" *ngIf='loginService.getCurrentUserRole().slug !== "student"'>Grade</th>
                                        <th class="text-dark" *ngIf="(loginService.getCurrentUserRole().slug == 'student' || loginService.getCurrentUserRole().slug == 'parent' || loginService.getCurrentUserRole().slug == 'super-admin') && activeTab === 'yes'">Approved By</th>
                                        <th class="text-dark" *ngIf="loginService.getCurrentUserRole().slug == 'student' || loginService.getCurrentUserRole().slug == 'parent' || loginService.getCurrentUserRole().slug == 'super-admin'">Uploaded By</th>
                                        <th class="text-dark" *ngIf="(loginService.getCurrentUserRole().slug == 'student' || loginService.getCurrentUserRole().slug == 'parent' || loginService.getCurrentUserRole().slug == 'super-admin') && activeTab === 'yes'">Approved At</th>
                                        <th class="text-dark" *ngIf="loginService.getCurrentUserRole().slug == 'student' || loginService.getCurrentUserRole().slug == 'parent' || loginService.getCurrentUserRole().slug == 'super-admin'">Uploaded At</th>
                                        <!-- <th class="text-dark">School Year</th>                                 -->
                                        <th class="text-dark" *ngIf="activeTab === 'no'">Status</th>
                                        <!-- <th class="text-dark" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" || loginService.getCurrentUserRole().slug == "principal"'>Action</th> -->
                                        <th class="text-dark">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor ="let docItem of docItems; let index = index">
                                        <td class="checkbox" *ngIf='activeTab === "yes"'>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="list{{index}}" [(ngModel)]="docItem.isSelected" (change)="isAllSelected()">
                                                <label class="custom-control-label" for="list{{index}}"> </label>
                                            </div>
                                        </td>
                                        <td>{{(index + 1) + (currentPage - 1) * tableDataPerPage}}</td>
                                        <td>
                                            <button class="previewBtn"  (click)="openGallery(index, docItems)">  
                                                <img *ngIf='docItem.documentHash' [src]="(docItem.fileType === 'pdf') ? 'assets/images/pdf.png' : 'https://school.mypinata.cloud/ipfs/'+docItem.documentHash" alt="Image {{ index + 1 }}"  default="assets/images/doc-icon-sm.png" class="image-doc" height="60px" width="60px">
                                                <img *ngIf='!docItem.documentHash' [src]="(docItem.fileType === 'pdf') ? 'assets/images/pdf.png' : docItem.documentLocalPath" alt="Image {{ index + 1 }}"  default="assets/images/doc-icon-sm.png" class="image-doc" height="60px" width="60px">
                                            </button>
                                        </td>
                                        <!-- <td>{{docItem.documentTypeName}}</td> -->
                                        <td *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" || loginService.getCurrentUserRole().slug == "principal" || loginService.getCurrentUserRole().slug == "secreatry" || loginService.getCurrentUserRole().slug == "teacher" || loginService.getCurrentUserRole().slug == "parent"'>{{docItem.firstName}} {{docItem.middleName}} {{docItem.lastName}}</td>
                                        <td *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>{{docItem.orgName}}</td>
                                        <td>{{docItem.courseName}}</td>
                                        <td *ngIf='loginService.getCurrentUserRole().slug !== "student"'    >{{ docItem?.grade?.grade }}</td>
                                        <td *ngIf="(loginService.getCurrentUserRole().slug == 'student' || loginService.getCurrentUserRole().slug == 'parent' || loginService.getCurrentUserRole().slug == 'super-admin') && activeTab === 'yes'">{{ docItem?.approvedByName}}</td>
                                        <td *ngIf="loginService.getCurrentUserRole().slug == 'student' || loginService.getCurrentUserRole().slug == 'parent' || loginService.getCurrentUserRole().slug == 'super-admin'">{{ docItem?.uploadedByName}}</td>
                                        <td *ngIf="(loginService.getCurrentUserRole().slug == 'student' || loginService.getCurrentUserRole().slug == 'parent' || loginService.getCurrentUserRole().slug == 'super-admin') && activeTab === 'yes'">{{ docItem?.uploaded_on_block_chain}}</td>
                                        <td *ngIf="loginService.getCurrentUserRole().slug == 'student' || loginService.getCurrentUserRole().slug == 'parent' || loginService.getCurrentUserRole().slug == 'super-admin'">{{ docItem?.uploaded_at}}</td>
                                        <!-- <td>{{ docItem?.docYear }} </td> -->
                                        <td *ngIf="activeTab === 'no'">
                                            <ng-container *ngIf="docItem?.uploadedRole !== 14 && docItem?.uploadedRole !== 15 && docItem?.uploadedRole !== 7 && !docItem?.isRejected">
                                                <ng-container *ngIf="loginService.getCurrentUserRole().slug == 'student' || loginService.getCurrentUserRole().slug == 'parent'">
                                                    Pending for approval
                                                </ng-container>
                                                <ng-container *ngIf="loginService.getCurrentUserRole().slug !== 'student' && loginService.getCurrentUserRole().slug !== 'parent'">
                                                    Waiting for approval
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="docItem?.uploadedRole === 14 || docItem?.uploadedRole === 15 || docItem?.uploadedRole === 7">                                                
                                                <ng-container *ngIf="loginService.getCurrentUserRole().slug == 'student' || loginService.getCurrentUserRole().slug == 'parent'">
                                                    Waiting for approval
                                                </ng-container>
                                                <ng-container *ngIf="loginService.getCurrentUserRole().slug !== 'student' && loginService.getCurrentUserRole().slug !== 'parent'">
                                                    Pending for approval
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="docItem?.uploadedRole !== 14 && docItem?.uploadedRole !== 15 && docItem?.uploadedRole !== 7 && docItem?.isRejected">
                                                Rejected
                                            </ng-container>
                                        </td>
                                        <td>                                           
                                            <a href="#shareRole" data-toggle="modal" (click)="shareInit([docItem.id])" title="Share" class="btn btn-sm btn-success light app-tooltip" *ngIf="(docItem.isApproved === 1) && isArchived !== 'yes'">
                                                <i class="bi bi-share"></i>
                                            </a>
                                            <ng-container *ngIf="docItem?.uploadedRole !== 14 && docItem?.uploadedRole !== 15 && docItem?.uploadedRole !== 7 && !docItem?.isRejected; else newDeb" >
                                                <ng-container *ngIf='loginService.getCurrentUserRole().slug !== "parent" && loginService.getCurrentUserRole().slug !== "student"'>
                                                    <a href="#deletePopup" data-toggle="modal" title="Delete" (click)="setDelete(docItem.id)" class="btn btn-sm btn-danger light app-tooltip" *ngIf='(activeTab === "no" || activeTab === "") && isArchived === ""'>
                                                        <i class="bi bi-trash"></i>
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf='loginService.getCurrentUserRole().slug == "parent" || loginService.getCurrentUserRole().slug == "student"'>
                                                    <a (click)="rejData(docItem.id)" title="Reject" class="btn btn-sm btn-danger light app-tooltip" *ngIf='(activeTab === "no" || activeTab === "") && isArchived === ""'>
                                                        <i class="bi bi-x-lg"></i>
                                                    </a>
                                                    <a (click)="approvedForm(docItem.id, $event);" title="Approve" *ngIf='activeTab !== "yes" && isArchived !== "yes"' class="btn btn-sm btn-primary light app-tooltip parAppBtn">
                                                        <i class="bi bi-check-lg"></i>
                                                        <span class="spinner-border spinner-border-sm"></span>
                                                    </a> 
                                                </ng-container>
                                                
                                            </ng-container>
                                            <ng-template #newDeb>
                                                <ng-container *ngIf='(loginService.getCurrentUserRole().slug == "parent" || loginService.getCurrentUserRole().slug == "student") && !docItem?.isRejected'>
                                                    <a href="#deletePopup" data-toggle="modal" title="Delete" (click)="setDelete(docItem.id)" class="btn btn-sm btn-danger light app-tooltip" *ngIf='(activeTab === "no" || activeTab === "") && isArchived === ""'>
                                                        <i class="bi bi-trash"></i>
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf='(loginService.getCurrentUserRole().slug !== "parent" && loginService.getCurrentUserRole().slug !== "student")'>
                                                    <a href="#deletePopup" data-toggle="modal" title="Delete" (click)="setDelete(docItem.id)" class="btn btn-sm btn-danger light app-tooltip" *ngIf='(activeTab === "no" || activeTab === "") && isArchived === ""'>
                                                        <i class="bi bi-trash"></i>
                                                    </a>
                                                </ng-container>
                                                <!-- <a href="#deletePopup" data-toggle="modal" title="Delete" (click)="setDelete(docItem.id)" class="btn btn-sm btn-danger light app-tooltip" *ngIf='(activeTab === "no" || activeTab === "") && isArchived === ""'>
                                                    <i class="bi bi-trash"></i>
                                                </a> -->
                                                <a href="#approveModal"  (click)="getSingleData(docItem.id)"  *ngIf='(loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" || loginService.getCurrentUserRole().slug == "principal" || loginService.getCurrentUserRole().slug == "secreatry" || loginService.getCurrentUserRole().slug == "teacher") && activeTab !== "yes" && !docItem?.isRejected' data-toggle="modal" title="Approve" class="btn btn-sm btn-primary light app-tooltip">
                                                    <i class="bi bi-file-earmark-check-fill"></i>
                                                </a>
                                            </ng-template>
                                            
                                            <a title="Archive" (click)="archiveItem(docItem.id)" class="btn btn-sm btn-secondary light app-tooltip" *ngIf='activeTab === "yes" && (loginService.getCurrentUserRole().slug == "student" || loginService.getCurrentUserRole().slug == "parent")'>
                                                <i class="bi bi-archive-fill"></i>
                                            </a>
                                            <a title="Unarchive" (click)="unarchiveItem(docItem.id)" class="btn btn-sm btn-secondary light app-tooltip" *ngIf='isArchived === "yes" && (loginService.getCurrentUserRole().slug == "student" || loginService.getCurrentUserRole().slug == "parent")'>                                           
                                                <i class="bi bi-archive"></i>
                                            </a>
                                                                                   
                                        </td>
                                    </tr>                               
                                </tbody>
                            
                            </table>
                        </div>
                        <div class="table-footer" *ngIf="docItems.length > 0">
                            <div class="row">
                                <div class="col-md-auto col-12 mb-md-0 mb-3" *ngIf="checkedList?.length > 0">
                                    <button type="button" class="btn-add" href="#previewDocs" data-toggle="modal">
                                       Preview
                                    </button>
                                    <span class="divider-v">|</span>
                                    <button type="button" class="btn-unchecked" (click)="unCheckSelItem()">
                                        Unchecked
                                     </button>
                                </div>
                                <div class="col-md-auto col-12 align-self-center ml-auto">
                                    <div class="justify-content-md-end">
                                        <div class="text-md-right">
                                            <div class="dropdown-select">
                                                <!-- <div class="dataTables_length" id="sortable_length">
                                                    <label>
                                                        <span class="mb-0">Rows per page:</span>
                                                        <select name="sortable_length">
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                        </select>
                                                    </label>
                                                </div> -->
                                                <div>
                                                    <div class="dataTables_info" id="sortable_info" role="status" aria-live="polite">{{ tableDataFrom }} - {{ tableDataTo }} of
                                                        {{tableDataTotal}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-auto col-12" *ngIf="totalPages?.length > 1">
                                    <div class="pagination dataTables_paginate" id="sortable_paginate">
                                        <a class="paginate_button previous " [ngClass]="{'disabled': currentPage == 1}" (click)="changeCurrentPage(currentPage - 1)">
                                            <i class="bi bi-chevron-left"></i>
                                        </a>
                                        <span>
                                            <ng-container *ngFor="let page of totalPages; let i = index;">
                                                <a *ngIf="page !== '...'" (click)="changeCurrentPage(page)" [ngClass]="page == currentPage ? 'current paginate_button':'paginate_button'"   >{{ page }}</a>
                                                <span *ngIf="page === '...'"   class="paginate_button"   >{{ page }}</span>
                                            </ng-container>                                    
                                        </span>
                                        <a class="paginate_button next" [ngClass]="{'disabled': currentPage == totalPages.at(-1)}" (click)="changeCurrentPage(currentPage + 1)">
                                            <i class="bi bi-chevron-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    
                </div>
            </div>
        </div>
        <!-- content-body -->
    </div>

   

    <!-- delete popup start -->
    <div id="deletePopup" class="modal fade" aria-hidden="true">
        <div class="modal-dialog modal-confirm modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header flex-column">
                    <div class="icon-box">
                        <i class="bi bi-x"></i>
                    </div>						
                    <h4 class="modal-title w-100">Are you sure?</h4>	
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="bi bi-x"></i></button>
                </div>
                <div class="modal-body">
                    <p>Do you really want to delete these record?</p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-secondary" (click)="cancelDelete()" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" (click)="confirmDelete()">Delete</button>
                </div>
            </div>
        </div>
    </div>
    <!-- delete popup end -->
   
    <!-- document approve  start -->
    <div class="modal fade addEditRole" id="approveModal" tabindex="-1" role="dialog" aria-hidden="true">    
        <div class="modal-dialog modal-dialog-centered">
            <form class="w-100">
                <div class="modal-content">
                    <div class="modal-header modal-heading">Approve Certificate</div>                    
                    <div class="modal-body" >
                        <div class="apploader " [ngClass]="{'show': shareLoader}" *ngIf="shareLoader">
                            <div class="loader">
                                <div class="spinner-border" role="status"></div>
                                <span class="d-block mt-3">Loading...</span>
                            </div>
                        </div>
                        <table class="table table-bordered">
                            <tr *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident"'>
                                <th>Organization</th>
                                <td>{{ approveDocItem.orgName }}</td>
                            </tr>
                            <tr *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" || loginService.getCurrentUserRole().slug == "principal" || loginService.getCurrentUserRole().slug == "teacher" || loginService.getCurrentUserRole().slug == "parent"'>
                                <th>Student Name</th>
                                <td>{{ approveDocItem.firstName }} {{ approveDocItem.middleName }} {{ approveDocItem.lastName }}</td>
                            </tr>
                            <tr>
                                <th>Course</th>
                                <td>{{ approveDocItem.courseName }}</td>
                            </tr>
                            <tr *ngIf="approveDocItem.grade">
                                <th>Grade</th>
                                <td>{{ (approveDocItem.grade) ? approveDocItem.grade.grade : '' }}</td>
                            </tr>
                            <!-- <tr>
                                <th>Session</th>
                                <td>{{ approveDocItem.sessionYear }} - {{ approveDocItem.sessionName}}</td>
                            </tr> -->
                            <tr *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" || loginService.getCurrentUserRole().slug == "principal" || loginService.getCurrentUserRole().slug == "secreatry" || loginService.getCurrentUserRole().slug == "teacher"'>
                                <th>Action</th>
                                <td>
                                    <label class="checkbox-label checkbox-t d-block mb-2" for="approve">
                                        <input type="radio" name="docAction" [(ngModel)]="docAction" id="approve" value="1" (change)="changeDocAction($event)">
                                        <span class="checkbox-custom circular"></span>
                                        <span class="checkbox-text">Approve</span>
                                    </label>
                                    <!-- <label class="checkbox-label checkbox-t d-block mb-2" for="unapprove">
                                        <input type="radio" name="docAction" [(ngModel)]="docAction" id="unapprove" value="0" (change)="changeDocAction($event)"> 
                                        <span class="checkbox-custom circular"></span>
                                        <span class="checkbox-text">Unapprove</span>
                                    </label> -->
                                    <label class="checkbox-label checkbox-t d-block" for="forword" *ngIf='!(loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" )'>
                                        <input type="radio" name="docAction" [(ngModel)]="docAction" id="forword" value="2" (change)="changeDocAction($event)"> 
                                        <span class="checkbox-custom circular"></span>
                                        <span class="checkbox-text">Forword</span>
                                    </label>
                                </td>
                            </tr>
                            <tr *ngIf="isForward">
                                <th>Forward To</th>
                                <td>
                                    <select name="" class="form-control" (change)="selectUser($event)">
                                        <option value="">Select User</option>
                                        <option *ngFor="let forward of forwardToWhom;" [value]="forward.id">
                                            {{ forward.givenName }} {{ forward.middleName }} {{ forward.familyName }} - {{ (forward.role === '10') ? 'Principal' : ((forward.role === '13')? 'Secreatry' : 'Superintendent') }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <ng-container *ngIf="showShareErr">
                            <div class="alert alert-danger mb-0 mt-2" role="alert">
                                {{shareErrMsg[0]}}
                            </div>
                        </ng-container>
                    </div>
                    <div class="modal-footer">                          
                        <button type="reset" class="btn btn-secondary user-btn" data-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-success user-btn" (click)="docActionType($event, true, approveDocItem.id)" [disabled]="isapprove">Submit  <span class="spinner-border spinner-border-sm d-none ml-2" role="status" aria-hidden="true"></span></button>
                    </div>   
                                
                </div>
            </form>
        </div>
    </div>
    <!-- document approve end -->

    <!-- lightbox gallery start -->
    <div class="lg-container" [ngClass]="showLightBox ? 'lg-show' : ''" *ngIf="showLightBox">
        <div class="lg-backdrop"></div>
        <div class="lg-outer no-thumbnail" [ngClass]="loginService.getCurrentUserRole().slug === 'parent' || loginService.getCurrentUserRole().slug === 'student' ? 'bottom-text' : ''">
            <div class="lg-content">
                <div class="lg-inner">
                    <div class="lg-item">
                        <div class="d-flex justify-content-center previewLoader" *ngIf="previewLoading">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                        </div>                       
                        <div class="" [ngClass]="(previewSRCType === 'pdf') ? 'lg-pdf-wrap':'lg-img-wrap'">
                            <picture class="lg-img-wrap" *ngIf="previewSRCType !== 'pdf'"> 
                                <img class="lg-object lg-image image-lg-doc" [src]="imgSrc" default="assets/images/doc-icon-lg.png">
                            </picture>                             
                            <div class="embed-responsive embed-responsive-1by1" *ngIf="previewSRCType === 'pdf'">
                                <iframe [src]="imgSrc"  class="embed-responsive-item"></iframe>
                            </div>                    
                        </div>
                    </div>
                </div>

                <button type="button" class="lg-prev lg-icon" *ngIf="docItems.length >1" (click)="prevImage()">
                    <i class="bi bi-arrow-left"></i>
                </button>
                <button type="button" class="lg-next lg-icon" *ngIf="docItems.length >1" (click)="nextImage()">
                    <i class="bi bi-arrow-right"></i>
                </button>
            </div>
            <div class="lg-toolbar">
                <div class="lg-heading" [innerHTML]="commonSrv.getTitle() + ' Document Preview'">
                   
                </div>
                
                <button type="button" class="lg-close lg-icon" (click)="closeLightBox($event)">
                    <i class="bi bi-x"></i>
                </button>
                <div class="lg-counter">
                    <span class="lg-counter-current">{{ currentImageIndex + 1 }}</span> /
                    <span class="lg-counter-all">{{ currentDocLength }}</span>
                </div>
            </div>
            <div class="lg-sub-html">
                <div *ngIf='loginService.getCurrentUserRole().slug === "parent" || loginService.getCurrentUserRole().slug === "student"'> 
                    <h4> Document Type - <span> {{previewDocName}} </span> <ng-container *ngIf='loginService.getCurrentUserRole().slug !== "student"'>&nbsp;&nbsp;|&nbsp;&nbsp;Student Name - <span>{{previewStdName}} </span></ng-container> </h4>                   
                    <p> <ng-container *ngIf="previewCourse">Course - <span>{{previewCourse}} </span>&nbsp;&nbsp;</ng-container><ng-container *ngIf="previewGrade && loginService.getCurrentUserRole().slug !== 'student'">|&nbsp;&nbsp;Grade - <span>{{previewGrade}} </span>&nbsp;&nbsp;|&nbsp;&nbsp;</ng-container>
                        <!-- <ng-container *ngIf="previewSession">Session - <span>{{previewSession}} </span></ng-container> -->
                    </p>
                    <p> <ng-container *ngIf="previewComment">Comment - <span>{{previewComment}} </span></ng-container></p>
                </div>
                <form *ngIf='loginService.getCurrentUserRole().slug !== "parent" && loginService.getCurrentUserRole().slug !== "student"'>  
                    <div class="apploader black-bg" [ngClass]="{'show': shareLoader}" *ngIf="shareLoader">
                        <div class="loader">
                            <div class="spinner-border" role="status"></div>
                            <span class="d-block mt-3">Loading...</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Document Type:-</label>
                        <p><span>{{previewDocName}}</span></p>
                    </div>
                    <div class="form-group" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident"'>
                        <label>School:-</label>
                        <p><span>{{previewOrgName}}</span></p>
                    </div>
                    <div class="form-group" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" || loginService.getCurrentUserRole().slug == "principal" || loginService.getCurrentUserRole().slug == "secreatry" || loginService.getCurrentUserRole().slug == "teacher" || loginService.getCurrentUserRole().slug == "parent"'>
                        <label>Student Name:-</label>
                        <p><span>{{previewStdName}}</span></p>
                    </div>
                    <div class="form-group" *ngIf='previewGrade && loginService.getCurrentUserRole().slug !== "student"'>
                        <label>Grade:-</label>
                        <p><span>{{previewGrade}}</span></p>
                    </div>
                    <div class="form-group" *ngIf="previewCourse">
                        <label>Course:-</label>
                        <p><span>{{previewCourse}}</span></p>
                    </div>
                    <!-- <div class="form-group" *ngIf="previewSession">
                        <label>Session:-</label>
                        <p><span>{{previewSession}}</span></p>
                    </div> -->
                    <div class="form-group" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" || loginService.getCurrentUserRole().slug == "principal" || loginService.getCurrentUserRole().slug == "secreatry" || loginService.getCurrentUserRole().slug == "teacher"'>
                        <label>Action</label>
                        <div>                            
                            <select name="docAction" [(ngModel)]="docAction" class="form-control form-control-sm" (change)="changeDocAction($event)">
                                <option value="">Select User</option>
                                <option value="1">Approve</option>
                                <!-- <option value="0">Unapprove</option> -->
                                <option value="2" *ngIf='!(loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident")'>Forword</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="isForward">
                        <label>Forward To</label>
                        <select name="" class="form-control form-control-sm" (change)="selectUser($event)">
                            <option value="">Select User</option>
                            <option *ngFor="let forward of forwardToWhom;" [value]="forward.id">
                                {{ forward.givenName }} {{ forward.middleName }} {{ forward.familyName }} - {{ (forward.role === '10') ? 'Principal' : 'Superident' }}
                            </option>
                        </select>
                    </div>
                    <ng-container *ngIf="showShareErr">
                        <div class="alert alert-danger mb-0 mt-2" role="alert">
                            {{shareErrMsg[0]}}
                        </div>
                    </ng-container>
                    <div class="form-group" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" || loginService.getCurrentUserRole().slug == "principal" || loginService.getCurrentUserRole().slug == "secreatry" || loginService.getCurrentUserRole().slug == "teacher"'>
                        <button type="submit" class="btn btn-success user-btn" (click)="docActionType($event, false, this.previewId)" [disabled]="isapprove">Submit  <span class="spinner-border spinner-border-sm d-none ml-2" role="status" aria-hidden="true"></span></button>
                    </div>
                </form>
               
            </div>
            <!-- <div class="lg-thumb-outer">
                <div class="lg-thumb-item" *ngFor ="let docItem of docItems; let index = index" [ngClass]="currentImageIndex === index ? 'active':''">
                    <img [src]="(docItem.type === 'pdf') ? 'assets/images/pdf.png' : docItem.src" (click)="openGallery(index)" alt="Image {{ index + 1 }}">
                </div>
            </div> -->
        </div>
    </div>
    <!-- end lightbox gallery end -->



    <!-- preview selected doc -->

    <div class="modal fade previewDocs" id="previewDocs" tabindex="-1" role="dialog" aria-hidden="true">    
        <div class="modal-dialog  modal-dialog-centered">
            
                <div class="modal-content">
                    <div class="modal-header modal-heading">Preview Documents  <button type="btn" class="btn close preview-close"  data-dismiss="modal"><i class="bi bi-x-circle-fill"></i></button>    </div>
                                   
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-2 col-md-3 col-sm-4 col-6 text-center" *ngFor="let listItem of checkedList; let index = index">
                                <button class="previewBtn"  (click)="openGallery(index, checkedList)">
                                    <img [src]="(listItem.fileType === 'pdf') ? 'assets/images/pdf.png' : 'https://ipfs.io/ipfs/'+listItem.documentHash" default="assets/images/doc-icon-sm.png" class="image-doc" alt="Image {{ index + 1 }}" >                                    
                                    <p>{{ listItem.documentTypeName }}</p>
                                    
                                </button>
                                <a (click)="rePreVItem(listItem.id)" class="remove-item"><i class="bi bi-x-circle-fill"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">            
                                     
                        <button type="btn" class="btn btn-primary user-btn" (click)='showSharePopup()'>Share</button> 
                        <button type="btn" class="btn btn-secondary user-btn" data-dismiss="modal">Cancel</button>
                    </div>   
                                   
                </div>
          
        </div>
    </div>
    

     <!-- share start -->
     <div class="modal fade addEditRole" id="shareRole" tabindex="-1" role="dialog" aria-hidden="true">    
        <div class="modal-dialog modal-dialog-centered">
            <form class="w-100" method="post" (ngSubmit)="shareDoc()">
                <div class="modal-content">
                    <div class="modal-header modal-heading">Share Certificate</div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="share_email">
                                Email Address <sup class="text-danger">*</sup>
                            </label>
                            <div class="emails-input">
                                <ng-container *ngFor ="let mailID of mailIDs; let index = index" class="input-tag">
                                    <span role="email-chip" class="email-chip" *ngIf="mailID"><span class="content">{{mailID}}</span><a (click)="deleteEmil(index)" class="remove">×</a></span>
                                </ng-container>
                                <input [(ngModel)]="shareEmail" type="email" name="shareEmail" id="share_email" (keydown)="onKeyDown($event)" (blur)="onBlurEmail($event)" placeholder="add more email ...">
                            </div>
                            
                        </div>
                        <div class="form-group">
                            <label for="share_dd">
                                Expiry Date <sup class="text-danger">*</sup>
                            </label>
                            <input required  [(ngModel)]="shareExpiryDate" type="date" [attr.min]='minShareDate' name="shareExpiryDate" class="form-control" id="share_dd" placeholder="Enter Due Date">
                            
                        </div>
                        <div class="form-group mb-0">
                            <label for="share_comment">
                                Comments
                            </label>
                            <textarea [(ngModel)]="shareComment" name="shareComment" placeholder="Comments" id="share_comment" class="form-control"></textarea>
                        </div>
                        <ng-container *ngIf="showShareErr">
                            <div class="alert alert-danger mb-0 mt-2" role="alert">
                                {{shareErrMsg[0]}}
                            </div>
                        </ng-container>
                    </div>
                    <div class="modal-footer">                          
                        <button type="reset" class="btn btn-secondary user-btn" data-dismiss="modal" >Cancel</button>
                        <button type="submit"  [disabled]="sharing"  class="btn btn-success user-btn" id="shareDoc">
                            Share
                            <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="sharing"></span>                            
                        </button>
                    </div>                    
                </div>
            </form>
        </div>
    </div>
    <!-- end share -->

    <!-- share start -->
    <div class="modal fade addEditRole" id="rejectDoc" tabindex="-1" role="dialog" aria-hidden="true">    
        <div class="modal-dialog modal-dialog-centered">
            <form class="w-100" method="post" (ngSubmit)="rejectData()">
                <div class="modal-content">
                    <div class="modal-header modal-heading">Reject Document</div>
                    <div class="modal-body">
                        
                        <div class="form-group mb-0">                            
                            <textarea [(ngModel)]="rejectComment" name="rejectComment" placeholder="Comments" id="rejectComment" class="form-control" required></textarea>
                        </div>
                        <ng-container *ngIf="showShareErr">
                            <div class="alert alert-danger mb-0 mt-2" role="alert">
                                {{shareErrMsg[0]}}
                            </div>
                        </ng-container>
                    </div>
                    <div class="modal-footer">                          
                        <button type="reset" class="btn btn-secondary user-btn" data-dismiss="modal" >Cancel</button>
                        <button type="submit"  [disabled]="rejecting"  class="btn btn-success user-btn" id="shareDoc">
                            Reject
                            <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="rejecting"></span>                            
                        </button>
                    </div>                    
                </div>
            </form>
        </div>
    </div>
    <!-- end share -->