   <!-- main-wrapper -->
   <div id="main-wrapper">
    <!-- sidebar menu  -->
  <app-sidebar></app-sidebar>
    <!-- sidebar menu -->
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form" [ngClass]="{'loading position-relative': isLoading}">
                <div class="apploader" [ngClass]="{'show': isLoading}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Getting your data...</span>
                    </div>
                </div>
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Add {{currentPath}}</h5>
                        <a [routerLink]="['/home/', currentPath]"><i class="bi bi-chevron-left mr-1"></i>Back to list</a>
                    </div>
                </div>
                <form [formGroup]= "addStudentForm">
                    <div class="card-body">
                        <div class="row">                                
                            <div class="col-lg-9">
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="first_name" class="text-dark fw-500 label-text">Name<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="row name-row">
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <input type="text" formControlName="firstName" id="first_name" class="form-control"
                                                        placeholder="First Name">
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <input type="text" formControlName="middleName" id="middle_name" class="form-control"
                                                        placeholder="Middle Name">
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <input type="text" formControlName="lastName" id="last_name" class="form-control"
                                                        placeholder="Last Name">
                                                </div>
                                            </div>
                                        </div>
                                        
                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center" *ngIf="showDOB">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="dob" class="text-dark fw-500 label-text">Date of Birth :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="dob" id="dob"  (blur)="dob($event)" class="form-control birthdate" placeholder="Date of Birth" readonly >
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center" *ngIf="showGender">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="genderType" class="text-dark fw-500 label-text">Gender
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="checkbox-label checkbox-t " for="male">
                                                <input type="radio" formControlName="genderType" id="male" value = "Male">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">Male</span>
                                            </label>
                                            <label class="checkbox-label checkbox-t" for="female">
                                                <input type="radio" formControlName="genderType" id="female" value="Female">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">Female</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                


                               
                                
                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="usaInd" class="text-dark fw-500 label-text">American-Indian / Alaska-Native :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="usaInd1" formControlName = "native">
                                                <label class="custom-control-label" for="usaInd1"> </label>
                                            </div>
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="isAsian" class="text-dark fw-500 label-text">Is Asian :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="isAsian1" formControlName = "asian">
                                                <label class="custom-control-label" for="isAsian1"> </label>
                                            </div>
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="nativeHawaiin" class="text-dark fw-500 label-text">Native Hawaiian / Other Pacific Islander :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="nativeHawaiin1" formControlName="nativeHawaiin1" >
                                                <label class="custom-control-label" for="nativeHawaiin1"> </label>
                                            </div>
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="isWhite" class="text-dark fw-500 label-text">Is White :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="isWhite" formControlName="isWhite">
                                                <label class="custom-control-label" for="isWhite"> </label>
                                            </div>
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="demographic" class="text-dark fw-500 label-text">Demographic Race Two / More Races :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="demographic1" formControlName = "demographic">
                                                <label class="custom-control-label" for="demographic1"> </label>
                                            </div>
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="hispanic" class="text-dark fw-500 label-text">Hispanic / Latino Ethnicity :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="hispanic1" formControlName = "hispanic1">
                                                <label class="custom-control-label" for="hispanic1"> </label>
                                            </div>
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="countOfBCode" class="text-dark fw-500 label-text">Country Of Birth Code :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="countryCode" id="countOfBCode" class="form-control" placeholder="Country Of Birth Code">
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="phone_number" class="text-dark fw-500 label-text">Phone Number :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="tel" formControlName="number" id="phone_number" class="form-control"
                                                placeholder="Phone Number">
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="email_address" class="text-dark fw-500 label-text">Email Address<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="email" formControlName="email" id="email_address" class="form-control autoComplete"
                                                placeholder="Email Address">
                                        </div>                                           
                                    </div>
                                </div>
                                
                                
                               
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="password" class="text-dark fw-500 label-text">Password<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="position-relative">
                                                <input type="password" formControlName="password" id="password" class="form-control" placeholder="Password">
                                                <span class="show-password"><i class="icon bi bi-eye"></i></span>
                                            </div>
                                            <small id="passwordHelpBlock" class="form-text text-muted">
                                                Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.
                                            </small>
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="address" class="text-dark fw-500 label-text">Address</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input type="text" formControlName="address" id="address" class="form-control" placeholder="Address">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input type="text" formControlName="state" id="state" class="form-control" placeholder="State">

                                                    <!-- <select  id="" class="form-control" formControlName = "">
                                                        <option value="">Select State</option>
                                                        <option value=176>Alabama</option>
                                                        <option value=1061>Alaska</option>
                                                        <option value=181>Arizona</option>
                                                        <option value=101>Arkansas</option>
                                                    </select> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-row">
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    <input type="text" formControlName="city" id="city" class="form-control" placeholder="City">
                                                    <!-- <select name="" id="" class="form-control"><option value="">Select City</option></select> -->
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" formControlName = "zipCode" placeholder="Zip Code">
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <!-- <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="parent" class="text-dark fw-500 label-text">Parents :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" name="" id="parent" class="form-control"
                                                placeholder="Parents">
                                        </div>                                           
                                    </div>
                                </div> -->
                                
                                <div class="row align-items-md-center" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="school_name" class="text-dark fw-500 label-text">School<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': orgData?.length < 1}">
                                            <select class="form-control" formControlName="school" id="school_name" (change)="schoolChange($event.target.value)">
                                                <option value="">{{ defOpOrg }}</option>
                                                <option *ngFor="let data of orgData;" value="{{data.id}}">{{ data.name }}</option>
                                           </select>
                                           <div class="spinner-border spinner-border-sm"></div>
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center" *ngIf="showGrade">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="grade" class="text-dark fw-500 label-text">Grade<sup class="text-danger" *ngIf="currentPath === 'student' ">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': gradLoader}">
                                            <select id="grade" class="form-control" formControlName="grade" required>
                                                <option value="">{{ defOpGrade }}</option>
                                                <option *ngFor="let item of gradeItems" [value]="item.id">{{ item.grade }}</option>
                                            </select>
                                            <div class="spinner-border spinner-border-sm"></div>
                                        </div>                                           
                                    </div>
                                </div>
                                <!-- <ng-container [formGroup]="empForm">
                                    <div formArrayName="employees">
                                        
                                        <div *ngFor="let employee of employees().controls; let empIndex=index" >
                                            <div [formGroupName]="empIndex" >
                                                
                                                <div class="row align-items-md-center position-relative">
                                                    <div class="col-md-3">
                                                        <div class="form-group col-label">
                                                            <label for="course" class="text-dark fw-500 label-text">Course &amp; Class :</label>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <select class="form-control" formControlName="course" id="course" (change)="getClass($event.target.value)">
                                                                        <option value="">Select Course</option>
                                                                        <option *ngFor ="let data of courseList" value="{{data.id}}">{{data.title}}</option>
                                                                    </select>
                                                                </div> 
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <select class="form-control" formControlName="class" id="course">
                                                                        <option value="">Select Class</option>
                                                                        <option  *ngFor = "let data of classList" value="{{data.id}}">{{data.title}} </option>
                                                                    </select>
                                                                </div> 
                                                            </div>
                                                        </div>
                                                                                                 
                                                    </div>
                                                    <div class="col-md-1 remove-action" *ngIf="empIndex > 0">
                                                        <button (click)="removeEmployee(empIndex)" class="btn btn-danger remove-btn"><i class="bi bi-x-lg"></i></button>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                     
                                    </div>
                                </ng-container> -->
                            
                         <div class="row align-items-md-center" *ngIf="showClass">
                                <div class="col-md-3">
                                    <div class="form-group col-label">
                                        <label for="session" class="text-dark fw-500 label-text">Class<sup class="text-danger">*</sup> :</label>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group asyncData" [ngClass]="{'is-loading': courseLoader}">
                                        <ng-multiselect-dropdown
                                        [placeholder]="defOpClass"
                                        [settings]="dropdownSettings"
                                        [data]="classList"
                                        formControlName="course"
                                        [disabled]="classList?.length < 1"
                                      >
                                      </ng-multiselect-dropdown>
                                      <div class="spinner-border spinner-border-sm"></div>
                                    </div>                                           
                                </div>
                            </div> 
                            <div class="row align-items-md-center" *ngIf="showParent">
                                <div class="col-md-3">
                                    <div class="form-group col-label">
                                        <label for="parent" class="text-dark fw-500 label-text">Parents :</label>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group asyncData" [ngClass]="{'is-loading': parentLoader}">                                        
                                    <ng-multiselect-dropdown
                                        [placeholder]="defOpParent"
                                        [settings]="parentDropDownSettings"
                                        [data]="parentList"
                                        formControlName ="parents"
                                        [disabled]="parentList?.length < 1"
                                      >
                                      </ng-multiselect-dropdown>
                                      <div class="spinner-border spinner-border-sm"></div>
                                    </div>                                           
                                </div>
                            </div>
                                
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="statusType" class="text-dark fw-500 label-text">Status
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="checkbox-label checkbox-t " for="active">
                                                <input type="radio" formControlName="statusType" id="active" value="1" checked>
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">Active</span>
                                            </label>
                                            <label class="checkbox-label checkbox-t" for="inactive">
                                                <input type="radio" formControlName="statusType" id="inactive" value="0">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">In-Active</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <ng-container *ngIf="showError">
                            <div class="alert alert-danger" role="alert">
                                {{errorMessages[0]}}
                            </div>
                        </ng-container>
                    </div>
                    <div class="card-footer">
                        <div class="form-group mb-0">                         
                            <button type="submit" class="btn btn-success" (click)="addStudent($event)">Save 
                                <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isSubmit"></span>
                            </button>
                            <button type="reset" [routerLink]="['/home/teacher']" class="btn btn-secondary mr-3">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>
<!-- main-wrapper end -->
