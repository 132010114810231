import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { LoginServiceService } from './login-service.service';

@Injectable({
  providedIn: 'root'
})
export class ClassService {
  token: string;


  constructor(private http: HttpClient, private loginSrv:LoginServiceService) {

    this.token = localStorage.getItem('token')
  }

  getHeader = () =>{
    return {
      headers : new HttpHeaders({
        "Authorization" :'Bearer ' + this.loginSrv.getUserToken()
      })
    }
  }


  // create class api
  createClass(data) {
    return this.http.post(env.apiurl + 'classes', data, this.getHeader()).toPromise()
  }

  // view class api
  viewClass(page:number = 1,title= '', course= '', school= '') {

    return this.http.get(env.apiurl + 'classes?page='+page+'&title='+ title + '&course=' + course+ '&school=' + school, this.getHeader())
  }

  // delete class

  deleteClass(data) {
    return this.http.delete(env.apiurl + 'classes/' + data, this.getHeader())
  }

  // edit class api

  editClass(data) {
    return this.http.get(env.apiurl + 'classes/' + data + '/edit', this.getHeader())
  }
  // update class api

  updateClass(id, data) {
    return this.http.put(env.apiurl + 'classes/' + id, data, this.getHeader()).toPromise()
  }
  // search api
  getAllClassApi(title, course, school) {
    return this.http.get(env.apiurl + 'classes?' + title + '&' + course + '&' + school, this.getHeader())
  }
  // search api
  getClassId(course) {
    return this.http.get(env.apiurl + 'classes?' + course + '&', this.getHeader())
  }

  // get classes by organisation id
  getClassdetails(org) {
    return this.http.get(env.apiurl + 'classes?org=' + org, this.getHeader())
  }

  assignClassToUser(formData) {
    return this.http.post(env.apiurl + 'assign-class-to-user', formData, this.getHeader()).toPromise();
  }

  getAllClasses(org){
    return this.http.get(env.apiurl + 'classes?school=' + org+"&allrecords=yes", this.getHeader())
  }

}
