<div id="main-wrapper">
    <app-sidebar  [addLink]="currentAddLnk" [addLinkTxt]="currAddLnkTxt"></app-sidebar>
        <!-- content-body -->
        <div class="content-body">
            <div class="container-fluid">
                <div class="card-table mb-0 {{ (isLoading)? 'loading' : ''}}">
                    <div class="apploader {{ (isLoading)? 'show' : ''}}">
                        <div class="loader">
                            <div class="spinner-border" role="status"></div>
                            <span class="d-block mt-3">Loading...</span>
                        </div>
                    </div>
                    <div class="table-head">
                        <form class="row align-items-md-end">
                        <div class="col align-self-md-center">
                            <div class="form-inline span-5">
                                <div class="form-group mb-1" *ngIf="loginService.getCurrentUserRole().slug != 'principal' && loginService.getCurrentUserRole().slug != 'student' && loginService.getCurrentUserRole().slug != 'teacher'">
                                    <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">School : </label>
                                    <select class="form-input" name="currentSchool" [(ngModel)]="currentSchool" >
                                        <option value="">All</option>
                                        <option value="{{ school.id }}" *ngFor="let school of allSchools">{{ school.name }}</option>
                                   </select>                                                
                                </div>
                                <div class="form-group mb-1" >
                                    <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">Role : </label>
                                    <select class="form-input" name="currentRole" [(ngModel)]="currentRole">
                                        <option value="">All</option>
                                        <option value="{{ item.id }}" *ngFor="let item of allRoles">{{ item.title }}</option>
                                   </select>                                                
                                </div>

                                <div class="form-group mb-1" >
                                    <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">Name : </label>
                                    <input type="text" class="form-input" name="currentName" [(ngModel)]="currentName">                                               
                                </div>

                                <div class="form-group mb-1" >
                                    <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">Email : </label>
                                    <input type="text" class="form-input" name="currentEmail" [(ngModel)]="currentEmail">                                               
                                </div>
                                  
                                <div class="form-group mb-1">
                                    <label class="fw-500 text-muted small w-100 justify-content-start mb-1"> <br> </label>
                                    <a class="btn-add" (click)="filterUsers()">Filter</a> 
                                    <button class="btn-reset" type="reset" (click)="clearFilter()">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                    
                    <div class="table-responsive">
                        <p *ngIf="message && !isLoading" class="text-center not-found">
                            <i class="bi bi-cloud-upload"></i>
                               {{ message }}
                        </p>
                        <table class="table table-sm table-bordered sortable datatable nowraptable" *ngIf="!message && !isLoading">
                            
                            <thead>
                                <tr class="table-header">
                                    <th class="checkbox">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="MasterCheckbox"  [(ngModel)]="masterSelected" (change)="checkUncheckAll()">
                                            <label class="custom-control-label" for="MasterCheckbox"> </label>
                                        </div>
                                    </th>
                                    <th class="text-dark">Full Name</th>
                                    <th class="text-dark">Role Name</th>
                                    <th class="text-dark">Email</th>
                                    <th class="text-dark" *ngIf='loginService.getCurrentUserRole().slug === "super-admin" || loginService.getCurrentUserRole().slug === "superident"'>School</th>
                                    <th class="text-dark">Class</th>
                                    <th class="text-dark">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor ="let userData of userDatas; let index = index">
                                    <td class="checkbox">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="list{{index}}" [(ngModel)]="userData.isSelected" (change)="isAllSelected()">
                                            <label class="custom-control-label" for="list{{index}}"> </label>
                                        </div>
                                    </td>       
                                    <td>{{ userData?.givenName }} {{ userData?.middleName }} {{ userData?.familyName }}</td>
                                    <td>{{ userData?.roleName  }}</td>
                                    <td>{{ userData?.email  }}</td>
                                    <td *ngIf='loginService.getCurrentUserRole().slug === "super-admin" || loginService.getCurrentUserRole().slug === "superident"'>{{ userData?.schoolName  }}</td>
                                    <td>
                                        <ul *ngIf="userData?.course?.length > 0">
                                            <li *ngFor="let course of userData.course">{{ course.title }}</li>
                                        </ul>

                                        <span *ngIf="!userData.course || !userData.course.length || userData?.course?.length === 0">NA</span>
                                    </td>
                                    <td>
                                        <!-- <a class="btn btn-sm btn-success light" (click)="edit(userData.id)">
                                            <i class="bi bi-pencil-fill"></i>
                                        </a> -->
                                        
                                        <a *ngIf="userData?.role === '8'" class="btn btn-sm btn-success light" [routerLink]="['/home/teacher/edit']" [queryParams]="{ id: userData?.id, redirectTo: 'uploaded-user', schoolId: userData?.orgSourcedIds}"
                                        routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
                                            <i class="bi bi-pencil-fill"></i>
                                        </a>
                                        <a *ngIf="userData?.role === '7'" class="btn btn-sm btn-success light" [routerLink]="['/home/student/edit']" [queryParams]="{ id: userData?.id, redirectTo: 'uploaded-user', schoolId: userData?.orgSourcedIds}"
                                        routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
                                            <i class="bi bi-pencil-fill"></i>
                                        </a>
                                        <a *ngIf="userData?.role === '14' || userData?.role === '15'" class="btn btn-sm btn-success light" routerLink="/home/parent/edit/{{userData?.id}}" [queryParams]="{ redirectTo: 'uploaded-user', schoolId: userData?.orgSourcedIds}"
                                        routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
                                            <i class="bi bi-pencil-fill"></i>
                                        </a>
                                        <!-- <a href="#deletePopup" data-toggle="modal" class="btn btn-sm btn-danger light">
                                            <i class="bi bi-trash"></i>
                                        </a> -->
                                    </td>                            
                                </tr> 
                            </tbody>
                        </table>
                    </div>
                    <div class="table-footer" *ngIf="!message && !isLoading">
                        <div class="row">
                            <div class="col-md-auto col-12 mb-md-0 mb-3" *ngIf="checkedList?.length > 0">
                                <button type="button" class="btn-add" (click)="bulkEdit(checkedList)">
                                   Bulk Edit
                                </button>
                            </div>
                            <div class="col-md-auto col-12 align-self-center ml-auto">
                                <div class="justify-content-md-end">
                                    <div class="text-md-right">
                                        <div class="dropdown-select">
                                            <!-- <div class="dataTables_length" id="sortable_length">
                                                <label>
                                                    <span class="mb-0">Rows per page:</span>
                                                    <select name="sortable_length">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </label>
                                            </div> -->
                                            <div>
                                                <div class="dataTables_info" id="sortable_info" role="status" aria-live="polite">{{ tableDataFrom }} - {{ tableDataTo }} of
                                                    {{tableDataTotal}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <div class="pagination dataTables_paginate" id="sortable_paginate"  *ngIf="totalPages?.length > 1">
                                    <a class="paginate_button previous " [ngClass]="{'disabled': currentPage == 1}" (click)="changeCurrentPage(currentPage - 1)">
                                        <i class="bi bi-chevron-left"></i>
                                    </a>
                                    <span>
                                        <ng-container *ngFor="let page of totalPages; let i = index;">
                                            <a *ngIf="page !== '...'" (click)="changeCurrentPage(page)" [ngClass]="page == currentPage ? 'current paginate_button':'paginate_button'"   >{{ page }}</a>
                                            <span *ngIf="page === '...'"   class="paginate_button"   >{{ page }}</span>
                                        </ng-container>                                        
                                    </span>
                                    <a class="paginate_button next" [ngClass]="{'disabled': currentPage == totalPages.at(-1)}" (click)="changeCurrentPage(currentPage + 1)">
                                        <i class="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                    
                </div>
            </div>
        </div>
        <!-- content-body -->
    </div>

   


    <!-- edit user -->
    <div class="modal fade addEditRole" id="editpopup" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">  
        <div class="modal-dialog modal-dialog-centered" [ngClass]="isBulkActive ? 'modal-lg': ''"> 
            <form class="w-100" [formGroup]="editBulkUser">
                <div class="modal-content">
                    <div class="modal-header modal-heading align-items-md-center">
                       Bulk Edit
                    </div>                    
                    <div class="modal-body">
                        <div class="form-group" *ngIf='loginService.getCurrentUserRole().slug === "super-admin" || loginService.getCurrentUserRole().slug === "superident"'>
                            <label>Select School<sup class="text-danger">*</sup></label>
                            <select class="form-control" formControlName="schoolId" (change)="selectSchool($event.target.value)">
                                <option value="">Select School</option>
                                <option value="{{ school.id }}" *ngFor="let school of allSchools">{{ school.name }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Select Classes<sup class="text-danger">*</sup> </label>
                            <div class="form-group mb-0 asyncData" [ngClass]="{'is-loading': classLoader}">
                                <ng-multiselect-dropdown formControlName="class_ids" [disabled]="classData?.length < 1"  [placeholder]="defOpClass" [data]="classData" [settings]="dropdownSettings"></ng-multiselect-dropdown>
                                <div class="spinner-border spinner-border-sm no"></div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Role <sup class="text-danger">*</sup></label>
                            <select class="form-control" id="userRole" formControlName="userRole">
                                <option value="">Select Role</option>
                                <option *ngFor="let role of allRoles;" value="{{ role.id }}">{{ role.title }}</option>
                            </select>
                        </div>
                        
                        <ng-container *ngIf="showError">
                            <div class="alert alert-danger" role="alert">
                                {{errorMessages[0]}}
                            </div>
                        </ng-container>
                        
                    </div>
                    <div class="modal-footer">   
                        <button type="reset" class="btn btn-secondary user-btn" (click)="cancelBulkEdit()">Cancel</button>
                        <button type="submit" class="btn btn-success user-btn" (click)="updateBulkUser($event)">                            
                            Update
                            <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isSubmit"></span>
                        </button>              
                        
                    </div>
                </div>  
            </form>
        </div>
    </div>
    <!-- edit user -->

 

   
   


   