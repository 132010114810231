<div id="main-wrapper">
    <app-sidebar [addLink]="currentAddLnk" [addLinkTxt]="currAddLnkTxt" (exportEvent)="exportParent()" [exportText]="'Export Parent Credentials'"></app-sidebar>
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-table" [ngClass]="{'loading': isLoading}">
                <div class="apploader" [ngClass]="{'show': isLoading}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Loading...</span>
                    </div>
                </div>
                <div class="table-head">
                    <form class="row align-items-md-end">
                        <div class="col align-self-md-center">
                            <div class="form-inline span-5">                                    
                                <div class="form-group mb-1" *ngIf="loginService.getCurrentUserRole().slug != 'principal' && loginService.getCurrentUserRole().slug != 'student' && loginService.getCurrentUserRole().slug != 'teacher'">
                                    <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">School : </label>
                                    <select class="form-input" name="schoolId" [(ngModel)]="currentSchool" >
                                        <option value="" selected>All</option>
                                        <option value="{{ school.id }}" *ngFor="let school of orgData">{{ school.name }}</option>
                                   </select>                                                
                                </div> 
                                <div class="form-group mb-1" >
                                    <label for="name" class="fw-500 text-muted small w-100 justify-content-start mb-1">Name : </label>
                                    <input type="text" id="name" class="form-input" name="name" [(ngModel)]="currentName">                                            
                                </div>  
                                <div class="form-group mb-1" >
                                    <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">Email : </label>
                                    <input type="text"  class="form-input" name="email" [(ngModel)]="currentEmail">                                            
                                </div>                                                                
                            </div>
                        </div>
                        <div class="col-auto text-md-right">                                
                            <div class="addMore d-md-inline-block">
                                <button class="btn-add" type="submit" (click)="filterDocuments()">                                       
                                   Search
                                </button>
                                <button class="btn-reset" type="reset" (click)="clearFilter()">
                                    Clear
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="table-responsive">
                    <p class="text-center not-found" *ngIf="message && !isLoading">
                        <i class="bi bi-person-circle"></i>
                       {{ message }}
                    </p>
                    <table class="table table-sm table-bordered sortable datatable nowraptable" *ngIf="!message && !isLoading">
                        <thead>
                            <tr class="table-header">
                                <th class="text-dark">S.No.</th>
                                <th class="text-dark">Name</th>
                                <th class="text-dark">Email</th>
                                <th class="text-dark" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>School</th>
                                <th class="text-dark">Students</th>
                                <th class="text-dark">Status</th>
                                <th class="text-dark no-sort" *ngIf="deleteButton || editButton">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of parentLists; let i = index;">
                                <td>{{(i + 1) + (currentPage - 1) * tableDataPerPage}}</td>
                                <td>{{ item.givenName }} {{ item.middleName }} {{ item.familyName }}</td>
                                <td>{{ item.email }}</td>
                                <td *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>{{ item?.organisaitonName }}</td>
                                <td>
                                    <ng-container *ngIf="item.students?.length <= 2">
                                        <ul class="single">
                                            <li *ngIf="item.students[0]?.givenName">{{item.students[0]?.givenName }} {{ item.students[0]?.middleName }} {{ item.students[0]?.familyName }}</li>
                                            <li *ngIf="item.students[1]?.givenName">{{item.students[1]?.givenName }} {{ item.students[1]?.middleName }} {{ item.students[1]?.familyName }}</li>
                                        </ul>  
                                    </ng-container>
                                    <ng-container *ngIf="item.students?.length > 2">
                                        <ul class="single">
                                            <li *ngIf="item.students[0]?.givenName">{{item.students[0]?.givenName }} {{ item.students[0]?.middleName }} {{ item.students[0]?.familyName }}</li>
                                            <li *ngIf="item.students[1]?.givenName">{{item.students[1]?.givenName }} {{ item.students[1]?.middleName }} {{ item.students[1]?.familyName }}</li>                                            
                                            <!-- <li *ngFor ="let data1 of data.course">{{data1.title}}</li> -->                                            
                                        </ul>  
                                        <a tabindex="-1" class="fw-500 small text-primary" (click)="openPopup($event.target, item.students)" data-trigger="focus" title="Students">View More</a>
                                    </ng-container>
                                </td>
                                <td>
                                    <span class="badge light" [ngClass]="(item?.enabledUser === 1) ? 'badge-success':'badge-danger' ">{{ (item?.enabledUser === 1) ? 'Active':'In-Active' }}</span>
                                </td>
                                <td *ngIf="deleteButton || editButton">
                                    
                                    <a routerLink="/home/parent/edit/{{item.id}}" *ngIf="editButton" class="btn btn-sm btn-success light">
                                        <i class="bi bi-pencil-fill"></i>
                                    </a>
                                    <a (click)="deletePopup(item.id)" *ngIf="deleteButton" class="btn btn-sm btn-danger light">
                                        <i class="bi bi-trash"></i>
                                    </a>
                                </td>
                            </tr>                            
                        </tbody>                        
                    </table>
                </div>
                <div class="table-footer" *ngIf="!message && !isLoading">
                    <div class="row">
                        <div class="col-md-auto align-self-center ml-auto">
                            <div class="justify-content-md-end">
                               
                            </div>
                        </div>
                        <div class="col-md-auto col-12 align-self-center ml-auto">
                            <div class="justify-content-md-end">
                                <div class="text-md-right">
                                    <div class="dropdown-select">
                                        <!-- <div class="dataTables_length" id="sortable_length">
                                            <label>
                                                <span class="mb-0">Rows per page:</span>
                                                <select name="sortable_length">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </label>
                                        </div> -->
                                        <div>
                                            <div class="dataTables_info" id="sortable_info" role="status" aria-live="polite">{{ tableDataFrom }} - {{ tableDataTo }} of
                                                {{tableDataTotal}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-auto">                            
                            <div class="pagination dataTables_paginate" id="sortable_paginate" *ngIf="totalPages?.length > 1">
                                <a class="paginate_button previous" [ngClass]="1 == currentPage ? 'disabled':''"  (click)="changeCurrentPage(currentPage-1)">
                                    <i class="bi bi-chevron-left"></i>
                                </a>
                                <span>
                                    <ng-container *ngFor="let page of totalPages; let i = index;">
                                        <a *ngIf="page !== '...'" (click)="changeCurrentPage(page)" [ngClass]="page == currentPage ? 'current paginate_button':'paginate_button'"   >{{ page }}</a>
                                        <span *ngIf="page === '...'"   class="paginate_button"   >{{ page }}</span>
                                    </ng-container>                                    
                                </span>
                                <a class="paginate_button next" [ngClass]="lastPage <= currentPage ? 'disabled':''"  (click)="changeCurrentPage(currentPage+1)">
                                    <i class="bi bi-chevron-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>


<!-- delete popup start -->
<div id="deletePopup" class="modal fade" aria-hidden="true" style="display: none;" #delete>
    <div class="modal-dialog modal-confirm modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header flex-column">
                <div class="icon-box">
                    <i class="bi bi-x"></i>
                </div>						
                <h4 class="modal-title w-100">Are you sure?</h4>	
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="bi bi-x"></i></button>
            </div>
            <div class="modal-body">
                <p>Do you really want to delete these record?</p>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" (click)="deleteGrade()">Delete</button>
            </div>
        </div>
    </div>
</div>
<!-- delete popup end -->