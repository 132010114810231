<div id="main-wrapper">
    <app-sidebar></app-sidebar>
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form" [ngClass]="{'loading position-relative': isLoading}">
                <div class="apploader" [ngClass]="{'show': isLoading}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Getting your data...</span>
                    </div>
                </div>
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Add School</h5>
                        <a routerLink="/home/school"><i class="bi bi-chevron-left mr-1"></i>Back to list</a>
                    </div>
                </div>
                <form [formGroup]="addOrgForm" enctype='multipart/form-data'>
                    <div class="card-body">
                        <div class="row">                                
                            <div class="col-lg-9">
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="staff_name" class="text-dark fw-500 label-text">Name<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="name" id="staff_name" class="form-control"
                                                placeholder="Name">
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="staff_name" class="text-dark fw-500 label-text">Type<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <select id="" class="form-control" formControlName="type">
                                                <option value="">Select type</option>
                                                <option value="school">School </option>                                             
                                                <option value="local">Local </option>
                                                <option value="state">State</option>
                                                <option value="national">National</option>
                                            </select>                                            
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="district" class="text-dark fw-500 label-text">District<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': districtList?.length < 1}">
                                            <select id="district" class="form-control" formControlName="district">
                                                <option value="">{{defOpDistrict}}</option>
                                                <option *ngFor ="let district of districtList;"  value="{{district.id}}">{{ district.name}}</option>
                                            </select>
                                            <div class="spinner-border spinner-border-sm"></div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="sourcedId" class="text-dark fw-500 label-text">Source ID :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" id="sourcedId" formControlName="sourcedId" class="form-control" placeholder="Source ID">
                                            <small id="HelpBlock" class="form-text text-muted">
                                                Your source id must be 36 characters long, contain letters and numbers
                                            </small>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="address" class="text-dark fw-500 label-text">Address</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <textarea id="address" formControlName="address" class="form-control" placeholder="Address"></textarea>
                                                    <!-- <input type="text" name="" id="address" formControlName="address" class="form-control" placeholder="Address"> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" id="" formControlName="country" class="form-control" placeholder="Country">
                                                    <!-- <select name="" id="" formControlName="country" class="form-control">
                                                        <option value="">Select Country</option>
                                                        <option value=176>Alabama</option>
                                                        <option value=1061>Alaska</option>
                                                        <option value=181>Arizona</option>
                                                        <option value=101>Arkansas</option>
                                                    </select> -->
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" id="" formControlName="state" class="form-control" placeholder="State">
                                                    <!-- <select name="" id="" formControlName="stete" class="form-control">
                                                        <option value="">Select State</option>
                                                        <option value=176>Alabama</option>
                                                        <option value=1061>Alaska</option>
                                                        <option value=181>Arizona</option>
                                                        <option value=101>Arkansas</option>
                                                    </select> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-row">
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    <input type="text" id="" formControlName="city" class="form-control" placeholder="City">
                                                    
                                                    <!-- <select name="" id="" formControlName="city" class="form-control"><option value="">Select City</option></select> -->
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <input type="text" formControlName="zipcode" class="form-control" placeholder="Zip Code">
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="statusType" class="text-dark fw-500 label-text">Logo
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="" for="active">
                                                <input type="file" name="schoolLogo" id="logo" (change)="changeLogo($event)"/>
                                            </label>
                                            <img *ngIf="currentLogo" [src]="currentLogo" class="school-logo-preview" alt="">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="statusType" class="text-dark fw-500 label-text">Data Source
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="ui checkbox checkbox-text">
                                                <input type="checkbox" formControlName="one_roaster_upload" id="one_roaster_upload" [value]='1' class="hidden" checked>
                                                <label for="one_roaster_upload"><span>OneRoster</span></label> 
                                            </div>
                                            <div class="ui checkbox checkbox-text">
                                                <input type="checkbox" formControlName="upload_csv" id="upload_csv"  [value]='1' class="hidden" checked>
                                                <label for="upload_csv"><span>CSV Import</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="statusType" class="text-dark fw-500 label-text">Status
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="checkbox-label checkbox-t " for="active">
                                                <input type="radio" id="active" formControlName="status" value="1" checked>
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">Active</span>
                                            </label>
                                            <label class="checkbox-label checkbox-t" for="inactive">
                                                <input type="radio" id="inactive" formControlName="status" value="0">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">In-Active</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="showError">
                            <div class="alert alert-danger" role="alert">
                                {{ errorMessages[0] }}
                            </div>
                        </ng-container>
                    </div>
                    <div class="card-footer">
                        <div class="form-group mb-0">                           
                            <button type="submit" class="btn btn-success" (click)="createOrg($event)">Save
                                <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isSubmit"></span>
                            </button>
                            <button type="reset" class="btn btn-secondary mr-3" [routerLink]="['/home/school']">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>