<div id="main-wrapper">
    <app-sidebar></app-sidebar>
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form" [ngClass]="{'loading position-relative':isLoading}">
                <div class="apploader" [ngClass]="{'show':isLoading}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Getting your data...</span>
                    </div>
                </div>
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Upload Child Document</h5>
                        <a [routerLink]="['/home/mychild']"><i class="bi bi-chevron-left mr-1"></i>Back to list</a>
                    </div>
                </div>
                <form [formGroup]="createDocForm" (ngSubmit)="submit()" enctype='multipart/form-data'>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-9">

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="grade" class="text-dark fw-500 label-text">Grade :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': gradeLoad}">
                                            <select id="grade" class="form-control" formControlName="grade">
                                                <option value="">{{ defOpGrade }}</option>
                                                <option *ngFor="let item of gradeItems" [value]="item.id">{{ item.grade }}</option>
                                            </select>
                                            <div class="spinner-border spinner-border-sm"></div>
                                        </div>     
                                                                            
                                    </div>
                                </div>

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="class" class="text-dark fw-500 label-text">Course<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': courseLoad}">
                                           <select class="form-control" id="course" formControlName="course" (change)="oncourseChange($event.target.value)" >
                                               <option value="">{{ defOpCourse }}</option>
                                               <option *ngFor="let c of courseItems" value="{{c.id}}">{{c.title}}</option>                                                   
                                           </select>
                                           <div class="spinner-border spinner-border-sm"></div>
                                        </div>                                           
                                    </div>
                                </div>

                                <div class="row align-items-md-center" *ngIf="isExp">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="assignedTo" class="text-dark fw-500 label-text">Assigned To :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': assignedToLoading}">
                                           <select class="form-control" id="assignedTo"  (change)="onAssignChange($event.target.value)" formControlName="assignedTo" disabled>
                                               <option value="">{{ defOpassignedTo }}</option>
                                               <option *ngFor="let c of assignedTo" value="{{c.id}}">{{c.givenName}} {{c.middleName}} {{c.familyName}}</option>                                                   
                                           </select>
                                           <div class="spinner-border spinner-border-sm"></div>
                                        </div>                                           
                                    </div>
                                </div>

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="student_name" class="text-dark fw-500 label-text">Student Name<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': studLoad}">
                                            <select class="form-control"  formControlName="name" >
                                                <option value="">{{ defOpStd }}</option> 
                                                <option *ngFor="let i of childs;" value="{{i.id}}">{{ i.givenName }} {{ i.middleName }} {{ i.familyName }}</option>
                                           </select>
                                           <div class="spinner-border spinner-border-sm"></div>
                                        </div>                                           
                                    </div>
                                </div>

                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="session" class="text-dark fw-500 label-text">Academic Session<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': sessionData?.length < 1}">
                                            <select class="form-control"  formControlName="session" >
                                                <option value="">{{defOpSess}}</option>
                                                <option *ngFor="let i of sessionData;" value="{{i.id}}">{{ i.title }} - ({{ i.schoolYear }} - {{ i.type }})</option>
                                           </select>
                                           <div class="spinner-border spinner-border-sm"></div>
                                        </div>                                           
                                    </div>
                                </div>

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="year" class="text-dark fw-500 label-text">School Year :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                           <input type="text" class="form-control" formControlName="year" id="year" placeholder="YYYY">                                               
                                        </div>                                           
                                    </div>
                                </div>

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="doc_type" class="text-dark fw-500 label-text">Document Type<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': docTypes?.length < 1}">
                                           <select class="form-control" id="doc_type" formControlName="document_type">
                                                <option value="">{{defOpType}}</option>
                                               <option [value]="doc.id" *ngFor="let doc of docTypes">{{ doc.name }}</option>                                              
                                           </select>
                                           <div class="spinner-border spinner-border-sm" *ngIf="defOpType !== 'No Document Type Found'"></div>
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="doc" class="text-dark fw-500 label-text">Browse Document<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="custom-file">
                                                <input type="file"  class="custom-file-input" id="customFile" formControlName="certificate" (change)="onFileChange($event)">
                                                <label class="custom-file-label" for="customFile">Choose File</label>
                                              </div>
                                            
                                        </div>
                                       
                                       
                                    </div>
                                </div>      

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="comment" class="text-dark fw-500 label-text">Comment :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <textarea id="comment" placeholder="Comment" class="form-control" formControlName="comment"></textarea>
                                        </div>                                           
                                    </div>
                                </div>


                                
                            </div>
                        </div>
                        <ng-container *ngIf="showError">
                            <div class="alert alert-danger" role="alert" *ngFor="let data of errorMessages">
                                {{errorMessages[0]}}
                            </div>
                        </ng-container>
                    </div>
                    <div class="card-footer">
                        <div class="form-group mb-0">
                            <button type="submit" [disabled]="creating" class="btn btn-success">
                                Upload Document
                                <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="creating"></span>
                            </button>
                            <button type="reset" class="btn btn-secondary mr-3">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
