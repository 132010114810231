<div id="main-wrapper">
    <app-sidebar></app-sidebar>
        <!-- content-body -->
        <div class="content-body">
            <div class="container-fluid">
                <div class="card-v-group card-form" [ngClass]="{'loading position-relative': isLoading}">
                    <div class="apploader" [ngClass]="{'show': isLoading}">
                        <div class="loader">
                            <div class="spinner-border" role="status"></div>
                            <span class="d-block mt-3">Getting your data...</span>
                        </div>
                    </div>
                    <div class="card shadow-none">
                        <div class="card-header header-grid">
                            <h5 class="card-title text-dark">Add Course</h5>
                            <a routerLink="/home/course"><i class="bi bi-chevron-left mr-1"></i>Back to list</a>
                        </div>
                    </div>
                    <form [formGroup]= "addCourseForm">
                        <div class="card-body">
                            <div class="row">                                
                                <div class="col-lg-9">
                                    <div class="row align-items-md-center">
                                        <div class="col-md-3">
                                            <div class="form-group col-label">
                                                <label for="title" class="text-dark fw-500 label-text">Title<sup class="text-danger">*</sup> :</label>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <input type="text" formControlName="title" id="title" class="form-control" placeholder="Title">
                                            </div>                                           
                                        </div>
                                    </div>
                                    <div class="row align-items-md-center">
                                        <div class="col-md-3">
                                            <div class="form-group col-label">
                                                <label for="course_code" class="text-dark fw-500 label-text">Course Code<sup class="text-danger">*</sup> :</label>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <input type="text" formControlName="courseCode" id="course_code" class="form-control" placeholder="Course Code">
                                            </div>                                           
                                        </div>
                                    </div>
                                   


                                    
                                    <div class="row align-items-md-center" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>
                                        <div class="col-md-3">
                                            <div class="form-group col-label">
                                                <label for="organization" class="text-dark fw-500 label-text">School<sup class="text-danger">*</sup> :</label>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group asyncData" [ngClass]="{'is-loading': orgData?.length < 1}">
                                               <select class="form-control" formControlName="organization" id="organization" (change)="selectSchool($event.target.value)">
                                                    <option value="">{{ defOpOrg }}</option>
                                                    <option *ngFor="let i of orgData;" value="{{i.id}}">{{ i.name }}</option>
                                               </select>
                                               <div class="spinner-border spinner-border-sm"></div>
                                            </div>                                           
                                        </div>
                                    </div>

                                    <div class="row align-items-md-center">
                                        <div class="col-md-3">
                                            <div class="form-group col-label">
                                                <label for="grades" class="text-dark fw-500 label-text">Grade :</label>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group asyncData" [ngClass]="{'is-loading': gradLoader}">
                                                <select id="grade" class="form-control" formControlName="grades">
                                                    <option value="">{{ defOpGrade }}</option>
                                                    <option *ngFor="let item of gradeItems" [value]="item.id">{{ item.grade }}</option>
                                                </select>
                                                <div class="spinner-border spinner-border-sm"></div>
                                            </div>                                           
                                        </div>
                                    </div>
                                   
                                    <div class="row align-items-md-center">
                                        <div class="col-md-3">
                                            <div class="form-group col-label">
                                                <label for="statusType" class="text-dark fw-500 label-text">Status
                                                    :</label>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label class="checkbox-label checkbox-t " for="active">
                                                    <input type="radio" formControlName="active" id="active" value= "1" checked>
                                                    <span class="checkbox-custom circular"></span>
                                                    <span class="checkbox-text">Active</span>
                                                </label>
                                                <label class="checkbox-label checkbox-t" for="inactive">
                                                    <input type="radio" formControlName="active" id="inactive" value="0">
                                                    <span class="checkbox-custom circular"></span>
                                                    <span class="checkbox-text">In-Active</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                   
                                   

                                </div>
                            </div>
                            <ng-container *ngIf="showError">
                                <div class="alert alert-danger" role="alert">
                                    {{errorMessages[0]}}
                                </div>
                            </ng-container>
                        </div>
                        <div class="card-footer">
                            <div class="form-group mb-0">                               
                                <button type="submit" class="btn btn-success" (click)="addCourse($event)">Add Course
                                    <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isSubmit"></span>
                                </button>
                                <button type="reset" class="btn btn-secondary mr-3" routerLink = "/home/course">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- content-body -->
    </div>