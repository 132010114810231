import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { OrganisationService } from 'src/app/services/organisation.service'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { UserServiceService } from 'src/app/services/user-service.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-add-organisation',
  templateUrl: './add-organisation.component.html',
  styleUrls: ['./add-organisation.component.css']
})
export class AddOrganisationComponent implements OnInit {
  userPermissionData:any;
  viewPage:boolean = false;
  constructor(private xj: externalJs, private service: OrganisationService,  private userService: UserServiceService, private router: Router, private toastr: NotificationService) { 
      var localData = JSON.parse(localStorage.getItem('userPermission'))
      const a = localData?.modules;
      a.forEach(element => {
        if (element.module === "School") {
          this.userPermissionData = element?.permission;
        }
      });
      this.userPermissionData?.forEach(element => {
        if (element?.permission === 'add organisation') {
          this.viewPage = true;
        } 
      });
   }
  districtList: any [];
  showError:boolean = false;
  errorMessages:any = [];
  currentLogo:any;
  isSubmit: boolean = false;
  submitBtn:any;
  isLoading: boolean = false;
  defOpDistrict:string='Select District';
  currentDistrict:any = parseInt(localStorage.getItem('district_flag')) || '';
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    // this.getDistrict();
  }

  getDistrict(){
    this.isLoading = true;
    this.userService.fetchDistrict().subscribe((res:any) => {
      if(res.status){
        this.addOrgForm.controls['district'].enable();
        this.districtList = res['data'];
        this.defOpDistrict= 'Select District';
      } else{
        this.defOpDistrict= 'No District Found';
        this.districtList = [];        
      }
      this.isLoading = false;
    }, (err:any)=>{
        this.toastr.showError('Something went wrong. Please try later','');
        this.router.navigate(['/home/school']);
        this.isLoading = false;
    })
  }

  //add organisation form
  addOrgForm = new FormGroup({
    name: new FormControl('',),
    type: new FormControl('school',),
    district: new FormControl(this.currentDistrict),
    address: new FormControl('',),
    country: new FormControl('',),
    state: new FormControl('',),
    city: new FormControl('',),
    zipcode: new FormControl('',),
    status: new FormControl('1',),
    one_roaster_upload: new FormControl('1',),
    upload_csv: new FormControl('1',),
    logo: new FormControl(''),
    sourcedId: new FormControl(''),
  })

  // chnage logo
  changeLogo(event:any){
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); 
    reader.onload = (_event) => { 
      this.currentLogo = reader.result; 
    }
    this.addOrgForm.get('logo').setValue(event.target.files[0]);
  }

  // add organisation 
  createOrg(e:any) {
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    this.errorMessages = []
    this.showError = false;
    let r = (Math.random() + 1).toString(36).substring(7); //for generating random string
    const data = new FormData;
    data.append('name', this.addOrgForm.value.name);
    data.append('type', this.addOrgForm.value.type);
    data.append('identifier', r );
    data.append('district', this.addOrgForm.value.district);
    data.append('address', this.addOrgForm.value.address);
    data.append('country', this.addOrgForm.value.country);
    data.append('state', this.addOrgForm.value.state);
    data.append('city', this.addOrgForm.value.city);
    data.append('pinCode', this.addOrgForm.value.zipcode);
    if(this.addOrgForm.value.one_roaster_upload){
      data.append('one_roaster_upload','1');
    } else{
      data.append('one_roaster_upload', '0');
    }
    if(this.addOrgForm.value.upload_csv){
      data.append('upload_csv','1');
    } else{
      data.append('upload_csv', '0');     
    }   
    data.append('isActive', this.addOrgForm.value.status);
    data.append('parentSourcedId', '');
    data.append('logo', this.addOrgForm.value.logo);
    data.append('sourcedId', this.addOrgForm.value.sourcedId);
    this.service.createOrganisation(data).then(res => {
      if(res['status']){
          this.toastr.showSuccess("School added successfully !!")
          this.router.navigate(['/home/school']);
      } else{
          if(res['message']){
            this.errorMessages.push(res['message']);
          }else{
            this.errorMessages.push('Something went wrong. Please try later');
          }         
          this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }
}
