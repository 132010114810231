import { Component, OnInit } from '@angular/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { externalJs } from 'src/app/services/external-js.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CourseService } from 'src/app/services/course.service';
import { Router } from '@angular/router';
import { MasterService } from 'src/app/services/master.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-create-course',
  templateUrl: './create-course.component.html',
  styleUrls: ['./create-course.component.css']
})

export class CreateCourseComponent implements OnInit {
  isLoading:boolean=true;
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  orgData: any = [];
  showError = false;
  errorMessages = [];
  gradeItems:any = [];
  userPermissionData:any;
  viewPage:boolean = false;

  isSubmit: boolean = false;
  submitBtn:any;

  defOpOrg:any ='Select School';
  defOpGrade:any ='Select Grade';
  gradLoader:boolean= false;
  constructor(private xj: externalJs, private serviceOrganisation: OrganisationService, public loginService: LoginServiceService,
    private service: CourseService, private toastr: NotificationService, private router: Router, private masterService: MasterService,) {
      var localData = JSON.parse(localStorage.getItem('userPermission'))
      const a = localData?.modules;

      a.forEach(element => {
        if (element.module === "Course") {
          this.userPermissionData = element?.permission;
        }
      });

      this.userPermissionData?.forEach(element => {
        if (element?.permission === 'create course') {
          this.viewPage = true;
        } 
      });
    }


  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    if(this.schoolId){
      this.getGradeItems(this.schoolId);
    } else{
      this.getOrganization();
    }
  }

   // add course form
   addCourseForm = new FormGroup({
    title: new FormControl('',),
    courseCode: new FormControl('',),
    grades: new FormControl({value:'', disabled:true}),
    organization: new FormControl({value: this.schoolId, disabled:true}),
    active: new FormControl('1',)
  })

  // viewOrganisationApi()
  getOrganization() {
    this.serviceOrganisation.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg='Select School';
        this.addCourseForm.controls['organization'].enable();
      } else{
        this.orgData = [];
        this.defOpOrg='No School Found';
      }
      this.isLoading = false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/course']);
      this.isLoading = false;
    })
    
  }

  selectSchool(e:any){
    this.gradLoader =true;
    this.addCourseForm.controls['grades'].disable();
    this.getGradeItems(e);
  }

  getGradeItems(schoolId:any){
    this.masterService.allGradeList(schoolId).subscribe((res:any)=>{
      if(res.status){
        this.gradeItems =  res.data;
        this.defOpGrade='Select Grade';
        this.addCourseForm.controls['grades'].enable();
      } else{
        this.gradeItems = [];
        this.defOpGrade='No Grade Found';
      }
      this.gradLoader= false;
      this.isLoading = false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/course']);
      this.gradLoader= false;
      this.isLoading = false;
    })
  }


 


  // add course api
  addCourse(e:any) {
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    this.showError = false;
    this.errorMessages = [];
    const data = {
      title: this.addCourseForm.value.title,
      courseCode: this.addCourseForm.value.courseCode,
      grades: this.addCourseForm.value.grades,
      orgSourcedId: this.addCourseForm.value.organization || this.schoolId,
      isActive: this.addCourseForm.value.active
    }
    this.service.createCourse(data).then(res => {
      if (res['status']) {
        this.router.navigate(['/home/course'])
        this.toastr.showSuccess('Course created successfully')
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }         
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }
}
