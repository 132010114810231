import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env} from '../../environments/environment';
import { Injectable } from '@angular/core';
import { LoginServiceService } from './login-service.service';


@Injectable({
  providedIn: 'root'
})
export class BulkImportCsvService {

  constructor(private http: HttpClient, private loginSrv:LoginServiceService) { }

  getHeader = () =>{
    return {
      headers : new HttpHeaders({
        "Authorization" :'Bearer ' + this.loginSrv.getUserToken()
      })
    }
  }

  bulkUploadOrgCSV(formData: any) {
    return this.http.post(env.apiurl +'import/csv/org', formData, this.getHeader()).toPromise();
  }

  bulkUploadAcademicCSV(formData: any) {
    return this.http.post(env.apiurl +'import/csv/academic-session', formData, this.getHeader()).toPromise();
  }
  bulkUploadCourseCSV(formData: any) {
    return this.http.post(env.apiurl +'import/csv/courses', formData, this.getHeader()).toPromise();
  }
  bulkUploadClassCSV(formData: any) {
    return this.http.post(env.apiurl +'import/csv/classes', formData, this.getHeader()).toPromise();
  }
  bulkUploadUserCSV(formData: any) {
    return this.http.post(env.apiurl +'import/csv/user', formData, this.getHeader()).toPromise();
  } 
  bulkUploadEnrollCSV(formData: any) {
    return this.http.post(env.apiurl +'import/csv/enrollment', formData, this.getHeader()).toPromise();
  }
  bulkUploadDemographicCSV(formData: any) {
    return this.http.post(env.apiurl +'import/csv/demograpy', formData, this.getHeader()).toPromise();
  }

  importBulkUserCSV(formData: any){
    return this.http.post(env.apiurl +'import/csv/import-user-csv', formData, this.getHeader()).toPromise();
  }


  // getImportUser
  getUploadedUser(page:number = 1,schoolId, role='', name='', email=''){
    return this.http.get(env.apiurl + 'import/csv/uploaded-users?page='+page+'&schoolId='+schoolId+'&role='+role+'&name='+name+'&email='+email, this.getHeader());
  }

  // update import user csv
  updateUser(userId, formData){
    return this.http.put( env.apiurl + 'import/csv/update-user/'+ userId, formData, this.getHeader()).toPromise();
  }
  // fetch class by schoolid 

  getClassesFromSchoolId(schoolId){
    return this.http.get( env.apiurl + 'classes?org='+schoolId, this.getHeader());
  }
 // fetch class by schoolid 
  getAllClassesFromSchoolId(schoolId){
    return this.http.get( env.apiurl + 'classes?school='+schoolId+'&allrecords=yes', this.getHeader());
  }

  // 
  getFailedUser(page:number =1){
    return this.http.get(env.apiurl + 'user/having-empty-mail?page='+page, this.getHeader());
  }

  exportFailedUser(){
    return this.http.get(env.apiurl + 'user/export-empty-mail?export=yes', this.getHeader());
  }
}
