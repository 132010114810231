<div id="main-wrapper">
    <!--  -->
    <!-- sidebar menu  -->
 <app-sidebar></app-sidebar>
    <!-- sidebar menu -->
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form">
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Change Password</h5>
                    </div>
                </div>
                <form [formGroup]="formUpdatePassword">
               
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="currPwd" class="text-dark fw-500 label-text">Current Password<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">                                           
                                            <div class="position-relative">
                                                <input type="password" formControlName="currPwd" id="currPwd" class="form-control" placeholder="Current Password " [ngClass]="((m.currPwd.dirty || m.currPwd.touched) && m.currPwd.invalid) ? 'is-invalid' : ''" >
                                                <span class="show-password"><i class="icon bi bi-eye"></i></span>
                                            </div>
                                            <div *ngIf="(m.currPwd.dirty || m.currPwd.touched) && m.currPwd.invalid" class="invalid-feedback">
                                             
                                              <div *ngIf="m.currPwd.errors?.required">
                                                The current password Field is required
                                              </div>
                                              <div *ngIf="m.currPwd.errors?.minlength">
                                                Current password must be at least 8 characters long.
                                              </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="newPwd" class="text-dark fw-500 label-text">New Password<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="position-relative">
                                                <input type="password" formControlName="newPwd" id="newPwd" class="form-control" placeholder="New Password " [ngClass]="((m.newPwd.dirty || m.newPwd.touched) && m.newPwd.invalid) ? 'is-invalid' : ''">
                                                <span class="show-password"><i class="icon bi bi-eye"></i></span>
                                            </div>
                                            <small id="passwordHelpBlock" class="form-text text-muted">
                                                Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.
                                            </small>
                                           
                                            <div *ngIf="(m.newPwd.dirty || m.newPwd.touched) && m.newPwd.invalid" class="invalid-feedback">
                                                <div *ngIf="m.newPwd.errors?.required">
                                                    The new password Field is required
                                                  </div>
                                                  <div *ngIf="m.newPwd.errors?.minlength">
                                                    New password must be at least 8 characters long.
                                                  </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="conPwd" class="text-dark fw-500 label-text">Confirm Password<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="position-relative">
                                                <input type="password" formControlName="conPwd" id="conPwd" class="form-control" placeholder="Confirm Password" [ngClass]="((m.conPwd.dirty || m.conPwd.touched) && m.conPwd.invalid) ? 'is-invalid': ''">
                                                <span class="show-password"><i class="icon bi bi-eye"></i></span>
                                            </div>
                                            <div class="invalid-feedback" *ngIf="(m.conPwd.dirty || m.conPwd.touched) && m.conPwd.invalid">
                                                <div *ngIf="m.conPwd.errors?.required">
                                                    The confirm password Field is required
                                                  </div>
                                                  <div *ngIf="m.conPwd.errors?.minlength">
                                                    Confirm password must be at least 8 characters long.
                                                  </div>
                                                  <div *ngIf="m.conPwd.errors.confirmPasswordValidator">
                                                      New Passsword and Confirm Password didn't match. </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                      
                                
                            </div>
                        </div>
                        <ng-container *ngIf="showError">
                            <div class="alert alert-danger" role="alert">
                                {{ errorMessages[0] }}
                            </div>
                        </ng-container>
                    </div>
                    <div class="card-footer">
                        <div class="form-group mb-0">
                            <button type="submit" class="btn btn-success" (click)="updatePassword($event)">Update Password
                                <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isSubmit"></span>
                            </button>
                            <a class="btn btn-secondary mr-3"  routerLink="/home/dashboard" >Cancel</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>