<div id="main-wrapper">
    <app-sidebar  [addLink]="currentAddLnk" [addLinkTxt]="currAddLnkTxt"></app-sidebar>
        <!-- content-body -->
        <div class="content-body">
            <div class="container-fluid">
                <div class="card-table {{ (isLoading)? 'loading' : ''}}">
                    <div class="apploader {{ (isLoading)? 'show' : ''}}">
                        <div class="loader">
                            <div class="spinner-border" role="status"></div>
                            <span class="d-block mt-3">Loading...</span>
                        </div>
                    </div>
                    <div class="table-head">
                        <form class="row align-items-md-end">
                            <div class="col align-self-md-center">
                                <div class="form-inline span-5">                                    
                                    <div class="form-group mb-1">
                                        <label for="table_name" class="fw-500 text-muted small w-100 justify-content-start mb-1">School Name  </label>
                                        <input type="text" id="table_name" class="form-input" placeholder="Name"  name="currentName"  [(ngModel)]="currentName">                                                  
                                    </div>                                
                                   
                                   
                                    <!-- <div class="form-group mb-1" *ngIf='loginService.getCurrentUserRole().slug == "super-admin"'>
                                        <label for="table_dstrict" class="fw-500 text-muted small w-100 justify-content-start mb-1">School District </label>
                                        
                                        <select id="table_dstrict" [(ngModel)]="currentDistrict" name="currentDistrict"  class="form-input">
                                            <option value="">Select District</option>
                                            <option *ngFor="let item of districtList" [value]="item.id">{{item.name}}</option>
                                        </select>                                            
                                    </div>                                                                    -->
                                </div>
                            </div>
                            <div class="col-auto text-md-right">                                
                                <div class="addMore d-md-inline-block">
                                    <button class="btn-add" type="submit" (click)="filterData()">                                       
                                       Search
                                    </button>
                                    <button class="btn-reset" type="reset" (click)="clearFilter()">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>                    
                    <div class="table-responsive">
                        <p class="text-center not-found" *ngIf="message && !isLoading">
                            <i class="bi bi-building"></i>
                            {{ message }}
                        </p>
                        <table class="table table-sm table-bordered sortable datatable nowraptable" *ngIf="!message && !isLoading">
                            <thead>
                                <tr class="table-header">
                                    <th class="text-dark">S.No. </th>
                                    <th class="text-dark" >School Name </th>
                                    <th class="text-dark" *ngIf='loginService.getCurrentUserRole().slug == "super-admin"'>District</th>
                                    <th class="text-dark">City</th>
                                    <th class="text-dark">Type </th>
                                    
                                    <th class="text-dark">Status</th>
                                    <th class="text-dark no-sort"  *ngIf="(editButton === true) || (deleteButton === true)">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of orgData;index as i">
                                    <td>{{(i + 1) + (currentPage - 1) * tableDataPerPage}}</td>
                                    <td>{{data.name}}</td>
                                    <td *ngIf='loginService.getCurrentUserRole().slug == "super-admin"'>{{data.district}}</td>
                                    <td>{{data.city}}</td>
                                    <td>{{data.type}}</td>
                                   
                                    <!-- <td>{{data.status}}</td> -->
                                    <td><span class="badge light" [ngClass]="data.isActive === 1 ? 'badge-success':'badge-danger'">{{ data.isActive === 1 ? 'Acitve':'In-Active' }}</span></td>
                                    <td *ngIf="(editButton === true) || (deleteButton === true)">
                                    
                                        <a *ngIf="editButton === true" (click)="goToEditPage(data.id)" class="btn btn-sm btn-success light">
                                            <i class="bi bi-pencil-fill"></i>
                                        </a>
                                        <a  *ngIf="deleteButton === true" href="#deletePopup" (click)="deleteOrgId(data.id)" data-toggle="modal" class="btn btn-sm btn-danger light">
                                            <i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                        
                            </tbody>
                        
                        </table>
                    </div>
                    <div class="table-footer" *ngIf="!message && !isLoading">
                        <div class="row">
                            <div class="col-md-auto align-self-center ml-auto">
                                <div class="justify-content-md-end">
                                <div class="text-md-right">
                                    <div class="dropdown-select">
                                        <!-- <div class="dataTables_length" id="sortable_length">
                                                                            <label>
                                                                                <span class="mb-0">Rows per page:</span>
                                                                                <select name="sortable_length">
                                                                                    <option value="10">10</option>
                                                                                    <option value="25">25</option>
                                                                                    <option value="50">50</option>
                                                                                    <option value="100">100</option>
                                                                                </select>
                                                                            </label>
                                                                        </div> -->
                                        <div>
                                            <div class="dataTables_info" id="sortable_info" role="status" aria-live="polite">{{ tableDataFrom }} - {{
                                                tableDataTo }} of
                                                {{tableDataTotal}}</div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-auto col-12">
                                <div class="pagination dataTables_paginate" id="sortable_paginate" *ngIf="pageList?.length > 1">
                                    <a class="paginate_button previous" [ngClass]="1 == currentPage ? 'disabled':''"  (click)="changePage(currentPage-1)">
                                        <i class="bi bi-chevron-left"></i>
                                    </a>
                                    <span>
                                        <ng-container *ngFor="let page of pageList; let i = index;">
                                            <a *ngIf="page !== '...'" (click)="changePage(page)" [ngClass]="page == currentPage ? 'current paginate_button':'paginate_button'"   >{{ page }}</a>
                                            <span *ngIf="page === '...'"   class="paginate_button"   >{{ page }}</span>
                                        </ng-container>                                        
                                    </span>
                                    <a class="paginate_button next" [ngClass]="lastPage == currentPage ? 'disabled':''"  (click)="changePage(currentPage+1)">
                                        <i class="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- content-body -->
    </div>
    <!-- delete popup start -->
<div id="deletePopup" class="modal fade" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header flex-column">
                <div class="icon-box">
                    <i class="bi bi-x"></i>
                </div>						
                <h4 class="modal-title w-100">Are you sure?</h4>	
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="bi bi-x"></i></button>
            </div>
            <div class="modal-body">
                <p>Do you really want to delete these record?</p>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" id="roleDelete" data-dismiss="modal" (click)="deleteData()">Delete</button>
            </div>
        </div>
    </div>
</div>