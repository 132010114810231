import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ActivatedRoute, Router } from '@angular/router'
import { NotificationService } from 'src/app/services/notification.service';
import { UserServiceService } from 'src/app/services/user-service.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
@Component({
  selector: 'app-organisation-view',
  templateUrl: './organisation-view.component.html',
  styleUrls: ['./organisation-view.component.css']
})
export class OrganisationViewComponent implements OnInit {
  orgId: any;
  currentName = '';
  typeSearch = '';
  currentDistrict =''
  districtList:any=[];
  orgData: any = [];
  currentAddLnk :any;
  currAddLnkTxt: string = "Add School";
  userPermissionData: any;
  editButton: boolean = false;
  deleteButton: boolean = false;
  viewPage: boolean = false;
  message: string= '';
  currentPage:number = 1;
  lastPage:number = 1;
  pageList:any = [];

  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  tableDataPerPage: number = 20;


  constructor(private xj: externalJs,public loginService: LoginServiceService, private service: OrganisationService, private userService: UserServiceService, private router: Router, private route: ActivatedRoute,  private toastr: NotificationService) { 
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if(element.module === "School"){
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {
      if(element?.permission === 'Delete Organisation'){
       this.deleteButton = true;
      }else if(element?.permission === 'Edit Organisation'){
        this.editButton = true;
      }else if(element?.permission === 'add organisation'){
        this.currentAddLnk = '/home/add-school';  
      } else if(element?.permission === 'View Organisation'){
        this.viewPage = true;
      }
    });

    // params
    this.route.queryParamMap
      .subscribe((params: any) => {
        let paramsObj = { ...params.keys, ...params };

        if (paramsObj.params.page) {
          this.currentPage = paramsObj.params.page;
        }
        if (paramsObj.params.name) {
          this.currentName = paramsObj.params.name;
        } else {
          this.currentName = '';
        } 
        if (paramsObj.params.district) {
          this.currentDistrict = paramsObj.params.district;
        } else {
          this.currentDistrict = '';
        }
        this.initComp();
      });
  }
  isLoading = true;
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();      
    this.getDistrict();
  }

  initComp(){
    this.getOrgList();
  }
  // get district
  getDistrict(){
    this.userService.fetchAllDistrict().subscribe((res:any) => {
      if(res.status){
        this.districtList = res.data
      } else{
        this.districtList = [];
      }
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
    })
  }

  // get organisation api

  getOrgList() {
    this.isLoading = true;
    if(this.currentName == null){
      this.currentName='';
    }
    this.service.viewOrganisation(this.currentPage,this.currentName,this.typeSearch,this.currentDistrict).subscribe(res => {      
      if(res['status']){
        this.orgData = res['data'].data
        this.currentPage = res['data'].current_page;
        this.lastPage = res['data'].last_page;
        this.mapPageList( res['data'].last_page);
        this.tableDataFrom = res['data']['from'];
        this.tableDataTo = res['data']['to'];
        this.tableDataTotal = res['data']['total'];
        this.tableDataPerPage = res['data']['per_page'];
        this.message = '';
        this.isLoading = false;
      } else if(res['status_code'] === 404){
        this.message = 'No School Data Found';
        this.orgData = [];
        this.isLoading = false;
      } else{
        this.message= "Something went wrong. Please try later";
        this.orgData = [];
        this.isLoading = false;
      }    
      
    }, (err:any)=>{
      this.orgData = [];
      this.message= "Something went wrong. Please try later";
      this.isLoading = false;
     
    })
   
  }
      // for mapping page list
  mapPageList(lastPage){
    let pageListArr = [];
    if(lastPage < 8){
      for (let index = 1; index <= lastPage; index++) {
        pageListArr.push(index);
      }
    } else {
       for (let index = 1; index <= 2; index++) {
        pageListArr.push(index);
      }
      if(![1,2, lastPage-1, lastPage].includes(this.currentPage)){
        pageListArr.push('...');
         pageListArr.push(this.currentPage);
        pageListArr.push('...');
      }else{
         pageListArr.push('...');
      }
       for (let index = lastPage - 1; index <= lastPage; index++) {
        pageListArr.push(index);
      }
    }
    this.pageList = pageListArr;
  }
    // change page
  changePage(page){
    this.router.navigate(
    [],
    {
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge'
    });
  }
  // get delete data id
  deleteOrgId(id) {
    this.orgId = id
  }
  // delete organisation api
  deleteData() {
    this.service.deleteOrganisation(this.orgId).subscribe((res:any) => {
      if(res.status){
        this.initComp();
        this.toastr.showSuccess("School deleted successfully !!");      
      } else{
        this.toastr.showError('Unable to delete school', '');
        this.initComp();
      }      
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later', '');
      this.initComp();
    })
  }

  // go to edit page
  goToEditPage(id) {
    this.router.navigate(['/home/edit-school'], { queryParams: { id: id } })
  }
   
  // filter
  filterData() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: 1, name: this.currentName, district: this.currentDistrict },
        queryParamsHandling: 'merge'
      });
  }

  clearFilter() {
    this.currentName='';
    this.currentDistrict='';
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, name: '',  district: '' },
        queryParamsHandling: 'merge'
      });
    this.initComp();
  }
}
