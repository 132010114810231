import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { environment as env } from '../../../environments/environment';
import { externalJs } from 'src/app/services/external-js.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  errMsg: string;
  ptoken: string;
  sucsMsg: string;
  isLoading: boolean;
  submitBtn:any;

  constructor(private http: HttpClient, private service:LoginServiceService, private route: ActivatedRoute,
    private router: Router,private xj:externalJs) { }

  ngOnInit(): void {
    this.xj.extraInit();
    this.sucsMsg = '';
    this.ptoken = localStorage.getItem('res_email');
    if (this.ptoken == null || this.ptoken == undefined) {
      this.router.navigate(['/']);
    }

  }

  reResetPass(np, cp, e:any) {
    var email = localStorage.getItem('res_email');
    this.isLoading = true;
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.errMsg = '';
    this.sucsMsg= '';
    if (np == '' || cp == '') {
      this.errMsg = "Enter password and confirm password";
      this.isLoading = false;
      this.submitBtn.disabled = false;
    } else {
      if (np === cp) {
        this.http.post(env.apiurl + 'reset-password', {
          new_password: np,
          email: email,
          update_password: "1",
          verify_otp: "0",
          send_otp: "0",
         
        }).subscribe(rawData => {
          this.isLoading = false;
          localStorage.removeItem('res_email');
          if (rawData['status']) {
            this.errMsg = '';
            this.sucsMsg = rawData['message'];
            setTimeout(() => {                     
              this.router.navigate(['/login']);
              this.isLoading = false;
              this.submitBtn.disabled = false;
            }, 2000);
          } else {
            this.errMsg = rawData['message'];
            this.isLoading = false;
            this.submitBtn.disabled = false;
          }
          
        },(err:any)=>{
          this.errMsg='Something went wrong. Please try later';
          this.isLoading = false;
          this.submitBtn.disabled = false;
        });
      } else {
        this.errMsg = "Password does not match";
        this.isLoading = false;
        this.submitBtn.disabled = false;
      }
    }
  }

}
