<div id="main-wrapper">
    <app-sidebar></app-sidebar>
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form"  [ngClass]="{'loading position-relative': isLoading}">
                <div class="apploader" [ngClass]="{'show': isLoading}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Getting your data...</span>
                    </div>
                </div>
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Settings</h5>
                        <!-- <a routerLink="/user"><i class="bi bi-chevron-left mr-1"></i>Back to list</a> -->
                    </div>
                </div>
                <form [formGroup]="settingsForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="districtId" class="text-dark fw-500 label-text">District :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': districtList?.length < 1}">
                                            <select id="district" class="form-control" formControlName="district">
                                                <option value="">{{defOpDistrict}}</option>
                                                <option *ngFor ="let district of districtList;"  value="{{district.id}}">{{ district.name}}</option>
                                            </select>
                                            <div class="spinner-border spinner-border-sm"></div>
                                        </div>

                                    </div>
                                </div>

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="statusType" class="text-dark fw-500 label-text">Data Source
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="ui checkbox checkbox-text">
                                                <input type="checkbox" formControlName="one_roaster_upload" id="one_roaster_upload" [value]='1' class="hidden" checked>
                                                <label for="one_roaster_upload"><span>OneRoster</span></label> 
                                            </div>
                                            <div class="ui checkbox checkbox-text">
                                                <input type="checkbox" formControlName="upload_csv" id="upload_csv"  [value]='1' class="hidden" checked>
                                                <label for="upload_csv"><span>CSV Import</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-11">
                                        <legend class="legend">
                                            <span>FTP Settings:</span>                                    
                                        </legend>
                                    </div>
                                </div>
                                
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="host" class="text-dark fw-500 label-text">Host or IP<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text"  id="host" class="form-control" formControlName="host" placeholder="Host or IP">                                            
                                        </div>     
                                                                            
                                    </div>
                                </div>

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="username" class="text-dark fw-500 label-text">User Name<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text"  id="username" class="form-control" formControlName="username" placeholder="Username"> 
                                            
                                        </div>     
                                                                            
                                    </div>
                                </div>

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="password" class="text-dark fw-500 label-text">Password<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text"  id="password" class="form-control" formControlName="password" placeholder="Password"> 
                                            
                                        </div>     
                                                                            
                                    </div>
                                </div>

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="port" class="text-dark fw-500 label-text">Port<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text"  id="port" class="form-control" formControlName="port" placeholder="Port"> 
                                          
                                        </div>     
                                                                            
                                    </div>
                                </div>

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="root" class="text-dark fw-500 label-text">Root<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text"  id="root" class="form-control" formControlName="root" placeholder="Ex. public_html/foldername"> 
                                            
                                        </div>     
                                                                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="showError">
                            <div class="alert alert-danger" role="alert">
                                {{ errorMessages[0] }}
                            </div>
                        </ng-container>
                    </div>
                    <div class="card-footer">
                        <div class="form-group mb-0">                            
                            <button type="submit" class="btn btn-success" (click)="updateUser($event)">Update Settings
                                <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isSubmit"></span>
                            </button>
                            <button type="reset" class="btn btn-secondary mr-3" routerLink="/home/dashboard">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>