import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { AcademicService } from 'src/app/services/academic.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TeacherService } from 'src/app/services/teacher.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ClassService } from 'src/app/services/class.service';
import { CourseService } from 'src/app/services/course.service';
import { UserServiceService } from '../.././../services/user-service.service'

@Component({ 
  selector: 'app-add-teacher',
  templateUrl: './add-teacher.component.html',
  styleUrls: ['./add-teacher.component.css']
})
export class AddTeacherComponent implements OnInit {
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  selectedArr: any = [];
  roleId: any;
  selectedAcademic: any = [];
  roleList: any;
  checked: any;
  classList: any;
  empForm: FormGroup;
  courseList: any;
  classListdata:any;
  constructor(private xj: externalJs, private fb: FormBuilder, private courseService: CourseService, private serviceAcademic: AcademicService,
     private classService: ClassService,private usrService:UserServiceService,private router: Router, private toastr: ToastrService, private service: TeacherService, private serviceOrganisation: OrganisationService, public loginService: LoginServiceService) {
    this.empForm = this.fb.group({
      employees: this.fb.array([]),
    })
  }
  sessionData: any;
  orgData: any;
  showError = false;
  errorMessages = [];
  ngOnInit(): void {
    this.xj.extraInit();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'select_txt',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.xj.initAutoComplete("#school_name");
    this.xj.initAutoComplete("#session");
    this.viewSession();
    this.viewOrganisationApi();
    this.getClass();
    this.initialForm();
    this.getRoles();
  }


  employees(): FormArray {
    return this.empForm.get("employees") as FormArray
  }
  newEmployee(): FormGroup {
    return this.fb.group({
      course: '',
      class: '',
    })
  }
  addEmployee() {
    this.employees().push(this.newEmployee());
  }

  removeEmployee(empIndex: number) {
    this.employees().removeAt(empIndex);
  }

  initialForm(){
    this.addEmployee();
  }

   // get role list
   getRoles() {
    this.usrService.fetchRole().subscribe(res => {
      this.roleList = res['data']['data']
    })
  }

  // getting role id
  getRoleId(evt){
    this.roleId = evt
    if(this.roleId == '11' || this.roleId == '10'){
     
    }
  }


  // view session api
  viewSession() {
    this.serviceAcademic.viewSession().subscribe(res => {
      this.sessionData = res['data']['data'];
    })
  }

  // view organisationApi
  viewOrganisationApi() {
    this.serviceOrganisation.viewOrganisation().subscribe(res => {
      this.orgData = res['data']['data']
    })
  }
     // on select dropdown
     onItemSelect(item :any) {
      this.selectedArr.push(item);
    }
  
    // all select item
    onSelectAll(item :any){
      this.selectedArr.push(item);
      console.log(this.selectedArr)
    }
  // add student form
  addTeacherForm = new FormGroup({
    firstName: new FormControl('',),
    middleName: new FormControl('',),
    lastName: new FormControl('',),
    number: new FormControl('',),
    email: new FormControl('',),
    userName: new FormControl('',),
    password: new FormControl('',),
    state: new FormControl('',),
    city: new FormControl('',),
    zipCode: new FormControl('',),
    address: new FormControl('',),
    parents: new FormControl('',),
    school: new FormControl('',),
    course: new FormControl('',),
    class: new FormControl('',),
    startDate: new FormControl('',),
    endDate: new FormControl('',),
    statusType: new FormControl('1',),
    grade: new FormControl('',),

  })
   

  // add teacher api
  addTeacher() {
    this.showError = false;
    this.errorMessages = [];
    for(const data of this.selectedArr){
      this.selectedAcademic.push(data.id) 
    }
    var orgId = localStorage.getItem('orgIds')
    var classData = this.employees().getRawValue();
    const data = {
      username: this.addTeacherForm.value.userName,
      givenName: this.addTeacherForm.value.firstName,
      middleName: this.addTeacherForm.value.middleName,
      familyName: this.addTeacherForm.value.lastName,
      email: this.addTeacherForm.value.email,
      phone: this.addTeacherForm.value.number,
      grades: this.addTeacherForm.value.grade,
      password: this.addTeacherForm.value.password,
      enabledUser: this.addTeacherForm.value.statusType,
      schoolSourcedId:this.addTeacherForm.value.school ? this.addTeacherForm.value.school : orgId,
      sms:this.addTeacherForm.value.address,
      country: this.addTeacherForm.value.countryCode,
      state: this.addTeacherForm.value.state,
      city: this.addTeacherForm.value.city,
      pinCode: this.addTeacherForm.value.zipCode,
      birthdate: this.addTeacherForm.value.dob,
      courseSourcedId: this.selectedAcademic,
      parents: this.addTeacherForm.value.parents,
      // classSourcedId: this.addTeacherForm.value.class,
      // beginDate: this.addTeacherForm.value.startDate,
      // endDate: this.addTeacherForm.value.endDate,
      beginDate:'',
      endDate:'',
      // "agentSourcedIds": [1,2,3],
    }
    this.service.createTeacher(data).then(res => {
      if (res['status'] == true) {
        this.router.navigate(['/home/teacher'])
        this.toastr.success('Teacher created successfully')
      }
    }).catch((err: any) => {
      let errData = err.error.errors;
      for (let key in errData) {
        this.errorMessages.push(errData[key][0]);
      }
      this.showError = true;
    })
  }
  // get course list

  getCourseList() {
    this.courseService.viewCourse().subscribe(res => {
      this.courseList = res['data']['data']
    })
  }

  // get class list
  getClassList(id){
    this.classService.getClassId(id).subscribe(res => {
      this.classListdata = res['data']['data']
    })
  }


  // get class by course id
  getClass(page = 1) {
    this.classService.viewClass(page).subscribe(res => {
      this.classList = res['data']['data']
      for(let item in this.classList){
        this.classList[item]['select_txt'] = this.classList[item]['title'] + ' - (' + this.classList[item]['course'] + ')';
        // console.log(this.classList[item]['title'] + ' - (' + this.classList[item]['course'] + ')')
      }
    })
  }

}
