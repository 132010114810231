import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { externalJs } from 'src/app/services/external-js.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { TeacherService } from 'src/app/services/teacher.service';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-teacher-list',
  templateUrl: './teacher-list.component.html',
  styleUrls: ['./teacher-list.component.css']
})
export class TeacherListComponent implements OnInit {
  @ViewChild('delete') delete;
  teacherList: any = [];
  teacherId: any;
  titleSearch = '';
  gradeSearch = '';
  schoolSearch = ''
  isLoading = true;
  currentAddLnk: any;
  currAddLnkTxt: string = "Add Teacher";
  orgData: any;
  userPermissionData: any;
  editButton: boolean = false;
  viewPage: boolean = false;
  deleteButton: boolean = false;
  message: string='';
  lastPage: number = 1;

  // filter and paging
  currentSchool: any = parseInt(localStorage.getItem('orgIds')) || '';
  currentName: any;
  currentEmail: any;
  currentCourse: any
  currentPage: number = 1;
  totalPages: any = [];
  courseData: any = [];

  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  tableDataPerPage: number = 20;

  // 
  


  constructor(private courseSrv: CourseService, private route: ActivatedRoute, private xj: externalJs, private toaster: NotificationService,
    private router: Router, private service: TeacherService, private serviceOrganisation: OrganisationService, public loginService: LoginServiceService, private usrSer: UserServiceService) {
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if (element.module === "Teacher") {
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {
      if (element?.permission === 'Delete Teacher') {
        this.deleteButton = true;
      } else if (element?.permission === 'Update Teacher') {
        this.editButton = true;
      } else if (element?.permission === 'Add Teacher') {
        this.currentAddLnk = "/home/teacher/add";
      }else if(element?.permission === "View Teacher"){
        this.viewPage = true;        
    }
    });

    // params
    this.route.queryParamMap
      .subscribe((params: any) => {
        let paramsObj = { ...params.keys, ...params };

        if (paramsObj.params.page) {
          this.currentPage = paramsObj.params.page;
        }
        if (paramsObj.params.school) {
          this.currentSchool = paramsObj.params.school;
        }
        // console.log(this.currentSchool);
        if (paramsObj.params.course) {
          this.currentCourse = paramsObj.params.course;
        } else {
          this.currentCourse = '';
        } if (paramsObj.params.name) {
          this.currentName = paramsObj.params.name;
        } else {
          this.currentName = '';
        } if (paramsObj.params.email) {
          this.currentEmail = paramsObj.params.email;
        } else {
          this.currentEmail = '';
        }
        this.initComp();
      });
  }

  exportTeacher() {
    this.usrSer.exportCSV(8).then((res: any) => {
      if(res.data){
          let requiredData = [];
          res.data.forEach((usr: any) => {
            requiredData.push({ fname: usr.givenName, lname: usr.familyName, email: usr.email, pass: usr.password_view, school: usr.organisaitonName })
          });
          let csv = this.ConvertToCSV(requiredData);
          var uri = 'data:text/csv;charset=utf-8,' + escape(csv);
          var downloadLink = document.createElement("a");
          downloadLink.href = uri;
          downloadLink.download = "teacher.csv";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          this.toaster.showSuccess('Teacher data export successfully');
      } else{
        this.toaster.showError('Unable to export data','');
      }
    }).catch(err=>{
        this.toaster.showError('Something went wrong. Please try later','');
    })
  }

  // JSON to CSV Converter
  ConvertToCSV(objArray: any) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;

    var str = '';

    var pre = 'First Name, Last Name, Email, Password, School' + '\r\n';
    str += pre;

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','

        line += array[i][index];
      }

      str += line + '\r\n';
    }

    return str;
  }



  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
   }   
   this.getSchool();
   this.getCourse();
  }

  getSchool(){
    this.serviceOrganisation.allSchool().subscribe((res:any) => {    
      if(res.status){
        this.orgData = res.data
      } else{
        this.orgData = []
      }
    }, (err:any)=>{
      this.toaster.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
    })
  }
  initComp() {
    this.xj.extraInit();
    this.getTeacherList();
  }

  //  get teacher list
  getTeacherList() {
    this.isLoading = true;
    this.service.viewTeacher(this.currentPage, this.currentSchool, this.currentName, this.currentEmail, this.currentCourse).subscribe(res => {
      if(res['status']){
        this.teacherList = res['data'].data
        this.currentPage = res['data'].current_page;
        this.lastPage = res['data'].last_page;
        this.mapPages(res['data'].last_page);
        this.tableDataFrom = res['data']['from'];
        this.tableDataTo = res['data']['to'];
        this.tableDataTotal = res['data']['total'];
        this.tableDataPerPage = res['data']['per_page'];        
        this.message = '';
        this.isLoading = false;
      } else if(res['status_code'] === 404){
        this.message = 'No Teacher Data Found';        
        this.teacherList = [];
        this.isLoading = false;
      } else {
        this.message= "Something went wrong. Please try later";      
        this.teacherList = []; 
        this.isLoading = false;       
      }
    }, (err:any)=>{
        this.message= "Something went wrong. Please try later";
        this.isLoading = false; 
    })
  }

  // map pages
  mapPages(lastPage = 1) {
    let pageListArr = [];
    this.totalPages = [];
    if(lastPage <=1) return;
    if(lastPage <= 5){
      for(var i =1; i<= lastPage; i++){
          pageListArr.push(i);
      }
    }
    if(lastPage > 5){
      if(this.currentPage <=4){        
          for(var i =1; i<= 5; i++){
            pageListArr.push(i);
          }      
          pageListArr.push('...');
          pageListArr.push(lastPage);
      } else if(lastPage - this.currentPage <=3){
        pageListArr.push(1);
        pageListArr.push('...');
        for(var i =lastPage - 4; i<= lastPage; i++){
          pageListArr.push(i);
        } 
      }
      else {
        pageListArr.push(1);
        pageListArr.push('...');  

        for(var i = this.currentPage - 2; i<= this.currentPage + 2; i++){
          pageListArr.push(i);
        }

        pageListArr.push('...');
        pageListArr.push(lastPage);
      } 
    } 
    this.totalPages = pageListArr;

    // console.log(this.totalPages);
    // console.log(this.currentPage)
  }
  // change page
  // pagination
  changeCurrentPage(page: any) {
    if(page != '...'){
    this.currentPage = page;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: page },
        queryParamsHandling: 'merge'
      });
    }
  }

  // filter
  filterDocuments() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: 1, course: this.currentCourse, school: this.currentSchool, name: this.currentName, email: this.currentEmail },
        queryParamsHandling: 'merge'
      });
  }
  // on school change
  filterSchool(orgId){
    this.getCourse(orgId);
  }
  //getCourse
  getCourse(orgID= this.currentSchool) {
    this.courseData = [];
    this.courseSrv.getAllCoursebyOrgid(orgID).subscribe(res => {
      if (res['status']) {
        this.courseData = res['data'];
      }else{
        this.courseData = [];
      }
    },(err:any)=>{
      this.toaster.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
    })
  }



  // get teacher id
  getTeacherId(id) {
    this.teacherId = id
  }
  // delete teacher
  deleteTeacher() {
    this.service.deleteTeacher(this.teacherId).subscribe(res => {
      if (res['status']) {
        this.toaster.showSuccess('Teacher deleted successfully')
        this.initComp();
        this.xj.closeModalPopup("#deletePopup");
      } else{
        this.toaster.showError('Unable to delete teacher', '');
        this.initComp();
        this.xj.closeModalPopup("#deletePopup"); 
      }
    }, (err:any)=>{
      this.toaster.showError('Something went wrong. Please try later', '');
      this.initComp();
      this.xj.closeModalPopup("#deletePopup");   
    })
  }
  // go to edit page
  goToEditPage(id) {
    this.router.navigate(['/home/teacher/edit'], { queryParams: { id: id } })
  }

 

  clearFilter() {
      this.currentSchool = parseInt(localStorage.getItem('orgIds')) || '';
      this.currentName = '';
      this.currentEmail = '';
      this.currentCourse = '';
      this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, course: '', email: '', school: '', name:'' },
        queryParamsHandling: 'merge'
      });
      
  }

  openPopup(e, items){
    var html = '';
    html+="<ul class='pl-4'>";
    for(var item of items ){
       html += `<li>${item?.title}</li>`;
    }
    html+="</ul>";
    this.xj.openPopup(e, html);
    
  }
  
}
