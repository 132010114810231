import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from '../services/document.service';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {

  currentHash:any='';
  documentData:any = [];
  expired:any = false;
  isLoading: boolean = true;

  constructor(private route :ActivatedRoute, private docSrv:DocumentService, private sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    this.route.queryParamMap
    .subscribe((params:any) => {
      let paramsObj = { ...params.keys, ...params };
      
      if(paramsObj.params.hsh){
        this.currentHash = paramsObj.params.hsh;
      }
    }
    );
    this.initComp();
  }

  initComp(){
       this.docSrv.shareList(this.currentHash).then((res:any)=>{
        //  console.log(res)
         if(!res.status){
           this.expired=true;
           this.documentData = [];
           this.isLoading = false;
         } else {
          this.documentData = res.data;
          this.expired=false;
          this.isLoading = false;
         }
       }).catch(err=>{
         this.documentData = [];
         this.expired = true;
         this.isLoading = false;
       })
  }

  pdfSanitizer(url) : SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
