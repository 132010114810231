<div id="main-wrapper">
    <app-sidebar></app-sidebar>
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form"  [ngClass]="{'loading position-relative': isLoading}">
                <div class="apploader" [ngClass]="{'show': isLoading}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Getting your data...</span>
                    </div>
                </div>
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Add User</h5>
                        <a routerLink="/home/user"><i class="bi bi-chevron-left mr-1"></i>Back to list</a>
                    </div>
                </div>
                <form [formGroup]="addUserForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-9">                                
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="first_name" class="text-dark fw-500 label-text">Name<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">                                        
                                        <div class="row name-row">
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <input type="text" name="" formControlName="firstname" id="first_name" class="form-control"
                                                        placeholder="First Name">
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <input type="text" name="" formControlName="middleName" id="middle_name" class="form-control"
                                                        placeholder="Middle Name">
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <input type="text" name="" formControlName="lastName" id="last_name" class="form-control"
                                                        placeholder="Last Name">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="principal_name" class="text-dark fw-500 label-text">Email<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                        
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="email" formControlName="email" id="principal_name"
                                                class="form-control" placeholder="Email">                                               
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="principal_name" class="text-dark fw-500 label-text">Phone Number
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="phoneNumber" id="principal_name"
                                                class="form-control" placeholder="Phone Number">
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="principal_name" class="text-dark fw-500 label-text">Password<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <!-- <input type="password" formControlName="password" id="principal_name"
                                                class="form-control" placeholder="Password"> -->
                                            <div class="position-relative">
                                                <input type="password" formControlName="password" id="password" class="form-control" placeholder="Password">
                                                <span class="show-password"><i class="icon bi bi-eye"></i></span>
                                            </div>
                                            <small id="passwordHelpBlock" class="form-text text-muted">
                                                Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="staff_name" class="text-dark fw-500 label-text">Role<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': roleList?.length < 1}">
                                            <select name="" id="" class="form-control" formControlName="role" (change)="getRoleId($event.target.value)">
                                                <option value="">{{ defOpRole }}</option>
                                                <ng-container *ngFor = "let data of roleList">
                                                    <option value="{{data.id}}" *ngIf="data?.id !== 7 && data?.id !== 8 && data?.id !== 14" >{{data.roleName}}</option>
                                                </ng-container>
                                            </select>
                                            <div class="spinner-border spinner-border-sm"></div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center" *ngIf="orgHide && loginService.getCurrentUserRole().slug !== 'principal'">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="staff_name" class="text-dark fw-500 label-text">School<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': orgData?.length < 1}">
                                            <select name="" id="" class="form-control" formControlName="organisation" (change)="getOrgId($event.target.value)">
                                                <option value="">{{ defOpOrg }}</option>
                                                <option value="{{data.id}}"  *ngFor = "let data of orgData">{{data.name}}</option>
                                            </select>
                                            <div class="spinner-border spinner-border-sm"></div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center" *ngIf="disHide">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="staff_name" class="text-dark fw-500 label-text">District<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': roleList?.length < 1}">
                                            <select name="" id="" class="form-control" formControlName="district">
                                                <option value="">Select District</option>
                                                <option *ngFor = "let data of districtList" value="{{data.id}}">{{data.name}}</option>
                                            </select>
                                            <div class="spinner-border spinner-border-sm"></div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="statusType" class="text-dark fw-500 label-text">Status
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="checkbox-label checkbox-t " for="active">
                                                <input type="radio"  id="active" value="1" formControlName="status" checked>
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text" >Active</span>
                                            </label>
                                            <label class="checkbox-label checkbox-t" for="unactive">
                                                <input type="radio"  id="unactive" value="0" formControlName="status">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">In-Active</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="showError">
                            <div class="alert alert-danger" role="alert" >
                                {{errorMessages[0]}}
                            </div>
                        </ng-container>
                    </div>
                    <div class="card-footer">
                        <div class="form-group mb-0">                            
                            <button type="submit" class="btn btn-success" (click)="addUser($event)">
                                Save 
                                <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isSubmit"></span>                                
                            </button>
                            <button type="reset" class="btn btn-secondary mr-3" routerLink="/home/user">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>