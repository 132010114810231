import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { MasterService } from 'src/app/services/master.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-add-grade',
  templateUrl: './add-grade.component.html',
  styleUrls: ['./add-grade.component.css']
})
export class AddGradeComponent implements OnInit {

  editId: number;
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  orgData: any = [];
  gradeForm: any;
  showError:boolean = false;
  errorMessages:any = [];
  isSubmit: boolean = false;
  submitBtn: any;
  userPermissionData:any;
  viewPage:boolean = false;
  isLoading: boolean = true;
  defOpOrg:any= 'Select School';
  constructor(private xj:externalJs,  private FB: FormBuilder, private route:ActivatedRoute, private router:  Router,  private serSchool:  OrganisationService, public loginService: LoginServiceService, private masterService: MasterService, private toastr: NotificationService,) { 
    this.editId = parseInt(this.route.snapshot.params.id);
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if (element.module === "Grade Management") {
        this.userPermissionData = element?.permission;
      }
    });
    if(this.editId){
      this.userPermissionData?.forEach(element => {
        if(element?.permission === 'Update Grade'){
          this.viewPage = true;
        }
      });
    } else{
      this.userPermissionData?.forEach(element => {
        if(element?.permission === 'Add Grade'){
          this.viewPage = true;
        }
      });
    }
  }
  
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    // console.log("edit Id=>"+this.editId);
   
    this.gradeForm = this.FB.group({
      school: [{value:this.schoolId, disabled: true}],
      name: [''],
      
    })
    this.getSchool();
    if(this.editId){
      this.getGradeItem(this.editId);
    }
  }

  getSchool(){
    this.isLoading=true;
    this.serSchool.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg= 'Select School';
        this.gradeForm.controls['school'].enable();
      } else{
        this.orgData = [];
        this.defOpOrg= 'No School Found';
      }
      this.isLoading = false;
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/master/grade-list']);
      this.isLoading = false;
    })
  }

  getGradeItem(id:any){
    this.isLoading = true;
    this.masterService.viewGradeItem(id).subscribe((res:any)=> {
      if(res.status){
        this.gradeForm.patchValue({
          school: res.data.school_id,
          name: res.data.grade
        })
        this.isLoading = false;
      } else{
        this.router.navigate(['/home/master/grade-list']);
        this.toastr.showError('Something went wrong. Please try later','');        
        this.isLoading = false;
      } 
    }, (err:any)=>{     
      this.router.navigate(['/home/master/grade-list']);         
      this.toastr.showError('Something went wrong. Please try later','');
      this.isLoading = false; 
      
    })

  }


  submitForm(e:any){
    this.isSubmit = true;
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.showError = false;
    this.errorMessages = [];
    if(this.editId){
      this.updateGrade()
    } else{
      this.addGrade();
    }
  }


  addGrade(){
    const formData = {
      school_id: this.gradeForm.value.school,
      grade: this.gradeForm.value.name
    }
    this.masterService.addGrade(formData).then((res: any)=> {
      if(res.status){
        this.router.navigate(['home/master/grade-list']);
        this.toastr.showSuccess(res.message);       
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }   
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any) =>{
      let errData = err.error.errors;
      for (let key in errData) {
        this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }

  updateGrade(){
    const formData = {
      school_id: this.gradeForm.value.school,
      grade: this.gradeForm.value.name
    }
    this.masterService.editGrade(this.editId, formData).then((res: any)=> {
      if(res.status){
        this.router.navigate(['home/master/grade-list'])
        this.toastr.showSuccess(res.data);
        this.isSubmit = false;
        this.submitBtn.disabled = false;
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }   
        this.showError = true;
      }
    }).catch((err:any) =>{
      let errData = err.error.errors;
      for (let key in errData) {
        this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }


  

}
