import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { UserServiceService } from 'src/app/services/user-service.service';
import { CommonService } from 'src/app/services/common.service';
import { LoginServiceService } from 'src/app/services/login-service.service';

import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { trim } from 'jquery';
interface IsActiveMatchOptions {
  matrixParams: 'exact' | 'subset' | 'ignored'
  queryParams: 'exact' | 'subset' | 'ignored'
  paths: 'exact' | 'subset'
  fragment: 'exact' | 'ignored'
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
  @Input() addLink:string = '';
  @Input() addLinkTxt: string = '';

  @Input() exportText:any;
  @Output() exportEvent = new EventEmitter<string>();

  isMMenuOpen: boolean = false;

  isAprrove:any;

  public config: PerfectScrollbarConfigInterface = {};
  userLogout:boolean= false;
  userProfileModule: string;
  roleId: number;
  documentModule: string;
  userModule: string;
  rolesModule: string;
  organisationModule: string;
  studentModule: string;
  teacherModule: string;
  classModule: string;
  courseModule: string;
  academicSesModule: string;
  bulkUploadModule: string;
  gradeModule: string;
  docTypeModule: string;
  parentModule: string;
  ftpModule: string;
  failedUser:string;
  userName: string;
  firstName: string;
  lastName: string;
  PageTitle: any;

  UserRoleName: any = JSON.parse(localStorage.getItem('userPermission'))?.roleName;
  orgId = parseInt(localStorage.getItem('orgIds'));
  isParents:boolean = false;

  

  constructor(private titleService:Title,public sanitizer:DomSanitizer, public commonSrv: CommonService,private xj:externalJs ,private router:Router, private title: Title, private activatedRoute: ActivatedRoute, private service: UserServiceService, public loginService: LoginServiceService,) { 
    this.userName = localStorage.getItem('username') || '',
    this.firstName = (localStorage.getItem('firstname') != 'null') ? localStorage.getItem('firstname') :'',
    this.lastName = (localStorage.getItem('lastname') != 'null') ? localStorage.getItem('lastname') :'',
    this.roleId = parseInt(localStorage.getItem('roleid'));
    this.userProfileModule = localStorage.getItem('Profile');
    this.documentModule = localStorage.getItem('Document');
    this.userModule = localStorage.getItem('User Management');
    this.rolesModule = localStorage.getItem('Role Management');
    // this.organisationModule = localStorage.getItem('Organisations');
    this.organisationModule = localStorage.getItem('School');
    this.studentModule = localStorage.getItem('Students');
    this.teacherModule = localStorage.getItem('Teacher');
    this.classModule = localStorage.getItem('Classes');
    this.courseModule = localStorage.getItem('Course');
    this.academicSesModule = localStorage.getItem('academic session');
    this.bulkUploadModule = localStorage.getItem('Bulk Upload');
    this.gradeModule = localStorage.getItem('Grade Management');
    this.docTypeModule = localStorage.getItem('DocType Management');
    this.parentModule = localStorage.getItem('Parents');
    this.ftpModule = localStorage.getItem('FTP');
    this.failedUser = localStorage.getItem('Failed User');
    
    let permission = JSON.parse(localStorage.getItem('userPermission'));
    if(permission?.isParents){
      this.isParents = permission?.isParents
    } else{
      this.isParents = false;
    }

    const defaultPageTitle = 'Clinton Co. R-III School';  
   
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
  
        if (!child) {
          return this.activatedRoute.snapshot.data.title || defaultPageTitle;
        }
  
        while (child.firstChild) {
          child = child.firstChild;
        }
  
        if (child.snapshot.data.title) {
          return child.snapshot.data.title || defaultPageTitle;
        }
      })
    ).subscribe((title: string) => {
      //this.title.setTitle(title); 
      if(title === 'Document' && (this.loginService.getCurrentUserRole().slug  === 'student' || this.loginService.getCurrentUserRole().slug  === 'parent')){
        this.PageTitle = 'Dashboard';
      } else{
        this.PageTitle = title;
      }
      
    } );
    
  }

  export(): void {
    this.exportEvent.next('export');
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
 
  bulkPermission(e:any){
    let role = this.loginService.getCurrentUserRole().slug;
    let permission = JSON.parse(localStorage.getItem('userPermission'));
    if(this.bulkUploadModule){
      let arr = JSON.parse(this.bulkUploadModule);    
      let arr1 =  arr?.some(function(hero) {   
        if(e === 'One roaster csv file import'){
          if(role == "principal" || role == "secreatry"){
            if(permission?.one_roaster_upload === 1){
              return hero.permission === e;
            } else{
              return false;
            }
          } 
        }
        if(e === 'Bulk user upload'){
          if(role == "principal" || role == "secreatry"){
            if(permission?.upload_csv === 1){
              return hero.permission === e;
            } else{
              return false;
            }
          } 
        }
        return hero.permission === e;
      });
      return arr1;    
    }
  }

  AvatarImage(){
    let canvas = document.createElement('canvas');
    let context = canvas.getContext("2d");
    let size = 46;
    // let color =  "#" + (Math.random() * 0xFFFFFF << 0).toString(16);
    let colors = ["#1abc9c", "#16a085", "#f1c40f", "#f39c12", "#2ecc71", "#27ae60", "#e67e22", "#d35400", "#3498db", "#2980b9", "#e74c3c", "#c0392b", "#9b59b6", "#8e44ad", "#bdc3c7", "#34495e", "#2c3e50", "#95a5a6", "#7f8c8d", "#ec87bf", "#d870ad", "#f69785", "#9ba37e", "#b49255", "#b49255", "#a94136"];
    // Set canvas with & height
    canvas.width = size;
    canvas.height = size;
    context.font = "700 "+Math.round(canvas.width / 2) + "px Lato, Helvetica Neue, Arial,Helvetica,sans-serif";
    context.textAlign = "center";
    let c = '';
    if(this.firstName){
      c = this.firstName.substr(0, 1).toUpperCase()
    }
    if(this.lastName){
      c += this.lastName.substr(0, 1).toUpperCase();
    }
   
    // Setup background and front color
    context.fillStyle = colors[c.charCodeAt(0) - 65];
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = "#FFF";
    context.fillText(c, size / 2, size / 1.5);
    return canvas.toDataURL("image/png");
  }

  ngOnInit(): void {  
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
          let token = localStorage.getItem('token');
          if(token == undefined) { 
            this.router.navigate(['/login']); 
          } 
      }
    });

    setTimeout(()=> {
      this.xj.extraInit();   
    }, 300)

     // get user role name
    // this.service.editRole(this.roleId).subscribe((res:any) => {
    //   if(res.success){
    //     this.UserRoleName = res['data']['roleName'];
    //   }
    // }, 
    // (error:any) => {
    //   //console.log(error);
    //   //if(error.status === 401 || error.status === 500){
    //     localStorage.clear();
    //     sessionStorage.clear();
    //     this.router.navigateByUrl('/login');
    //   //}
    // }
    // ) 

    
    
  }
  ngAfterViewInit(): void{
    this.removeElementsByClass(".side-rm-item");
    this.setTitle(this.commonSrv.getTitle());
  }
  
logout() {  
  this.userLogout = true;
  this.loginService.logout().subscribe((res:any)=>{   
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl('/login');    
    this.userLogout = false;
  },(err:any)=>{
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
    this.userLogout = false;
  })
}

  removeElementsByClass(className){
    const removeElements = (elms) => elms.forEach(el => el.remove());
    removeElements( document.querySelectorAll(className) );
  }

  // mobile menu

  mobileMenuOpen(){
    document.getElementById("main-wrapper").classList.toggle('menu-toggle');
    document.getElementById("navControlHam").classList.toggle('is-active');   
  }

}
