<div id="main-wrapper">
    <app-sidebar  [addLink]="currentAddLnk" [addLinkTxt]="currAddLnkTxt"></app-sidebar>
        <!-- content-body -->
        <div class="content-body">
            <div class="container-fluid">
                <div class="card-table {{ (isLoading)? 'loading' : ''}}">
                    <div class="apploader {{ (isLoading)? 'show' : ''}}">
                        <div class="loader">
                            <div class="spinner-border" role="status"></div>
                            <span class="d-block mt-3">Loading...</span>
                        </div>
                    </div>
                    <div class="table-head">
                        <!-- <div class="row align-items-md-center">
                            <div class="col align-self-md-center">
                                <div class="form-inline span-5">
                                    <div class="form-group mb-1">
                                        <label for="" class="mr-2 fw-500 text-muted">Search : </label>
                                        <input type="search" id="table_search" class="form-input">                                                  
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto text-md-right">                               
                                <div class="addMore d-md-inline-block">
                                    <a class="btn-add" routerLink="/home/add-academic-session">
                                        <i class="hi hi-create-lg mr-1"></i>
                                        Add More
                                    </a>
                                </div>
                            </div>
                        </div> -->
                        <form class="row align-items-md-end">
                            <div class="col align-self-md-center">
                                <div class="form-inline span-5">                                    
                                    <div class="form-group mb-1">
                                        <label for="table_start_date" class="fw-500 text-muted small w-100 justify-content-start mb-1">Year : </label>
                                        <input type="text" id="table_start_date" class="form-input " placeholder="Year" name="year"  [(ngModel)]="currentYear" >                                                  
                                    </div>                                
                                    <!-- <div class="form-group mb-1">
                                        <label for="table_end_date" class="fw-500 text-muted small w-100 justify-content-start mb-1">End Date : </label>
                                        <input type="text" id="table_end_date" class="form-input " placeholder="From Date" name="endDate"  [(ngModel)]="endDate" >                                                  
                                    </div>   -->
                                    <div class="form-group mb-1">
                                        <label for="table_type" class="fw-500 text-muted small w-100 justify-content-start mb-1" >Type : </label>
                                        <select class="form-input" name="type"  [(ngModel)]="currentType" id="table_type">
                                            <option value="">Select Type</option>
                                            <option *ngFor="let item of types " [value]="item.value">{{ item.name }}</option>
                                       </select>                                                
                                    </div>                                                                     
                                </div>
                            </div>
                            <div class="col-auto text-md-right">                                
                                <div class="addMore d-md-inline-block">
                                    <button class="btn-add" type="submit" (click)="filterDocuments()">                                       
                                       Search
                                    </button>
                                    <button class="btn-reset" type="reset" (click)="clearFilter()">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <p *ngIf="sessionData.length < 1 && !isLoading" class="text-center not-found">
                        <i class="bi bi-calendar-range"></i>
                       {{ message }}
                    </p>

                    <div class="table-responsive" *ngIf="sessionData.length > 0 && !isLoading">
                        <table class="table table-sm table-bordered sortable datatable nowraptable">
                            <thead>
                                <tr class="table-header">
                                    <th class="text-dark">S.No.</th>
                                    <th class="text-dark">Title</th>
                                    <th class="text-dark">Type</th>
                                    <th class="text-dark">Start Date</th>
                                    <th class="text-dark">End Date</th>
                                    <th class="text-dark">School Year</th>
                                    <th class="text-dark no-sort" *ngIf="editButton || deleteButton">Action</th>
                                </tr>
                            </thead>
                            <tbody>                            
                                <tr *ngFor="let data of sessionData;index as i">
                                    <td>{{(i + 1) + (currentPage - 1) * tableDataPerPage}}</td>
                                    <td>{{data.title}}</td>
                                    <td>{{data.type}}</td>
                                    <td>{{data.startDate}}</td>
                                    <td>{{data.endDate}}</td>
                                    <td>{{data.schoolYear}}</td>
                                    <td  *ngIf="editButton || deleteButton">
                                    
                                        <a *ngIf="editButton === true"  (click)="goToEditPage(data.id)" class="btn btn-sm btn-success light">
                                            <i class="bi bi-pencil-fill"></i>
                                        </a>
                                        <a  *ngIf="deleteButton === true" href="#deletePopup" (click)="deleteSessionId(data.id)" data-toggle="modal" class="btn btn-sm btn-danger light">
                                            <i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                        
                            </tbody>
                        
                        </table>
                    </div>
                    <div class="table-footer" *ngIf="sessionData.length > 0">
                        <div class="row">
                            <div class="col-md-auto align-self-center ml-auto">
                                <div class="justify-content-md-end"></div>
                            </div>
                            <div class="col-md-auto col-12 align-self-center ml-auto">
                                <div class="justify-content-md-end">
                                    <div class="text-md-right">
                                        <div class="dropdown-select">
                                            <!-- <div class="dataTables_length" id="sortable_length">
                                                <label>
                                                    <span class="mb-0">Rows per page:</span>
                                                    <select name="sortable_length">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </label>
                                            </div> -->
                                            <div>
                                                <div class="dataTables_info" id="sortable_info" role="status" aria-live="polite">{{ tableDataFrom }} - {{ tableDataTo }} of
                                                    {{tableDataTotal}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-auto col-12">
                                <div class="pagination dataTables_paginate" id="sortable_paginate" *ngIf="pageList?.length > 1">
                                    <a class="paginate_button previous" [ngClass]="1 == currentPage ? 'disabled':''"  (click)="changePage(currentPage-1)">
                                        <i class="bi bi-chevron-left"></i>
                                    </a>
                                    <span>
                                        <ng-container *ngFor="let page of pageList; let i = index;">
                                            <a *ngIf="page !== '...'" (click)="changePage(page)" [ngClass]="page == currentPage ? 'current paginate_button':'paginate_button'"   >{{ page }}</a>
                                            <span *ngIf="page === '...'"   class="paginate_button"   >{{ page }}</span>
                                        </ng-container>                                        
                                    </span>
                                    <a class="paginate_button next" [ngClass]="lastPage == currentPage ? 'disabled':''"  (click)="changePage(currentPage+1)">
                                        <i class="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        <!-- content-body -->
    </div>
    <!-- delete popup start -->
<div id="deletePopup" class="modal fade" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header flex-column">
                <div class="icon-box">
                    <i class="bi bi-x"></i>
                </div>						
                <h4 class="modal-title w-100">Are you sure?</h4>	
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="bi bi-x"></i></button>
            </div>
            <div class="modal-body">
                <p>Do you really want to delete these record?</p>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" id="roleDelete" data-dismiss="modal" (click)="deleteSessionApi()">Delete</button>
            </div>
        </div>
    </div>
</div>