<div id="main-wrapper">
    <app-sidebar [addLink]="currentAddLnk" [addLinkTxt]="currAddLnkTxt"></app-sidebar>
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-table" [ngClass]="{'loading': isLoading}">
                <div class="apploader" [ngClass]="{'show': isLoading}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Loading...</span>
                    </div>
                </div>
                <div class="table-responsive">
                    <p class="text-center not-found" *ngIf="message && !isLoading">
                        <i class="bi bi-file-earmark-text"></i>
                        {{ message }}
                    </p>
                    <table class="table table-sm table-bordered sortable datatable nowraptable" *ngIf="!message && !isLoading">
                        <thead>
                            <tr class="table-header">
                                <th class="text-dark">S.No.</th>
                                <th class="text-dark" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>School</th>
                                <th class="text-dark">Host Or Ip </th>
                                <th class="text-dark">User Name </th>
                                <!-- <th class="text-dark">Password </th> -->
                                <th class="text-dark">Port </th>
                                <th class="text-dark">Root </th>
                                <th class="text-dark no-sort" *ngIf="deleteButton || editButton">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ftpItems; let i = index;">
                                <td>{{(i + 1) + (currentPage - 1) * tableDataPerPage}}</td>
                                
                                <td *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>{{(item?.organisation) ? item?.organisation?.name : 'NA'}}</td>
                                <td>{{item.host}}</td>
                                <td>{{item.username}}</td>
                                <!-- <td>{{item.password}}</td> -->
                                <td>{{item.port}}</td>
                                <td>{{item.root}}</td>
                                <td *ngIf="deleteButton || editButton">
                                    
                                    <a *ngIf="editButton" [routerLink]="['/home/master/edit-ftp/', item.id]" class="btn btn-sm btn-success light">
                                        <i class="bi bi-pencil-fill"></i>
                                    </a>
                                    <a *ngIf="deleteButton" (click)="deletePopup(item.id)" class="btn btn-sm btn-danger light">
                                        <i class="bi bi-trash"></i>
                                    </a>
                                </td>
                            </tr>
                            
                        </tbody>
                        
                    </table>
                </div>
                <div class="table-footer" *ngIf="!message && !isLoading">
                    <div class="row">                        
                        <div class="col-md-auto col-12 align-self-center ml-auto">
                            <div class="justify-content-md-end">
                                <div class="text-md-right">
                                    <div class="dropdown-select">
                                        <!-- <div class="dataTables_length" id="sortable_length">
                                            <label>
                                                <span class="mb-0">Rows per page:</span>
                                                <select name="sortable_length">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </label>
                                        </div> -->
                                        <div>
                                            <div class="dataTables_info" id="sortable_info" role="status" aria-live="polite">{{ tableDataFrom }} - {{ tableDataTo }} of
                                                {{tableDataTotal}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-auto">                            
                            <div class="pagination dataTables_paginate" id="sortable_paginate" *ngIf="totalPages?.length > 1">
                                <a class="paginate_button previous" [ngClass]="1 == currentPage ? 'disabled':''"  (click)="changeCurrentPage(currentPage-1)">
                                    <i class="bi bi-chevron-left"></i>
                                </a>
                                <span>
                                    <ng-container *ngFor="let page of totalPages; let i = index;">
                                        <a *ngIf="page !== '...'" (click)="changeCurrentPage(page)" [ngClass]="page == currentPage ? 'current paginate_button':'paginate_button'"   >{{ page }}</a>
                                        <span *ngIf="page === '...'"   class="paginate_button"   >{{ page }}</span>
                                    </ng-container>                                        
                                </span>
                                <a class="paginate_button next" [ngClass]="totalPages.length <= currentPage ? 'disabled':''"  (click)="changeCurrentPage(currentPage+1)">
                                    <i class="bi bi-chevron-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>


<!-- delete popup start -->
<div id="deletePopup" class="modal fade" aria-hidden="true" style="display: none;" #delete>
    <div class="modal-dialog modal-confirm modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header flex-column">
                <div class="icon-box">
                    <i class="bi bi-x"></i>
                </div>						
                <h4 class="modal-title w-100">Are you sure?</h4>	
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="bi bi-x"></i></button>
            </div>
            <div class="modal-body">
                <p>Do you really want to delete these record?</p>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" (click)="deleteFTP()">Delete</button>
            </div>
        </div>
    </div>
</div>
<!-- delete popup end -->