<div id="main-wrapper">
    <!--  -->
    <app-sidebar></app-sidebar>
    <!-- sidebar menu -->
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form">
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Upload Documents </h5>                        
                    </div>
                </div>
                <form class="position-relative {{ (isLoading)? 'loading' : ''}}" [formGroup]="bulkUploadDocFrm" (ngSubmit)="submit()" enctype='multipart/form-data'>
                    <div class="apploader {{ (isLoading)? 'show' : ''}}">
                        <div class="loader">
                            <div class="spinner-border" role="status"></div>
                            <span class="d-block mt-3">Loading...</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row" *ngIf="step === 0">                                
                            <div class="col-lg-9">
                                <div class="row align-items-md-center"  *ngIf='loginService.getCurrentUserRole().slug === "super-admin" || loginService.getCurrentUserRole().slug === "superident"'>
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="name" class="text-dark fw-500 label-text">School Name<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <select class="form-control" formControlName="organisation" id="organisation" (change)="onschoolChange($event.target.value)"  [ngClass]="(m.organisation.touched && m.organisation.invalid) ? 'is-invalid' : ''">
                                                <option value="">Select School</option>
                                                <option *ngFor="let i of orgData;" [value]="i.id">{{ i.name }}</option>
                                           </select>
                                           <div *ngIf="m.organisation.touched && m.organisation.invalid" class="invalid-feedback">
                                            Please select school
                                            </div>
                                        </div>                                       
                                    </div>
                                </div>
                                
                                <div class="row align-items-md-center" *ngIf='loginService.getCurrentUserRole().slug === "super-admin" || loginService.getCurrentUserRole().slug === "superident" || loginService.getCurrentUserRole().slug == "principal"'>
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="grade" class="text-dark fw-500 label-text">Grade :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': GradeLoader}">
                                            <select id="grade" class="form-control" formControlName="grade">
                                                <option value="">{{ defOpGrade }}</option>
                                                <option *ngFor="let item of gradeItems" [value]="item.id">{{ item.grade }}</option>
                                            </select>
                                            <div class="spinner-border spinner-border-sm"></div>
                                        </div>                                           
                                    </div>
                                </div>

                                <div class="row align-items-md-center"> 
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="course" class="text-dark fw-500 label-text">Course<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8"> 
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': CourseLoader}">
                                            <select class="form-control" formControlName="course" id="course">
                                                <option value="">{{ defOpCourse }}</option>
                                                <option *ngFor="let i of courseData;" value="{{i.id}}">{{ i.title }}</option>
                                           </select>
                                           <div class="spinner-border spinner-border-sm"></div>
                                        </div>                                       
                                    </div>
                                </div>

                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="acaSession" class="text-dark fw-500 label-text">Academic Session :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <select class="form-control" formControlName="acaSession" id="acaSession">
                                                <option value="">Select Academic Session</option>
                                                <option *ngFor="let i of sessionData;" value="{{i.id}}">{{ i.title }} - ( {{ i.schoolYear }} )</option>
                                           </select>
                                        </div>                                       
                                    </div>
                                </div>

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="year" class="text-dark fw-500 label-text">School Year :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                           <input type="text" class="form-control" formControlName="year" id="year" placeholder="YYYY">                                               
                                        </div>                                           
                                    </div>
                                </div>
                                


                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="doc_type" class="text-dark fw-500 label-text">Document Type<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': docTypesL}">
                                           <select class="form-control" id="doc_type" formControlName="doc_type" [ngClass]="(m.doc_type.touched && m.doc_type.invalid) ? 'is-invalid' : ''">
                                               <option value="">Select Type</option>
                                               <option *ngFor="let i of docTypes;" value="{{i.id}}">{{ i.name }}</option>
                                           </select>
                                           <div class="spinner-border spinner-border-sm"></div>
                                           <div *ngIf="m.doc_type.touched && m.doc_type.invalid" class="invalid-feedback">
                                             Please select school
                                            </div>
                                        </div>                                           
                                    </div>
                                </div>

                                


                                <!-- <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="doc" class="text-dark fw-500 label-text mb-0">Upload Document :</label>
                                            <small class="text-muted d-block">
                                                Support only formats .csv
                                              </small>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <div class="custom-file">
                                                <input type="file" name="doc" class="custom-file-input" id="customFile">
                                                <label class="custom-file-label" for="customFile">Choose File</label>
                                              </div>
                                              
                                        </div>                                       
                                    </div>

                                    <div class="col-md-4">
                                        <button type="submit" class="btn btn-success btn-big" (click)="uploadDocCSV()">Upload</button>
                                    </div>
                                </div> -->
                               
                                

                            </div> 
                        </div>
                        <div class="row" *ngIf="step === 1">
                            <div class="col-lg-12">
                                <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
                                    <input type="file" formControlName="document" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                                    <img src="assets/images/fileuploader-dragdrop-icon.png" alt="">
                                    <h3>Drag and drop file here</h3>
                                    <p>or</p>
                                    <label for="fileDropRef">Browse for file</label>
                                </div>
                                <!-- <div class="files-list">
                                    <app-progress [progress]="progress" *ngIf="progress > 0"></app-progress>                                   
                                </div> -->
                            </div>
                        </div>

                        <!-- <div *ngIf="fileUpload.fileStatus==='progress'">
                            <div class="progress" style="height: 20px;margin-bottom:20px;">
                              <div class="progress-bar" role="progressbar" [style.width.%]="fileUpload.message" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{fileUpload.message}}%</div>
                            </div>
                        </div> -->
                        <div *ngIf="progressInfos.fileStatus" class="mb-2">
                            <div class="progress" style="height: 20px;margin-bottom:20px;">
                              <div
                                class="progress-bar progress-bar-info progress-bar-striped"
                                role="progressbar"
                                attr.aria-valuenow="{{ progressInfos.value }}"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                [ngStyle]="{ width: progressInfos.value + '%' }"
                              >
                                {{ progressInfos.value }}%
                              </div>
                            </div>
                          </div>
                        <ng-container *ngIf="errorMessages?.length > 0">
                            <div class="alert alert-danger" role="alert">
                                {{errorMessages[0]}}
                            </div>
                        </ng-container>

                    </div>
                    <div class="card-footer">
                        <div class="form-group mb-0" *ngIf="step === 0">                            
                            <button type="reset" class="btn btn-secondary" [routerLink]="['/home//bulk/uploaded-document']" [queryParams]="{page: 1}" >Cancel</button>
                            <button type="button" class="btn btn-success" (click)="nextStep()">Next</button>
                        </div>
                        <div class="form-group mb-0" *ngIf="step === 1">                           
                            <button type="button" class="btn btn-secondary" (click)="preStep()">Previous</button>
                            <button type="submit" class="btn btn-success" [disabled]="submitted">Upload Doucments <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="submitted"></span></button>
                        </div>
                    </div>
                    
                   
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>



