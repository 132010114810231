<!-- preloader start -->
<div id="preloader" style="display: none;">
    <div class="three-bounce">
        <div class="child bounce1"></div>
        <div class="child bounce2"></div>
        <div class="child bounce3"></div>
    </div>
</div>
<!-- preloader end -->
<!-- main-wrapper -->
<div id="main-wrapper {{ (isLoading)? 'loading' : ''}}">
    <div class="apploader {{ (isLoading)? 'show' : ''}}">
        <div class="loader">
            <div class="spinner-border" role="status"></div>
            <span class="d-block mt-3">Loading...</span>
        </div>
    </div>
        <!--  -->
        <div class="header">
            <div class="header-content">
                <nav class="navbar navbar-expand">
                    <div class="collapse navbar-collapse justify-content-between">
                        <div class="header-left">
                            <div class="nav-header border-0 w-auto">
                                <a class="brand-logo">
                                    <svg class="logo-abbr" width="50" height="50" viewBox="0 0 50 50" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect class="svg-logo-rect" width="50" height="50" rx="6" fill="#0152a5"></rect>
                                        <path class="svg-logo-path"
                                            d="M17.5158 25.8619L19.8088 25.2475L14.8746 11.1774C14.5189 9.84988 15.8701 9.0998 16.8205 9.75055L33.0924 22.2055C33.7045 22.5589 33.8512 24.0717 32.6444 24.3951L30.3514 25.0095L35.2856 39.0796C35.6973 40.1334 34.4431 41.2455 33.3397 40.5064L17.0678 28.0515C16.2057 27.2477 16.5504 26.1205 17.5158 25.8619ZM18.685 14.2955L22.2224 24.6007L29.4633 22.6605L18.685 14.2955ZM31.4751 35.9615L27.8171 25.6886L20.5762 27.6288L31.4751 35.9615Z"
                                            fill="white"></path>
                                    </svg>
                                    <span class="brand-title">Clinton County R-III School</span>
                                </a>
                               
                            </div>
                        </div>
                        
                    </div>
                </nav>
                <div class="sub-header pl-4">
                    <div class="d-flex align-items-center flex-wrap mr-auto">
                        <h5 class="dashboard_bar">Documents</h5>
                    </div>
                    
                </div>
            </div>
        </div>
    <!-- content-body -->
    <div class="content-body mt-4 ml-0">
        
        <div class="col-md-7  mx-auto">
            <div class="card doc-list mb-4"  *ngIf="expired && !isLoading">
               
                <div class="card-body text-center">
                    <h4 class="card-title mb-3"> The link has been expired</h4>
                </div>
            </div>

            <h4 *ngIf="!expired && !isLoading" class="card-title mb-3"> Student Name - {{ documentData[0]?.student?.firstName }}  {{ documentData[0]?.student?.middleName }} {{ documentData[0]?.student?.lastName }} </h4>

            <div class="card doc-list mb-4" *ngFor="let doc of documentData">
               
                <div class="card-body text-center">
                    <div class="embed-responsive embed-responsive-1by1" *ngIf="doc?.fileType === 'pdf'">
                        <iframe [src]="pdfSanitizer(doc.documentLocalPath+'#toolbar=0')" loading="lazy"  class="embed-responsive-item"></iframe>
                    </div> 
                    <img *ngIf="doc?.fileType !== 'pdf'" src="{{ doc.documentLocalPath }}" loading="lazy" default="assets/images/doc-icon-sm.png" class="img-fluid mb-4 image-doc" alt="{{ doc.studentName }}">

                    <p class="card-text mt-2" *ngIf="doc?.approvedBy"> Approved by : {{ doc?.approvedBy }}  </p>

                    <p class="card-text mt-2" *ngIf="doc?.comment"> {{ doc?.comment }}  </p>                    
                   
                </div>
            </div>




        </div>
    </div>
    <!-- content-body -->
</div>
<!-- main-wrapper end -->