import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassService } from 'src/app/services/class.service';
import { externalJs } from 'src/app/services/external-js.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { CourseService } from 'src/app/services/course.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-classes-list',
  templateUrl: './classes-list.component.html',
  styleUrls: ['./classes-list.component.css']
})
export class ClassesListComponent implements OnInit {
  @ViewChild('delete') delete;
  
  classId: any;
  classList: any = [];
  currentAddLnk: any;
  currAddLnkTxt: string = "Add Class";
  message: any = '';
  userPermissionData: any;
  editButton: boolean = false;
  deleteButton: boolean = false;
  viewPage: boolean = false;
  courseData: any;
  orgData: any;

  // filter and pageing
  currentName = '';
  currentCourse = '';
  currentSchool: any = parseInt(localStorage.getItem('orgIds')) || '';
  currentPage:number = 1;
  lastPage:number = 1;
  pageList:any = [];

  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  tableDataPerPage: number = 20;
  
  constructor(private xj: externalJs, public loginService: LoginServiceService, private service: ClassService, private router: Router, private toaster: NotificationService, private serviceCourse : CourseService, private schoolService : OrganisationService, private route:ActivatedRoute,) {
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if (element.module === "Classes") {
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {
      if (element?.permission === 'Delete class') {
        this.deleteButton = true;
      } else if (element?.permission === 'update class') {
        this.editButton = true;
      } else if (element?.permission === 'create class') {
        this.currentAddLnk = '/home/create-class';
      } else if(element?.permission === "view class"){
        this.viewPage = true;
      }
    });

    // params
    this.route.queryParamMap
      .subscribe((params: any) => {
        let paramsObj = { ...params.keys, ...params };

        if (paramsObj.params.page) {
          this.currentPage = paramsObj.params.page;
        }
        if (paramsObj.params.school) {
          this.currentSchool = paramsObj.params.school;
        }
        if (paramsObj.params.course) {
          this.currentCourse = paramsObj.params.course;
        } else {
          this.currentCourse = '';
        } if (paramsObj.params.name) {
          this.currentName = paramsObj.params.name;
        } else {
          this.currentName = '';
        } 
        this.initComp();
      });


  }
  isLoading = true;
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.getSchool();
    this.getCourse();
    this.xj.extraInit();
  }
  getSchool(){
    this.schoolService.allSchool().subscribe((res:any) => {      
      if(res.status){
        this.orgData = res.data
      } else{
        this.orgData = []
      }
    }, (err:any)=>{
      this.toaster.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
    })
  }
  // get class list api  
  initComp(){   
    this.getClass();
  }

  getClass() {
    this.isLoading = true
    if( this.currentSchool == null){
      this.currentSchool = '';
    }
    this.service.viewClass(this.currentPage,this.currentName,this.currentCourse,this.currentSchool).subscribe((res:any) => {
      if(res.status){
        this.classList = res.data.data
        this.currentPage = res.data.current_page;
        this.lastPage = res.data.last_page;
        this.mapPageList(res.data.last_page);
        this.tableDataFrom = res.data.from;
        this.tableDataTo = res.data.to;
        this.tableDataTotal = res.data.total;
        this.tableDataPerPage = res.data.per_page;        
        this.message = '';
        this.isLoading = false;
      } else if(res.status_code === 404){
        this.message = 'No Class Data Found';        
        this.classList = [];
        this.isLoading = false;
      } else{
        this.message = 'Somenthing went wrong. Please try later';      
        this.classList = [];
        this.isLoading = false;
      }
    },(err:any)=>{
      this.message= "Something went wrong. Please try later";
      this.isLoading = false;
    })
  }
  // for mapping page list
  mapPageList(lastPage){
    this.pageList = [];
    let pageListArr = [];
    if(lastPage <=1) return;
    if(lastPage <= 5){
      for(var i =1; i<= lastPage; i++){
          pageListArr.push(i);
      }
    }
    if(lastPage > 5){
      if(this.currentPage <=4){        
          for(var i =1; i<= 5; i++){
            pageListArr.push(i);
          }      
          pageListArr.push('...');
          pageListArr.push(lastPage);
      } else if(lastPage - this.currentPage <=3){
        pageListArr.push(1);
        pageListArr.push('...');
        for(var i =lastPage - 4; i<= lastPage; i++){
          pageListArr.push(i);
        } 
      }
      else {
        pageListArr.push(1);
        pageListArr.push('...');  

        for(var i = this.currentPage - 2; i<= this.currentPage + 2; i++){
          pageListArr.push(i);
        }

        pageListArr.push('...');
        pageListArr.push(lastPage);
      } 
    } 
  
    // if(lastPage < 8){
    //   for (let index = 1; index <= lastPage; index++) {
    //     pageListArr.push(index);
    //   }
    // } else {
    //    for (let index = 1; index <= 2; index++) {
    //     pageListArr.push(index);
    //   }
    //   if(![1,2, lastPage-1, lastPage].includes(this.currentPage)){
    //     pageListArr.push('...');
    //      pageListArr.push(this.currentPage);
    //     pageListArr.push('...');
    //   }else{
    //      pageListArr.push('...');
    //   }
    //    for (let index = lastPage - 1; index <= lastPage; index++) {
    //     pageListArr.push(index);
    //   }
    // }
    // console.log(pageListArr);
    this.pageList = pageListArr;
  }
  // change page
  changePage(page){
    
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: page },
        queryParamsHandling: 'merge'
      });
    
    // console.log(page)
  }
  // go to edit page
  goToEditPage(id) {
    this.router.navigate(['/home/edit-class'], { queryParams: { id: id } })
  }

  // get course id
  getCourseId(id) {
    this.classId = id
  }

  // delete course

  deleteCourse() {
    this.service.deleteClass(this.classId).subscribe((res:any) => {
      if(res.status){
        this.initComp();
        this.toaster.showSuccess("Class deleted successfully !!");
        this.xj.closeModalPopup("#deletePopup");        
      } else{
        this.toaster.showError('Unable to delete class', '');
        this.initComp();
        this.xj.closeModalPopup("#deletePopup");   
      }      
    }, (err:any)=>{
        this.toaster.showError('Something went wrong. Please try later', '');
        this.initComp();
        this.xj.closeModalPopup("#deletePopup");   
    })
      
  }

  // filter
  filterData() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: 1, name: this.currentName, school: this.currentSchool, course: this.currentCourse },
        queryParamsHandling: 'merge'
      });
  }
  // clear filter

  clearFilter() {
    this.currentSchool = parseInt(localStorage.getItem('orgIds')) || '';
    this.currentName = '';
    this.currentCourse = '';
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, name: '',  school: '', course: '' },
        queryParamsHandling: 'merge'
      });
  }

   // on school change
   filterSchool(orgId){
    this.currentCourse = '';
    this.getCourse(orgId);
  }
  //getCourse
  getCourse(orgID: any = this.currentSchool) {
    this.courseData = [];
    this.serviceCourse.getAllCoursebyOrgid(orgID).subscribe(res => {
      if (res['status']) {
        this.courseData = res['data'];
      } else{
        this.courseData= [];
      }
    }, (err:any)=>{
      this.toaster.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
    })
  }

  openPopup(e, items){
    var html = '';
    html+="<ul class='pl-4 grid-list'>";
    for(var item of items ){
       html += `<li>${item?.schoolYear} - ${item?.title} - ${item?.type}</li>`;
    }
    html+="</ul>";
    this.xj.openPopup(e, html);
    
  }

}
