         <!--  -->
         <div class="header">
            <div class="header-content">
                <nav class="navbar navbar-expand">
                    <div class="collapse navbar-collapse justify-content-between">
                        <div class="header-left">
                            <div class="nav-header border-0 w-auto">
                               <a routerLink="/home/dashboard" class="brand-logo" *ngIf="loginService.getCurrentUserRole().slug  !== 'student' && loginService.getCurrentUserRole().slug  !== 'parent'" [innerHTML] ="sanitizer.bypassSecurityTrustHtml(commonSrv.getLogo())" >
                               </a>
                               <a routerLink="/home/document" class="brand-logo" *ngIf="loginService.getCurrentUserRole().slug  === 'student' || loginService.getCurrentUserRole().slug  === 'parent'" [innerHTML] ="sanitizer.bypassSecurityTrustHtml(commonSrv.getLogo())" >
                                </a>
                               <div class="nav-control" (click)="mobileMenuOpen()">
                                   <div class="hamburger" id="navControlHam">
                                       <span class="line"></span><span class="line"></span><span class="line"></span>
                                   </div>
                               </div>
                            </div>
                        </div>
                        <ul class="navbar-nav header-right main-notification">
                           <li class="nav-item dropdown header-profile">
                               <a class="nav-link" href="#userDropDownMenu" role="button" data-toggle="dropdown" data-target="userDropDownMenu">
                                   <img [src]="AvatarImage()" width="20" alt="">
                                   <div class="header-info">
                                       <span>{{firstName}} {{lastName}}</span>
                                       <!-- <small>{{userName}}</small> -->
                                       <small>{{UserRoleName | titlecase}}</small>
                                   </div>
                               </a>
                               <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" id="userDropDownMenu" >
                                   <div class="drop-heading">
                                       <div class="text-center">
                                           <h5 class="text-dark mb-0">{{firstName}} {{lastName}}</h5>
                                           <!-- <small class="text-muted">{{userName}}</small> -->
                                           <small class="text-muted">{{UserRoleName | titlecase}}</small>
                                       </div>
                                   </div>
                                   <div class="dropdown-divider m-0"></div>
                                   <a class="dropdown-item" routerLink="/home/profile-setting"> <i class="dropdown-icon bi bi-person-bounding-box"></i>
                                       Profile </a>
                                   <a class="dropdown-item" *ngIf='loginService.getCurrentUserRole().slug === "super-admin"' routerLink="/home/settings"> <i class="dropdown-icon bi bi-gear"></i>
                                        Settings </a>
                                   <a class="dropdown-item" routerLink="/home/change-password"> <i class="dropdown-icon bi bi-toggles2"></i>
                                       Change Password </a>

                                   <div class="dropdown-divider mt-0"></div>
                                   <a class="dropdown-item" (click)="logout()"> <i
                                           class="dropdown-icon bi bi-power"></i> Logout</a>
                               </div>
                           </li>
                        </ul>
                    </div>
                </nav>
                <div class="sub-header">
                   <div class="d-flex align-items-center flex-wrap mr-auto">
                       <h5 class="dashboard_bar">{{PageTitle}}</h5>
                   </div>
                   <div class="d-flex align-items-center" *ngIf="addLink || exportText">
                        <a *ngIf="exportText" class="btn-add mr-2" (click)="export()">
                            <i class="bi bi-download d-inline-block d-sm-none"></i>
                            <span class="d-none d-sm-inline-block">
                                {{ exportText ? exportText : 'Export' }}
                            </span>
                       
                        </a>
                        <a class="btn-add" *ngIf="addLinkTxt" [routerLink]="[addLink]">                            
                            {{ addLinkTxt ? addLinkTxt : 'Add More' }}
                        </a>
                   </div>
                </div>
            </div>
        </div>
        <!--  -->
        <!-- sidebar menu  -->
        <div class="deznav">
           <div class="deznav-scroll ps" [perfectScrollbar]="config" >
               <div class="main-profile">
                   <div class="image-bx">
                       <img  [src]="AvatarImage()" alt="{{userName}}">
                   </div>
                   <h5 class="name"><span class="fw-400">Hello,</span> {{firstName}}</h5>
                   <p class="email">{{UserRoleName | titlecase}}</p>
               </div>
               <ul class="metismenu" metis-menu>
                   <li class="nav-label first">Main Menu</li>
                   <li routerLinkActive="mm-active" *ngIf="loginService.getCurrentUserRole().slug  !== 'student' && loginService.getCurrentUserRole().slug  !== 'parent'">
                       <a class="ai-icon" routerLink="/home/dashboard">
                           <i class="bi bi-grid"></i>
                           <span class="nav-text">Dashboard</span>
                       </a>
                   </li>
                  
                    <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active" [ngClass]="documentModule ? '' : 'd-none side-rm-item'">
                        <a class="ai-icon" [routerLink]="['/home/document']">  <i [ngClass]="(loginService.getCurrentUserRole().slug  === 'student' || loginService.getCurrentUserRole().slug  === 'parent') ? 'bi bi-grid' : 'bi bi-file-pdf' "></i> {{ (loginService.getCurrentUserRole().slug  === 'student' || loginService.getCurrentUserRole().slug  === 'parent') ? 'Dashboard' : 'Documents' }}</a>                    
                    </li>
                   <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active" [ngClass]="userModule ? '' : 'd-none side-rm-item'">
                        <a class="ai-icon" routerLink="/home/user"> <i class="bi bi-person-plus-fill"></i> User Management</a>                       
                   </li>
                  
                   <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active"  [ngClass]="rolesModule ? '' : 'd-none side-rm-item'">
                        <a class="ai-icon" routerLink="/home/user-role"> <i class="bi bi-diagram-2-fill"></i> Roles</a>                        
                   </li>  
               
                <!-- <li class="nav-label first" *ngIf="teacherModule || studentModule || organisationModule || academicSesModule">School</li> -->
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active"  [ngClass]="(teacherModule || studentModule || parentModule) ? '' : 'd-none side-rm-item'">
                    <a aria-expanded="false" [ngClass]="(teacherModule || studentModule || parentModule) ? 'has-arrow ai-icon': '' ">
                    <i class="bi bi-people-fill"  *ngIf="teacherModule || studentModule || parentModule"></i>
                        <span class="nav-text"  *ngIf="teacherModule || studentModule || parentModule">School Users</span>
                    </a>
                    <ul aria-expanded="false"  routerLinkActive="mm-collapse mm-show">
                        <li routerLinkActive="mm-active"  [routerLinkActiveOptions]="{exact: true}" *ngIf="teacherModule"><a [routerLink]="['/home/teacher']">Teachers</a></li>
                        <li routerLinkActive="mm-active"  [routerLinkActiveOptions]="{exact: true}" *ngIf="studentModule"><a [routerLink]="['/home/student']">Students</a></li>
                        <li routerLinkActive="mm-active"  [routerLinkActiveOptions]="{exact: true}" *ngIf="parentModule"><a [routerLink]="['/home/parent']">Parents</a></li>
                        <!-- <li routerLinkActive="mm-active"  [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/home/student/add']">Add Teacher / Student</a></li> -->
                    </ul>
                </li>               
                
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active" [ngClass]="organisationModule ? '' : 'd-none side-rm-item'">
                    <a class="ai-icon" [routerLink]="['/home/school']"> <i class="bi bi-building"></i> School Management</a>                   
                </li>
                <!-- <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active" [ngClass]="academicSesModule ? '' : 'd-none side-rm-item'">
                    <a class="ai-icon" [routerLink]="['/home/academic-session']"> <i class="bi bi-calendar-range"></i>Academic Sessions</a>
                </li> -->
               
                <!-- <li class="nav-label first"  *ngIf="courseModule || classModule">Course</li> -->
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active"  [ngClass]="courseModule ? '' : 'd-none side-rm-item'">
                    <a class="ai-icon" [routerLink]="['/home/course']"> <i class="bi bi-book"></i> Course Management</a>                   
                </li>
                
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active" [ngClass]="classModule ? '' : 'd-none side-rm-item'">
                    <a class="ai-icon" [routerLink]="['/home/class-list']"> <i class="bi bi-card-heading"></i> Class Management</a>
                </li>

                <!-- <li class="nav-label first"  *ngIf="bulkUploadModule">Bulk Upload</li> -->
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active" [ngClass]="bulkUploadModule ? '' : 'd-none side-rm-item'">
                    <a aria-expanded="false" [ngClass]="{'has-arrow ai-icon': bulkUploadModule}"> 
                        <i class="bi bi-cloud-upload"  *ngIf="bulkUploadModule"></i>
                        <span class="nav-text"  *ngIf="bulkUploadModule">Bulk Upload</span>
                    </a>
                    <ul aria-expanded="false"  routerLinkActive="mm-collapse mm-show">
                        <li routerLinkActive="mm-active"  [routerLinkActiveOptions]="{exact: true}" *ngIf="bulkPermission('Bulk upload document')"><a [routerLink]="['/home//bulk/uploaded-document']" [queryParams]="{page: 1}">Upload Document</a></li>
                        <li routerLinkActive="mm-active"  [routerLinkActiveOptions]="{exact: false}" *ngIf="bulkPermission('Bulk user upload')"><a [routerLink]="['/home//bulk/view-upload-user']"  [queryParams]="orgId ? { schoolId: orgId} : {} ">User CSV Import</a></li>                        
                        <li routerLinkActive="mm-active"  [routerLinkActiveOptions]="{exact: true}" *ngIf="bulkPermission('One roaster csv file import')"><a [routerLink]="['/home/bulk/uploadcsv']">One Roster CSV Files Import</a></li>             
                    </ul>
                </li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active" [ngClass]=" gradeModule ? '' : 'd-none side-rm-item'">
                    <a class="ai-icon" [routerLink]="['/home/master/grade-list']"> <i class="bi bi-diagram-3"></i> Grade Management</a>
                </li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active" [ngClass]="docTypeModule ? '' : 'd-none side-rm-item'">
                    <a class="ai-icon" [routerLink]="['/home/master/doc-type-list']"> <i class="bi bi-folder2-open"></i> DocType Management</a>
                </li>
                <!-- <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active" [ngClass]="ftpModule ? '' : 'd-none side-rm-item'">
                    <a class="ai-icon" [routerLink]="['/home/master/ftp-list']"> <i class="bi bi-folder2-open"></i> FTP Management</a>
                </li> -->
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active" [ngClass]="failedUser ? '' : 'd-none side-rm-item'">
                    <a class="ai-icon" [routerLink]="['/home/failed-user']"> <i class="bi bi-person-x"></i> Failed Users</a>
                </li>

                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active" [ngClass]="isParents ? '' : 'd-none side-rm-item'">
                    <a class="ai-icon" [routerLink]="['/home/mychild']"> <i class="bi bi-person-bounding-box"></i> My Child Document</a>
                </li>
                
                <!-- <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-active"  [ngClass]="bulkUploadModule ? '' : 'd-none side-rm-item'">
                    <a aria-expanded="false" [ngClass]="{'has-arrow ai-icon': bulkUploadModule}"> 
                        <i class="bi bi-diagram-3"  *ngIf="bulkUploadModule"></i>
                        <span class="nav-text"  *ngIf="bulkUploadModule">Master</span>
                    </a>
                    <ul aria-expanded="false" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="mm-collapse mm-show">
                        <li routerLinkActive="mm-active"  [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/home//master/grade-list']">Grade</a></li>
                        <li routerLinkActive="mm-active"  [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/home//master/doc-type-list']">Document Type</a></li>                        
                        <li routerLinkActive="mm-active"  [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/home/master/ftp-list']">FTP Setting</a></li>             
                    </ul>
                </li> -->
               
                   <li (click)="logout()">
                       <a class="ai-icon" href="javasccript:void()">
                       <i class="bi bi-power"></i>
                           <span class="nav-text">Logout</span>
                       </a>
                   </li>
                   
               </ul>
               <div class="copyright">
                   <p><strong>Clinton County R-III School </strong> <br>© 2021 All Rights Reserved</p>
               </div>
           </div>
       </div>
        <!-- sidebar menu -->

        <div class="loading" *ngIf="userLogout">
            <div class="apploader show position-fixed">
                <div class="loader">
                    <div class="spinner-border" role="status"></div>
                    <span class="d-block mt-3">Logging Off...</span>
                </div>
            </div>
        </div>