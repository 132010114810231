import { Component, OnInit } from '@angular/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { externalJs } from 'src/app/services/external-js.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CourseService } from 'src/app/services/course.service'
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'src/app/services/master.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-edit-course',
  templateUrl: './edit-course.component.html',
  styleUrls: ['./edit-course.component.css']
})
export class EditCourseComponent implements OnInit {
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  orgData: any = [];
  id: any;
  editCourseData: any;
  showError = false;
  errorMessages = [];
  gradeItems:any = [];
  userPermissionData:any;
  viewPage:boolean = false;

  isSubmit: boolean = false;
  submitBtn:any;
  isLoading:boolean= true;
  defOpOrg:any ='Select School';
  defOpGrade:any ='Select Grade';
  gradLoader:boolean= false;
  constructor(private xj: externalJs, private serviceOrganisation: OrganisationService, public loginService: LoginServiceService,
    private service: CourseService, private toastr: NotificationService, private router: Router,private route: ActivatedRoute, private  masterService: MasterService) { 
      this.route.queryParamMap.subscribe(queryParams => {
        this.id = queryParams.get("id")
      })
      var localData = JSON.parse(localStorage.getItem('userPermission'))
      const a = localData?.modules;

      a.forEach(element => {
        if (element.module === "Course") {
          this.userPermissionData = element?.permission;
        }
      });

      this.userPermissionData?.forEach(element => {
        if (element?.permission === 'update course') {
          this.viewPage = true;
        } 
      });
    }


  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();    
    if(this.schoolId){
      this.gradLoader =true;
      this.getGradeItems(this.schoolId);
    } else{
      this.getOrganization();
    }
    this.editCourse();
  }
  // edit course form
  editCourseForm = new FormGroup({
    title: new FormControl('',),
    courseCode: new FormControl('',),
    grades: new FormControl({value:'', disabled:true}),
    organization: new FormControl({value: this.schoolId, disabled:true}),
    active: new FormControl('1',)
  })
  // viewOrganisationApi()
  getOrganization() {
    this.serviceOrganisation.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg='Select School';
        this.editCourseForm.controls['organization'].enable();
      } else{
        this.orgData = [];
        this.defOpOrg='No School Found';
      }
      this.isLoading = false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/class-list']);
      this.isLoading = false;
    })
  }


  selectSchool(e:any){
    this.gradLoader =true;
    this.editCourseForm.controls['grades'].disable();
    this.editCourseForm.get('grades').setValue('');
    this.getGradeItems(e);
  }

  getGradeItems(schoolId:any){
    this.masterService.allGradeList(schoolId).subscribe((res:any)=>{
      if(res.status){
        this.gradeItems =  res.data;
        this.defOpGrade='Select Grade';
        this.editCourseForm.controls['grades'].enable();
      } else{
        this.gradeItems = [];
        this.defOpGrade='No Grade Found';
      }
      this.gradLoader= false;
      this.isLoading = false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/course']);
      this.gradLoader= false;
      this.isLoading = false;
    })
  }

  

// edit course 
editCourse() {
  this.service.editCourse(this.id).subscribe((res:any) => {
    if(res.status){
      this.editCourseData = res['data'];
      if(!this.schoolId){
        this.gradLoader =true;
        this.editCourseForm.controls['grades'].disable();
        this.getGradeItems(this.editCourseData.orgSourcedId);
      }
      this.editCourseForm.patchValue({
        title: this.editCourseData.title,
        courseCode: this.editCourseData.courseCode,
        grades: (this.editCourseData.grades ?? ''),
        organization: this.editCourseData.orgSourcedId,
        active : this.editCourseData.isActive,
      })
    } else{
      this.router.navigate(['/home/course'])
      this.toastr.showError('Something went wrong. Please try later','');       
      this.isLoading=false; 
    }
    
    this.isLoading = false;    
  },(err:any)=>{
    this.router.navigate(['/home/course'])
    this.toastr.showError('Something went wrong. Please try later','');        
    this.isLoading=false;
  })
}
  // update course api
  updateCourse(e:any) {
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    this.showError = false;
    this.errorMessages = [];
    const data = {
      title: this.editCourseForm.value.title,
      courseCode: this.editCourseForm.value.courseCode,
      grades: this.editCourseForm.value.grades,
      orgSourcedId: this.editCourseForm.value.organization || this.schoolId,
      isActive: this.editCourseForm.value.active
    }
    this.service.updateCourse(this.id,data).then(res => {
      if (res['status']) {
        this.router.navigate(['/home/course']);
        this.toastr.showSuccess('Course updated successfully');
      }else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }         
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }

}
