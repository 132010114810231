import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { externalJs } from 'src/app/services/external-js.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserServiceService } from 'src/app/services/user-service.service';
import { OrganisationService } from 'src/app/services/organisation.service';

@Component({
  selector: 'app-profile-setting',
  templateUrl: './profile-setting.component.html',
  styleUrls: ['./profile-setting.component.css']
})
export class ProfileSettingComponent implements OnInit {
  showErrMsg: string;
  editUserData: any;
  districtList:any = [];
  orgData:any = [];
  id: string;
  showError:boolean = false;
  errorMessages:any = [];
  isLoading: boolean = true;
  isSubmit: boolean = false;
  submitBtn: any;
  defOpOrg:any= 'Select School';
  defOpDistrict:any= 'Select District';
  constructor(private xj: externalJs, private service: UserServiceService, public loginService: LoginServiceService,
     private router: Router, private toastr: NotificationService, private route: ActivatedRoute, private orgService: OrganisationService) {
  }

  ngOnInit(): void {
    this.xj.extraInit();
    if(this.loginService.getCurrentUserRole().slug == "superident"){
      this.getDistrict();
    }
   
    this.editUser();
    if(this.loginService.getCurrentUserRole().slug == "principal"){
      this.getOrgList()
    }
   
  
  }
  // get district
  getOrgList() {   
    this.isLoading = true;
    this.orgService.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg= 'Select School';
      } else{
        this.orgData = [];
        this.defOpOrg= 'No School Found';
      }
      this.isLoading = false;
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
      this.isLoading = false;
    })
  }
   // fetch district
   getDistrict(){
    this.isLoading = true;
      this.service.fetchAllDistrict().subscribe((res:any) => {
       if(res.status){
        this.districtList = res.data;
        this.defOpDistrict= 'Select District';
       } else{
        this.orgData = [];
        this.defOpDistrict= 'No District Found';
      }
      this.isLoading = false;       
      }, (err:any)=>{
        this.toastr.showError('Something went wrong. Please try later','');
        this.router.navigate(['/home/dashboard']);
        this.isLoading = false;
      })
  }
  //  add form validation
  profileForm = new FormGroup({
    username: new FormControl('',),
    firstname: new FormControl('',),
    middleName: new FormControl('',),
    lastName: new FormControl('',),
    phoneNumber: new FormControl('',),
    email: new FormControl({value:''}),
    password: new FormControl('',),
    district: new FormControl({value:'', disabled:true}),
    organisation: new FormControl({value:'', disabled:true}),
  })

  // edit user api
  editUser() {
    this.isLoading = true;
    this.id = localStorage.getItem('userid')
    this.service.editUsers(this.id).subscribe((res:any) => {
      if(res.status){
        this.editUserData = res['data'];
        this.profileForm.patchValue({
          username: this.editUserData.username,
          firstname: this.editUserData.givenName,
          middleName: this.editUserData.middleName,
          lastName: this.editUserData.familyName,
          phoneNumber: this.editUserData.phone,
          email: this.editUserData.email,
          organisation: this.editUserData.orgSourcedIds,
          district: this.editUserData.district
        })
        this.isLoading = false;
      }  else{
        this.router.navigate(['/home/dashboard']);
        this.toastr.showError('Something went wrong. Please try later','');        
        this.isLoading = false;
      } 
    }, (err:any)=>{     
      this.router.navigate(['/home/dashboard']);         
      this.toastr.showError('Something went wrong. Please try later','');
      this.isLoading = false;       
    })
  }

  // add user api
  updateUser(e:any) {
    this.isSubmit = true;
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.showError = false;
    this.errorMessages = [];
    let r = (Math.random() + 1).toString(36).substring(7); //for generating random string
    const data = {
      roleId: localStorage.getItem('roleid'),
      userId: r,
      username: this.profileForm.value.username,
      givenName: this.profileForm.value.firstname,
      middleName: this.profileForm.value.middleName,
      familyName: this.profileForm.value.lastName,
      phone: this.profileForm.value.phoneNumber,
      email: this.profileForm.value.email,
      orgSourceId: this.editUserData.orgSourcedIds,
      district: this.editUserData.district,
    }
    this.service.updateUsers(this.id, data).then((res:any) => {
      if(res.success){
        this.toastr.showSuccess("Profile updated successfully!!")
        this.editUser();
      } else {
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }   
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }

}
