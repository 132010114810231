import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { DomSanitizer} from '@angular/platform-browser';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { DocumentService } from 'src/app/services/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { CommonService } from 'src/app/services/common.service';
import { MasterService } from 'src/app/services/master.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-doc-list',
  templateUrl: './doc-list.component.html',
  styleUrls: ['./doc-list.component.css']
})
export class DocListComponent implements OnInit {
  currentAddLnk = "/home/mychild/upload"; 
  currAddLnkTxt: string = "Upload Child Document";
  activeTab: any;
  isForwarded: any = '';
  isArchived: any;
  isLoading:boolean = true;

  totalPages:any;
  currentPage = 1;
  currentDocType:any;
  currentCourseId:any;
  currentSchoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  currentStudentName : any;

  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  tableDataPerPage: number = 20;

  docTypes:any = [];
  courseData:any = [];
  docItems:any = [];

  masterSelected:boolean;
  checkedList:any;


  showLightBox : boolean = false;
  previewLoading:boolean= false;
  currentImageIndex:number = 0;
  currentDocLength: number = 0;
  previewSRCType: string = '';
  previewDocName: string = '';
  previewStdName: string = '';
  previewOrgName: string = '';
  previewCourse: string = '';
  previewGrade: string = '';
  previewSession: string = '';
  previewComment: string = '';

  preiewLists: any;
  imgSrc:any;

  shareDocumentsIds:any = [];
  shareEmail:any;
  shareExpiryDate:any;
  shareComment:any;
  shareUserID:any;

  deleteId:any = '';
  isDeleted:boolean = false;
  sharing:any = false;

  approveDocIds: any = [];
  viewPage: boolean = false;
  minShareDate= new Date().getFullYear() + "-" + new Date().getMonth()+1 + "-" + new Date().getDate();
  message:any= '';
  showShareErr:boolean = false;
  shareErrMsg:any= [];
  mailIDs: any = [];
  constructor(private router:Router,private serviceCourse : CourseService,private docSrv:DocumentService, private route:ActivatedRoute , private xj:externalJs, private sanitizer: DomSanitizer, private serviceOrganisation: OrganisationService,public loginService: LoginServiceService, public commonSrv: CommonService, private MTSer : MasterService, private toaster: NotificationService) {
    let permission = JSON.parse(localStorage.getItem('userPermission'));
    if(permission?.isParents){
      this.viewPage = true;
    } else{
      this.viewPage = false;
    }

    this.route.queryParamMap
    .subscribe((params:any) => {
      let paramsObj = { ...params.keys, ...params };
      
      if(paramsObj.params.page){
        this.currentPage = paramsObj.params.page;
      }
      if(paramsObj.params.orgId){
        this.currentSchoolId = paramsObj.params.orgId;
      }
      if(paramsObj.params.course){
        this.currentCourseId = paramsObj.params.course;
      }else {
        this.currentCourseId = '';
      }
      if(paramsObj.params.type){
        this.currentDocType = paramsObj.params.type;
      }else {
        this.currentDocType = '';
      }
      if(paramsObj.params.name){
        this.currentStudentName = paramsObj.params.name;
      }else {
        this.currentStudentName = '';
      }
      if (paramsObj.params.approve) {
        this.activeTab = paramsObj.params.approve;
      } else {
        this.activeTab = 'yes';        
      }
      if(paramsObj.params.archived){
        this.isArchived = paramsObj.params.archived
        this.activeTab = '';
      } else{
        this.isArchived = ''
      }
      this.initComp();
    });
   }

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
   }
    this.getDocumentType();
    this.getCourse();
  }
  // map pages
  mapPages(lastPage = 1){
    let pageListArr = [];
    this.totalPages = [];
    if(lastPage <=1) return;
    if(lastPage <= 5){
      for(var i =1; i<= lastPage; i++){
          pageListArr.push(i);
      }
    }
    if(lastPage > 5){
      if(this.currentPage <=4){        
          for(var i =1; i<= 5; i++){
            pageListArr.push(i);
          }      
          pageListArr.push('...');
          pageListArr.push(lastPage);
      } else if(lastPage - this.currentPage <=3){
        pageListArr.push(1);
        pageListArr.push('...');
        for(var i =lastPage - 4; i<= lastPage; i++){
          pageListArr.push(i);
        } 
      }
      else {
        pageListArr.push(1);
        pageListArr.push('...');  

        for(var i = this.currentPage - 2; i<= this.currentPage + 2; i++){
          pageListArr.push(i);
        }

        pageListArr.push('...');
        pageListArr.push(lastPage);
      } 
    } 
     this.totalPages = pageListArr;
  }

  // pagination
  changeCurrentPage(page:any){
    if(page != '...'){
      this.currentPage = page;
      this.router.navigate(
        [], 
        {
          relativeTo: this.route,
          queryParams: { page: page },
          queryParamsHandling: 'merge'
        });
      }
  }

  // filter
  filterDocuments(){
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, course: this.currentCourseId, type: this.currentDocType, orgId: this.currentSchoolId, name:this.currentStudentName },
        queryParamsHandling: 'merge'
      });
  }

  getDocList(page=1, orgId = '', course = '', type = '', name = '', verified='', forwarded='',archived='' ){
    this.isLoading = true; 
    if(course == null){
      course = ''
    }
    if(type == null){
      type = ''
    }
    if(name == null){
      name = ''
    }
    this.docItems = []; 
    this.serviceOrganisation.fetchTeachChildDocList(page, orgId, course, type, name, verified, forwarded, archived).subscribe((res:any) => {
      if(res['status']){
        this.docItems = res['data']['data'];
        this.currentPage = res.data.current_page;
        this.mapPages(res.data.last_page);
        this.tableDataFrom = res.data.from;
        this.tableDataTo = res.data.to;
        this.tableDataTotal = res.data.total;
        this.tableDataPerPage = res.data.per_page;
        for(let item in this.docItems){
          this.docItems[item]['isSelected'] = false;
        }  
        this.message = '';   
        this.isLoading = false;  
      } else if(res['status_code'] === 404){
        this.message = 'No Document Data Found';       
        this.docItems = [];
        this.isLoading = false;
      } else{
        this.docItems = []; 
        this.message= "Something went wrong. Please try later";
        this.isLoading = false; 
      }       
    }, (err:any)=>{
      this.message= "Something went wrong. Please try later";
      this.isLoading = false;
    })
  }
  toggleTab(tabId: any){
    this.activeTab, this.isArchived = '';
    this.currentDocType = '';
    this.currentCourseId = '';
    this.currentSchoolId = parseInt(localStorage.getItem('orgIds')) || '';
    this.currentStudentName = '';
    this.activeTab = tabId;
    this.isArchived = '';
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: 1, course: '', type: '', orgId: '', name: '', approve: this.activeTab, archived: this.isArchived},
        queryParamsHandling: 'merge'
      });
  }
  showArchive(tabId:any){
    this.activeTab, this.isArchived = '';
    this.currentDocType = '';
    this.currentCourseId = '';
    this.currentSchoolId = parseInt(localStorage.getItem('orgIds')) || '';
    this.currentStudentName = '';
    this.activeTab = '';
    this.isArchived = tabId;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: 1, course: '', type: '', orgId: '', name: '', approve: this.activeTab, archived: this.isArchived},
        queryParamsHandling: 'merge'
      });
  }
  initComp(){
    // default open approve document for student 
    this.getDocList(this.currentPage, this.currentSchoolId, this.currentCourseId, this.currentDocType, this.currentStudentName, this.activeTab, this.isForwarded, this.isArchived);
    this.getCheckedItemList();
  }
  getDocumentType() {
    this.serviceCourse.getdocTypes().subscribe((res:any) => {
      if(res.status){
        this.docTypes = res.data
      } else{
        this.docTypes = []
      }
      // this.isLoading = false;
    })
  }
  // course
  getCourse(orgID: any = this.currentSchoolId) {
    this.courseData = [];
    this.serviceCourse.getAllCoursebyOrgid(orgID).subscribe(res => {
      if (res['status'] && res['status_code'] === 200) {
        this.courseData = res['data'];
        // this.isLoading = false;
      }
    })
  }
  clearFilter() {
    this.currentDocType = '';
    this.currentCourseId = '';
    this.currentSchoolId = parseInt(localStorage.getItem('orgIds')) || '';
    this.currentStudentName = '';
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1},
        queryParamsHandling: 'merge'
    });   

 }


 shareInit(ids:any = []) {
  this.shareDocumentsIds = ids;
  this.shareUserID = JSON.parse(localStorage.getItem('userid'));
}

// share the doc
shareDoc(){
  this.sharing = true;
  this.showShareErr = false;
  this.shareErrMsg= [];
  let formData = {
   user_id:this.shareUserID,
   email: this.mailIDs,
   document_id:this.shareDocumentsIds,
   expiry_date:this.shareExpiryDate,
   comment: this.shareComment
  }
  
 //  console.log(formData);
 //  return ;
 this.docSrv.share(formData).then((res:any)=>{
    if(res.status){
    this.hideSharePopup();        
    this.mailIDs = [];
    this.toaster.showSuccess('Share document successfully');
    } else{
    if(res['message']){
      this.shareErrMsg.push(res['message']);
    }else{
      this.shareErrMsg.push('Something went wrong. Please try later');
    }         
    this.showShareErr=true;   
    }
    this.shareEmail = '';
    this.shareExpiryDate = '';
    this.shareComment = '';
    this.sharing = false;
  }).catch(err=>{
    //this.hideSharePopup();
    let errData = err.error.errors;
    for(let key in errData){
      this.shareErrMsg.push(errData[key][0]);
    }
    if(errData == undefined || errData == null){
    this.shareErrMsg.push('Something went wrong. Please try later');
    }
    this.sharing = false; 
    this.showShareErr=true;     
  })
}


  imgSanitizer(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  pdfSanitizer(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openLightBox(event: any = ''){
    this.showLightBox = true;
    document.getElementsByTagName('body')[0].style.overflow = "hidden";
  }

  closeLightBox(event: any){
    this.showLightBox = false;
    document.getElementsByTagName('body')[0].style.overflow = "auto";
  }
  prevImage() {
    this.previewLoading = true;
    this.currentImageIndex--;
    if(this.currentImageIndex < 0) {
      this.currentImageIndex = this.preiewLists?.length-1  ;
    }
    this.openGallery(this.currentImageIndex, this.preiewLists);
  }

  nextImage() {
    this.previewLoading = true;
    this.currentImageIndex++;
    if(this.preiewLists?.length === this.currentImageIndex) {
      this.currentImageIndex = 0;
      // if (this.currentImageIndex === 0) {
      //   this.router.navigate(['/home/mychild']);
      // }
    }
    this.openGallery(this.currentImageIndex, this.preiewLists);

  }
// open gallery
openGallery(index, docArr: any = "") {
this.preiewLists = [];
this.previewSRCType='';
this.imgSrc= '';
if(!this.showLightBox){
 this.openLightBox();
}
if(!index) {
 this.currentImageIndex = 1;
}
this.preiewLists = docArr;
this.currentImageIndex = index;
this.currentDocLength = docArr.length;

this.previewLoading = true;
for (var i = 0; i < docArr.length; i++) {
       if (i === this.currentImageIndex ) {   
            this.previewSRCType = docArr[i].fileType;         
         if (docArr[i].fileType === "pdf") {           
           if(this.activeTab === 'yes'){
             this.imgSrc = this.pdfSanitizer('https://ipfs.io/ipfs/'+this.preiewLists[i].documentHash+'#toolbar=0');
           } else{
             this.imgSrc = this.pdfSanitizer(this.preiewLists[i].documentLocalPath+'#toolbar=0');
           }
           
         } else {
           if(this.activeTab === 'yes'){
             this.imgSrc = this.imgSanitizer('https://school.mypinata.cloud/ipfs/'+this.preiewLists[i].documentHash);
           } else{
             this.imgSrc = this.imgSanitizer(this.preiewLists[i].documentLocalPath);
           }
         }
         this.approveDocIds.push(this.preiewLists[i].id);
         this.previewDocName = this.preiewLists[i].documentTypeName;
         if(this.preiewLists[i].firstName != null){
           this.previewStdName = this.preiewLists[i].firstName + ' ';
         } 
         if(this.preiewLists[i].middleName != null){
           this.previewStdName += this.preiewLists[i].middleName + ' ';
         } 
         if(this.preiewLists[i].lastName != null){
           this.previewStdName += this.preiewLists[i].lastName;
         } 
         this.previewOrgName = this.preiewLists[i].orgName;
         this.previewCourse = this.preiewLists[i].courseName;
         this.previewGrade = this.preiewLists[i].grade?.grade || '';
         this.previewSession = this.preiewLists[i].sessionName;
         this.previewComment = this.preiewLists[i].remarks || '';
         setTimeout(() => {
           this.previewLoading = false;   
         }, 100);            
         break;
       }
  }
}


// Check All Checkbox Checked
isAllSelected() {
this.masterSelected = this.docItems.every(function(item:any) {
   return item.isSelected == true;
})
this.getCheckedItemList();
}

checkUncheckAll() {
for (var i = 0; i < this.docItems.length; i++) {
 this.docItems[i].isSelected = this.masterSelected;
}
this.getCheckedItemList();
}

// unchecked select item
unCheckSelItem(){
for (var i = 0; i < this.docItems.length; i++) {
 if(this.docItems[i].isSelected)
   this.docItems[i].isSelected = false;
   this.masterSelected = false;
}
this.getCheckedItemList();
}

// remove from preview 
rePreVItem(dataId: number){ 
let index = this.docItems.findIndex(rank => rank.id === dataId);
this.docItems[index].isSelected = false;
this.masterSelected = false;  
this.getCheckedItemList();  
if(this.checkedList.length === 0){
 this.xj.closeModalPopup("#previewDocs");
}
}
// Get List of Checked Items
getCheckedItemList(){
this.checkedList = [];
for (var i = 0; i < this.docItems.length; i++) {
 if(this.docItems[i].isSelected){
   this.checkedList.push(this.docItems[i]);
 }
} 

}


// showSharePopup
showSharePopup(){
  this.shareEmail='';
  this.shareExpiryDate = '';
  this.shareComment='';
let Ids = [];
this.checkedList.forEach((li:any) => {
 Ids.push(li.id);
});
this.shareInit(Ids);
this.xj.closeModalPopup("#previewDocs");
setTimeout(() => {
 this.xj.openModalPopup("#shareRole");
}, 200);

}

// showSharePopup
hideSharePopup(){
 this.xj.closeModalPopup("#shareRole");

}

setDelete(id){
  this.deleteId = id;
  this.isDeleted = true;
}
archiveItem(id){
  this.deleteId= id;
  this.isDeleted = false;
  this.confirmDelete();  
}
cancelDelete(){
this.deleteId = '';
}
confirmDelete(){
  const delAction = (this.isDeleted) ? 'delete' : '';
  this.docSrv.delete(this.deleteId, delAction).then((res:any)=>{
      if(res.status){
        this.deleteId = '';   
        
        this.initComp();
        this.hideDeletePopup();
        if(this.isDeleted){
          this.toaster.showSuccess("Document deleted successfully !!");
        } else{
          this.toaster.showSuccess("Document archived successfully !!");
        }
        this.isDeleted = false;   
      } else{
        if(this.isDeleted){
          this.toaster.showError('Unable to delete documet', '');
        } else{
          this.toaster.showError('Unable to archived documet', '');
        }
        this.initComp();
        this.hideDeletePopup();
      }
    }).catch(err=>{
      this.deleteId = '';
      this.initComp();
      this.hideDeletePopup();
      this.toaster.showError('Something went wrong. Please try later', '');
    })  
  }

  unarchiveItem(id){
  this.docSrv.delete(id, 'undo').then((res:any)=>{
    if(res.status){
      this.initComp();
      this.toaster.showSuccess("Unarchived successfully !!");      
    } else{
      this.toaster.showError('Unable to unarchived documet', '');
      this.initComp();
    }
  }).catch(err=>{
    this.initComp();
    this.toaster.showError('Something went wrong. Please try later', '');
  })
}
  hideDeletePopup(){
    this.deleteId = '';
    this.xj.closeModalPopup("#deletePopup");

  }


  // multiple  email

  isValidEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }
  onKeyDown(e:any){
    // console.log(e)
    const input = e.target.value;   
    // add emailids on key press tab, space
    if(e.keyCode === 32 || e.keyCode === 9){     
      if(this.isValidEmail(input)){
        this.mailIDs.push(input);
        e.target.value = '';
        this.shareEmail =  this.shareEmail.replace(',', '');
      //  console.log(this.shareEmail);
      } else{
        e.target.value = '';
        this.shareEmail = '';
      }
    }
    // backspace to delete emailids
    if(e.keyCode === 8){
      if(this.mailIDs.length > 0 && e.target.value === ''){
        this.mailIDs.pop();
      } else{
        return;
      }
    }

    // console.log(this.mailIDs);
  }


  deleteEmil(index:any){
    this.mailIDs.splice(index, 1);
    // console.log(this.mailIDs);
  }

  onBlurEmail(e:any){
    const input = e.target.value;
    if(this.isValidEmail(input)){
      this.mailIDs.push(input);
      e.target.value = '';
      if(e.keyCode === 188 ){
        this.shareEmail =  this.shareEmail.replace(',', '');
      }
     
    } else{
      e.target.value = '';
      this.shareEmail = '';
    }
  }


  // approved or reject document by parent or student
  approvedForm(curDocId:any, e:any){
    let docs = [];
    docs.push(curDocId);
    $(e.target.closest('.parAppBtn')).addClass('loader disabled');
    // return;
    const formData = {
      'documentId': docs,
      'isApproved': 1
    }
    this.serviceOrganisation.approveAlldocs(formData).then(res => {
      // console.log(res);       
      if (res['status']) {        
        this.initComp();
        this.toaster.showSuccess('Approved Document successfully');
      } else {
        this.toaster.showError('Something went wrong. Please try later', '');
      }
      $(e.target.closest('.parAppBtn')).removeClass('loader disabled');
    }, (err: any) => {
      this.toaster.showError('Something went wrong. Please try later', '');  
      $(e.target.closest('.parAppBtn')).removeClass('loader disabled');   
    })
  }

  rejectId: any;
  rejectComment:any;
  rejecting:boolean =false;
  rejData(id:any){
    this.xj.openModalPopup('#rejectDoc');
    this.rejectId = id;
  }
  rejectData(){
    if(!this.rejectComment){
      document.getElementById('rejectComment').classList.add('is-invalid');
      return;
    }
    const formData = {
      'documentId': this.rejectId,
      'comment': this.rejectComment
    }
    this.rejecting = true;
    this.showShareErr = false;
    this.shareErrMsg = [];   
    document.getElementById('rejectComment').classList.remove('is-invalid');
    this.docSrv.reject(formData).then((res: any) => {
      if (res.status) {
        this.xj.closeModalPopup('#rejectDoc');       
        this.toaster.showSuccess('Document rejected successfully');
        this.initComp();
      } else {
        if (res['message']) {
          this.shareErrMsg.push(res['message']);
        } else {
          this.shareErrMsg.push('Something went wrong. Please try later');
        }
        this.showShareErr = true;
      }
      this.rejectId = null;
      this.rejectComment = null;
      this.rejecting = false;
    }).catch(err => {
      //this.hideSharePopup();
      let errData = err.error.errors;
      for (let key in errData) {
        this.shareErrMsg.push(errData[key][0]);
      }
      if (errData == undefined || errData == null) {
        this.shareErrMsg.push('Something went wrong. Please try later');
      }
      this.rejecting = false;
      this.showShareErr = true;
    })
  }

}
