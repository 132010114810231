import { Component, OnInit } from '@angular/core';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css']
})
export class DashboardPageComponent implements OnInit {
  userRole: any;
  dashboardData: any;
  isParents: boolean  = false;
  constructor(public loginService: LoginServiceService, private dashsrv: DashboardService) { 
    let permission = JSON.parse(localStorage.getItem('userPermission'));
    if(permission?.isParents){
      this.isParents = permission?.isParents
    } else{
      this.isParents = false;
    }
    this.loginService.isCompleteProfile();
  }

  ngOnInit(): void {
    // window.location.reload()
    this.getDashboardData();
  }

  getDashboardData() {
      this.dashsrv.getData().then((res: any) => {
        this.dashboardData = res.data;
      }).catch((err: any) => {

      })
  }

}
