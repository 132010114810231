import { Component, OnInit } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { environment as env } from '../../../environments/environment';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  errMsg: string;
  submitBtn:any;
  isLoading: boolean;

  constructor(private http :HttpClient, 
    private route: ActivatedRoute, 
    private router :Router) { }

  ngOnInit(): void {
  }

  getEmail(email, e:any) {
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isLoading = true;
    this.errMsg='';
    if (email != '' && email != undefined) {
      this.http.post(env.apiurl + 'reset-password', {
        email: email,
        "send_otp" : "1",
	      "update_password" : "0",
	      "verify_otp" : "0",
      }).subscribe(rawData => {       
        if (rawData['status']) {
          localStorage.setItem('res_email', email);
          this.router.navigate(['/verify-otp', { isForgotpas: 1 }]);
        } else {
          this.errMsg = rawData['message'];
        }
        this.isLoading = false;
        this.submitBtn.disabled = false;
      }, (err:any)=>{
        this.errMsg = "Something went wrong. Please try later";
        this.isLoading = false;
        this.submitBtn.disabled = false;
      });
    } else {
      this.errMsg = "Please enter a valid email";
      this.isLoading = false;
      this.submitBtn.disabled = false;
    }

  }

}
