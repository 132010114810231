import { Component, OnInit } from '@angular/core';
import { externalJs} from 'src/app/services/external-js.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BulkImportCsvService } from 'src/app/services/bulk-import-csv.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-bulk-upload-user',
  templateUrl: './bulk-upload-user.component.html',
  styleUrls: ['./bulk-upload-user.component.css']
})
export class BulkUploadUserComponent implements OnInit {
  userPermissionData:any;
  viewPage:boolean = false;
  isSubmit: boolean = false;
  isLoading:boolean= true;
  submitBtn:any;
  constructor(private xj: externalJs, private router: Router, private serviceOrganisation : OrganisationService, public loginService : LoginServiceService, private serviceBulkUpload: BulkImportCsvService, private FB : FormBuilder, private toastr: NotificationService) { 
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if (element.module === "Bulk Upload") {
        this.userPermissionData = element?.permission;
      }
    });
    this.userPermissionData?.forEach(element => {
      if(element?.permission === 'Bulk user upload'){
        this.viewPage = true;
      }
    });
  }
  orgData: any;
  courseData: any;
  errorMessages:any =[];
  showError:boolean = false;
  schoolId: any = parseInt(localStorage.getItem('orgIds')) ||  '';
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    if(this.schoolId){
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    } else{
      this.serviceOrganisation.allSchool().subscribe((res:any) => {
        if(res.status){
          this.orgData = res.data
        }else{
          this.orgData = []
        }
        this.isLoading = false;
      })
    }
    
  }

 uploadBulkUser = this.FB.group({
  school: [this.schoolId],
  userCSV: ['']

 })

 get m(){
  return this.uploadBulkUser.controls;
}


onChnageUser(event){
  if (event.target.files && event.target.files[0]) {
    let fileCSV =  event.target.files[0];
    this.uploadBulkUser.patchValue({
      userCSV: fileCSV
    })
  }
}

  // uploadItem
uploadDocCSV(e:any){
  this.submitBtn = e.target;
  this.submitBtn.disabled = true;
  this.isSubmit = true;
  // this.isLoading = true;
  this.errorMessages = [];
  this.showError = false;
  const formData = new FormData();
  formData.append('schoolId', this.uploadBulkUser.value.school);
  formData.append('csv_file', this.uploadBulkUser.value.userCSV);
  this.serviceBulkUpload.importBulkUserCSV(formData).then(res => {
    // console.log(res);
    if(res['status']){
      this.toastr.showSuccess(res['message']);
      this.router.navigate(['/home//bulk/view-upload-user'], { queryParams: { schoolId: this.uploadBulkUser.value.school} });    
    } else{
      if(res['message']){
        this.errorMessages.push(res['message']);
      }else{
        this.errorMessages.push('Something went wrong. Please try later');
      }         
      this.showError = true;
    }
    this.isSubmit = false;
    this.submitBtn.disabled = false;
  }).catch((err:any)=>{
    let errData = err.error.errors;
    for(let key in errData){
      this.errorMessages.push(errData[key][0]);
    }
    if(errData == undefined || errData == null){
      this.errorMessages.push('Something went wrong. Please try later');
    }
    this.showError = true;
    this.isSubmit = false;
    this.submitBtn.disabled = false;
  });
 
}



}
