<div id="main-wrapper">
    <app-sidebar></app-sidebar>
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form"  [ngClass]="{'loading position-relative': isLoading}">
                <div class="apploader" [ngClass]="{'show': isLoading}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Getting your data...</span>
                    </div>
                </div>
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Profile Setting</h5>
                        <!-- <a routerLink="/user"><i class="bi bi-chevron-left mr-1"></i>Back to list</a> -->
                    </div>
                </div>
                <form [formGroup]="profileForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-9">
                                <!-- <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="school_code" class="text-dark fw-500 label-text">User Name
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="username" id="school_code"
                                                class="form-control" placeholder="User Name">
                                        </div>

                                    </div>
                                </div> -->
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="school_name" class="text-dark fw-500 label-text">First Name<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="firstname" id="school_name"
                                                class="form-control" placeholder="First name">
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="principal_name" class="text-dark fw-500 label-text">Middle Name
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="middleName" id="principal_name"
                                                class="form-control autoComplete" placeholder="Middle name">
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="secretary_name" class="text-dark fw-500 label-text">Last Name<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="lastName" id="secretary_name"
                                                class="form-control autoComplete" placeholder="Last name">
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="principal_name" class="text-dark fw-500 label-text">Phone Number
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="phoneNumber" id="principal_name"
                                                class="form-control autoComplete" placeholder="Phone Number">
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="principal_name" class="text-dark fw-500 label-text">Email<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="email" id="principal_name"
                                                class="form-control autoComplete" placeholder="Email">
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center" *ngIf='loginService.getCurrentUserRole().slug == "superident"'>
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="principal_name" class="text-dark fw-500 label-text">District<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': districtList?.length < 1}">
                                            <select formControlName="district" class="form-control">
                                                <option value="">{{ defOpDistrict }}</option>
                                                <option *ngFor="let data of districtList"  [value]="data.id">{{data.name}}</option>
                                            </select>
                                            <div class="spinner-border spinner-border-sm"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center" *ngIf='loginService.getCurrentUserRole().slug == "principal"'>
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="principal_name" class="text-dark fw-500 label-text">School<sup class="text-danger">*</sup>
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': orgData?.length < 1}">                                     
                                            <select formControlName="organisation" class="form-control">
                                                <option value="">{{ defOpOrg }}</option>
                                                <option *ngFor="let data of orgData"  [value]="data.id">{{data.name}}</option>
                                            </select>
                                            <div class="spinner-border spinner-border-sm"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="showError">
                            <div class="alert alert-danger" role="alert">
                                {{ errorMessages[0] }}
                            </div>
                        </ng-container>
                    </div>
                    <div class="card-footer">
                        <div class="form-group mb-0">                            
                            <button type="submit" class="btn btn-success" (click)="updateUser($event)">Update Profile
                                <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isSubmit"></span>
                            </button>
                            <button type="reset" class="btn btn-secondary mr-3" routerLink="/home/dashboard">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>