import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { externalJs } from 'src/app/services/external-js.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AcademicService } from 'src/app/services/academic.service';

@Component({
  selector: 'app-session-list',
  templateUrl: './session-list.component.html',
  styleUrls: ['./session-list.component.css']
})
export class SessionListComponent implements OnInit {
  sessionData: any = [];
  userId: any;
  message: string;
  endDate = '';
  userPermissionData: any;
  editButton: boolean = false;
  deleteButton: boolean = false;
  viewPage: boolean = false;
  addButton: boolean;
  currentAddLnk: any;
  currAddLnkTxt: string = "Add Academic Session";
  isLoading = true;
  currentPage: number = 1;
  lastPage: number = 1;
  pageList: any = [];


  currentYear: any;
  currentType: any;

  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  tableDataPerPage: number = 20;

  types = [
    {name:'Term', value: 'term'},
    {name:'Grading Period', value: 'gradingPeriod'},
    {name:'Semester', value: 'semester'},
    {name:'School Year', value: 'schoolYear'}
  ]

  constructor(private xj: externalJs, private service: AcademicService, private route:ActivatedRoute , private router: Router, private toastr: NotificationService) {
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if (element.module === "academic session") {
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {
      if (element?.permission === 'Delete Session') {
        this.deleteButton = true;
      } else if (element?.permission === 'Update Session') {
        this.editButton = true;
      } else if (element?.permission === 'create session') {
        this.currentAddLnk = '/home/add-academic-session';
        this.addButton = true;
      } else if(element?.permission === 'Academic Session'){
        this.viewPage = true;
      }
    });
    

    this.route.queryParamMap
      .subscribe((params:any) => {
        let paramsObj = { ...params.keys, ...params };
        
        if(paramsObj.params.page){
          this.currentPage = paramsObj.params.page;
        }
       
        if(paramsObj.params.year){
          this.currentYear = paramsObj.params.year;
        }else {
          this.currentYear = '';
        }
        if(paramsObj.params.type){
          this.currentType = paramsObj.params.type;
        }else {
          this.currentType = '';
        }
        this.initComp();
      });
  }
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
   }
    this.xj.extraInit();
  }


  initComp(){
    this.sessionList();
  }
  // get session list

  sessionList() {
    this.isLoading = true;
    this.service.viewSession(this.currentPage, this.currentYear, this.currentType).subscribe(res => {
      if (res['status']) {
         this.sessionData = res['data'].data
         this.currentPage = res['data'].current_page;
         this.lastPage = res['data'].last_page;
         this.mapPageList(res['data'].last_page);
         this.tableDataFrom = res['data']['from'];
         this.tableDataTo = res['data']['to'];
         this.tableDataTotal = res['data']['total'];
         this.tableDataPerPage = res['data']['per_page'];
         this.isLoading = false;
         this.message= '';
      } else if(res['status_code'] === 404){
        this.message = 'Academic session not found';     
        this.sessionData = [];
        this.isLoading = false;
      } else {
        this.sessionData=[];
        this.message = "Something went wrong. Please try later";
        this.isLoading = false;
      }
    }, (err:any)=>{
      this.message = "Something went wrong. Please try later";
      this.isLoading = false;
    })
  }

  // for mapping page list
  mapPageList(lastPage) {
    let pageListArr = [];
    this.pageList = [];
    if(lastPage <=1) return;
    if(lastPage <= 5){
      for(var i =1; i<= lastPage; i++){
          pageListArr.push(i);
      }
    }
    if(lastPage > 5){
      if(this.currentPage <=4){        
          for(var i =1; i<= 5; i++){
            pageListArr.push(i);
          }      
          pageListArr.push('...');
          pageListArr.push(lastPage);
      } else if(lastPage - this.currentPage <=3){
        pageListArr.push(1);
        pageListArr.push('...');
        for(var i =lastPage - 4; i<= lastPage; i++){
          pageListArr.push(i);
        } 
      }
      else {
        pageListArr.push(1);
        pageListArr.push('...');  

        for(var i = this.currentPage - 2; i<= this.currentPage + 2; i++){
          pageListArr.push(i);
        }

        pageListArr.push('...');
        pageListArr.push(lastPage);
      } 
    } 
    this.pageList = pageListArr;
  }
  // change page
  changePage(page) {
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: page },
        queryParamsHandling: 'merge'
      });
   
  }
  // go to edit page
  goToEditPage(id) {
    this.router.navigate(['/home/edit-academic-session'], { queryParams: { id: id } })
  }

  // get delete data id
  deleteSessionId(id) {
    this.userId = id
  }

  // delete api
  deleteSessionApi() {
    this.service.deleteSession(this.userId).subscribe(res => {      
      this.initComp();
      this.toastr.showSuccess("Session deleted successfully !!")
    })
  }

  //  search
  // getAllSession(){
  //   this.isLoading = true
  //   this.message = ''
  //   this.service.getAllSessionApi('year='+this.year,'type=' +this.type).subscribe(res => {
  //     this.isLoading = false
  //      if(res['message'] =='Session not found'){
  //        this.message = 'Academic session not found'
  //      }else{
  //      this.toastr.showSuccess(res['message']);
  //        this.sessionData = res['data'].data
  //      }
  //   //  }
  //   })
  // }

  clearFilter() {
    this.currentYear = '';
    this.currentType = '';
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: 1, year: '', type: ''},
      queryParamsHandling: 'merge'
    });
  
  }

  filterDocuments(){
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, year: this.currentYear, type: this.currentType },
        queryParamsHandling: 'merge'
      });
  }

}
