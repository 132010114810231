   <!-- main-wrapper -->
   <div id="main-wrapper">
    <!-- sidebar menu  -->
  <app-sidebar></app-sidebar>
    <!-- sidebar menu -->
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form" [ngClass]="{'loading position-relative': isLoading}">
                <div class="apploader" [ngClass]="{'show': isLoading}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Getting your data...</span>
                    </div>
                </div>
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Edit Parent</h5>
                        <a routerLink="{{isRedirectTo}}" [queryParams]="redirectSchool ? { schoolId: redirectSchool} : {} "><i class="bi bi-chevron-left mr-1"></i>Back to list</a>
                    </div>
                </div>
                <form [formGroup]= "editParentForm">
                    <div class="card-body">
                        <div class="row">                                
                            <div class="col-lg-9">
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="first_name" class="text-dark fw-500 label-text">Name<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="row name-row">
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <input type="text" formControlName="firstName" id="first_name" class="form-control"
                                                        placeholder="First Name">
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <input type="text" formControlName="middleName" id="middle_name" class="form-control"
                                                        placeholder="Middle Name">
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <input type="text" formControlName="lastName" id="last_name" class="form-control"
                                                        placeholder="Last Name">
                                                </div>
                                            </div>
                                        </div>
                                        
                                       
                                    </div>
                                </div>
                                <!-- <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="dob" class="text-dark fw-500 label-text">Date of Birth :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="dob" id="dob"  (blur)="dob($event)" class="form-control datepicker" placeholder="Date of Birth" readonly >
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="genderType" class="text-dark fw-500 label-text">Gender
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="checkbox-label checkbox-t " for="male">
                                                <input type="radio" formControlName="genderType" id="male" value = "Male">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">Male</span>
                                            </label>
                                            <label class="checkbox-label checkbox-t" for="female">
                                                <input type="radio" formControlName="genderType" id="female" value="Female">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">Female</span>
                                            </label>
                                        </div>
                                    </div>
                                </div> -->

                                
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="phone_number" class="text-dark fw-500 label-text">Phone Number :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="tel" formControlName="number" id="phone_number" class="form-control"
                                                placeholder="Phone Number">
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="email_address" class="text-dark fw-500 label-text">Email Address<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="email" formControlName="email" id="email_address" class="form-control autoComplete"
                                                placeholder="Email Address">
                                        </div>                                           
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="address" class="text-dark fw-500 label-text">Address</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input type="text" formControlName="address" id="address" class="form-control" placeholder="Address">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input type="text" formControlName="state" id="state" class="form-control" placeholder="State">

                                                    <!-- <select  id="" class="form-control" formControlName = "">
                                                        <option value="">Select State</option>
                                                        <option value=176>Alabama</option>
                                                        <option value=1061>Alaska</option>
                                                        <option value=181>Arizona</option>
                                                        <option value=101>Arkansas</option>
                                                    </select> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-row">
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    <input type="text" formControlName="city" id="city" class="form-control" placeholder="City">
                                                    <!-- <select name="" id="" class="form-control"><option value="">Select City</option></select> -->
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" formControlName = "zipCode" placeholder="Zip Code">
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                           
                               

                                <div class="row align-items-md-center" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="school_name" class="text-dark fw-500 label-text">School<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group asyncData" [ngClass]="{'is-loading': orgData?.length < 1}">
                                            <select class="form-control" formControlName="school" id="school_name">
                                                <option value="">{{ defOpOrg }}</option>
                                                <option *ngFor="let data of orgData;" value="{{data.id}}">{{ data.name }}</option>
                                           </select>
                                           <div class="spinner-border spinner-border-sm"></div>
                                        </div>                                           
                                    </div>
                                </div>
                                

                        
                                
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="statusType" class="text-dark fw-500 label-text">Status
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="checkbox-label checkbox-t " for="active">
                                                <input type="radio" formControlName="statusType" id="active" [value]= "1">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">Active</span>
                                            </label>
                                            <label class="checkbox-label checkbox-t" for="inactive">
                                                <input type="radio" formControlName="statusType" id="inactive" [value] = "0">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">In-Active</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <ng-container *ngIf="showError">
                            <div class="alert alert-danger" role="alert">
                                {{errorMessages[0]}}
                            </div>
                        </ng-container>
                    </div>
                    <div class="card-footer">
                        <div class="form-group mb-0">                          
                            <button type="submit" class="btn btn-success" (click)="updateParent($event)">Save
                                <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isSubmit"></span>
                            </button>
                            <button type="reset" routerLink="{{isRedirectTo}}" [queryParams]="redirectSchool ? { schoolId: redirectSchool} : {} " class="btn btn-secondary mr-3">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>
<!-- main-wrapper end -->
