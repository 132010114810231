import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { AcademicService } from 'src/app/services/academic.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TeacherService } from 'src/app/services/teacher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassService } from 'src/app/services/class.service';
import { CourseService } from 'src/app/services/course.service';
import { MasterService } from 'src/app/services/master.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-edit-teacher',
  templateUrl: './edit-teacher.component.html',
  styleUrls: ['./edit-teacher.component.css']
})
export class EditTeacherComponent implements OnInit {
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  selectedArr: any = [];
  selectedClasses: any = [];
  checked: any;
  classList: any;
  empForm: FormGroup;
  courseList: any;
  id: any;
  teacherEditData: any;
  teacherInfo: any;
  editCourseId = [];
  editCourseIds = [];
  userPermissionData: any;
  viewPage:boolean = false;
  isRedirectTo: any = '';
  redirectSchool: any = '';
  isSubmit: boolean = false;
  submitBtn:any;

  isLoading:boolean = true;

  defOpOrg:any ='Select School';
  defOpGrade:any ='Select Grade';
  defOpClass:any = 'Select Class';
  gradLoader:boolean= false;
  courseLoader:boolean=false;
  constructor(private xj: externalJs, private fb: FormBuilder, private courseService: CourseService, private serviceAcademic: AcademicService, private route: ActivatedRoute,
    private classService: ClassService, private router: Router, private toastr: NotificationService, private service: TeacherService, private serviceOrganisation: OrganisationService, public loginService: LoginServiceService, private masterService: MasterService,) {
    this.empForm = this.fb.group({
      employees: this.fb.array([]),
    })
    this.route.queryParamMap.subscribe(queryParams => {
      this.id = queryParams.get("id");
      if(queryParams.get("redirectTo")){
        this.isRedirectTo = '/home/bulk/view-upload-user'
      } else{
        this.isRedirectTo = '/home/teacher';
      } 
      if(queryParams.get('schoolId')){
        this.redirectSchool = queryParams.get('schoolId');
      } else{
        this.redirectSchool= '';
      }
     
    })
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if(element.module === "Teacher"){
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {      
      if(element?.permission === 'Update Teacher'){
        this.viewPage = true;
      }
    });
  }
  sessionData: any;
  orgData: any;
  showError = false;
  errorMessages = [];
  gradeItems:any = [];
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'select_txt',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    //this.viewSession();  
    
    if(this.schoolId){
      this.getGradeItems(this.schoolId);
      this.getClass(this.schoolId);     
    } else{
      this.viewOrganisationApi();  
    }
    this.initialForm();
    this.editTeacher();
  }


  employees(): FormArray {
    return this.empForm.get("employees") as FormArray
  }
  newEmployee(): FormGroup {
    return this.fb.group({
      course: '',
      class: '',
    })
  }
  addEmployee() {
    this.employees().push(this.newEmployee());
  }

  removeEmployee(empIndex: number) {
    this.employees().removeAt(empIndex);
  }

  initialForm() {
    this.addEmployee();
  }
  // view session api
  viewSession() {
    this.serviceAcademic.viewSession().subscribe(res => {
      this.sessionData = res['data']['data'];
    })
  }

  // view organisationApi
  viewOrganisationApi() {
    this.serviceOrganisation.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg='Select School';
        this.addTeacherForm.controls['school'].enable();
      } else{
        this.orgData = [];
        this.defOpOrg='No School Found';
      }      
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/teacher']);
      this.isLoading = false;
    })
  }

  schoolChange(e){
    this.gradLoader= true;
    this.courseLoader= true;
    this.addTeacherForm.controls['grade'].disable();
    this.addTeacherForm.get('grade').setValue('');
    this.getGradeItems(e);
    this.getClass(e);
  }
  getGradeItems(schoolId:any){
    this.gradLoader= true;
    this.masterService.allGradeList(schoolId).subscribe((res:any)=>{
      if(res.status){
        this.gradeItems =  res.data;
        this.defOpGrade='Select Grade';
        this.addTeacherForm.controls['grade'].enable();
      } else{
        this.gradeItems = [];
        this.defOpGrade='No Grade Found';
      }
      this.gradLoader= false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/teacher']);
      this.isLoading = false;
    })
  }

 // add student form
 addTeacherForm = new FormGroup({
  firstName: new FormControl('',),
  middleName: new FormControl('',),
  lastName: new FormControl('',),
  number: new FormControl('',),
  email: new FormControl('',),
  userName: new FormControl('',),
  password: new FormControl('',),
  state: new FormControl('',),
  city: new FormControl('',),
  zipCode: new FormControl('',),
  address: new FormControl('',),
  parents: new FormControl('',),
  school: new FormControl({value: this.schoolId, disabled:true}),
  course: new FormControl('',),
  class: new FormControl('',),
  startDate: new FormControl('',),
  endDate: new FormControl('',),
  statusType: new FormControl('',),
  grade: new FormControl({value:'', disabled:true}),

})
  editTeacher() {
    this.employees().clear();
    this.service.editTeacher(this.id).subscribe(res => {
      if(res['status']){
        this.teacherEditData = res['data'];
        // for (let i = 0; i < this.teacherEditData.class.length; i++) {
        //   var titleData = this.teacherEditData.class[i]
        //   this.addEmployee();
        //   this.employees().at(i).patchValue({
        //     course: titleData.courseId,
        //     class: titleData.classId,
        //   })
        // }
        // if (titleData.classId != undefined || titleData.classId != null) {
        //   this.getClass(titleData.classId);
        // }
        // this.teacherEditData.class.forEach(element => {
        //   this.editCourseId.push(element.title)
        // });
        this.teacherEditData.class.forEach(element => {
          this.editCourseIds.push(element.id)
        });
        // this.getClass(this.teacherEditData.orgSourcedIds);
        for(let item of this.teacherEditData.class){        
         this.selectedClasses.push({
            'id':  item.id,
            'select_txt':  item.title + ' - ['+ item.classCode +'] - (' +  item.courseName + ')'
          })
          // console.log(this.classList[item]['title'] + ' - (' + this.classList[item]['course'] + ')')
        }
        if(!this.schoolId){
          this.gradLoader= true;
          this.courseLoader= true;
          this.addTeacherForm.controls['grade'].disable();
          this.getGradeItems(this.teacherEditData.orgSourcedIds);
          this.getClass(this.teacherEditData.orgSourcedIds);
        }
        
        this.addTeacherForm.patchValue({
          firstName: this.teacherEditData.givenName,
          middleName: this.teacherEditData.middleName,
          lastName: this.teacherEditData.familyName,
          // dob: this.teacherInfo.birthdate,
          // genderType: this.teacherInfo.sex,
          grade: (this.teacherEditData.grades === 0 ? '' : this.teacherEditData.grades ) || '',
          // countryCode: this.teacherInfo.countryOfBirthCode,
          number: this.teacherEditData.phone,
          email: this.teacherEditData.email,
          userName: this.teacherEditData.username,
          // password: this.teacherEditData.password,
          state: this.teacherEditData.state,
          city: this.teacherEditData.city,
          zipCode: this.teacherEditData.zipCode,
          address: this.teacherEditData.sms,
          parents: this.teacherEditData.givenName,
          school: this.teacherEditData.orgSourcedIds,
          course: this.selectedClasses,
          class: this.teacherEditData.givenName,
          // startDate: this.teacherEditData.beginDate,
          // endDate: this.teacherEditData.endDate,
          startDate: '',
          endDate: '',
          statusType: this.teacherEditData.enabledUser,
        })
        this.isLoading=false; 
      } else{
        this.router.navigate(['/home/teacher']);
        this.toastr.showError('Something went wrong. Please try later','');       
        this.isLoading=false; 
      }
    },(err:any)=>{
      this.router.navigate(['/home/teacher']);
      this.toastr.showError('Something went wrong. Please try later','');        
      this.isLoading=false;
    })
  }
    
 


  // add teacher api
  addTeacher(e:any){
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    this.showError = false;
    this.errorMessages = [];
    var course = []
    for(const data of this.addTeacherForm.value.course){
      course.push(data.id) 
    }
    //var course = this.editCourseIds.concat(this.selectedAcademic)
    //var classData = this.employees().getRawValue();
    var orgId = localStorage.getItem('orgIds')
    const data = {
      username: this.addTeacherForm.value.userName,
      givenName: this.addTeacherForm.value.firstName,
      middleName: this.addTeacherForm.value.middleName,
      familyName: this.addTeacherForm.value.lastName,
      email: this.addTeacherForm.value.email,
      phone: this.addTeacherForm.value.number,
      grades: this.addTeacherForm.value.grade,
      enabledUser: this.addTeacherForm.value.statusType,
      schoolSourcedId: this.addTeacherForm.value.school ? this.addTeacherForm.value.school: orgId,
      sms: this.addTeacherForm.value.address,
      country: this.addTeacherForm.value.countryCode,
      state: this.addTeacherForm.value.state,
      city: this.addTeacherForm.value.city,
      pinCode: this.addTeacherForm.value.zipCode,
      birthdate: this.addTeacherForm.value.dob || '',
      courseSourcedId: course,
      parents: this.addTeacherForm.value.parents,
      beginDate: '',
      endDate: '',
    }
   
    this.service.updateTeacher(this.id, data).then(res => {
      if (res['status'] == true) {
        this.router.navigate(['/home/teacher'])
        this.toastr.showSuccess('Teacher updated successfully');
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }         
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err: any) => {
      let errData = err.error.errors;
      for (let key in errData) {
        this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }
  // get course list

  getCourseList() {
    this.courseService.viewCourse().subscribe(res => {
      this.courseList = res['data']['data']
    })
  }

  // get class by course id
  getClass(schoolId = this.schoolId) {
    this.courseLoader = true;
    this.classList = [];
    this.addTeacherForm.get('course').setValue('');
    this.classService.getAllClasses(schoolId).subscribe(res => {
      if(res['status']){
        this.classList = res['data'];
        for(let item in this.classList){
          this.classList[item]['select_txt'] = this.classList[item]['title'] + ' - ['+ this.classList[item]['classCode'] +'] - (' + this.classList[item]['course'] + ')';
          // console.log(this.classList[item]['title'] + ' - (' + this.classList[item]['course'] + ')')
        }
        this.defOpClass = 'Select Class';
      } else{
        this.classList = [];
        this.defOpClass = 'No Class Found';
      }
      this.courseLoader = false;
      this.isLoading = false;
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/teacher']);
      this.isLoading = false;
    })
  }

}
