<div id="main-wrapper">
    <!--  -->
    <app-sidebar></app-sidebar>
    <!-- sidebar menu -->
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form">
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Add Student</h5>
                        <a ><i class="bi bi-chevron-left mr-1" routerLink="/home/student"></i>Back to list</a>
                    </div>
                </div>
                <form [formGroup]="addStudentForm">
                    <div class="card-body">
                        <div class="row">                                
                            <div class="col-lg-9">
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="name" class="text-dark fw-500 label-text">User Name :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="userName" id="name" class="form-control"
                                                placeholder="Name">
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="name" class="text-dark fw-500 label-text">First Name :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="firstName" id="name" class="form-control"
                                                placeholder="First Name">
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="name" class="text-dark fw-500 label-text">Middle Name :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="middleName" id="name" class="form-control"
                                                placeholder="Middle Name">
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="name" class="text-dark fw-500 label-text">Last Name :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="lastName" id="name" class="form-control"
                                                placeholder="Last Name">
                                        </div>
                                       
                                    </div>
                                </div>
                                <!-- <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="dob" class="text-dark fw-500 label-text">Date of Birth :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" name="" id="dob" class="form-control datepicker"
                                                placeholder="DOB">
                                        </div>
                                    </div>
                                </div> -->
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="genderType" class="text-dark fw-500 label-text">Enable User
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="checkbox-label checkbox-t " for="male">
                                                <input type="radio" formControlName="enableUser" id="male">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">Active</span>
                                            </label>
                                            <label class="checkbox-label checkbox-t" for="female">
                                                <input type="radio" formControlName="enableUser" id="female">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">Inactive</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="class" class="text-dark fw-500 label-text">Class :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                           <!-- <select class="form-control" formControlName="class" id="class">
                                               <option value="">Select Class</option>
                                               <option value="0">Pre School</option>
                                               <option value="1">1st Grade</option>
                                               <option value="2">2nd Grade</option>
                                               <option value="3">3rd Grade</option>
                                               <option value="4">4th Grade</option>
                                           </select> -->

                                        <ng-multiselect-dropdown [placeholder]="'Select Class'" [settings]="dropdownSettings" [data]="dropdownList">
                                        </ng-multiselect-dropdown>

                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="class" class="text-dark fw-500 label-text">School :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                           <select class="form-control" formControlName="school" id="class">
                                               <option value="">Select School</option>
                                               <option value="0">Pre School</option>
                                               <option value="1">1st Grade</option>
                                               <option value="2">2nd Grade</option>
                                               <option value="3">3rd Grade</option>
                                               <option value="4">4th Grade</option>
                                           </select>
                                        </div>                                           
                                    </div>
                                </div>
                                <!-- <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="school_name" class="text-dark fw-500 label-text">School Name :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="tel" name="" id="school_name" class="form-control"
                                                placeholder="School Name">
                                        </div>                                           
                                    </div>
                                </div> -->
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="phone_number" class="text-dark fw-500 label-text">Phone Number :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="tel" formControlName="phoneNumber" id="phone_number" class="form-control"
                                                placeholder="Phone Number">
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="email_address" class="text-dark fw-500 label-text">Email Address :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="email" formControlName="email" id="email_address" class="form-control autoComplete"
                                                placeholder="Email Address">
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="password" class="text-dark fw-500 label-text">Password :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <div class="position-relative">
                                                <input type="password" formControlName="password" id="password" class="form-control" placeholder="Password">
                                                <span class="show-password"><i class="icon bi bi-eye"></i></span>
                                            </div>
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="start_date" class="text-dark fw-500 label-text">Start Date :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="date" formControlName="startDate" id="start_date" class="form-control autoComplete"
                                                placeholder="Start Date">
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="end_date" class="text-dark fw-500 label-text">End Date :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="date" formControlName="endDate" id="end_date" class="form-control autoComplete"
                                                placeholder="End Date">
                                        </div>                                           
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="sms" class="text-dark fw-500 label-text">Sms :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" formControlName="sms" id="sms" class="form-control autoComplete"
                                                placeholder="Sms">
                                        </div>                                           
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="address" class="text-dark fw-500 label-text">Address</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input type="text" name="" id="address" class="form-control" placeholder="Address">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <select name="" id="" class="form-control">
                                                        <option value="">Select State</option>
                                                        <option value=176>Alabama</option>
                                                        <option value=1061>Alaska</option>
                                                        <option value=181>Arizona</option>
                                                        <option value=101>Arkansas</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-row">
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    <select name="" id="" class="form-control"><option value="">Select City</option></select>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="Zip Code">
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div> -->
                                <!-- <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="statusType" class="text-dark fw-500 label-text">Status
                                                :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="checkbox-label checkbox-t " for="active">
                                                <input type="radio" name="statusType" id="active">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">Active</span>
                                            </label>
                                            <label class="checkbox-label checkbox-t" for="unactive">
                                                <input type="radio" name="statusType" id="unactive">
                                                <span class="checkbox-custom circular"></span>
                                                <span class="checkbox-text">Un-Active</span>
                                            </label>
                                        </div>
                                    </div>
                                </div> -->

                            </div>
                        </div>

                    </div>
                    <div class="card-footer">
                        <div class="form-group mb-0">
                            <!-- <button type="submit" class="btn btn-success" (click)="addStudent()">Save</button> -->
                            <button type="reset" class="btn btn-secondary mr-3" routerLink="/home/student">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>