import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { FormControl, FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { BulkImportCsvService } from 'src/app/services/bulk-import-csv.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { ThrowStmt } from '@angular/compiler';
import { Router  } from '@angular/router';
@Component({
  selector: 'app-uploadbulkcsv',
  templateUrl: './uploadbulkcsv.component.html',
  styleUrls: ['./uploadbulkcsv.component.css']
})
export class UploadbulkcsvComponent implements OnInit {
  userPermissionData:any;
  viewPage:boolean = false;
  constructor(private xj:externalJs, private serviceOrganisation: OrganisationService, private serviceBulkUpload: BulkImportCsvService, public loginService : LoginServiceService, private FB : FormBuilder, private toastr: NotificationService, private router: Router) { 
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;
    a.forEach(element => {
      if (element.module === "Bulk Upload") {
        this.userPermissionData = element?.permission;
      }
    });
    this.userPermissionData?.forEach(element => {
      if(element?.permission === 'One roaster csv file import'){
        this.viewPage = true;
      }
    });
   }
  orgData: any = [];
  isLoading:boolean =true;
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  uploadBulkDocForm =  this.FB.group({
    school: [this.schoolId],
    districtId: ['6'],
    uploadOrg:[''],
    uploadAcademic: [''],
    uploadCourse: [''],
    uploadClass:[''],
    uploadUser:[''],
    uploadEnroll: [''],
    uploadDemographic: [''],
  });
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    if(!this.schoolId){
      this.serviceOrganisation.allSchool().subscribe((res:any) => {
        if(res.status){
          this.orgData = res.data
        } else{
          this.orgData = []
        }
        this.isLoading = false;
      }, (err:any)=>{
        this.toastr.showError('Something went wrong. Please try later', '');
        this.router.navigate(['/home/dashboard']);
        this.isLoading = false;
      })
    } else{
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); 
    }
   
  }

  get m(){
    return this.uploadBulkDocForm.controls;
  }

   // onAcademicFileChange
   onOrgFileChange(event: any){    
    if (event.target.files && event.target.files[0]) {
      let fileCSV = event.target.files[0];
      this.uploadBulkDocForm.patchValue({
        uploadOrg: fileCSV
      }); 
    }
  }
   // onAcademicFileChange
  onAcademicFileChange(event: any){    
    if (event.target.files && event.target.files[0]) {
      let fileCSV = event.target.files[0];
      this.uploadBulkDocForm.patchValue({
        uploadAcademic: fileCSV
      }); 
    }
  }
  // onCourseFileChange
  onCourseFileChange(event){
    if (event.target.files && event.target.files[0]) {
      let fileCSV = event.target.files[0];
      this.uploadBulkDocForm.patchValue({
        uploadCourse: fileCSV
      });
    }   
  }
  // onClassesFileChange
  onClassesFileChange(event){
    if (event.target.files && event.target.files[0]) {
      let fileCSV = event.target.files[0];
      this.uploadBulkDocForm.patchValue({
        uploadClass: fileCSV
      });
    }    
  }
  // onUserFileChange
  onUserFileChange(event){
    if (event.target.files && event.target.files[0]) {
      let fileCSV = event.target.files[0];
      this.uploadBulkDocForm.patchValue({
        uploadUser: fileCSV
      });     
    }     
  }
  // onEnrollFileChange
  onEnrollFileChange(event){
    if (event.target.files && event.target.files[0]) {
      let fileCSV = event.target.files[0];
      this.uploadBulkDocForm.patchValue({
        uploadEnroll: fileCSV
      });     
    }     
  }
  // onDemographicFileChange
  onDemographicFileChange(event){
    if (event.target.files && event.target.files[0]) {
      let fileCSV = event.target.files[0];
      this.uploadBulkDocForm.patchValue({
        uploadDemographic: fileCSV
      });     
    } 
  }

  // upload school resquest
  uploadOrgDoc(e:any){
    const btn =  e.target;
    btn.classList.add('uploading');   
    btn.setAttribute('disabled', true);
    const formData = new FormData();    
    formData.append('districtId', this.uploadBulkDocForm.value.districtId);    
    formData.append('csv_file', this.uploadBulkDocForm.value.uploadOrg);

   this.serviceBulkUpload.bulkUploadOrgCSV(formData).then(res => {
    //  console.log(res);
     if(res['success']){
       btn.classList.add('uploaded');         
      setTimeout(() => {
        btn.classList.remove('uploading');
        btn.classList.remove('uploaded');
        btn.removeAttribute('disabled');
        this.uploadBulkDocForm.get('uploadOrg').setValue('');
        this.xj.clearInput("#orgFile");
        this.toastr.showSuccess(res['message']);
      }, 1000);
     } else{
      btn.classList.remove('uploading');
      btn.removeAttribute('disabled');
      this.toastr.showError('Unable to upload Org.csv file', '');
     }
    
   }).catch((err:any)=>{
     btn.classList.remove('uploading');
     btn.removeAttribute('disabled');
     this.toastr.showError('Unable to upload Org.csv file', '');
    //  console.log(err);
   })
  }
// upload academic session resquest
  uploadAcDoc(e:any){
    const btn =  e.target;
    btn.classList.add('uploading');   
    btn.setAttribute('disabled', true);
    const formData = new FormData();    
    formData.append('districtId', this.uploadBulkDocForm.value.districtId);
    formData.append('csv_file', this.uploadBulkDocForm.value.uploadAcademic);

   this.serviceBulkUpload.bulkUploadAcademicCSV(formData).then(res => {
    //  console.log(res);
     if(res['success']){
       btn.classList.add('uploaded');         
      setTimeout(() => {
        btn.classList.remove('uploading');
        btn.classList.remove('uploaded');
        btn.removeAttribute('disabled');
        this.uploadBulkDocForm.get('uploadAcademic').setValue('');
        this.xj.clearInput("#customFile");
        this.toastr.showSuccess(res['message']);
      }, 1000);
     } else{
      btn.classList.remove('uploading');
      btn.removeAttribute('disabled');
      this.toastr.showError('Unable to upload Academicsessuib.csv file', '');
     }
    
   }).catch((err:any)=>{
     btn.classList.remove('uploading');
     btn.removeAttribute('disabled');
     this.toastr.showError('Unable to upload Academicsessuib.csv file', '');
    //  console.log(err);
   })
  }
  // upload course request
  uploadCRDoc(e:any){
    const btn =  e.target;
    btn.classList.add('uploading');   
    btn.setAttribute('disabled', true);
    const formData = new FormData();    
    formData.append('districtId', this.uploadBulkDocForm.value.districtId);
    formData.append('csv_file', this.uploadBulkDocForm.value.uploadCourse);

    this.serviceBulkUpload.bulkUploadCourseCSV(formData).then(res => {
      // console.log(res);
      if(res['success']){
        btn.classList.add('uploaded');  
       setTimeout(() => {
         btn.classList.remove('uploading');
         btn.classList.remove('uploaded');
         btn.removeAttribute('disabled');
         this.uploadBulkDocForm.get('uploadCourse').setValue('');
         this.xj.clearInput("#uploadCourse");
         this.toastr.showSuccess(res['message']);
       }, 1000);
      } else{
        btn.classList.remove('uploading');
        btn.removeAttribute('disabled');
        this.toastr.showError('Unable to upload Course.csv file', '');
      }
     
    }).catch((err:any)=>{
      btn.classList.remove('uploading');
      btn.removeAttribute('disabled');
      this.toastr.showError('Unable to upload Course.csv file', '');
      // console.log(err);
    })
  }

// upload Classes resquest
  uploadCLDoc(e:any){
    const btn =  e.target;
    btn.classList.add('uploading');   
    btn.setAttribute('disabled', true);
    const formData = new FormData();    
    formData.append('districtId', this.uploadBulkDocForm.value.districtId);
    formData.append('csv_file', this.uploadBulkDocForm.value.uploadClass);

    this.serviceBulkUpload.bulkUploadClassCSV(formData).then(res => {
      // console.log(res);
      if(res['success']){
        btn.classList.add('uploaded');  
       setTimeout(() => {
         btn.classList.remove('uploading');
         btn.classList.remove('uploaded');
         btn.removeAttribute('disabled');
         this.uploadBulkDocForm.get('uploadClass').setValue('');
         this.xj.clearInput("#uploadClass");
         this.toastr.showSuccess(res['message']);
       }, 1000);
      } else{
        btn.classList.remove('uploading');
        btn.removeAttribute('disabled');
        this.toastr.showError('Unable to upload Classes.csv file', '');
      }
     
    }).catch((err:any)=>{
      btn.classList.remove('uploading');
      btn.removeAttribute('disabled');
      this.toastr.showError('Unable to upload Classes.csv file', '');
      // console.log(err);
    })
  }

  // upload users resquest
  uploadURDoc(e:any){
    const btn =  e.target;
    btn.classList.add('uploading');   
    btn.setAttribute('disabled', true);
    const formData = new FormData();    
    formData.append('districtId', this.uploadBulkDocForm.value.districtId);
    formData.append('csv_file', this.uploadBulkDocForm.value.uploadUser);

    this.serviceBulkUpload.bulkUploadUserCSV(formData).then(res => {
      // console.log(res);
      if(res['success']){
        btn.classList.add('uploaded');  
       setTimeout(() => {
         btn.classList.remove('uploading');
         btn.classList.remove('uploaded');
         btn.removeAttribute('disabled');
         this.uploadBulkDocForm.get('uploadUser').setValue('');
         this.xj.clearInput("#uploadUser");
         this.toastr.showSuccess(res['message']);
       }, 1000);
      } else{
        btn.classList.remove('uploading');
        btn.removeAttribute('disabled');
        this.toastr.showError('Unable to upload User.csvfile', '');
      }
     
    }).catch((err:any)=>{
      btn.classList.remove('uploading');
      btn.removeAttribute('disabled');
      this.toastr.showError('Unable to upload User.csvfile', '');
      // console.log(err);
    })
  }

// upload enroll resquest
  uploadENDoc(e:any){
    const btn =  e.target;
    btn.classList.add('uploading');   
    btn.setAttribute('disabled', true);
    const formData = new FormData();    
    formData.append('districtId', this.uploadBulkDocForm.value.districtId);
    formData.append('csv_file', this.uploadBulkDocForm.value.uploadEnroll);

    this.serviceBulkUpload.bulkUploadEnrollCSV(formData).then(res => {
      // console.log(res);
      if(res['success']){
        btn.classList.add('uploaded');  
       setTimeout(() => {
         btn.classList.remove('uploading');
         btn.classList.remove('uploaded');
         btn.removeAttribute('disabled');
         this.uploadBulkDocForm.get('uploadEnroll').setValue('');
         this.xj.clearInput("#uploadEnroll");
         this.toastr.showSuccess(res['message']);
       }, 1000);
      } else{
        btn.classList.remove('uploading');
        btn.removeAttribute('disabled');
        this.toastr.showError('Unable to upload Enrollments.csv file', '');
      }
     
    }).catch((err:any)=>{
      btn.classList.remove('uploading');
      btn.removeAttribute('disabled');
      this.toastr.showError('Unable to upload Enrollments.csv file', '');
      // console.log(err);
    })
  }

// upload  resquest
  uploadDGDoc(e:any){
    const btn =  e.target;
    btn.classList.add('uploading');   
    btn.setAttribute('disabled', true);
    const formData = new FormData();    
    formData.append('districtId', this.uploadBulkDocForm.value.districtId);
    formData.append('csv_file', this.uploadBulkDocForm.value.uploadDemographic);
    this.serviceBulkUpload.bulkUploadDemographicCSV(formData).then(res => {
      // console.log(res);
      if(res['success']){
        btn.classList.add('uploaded');  
       setTimeout(() => {
         btn.classList.remove('uploading');
         btn.classList.remove('uploaded');
         btn.removeAttribute('disabled');
         this.uploadBulkDocForm.get('uploadDemographic').setValue('');
         this.xj.clearInput("#uploadDemographic");
         this.toastr.showSuccess(res['message']);
       }, 1000);
      } else{
        btn.classList.remove('uploading');
        btn.removeAttribute('disabled');
        this.toastr.showError('Unable to upload Demographics.csv  file', '');
      }
     
    }).catch((err:any)=>{
      btn.classList.remove('uploading');
      btn.removeAttribute('disabled');
      this.toastr.showError('Unable to upload Demographics.csv  file', '');
      // console.log(err);
    })
  }

}
