import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { MasterService } from 'src/app/services/master.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-add-ftp-setting',
  templateUrl: './add-ftp-setting.component.html',
  styleUrls: ['./add-ftp-setting.component.css']
})
export class AddFtpSettingComponent implements OnInit {

  editId: number;
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  orgData: any = [];
  gradeForm: any;
  showError:boolean = false;
  errorMessages:any = [];
  isSubmit: boolean = false;
  submitBtn: any;
  userPermissionData:any;
  viewPage:boolean = false;
  FTPForm: any;
  isLoading: boolean = true;
  defOpOrg:any= 'Select School';
  constructor(private xj:externalJs,  private FB: FormBuilder, private route:ActivatedRoute, private router:  Router,  private serSchool:  OrganisationService, public loginService: LoginServiceService, private masterService: MasterService, private toastr: NotificationService,) { 
    this.editId = parseInt(this.route.snapshot.params.id);
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if (element.module === "FTP") {
        this.userPermissionData = element?.permission;
      }
    });
    if(this.editId){
      this.userPermissionData?.forEach(element => {
        if(element?.permission === 'Update Ftp'){
          this.viewPage = true;
        }
      });
    } else{
      this.userPermissionData?.forEach(element => {
        if(element?.permission === 'Add Ftp'){
          this.viewPage = true;
        }
      });
    }
  }
  
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.getSchool();
    this.FTPForm = this.FB.group({
      school: [{value:this.schoolId, disabled:true}],
      host: ['',],
      username: ['',],
      password: ['',],
      port: ['',],
      root: ['',],
    })
    if(this.editId){
      this.getFTPItem(this.editId);
    }
  }

  getSchool(){
    this.isLoading=true;
    this.serSchool.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg= 'Select School';
        this.FTPForm.controls['school'].enable();
      } else{
        this.orgData = [];
        this.defOpOrg= 'No School Found';
      }
      this.isLoading = false;
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/master/grade-list']);
      this.isLoading = false;
    })
  }

  getFTPItem(id:any){
    this.isLoading = true;
    this.masterService.viewFTPItem(id).subscribe((res:any)=> {
      if(res.status){
        this.FTPForm.patchValue({
          school: res.data.organisation_id,
          host: res.data.host,
          username: res.data.username,
          password: res.data.password,
          port: res.data.port,
          root: res.data.root
        })
        this.isLoading = false;
      }  else{
        this.router.navigate(['/home/master/ftp-list']);
        this.toastr.showError('Something went wrong. Please try later','');        
        this.isLoading = false;
      }
    }, (err:any)=>{     
      this.router.navigate(['/home/master/ftp-list']);
      this.toastr.showError('Something went wrong. Please try later','');        
      this.isLoading = false;      
    })

  }

  submitForm(e:any){
    this.isSubmit = true;
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.showError = false;
    this.errorMessages = [];
    if(this.editId){
      this.updateFTP()
    } else{
      this.addFTP();
    }
  }


  addFTP(){
    const formData = {
      school_id: this.FTPForm.value.school,
      host: this.FTPForm.value.host,
      username: this.FTPForm.value.username,
      password : this.FTPForm.value.password,
      port: this.FTPForm.value.port,
      root: this.FTPForm.value.root,
    }
    this.masterService.addFTP(formData).then((res: any)=> {
      if(res.status){
        this.router.navigate(['home/master/ftp-list']);
        this.toastr.showSuccess(res.message);
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }   
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any) =>{
      let errData = err.error.errors;
      for (let key in errData) {
        this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }

  updateFTP(){
    const formData = {
      school_id: this.FTPForm.value.school,
      host: this.FTPForm.value.host,
      username: this.FTPForm.value.username,
      password : this.FTPForm.value.password,
      port: this.FTPForm.value.port,
      root: this.FTPForm.value.root,
    }
    // console.log(formData);
    this.masterService.editFTP(this.editId, formData).then((res: any)=> {
      console.log(res);
      if(res.status){
        this.router.navigate(['home/master/ftp-list']);
        this.toastr.showSuccess(res.data);
        this.isSubmit = false;
        this.submitBtn.disabled = false;
      }
    }).catch((err:any) =>{
      let errData = err.error.errors;
      for (let key in errData) {
        this.errorMessages.push(errData[key][0]);
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }

}
