import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { AcademicService } from 'src/app/services/academic.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ClassService } from 'src/app/services/class.service'
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MasterService } from 'src/app/services/master.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-edit-class',
  templateUrl: './edit-class.component.html',
  styleUrls: ['./edit-class.component.css']
})
export class EditClassComponent implements OnInit {
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  courseList: any = [];
  dropdownList = [];
  selectedItems = [];
  selectedArr: any = [];
  selectedAcademic: any = [];
  editClassData: any;
  id: string;
  sessionData: any;
  orgData: any = [];
  session: any;
  editAcdemicId: any = [];
  showError = false;
  errorMessages = [];
  dropdownSettings: IDropdownSettings = {};
  gradeItems: any = [];
  userPermissionData:any;
  viewPage :boolean = false;

  isLoading:boolean = true;
  isSubmit: boolean = false;
  submitBtn:any;
  defOpOrg:any ='Select School';
  defOpGrade:any ='Select Grade';
  defOpCourse:any ='Select Course';
  gradLoader:boolean= false;
  courseLoader:boolean=false;
  constructor(private xj: externalJs, private serviceAcademic: AcademicService,
    private serviceOrganisation: OrganisationService,
    public loginService: LoginServiceService, private service: ClassService, private courseService: CourseService,
    private route: ActivatedRoute, private router: Router, private toaster: NotificationService, private masterService : MasterService) {
    this.route.queryParamMap.subscribe(queryParams => {
      this.id = queryParams.get("id")
    })
      var localData = JSON.parse(localStorage.getItem('userPermission'))
      const a = localData?.modules;

      a.forEach(element => {
        if (element.module === "Classes") {
          this.userPermissionData = element?.permission;
        }
      });

      this.userPermissionData?.forEach(element => {
        if (element?.permission === 'update class') {
          this.viewPage = true;
        } 
      });
  }

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit(); 
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'select_txt',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.viewSession();
    if(this.schoolId){
      this.gradLoader =true;
      this.courseLoader = true;
      this.getGradeItems(this.schoolId);
      this.getCourseList(this.schoolId);
    } else{
      this.viewOrganisation();   
    }    
    this.editCourse();   
  }
  // add class api
  editClassForm = new FormGroup({
    title: new FormControl('',),
    grades: new FormControl({value:'', disabled:true}),
    classCode: new FormControl('',),
    classType: new FormControl('',),
    location: new FormControl('',),
    organisation: new FormControl({value: this.schoolId, disabled:true}),
    academicSession: new FormControl('',),
    statusType: new FormControl('1',),
    course: new FormControl({value:'', disabled:true})
  })
  //  view session api
  viewSession() {
    this.serviceAcademic.getAllSession().subscribe((res:any) => {
      if(res.status){
        this.sessionData = res['data'];
        for(let item in this.sessionData){ 
          this.sessionData[item]['select_txt'] = this.sessionData[item]['title'] + ' - (' + this.sessionData[item]['schoolYear'] + ' - ' + this.sessionData[item]['type'] + ')';
        }  
      } else{
        this.sessionData = [];
      }
    },(err:any)=>{
      this.toaster.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/class-list']);
      this.isLoading = false;
    })
  }

 

  // view organisation
  viewOrganisation() {
    this.serviceOrganisation.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg='Select School';
        this.editClassForm.controls['organisation'].enable();
      } else{
        this.orgData = [];
        this.defOpOrg='No School Found';
      }
      //this.isLoading = false;
    },(err:any)=>{
      this.toaster.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/class-list']);
      this.isLoading = false;
    })
  }

  selectSchool(e:any){
    this.gradLoader =true;
    this.courseLoader = true;
    this.editClassForm.controls['course'].disable();
    this.editClassForm.controls['grades'].disable();
    this.editClassForm.get('course').setValue('');
    this.editClassForm.get('grades').setValue('');
    this.getGradeItems(e);
    this.getCourseList(e);
  }

  getGradeItems(schoolId:any){
    this.masterService.allGradeList(schoolId).subscribe((res:any)=>{
      if(res.status){
        this.gradeItems =  res.data;
        this.defOpGrade='Select Grade';
        this.editClassForm.controls['grades'].enable();
      } else{
        this.gradeItems = [];
        this.defOpGrade='No Grade Found';
      }
      this.gradLoader= false;
    },(err:any)=>{
      this.toaster.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/class-list']);
      this.isLoading = false;
    })
  }

  

  // add class api
  updateClass(e:any) {
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    this.showError = false;
    this.errorMessages = [];
    var session = [];
    for(let item of this.editClassForm.value.academicSession){
      session.push(item.id)
    }
    const data = {
      classCode: this.editClassForm.value.classCode,
      classType: this.editClassForm.value.classType,
      location: this.editClassForm.value.location,
      schoolSourcedId: this.editClassForm.value.organisation || this.schoolId,
      termSourcedIds: session,
      isActive: this.editClassForm.value.statusType,
      title: this.editClassForm.value.title,
      grades: this.editClassForm.value.grades,
      courseSourcedId: this.editClassForm.value.course
    }
   
    this.service.updateClass(this.id, data).then(res => {
      if (res['status']) {
        this.router.navigate(['/home/class-list']);
        this.toaster.showSuccess('Class updated successfully');
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }         
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }

  // get course list

  getCourseList(schoolId:any= this.schoolId){  
    this.courseService.getAllCoursebyOrgid(schoolId).subscribe(res => {
      if(res['status']){
        this.courseList = res['data'];
        this.defOpCourse = 'Select Course';
        this.editClassForm.controls['course'].enable();
      } else{
        this.courseList= [];
        this.defOpCourse= 'No Course Found';
      }
      this.courseLoader = false;
    },(err:any)=>{
      this.toaster.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/class-list']);
      this.isLoading = false;
    })
  }

  // edit course 
  editCourse() {    
    this.service.editClass(this.id).subscribe((res:any) => {
      if(res.status){
        this.editClassData = res['data'];
        this.editClassData.academic_session.forEach(element => {
          this.editAcdemicId.push({id:element.id,select_txt:element.title  +' - ('+ element.schoolYear +' - '+ element.type +')'})
        });
        if(!this.schoolId){
          this.gradLoader =true;
          this.courseLoader = true;
          this.editClassForm.controls['course'].disable();
          this.editClassForm.controls['grades'].disable();
          this.getCourseList(this.editClassData.schoolSourcedId);
          this.getGradeItems(this.editClassData.schoolSourcedId);       
        }
        this.editClassForm.patchValue({
          title: this.editClassData.title,
          classCode: this.editClassData.classCode,
          classType: this.editClassData.classType,
          location: this.editClassData.location,
          organisation: this.editClassData.schoolSourcedId,
          academicSession: this.editAcdemicId,
          statusType: this.editClassData.isActive,
          grades: this.editClassData.grades || '',
          course: this.editClassData.courseSourcedId,
        })
      } else{
        this.router.navigate(['/home/class-list']);
        this.toaster.showError('Something went wrong. Please try later','');       
        this.isLoading=false; 
      }
      this.isLoading = false;      
    },(err:any)=>{
      this.router.navigate(['/home/class-list']);
      this.toaster.showError('Something went wrong. Please try later','');        
      this.isLoading=false;
    })
  }
}
