import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { AcademicService } from 'src/app/services/academic.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ClassService} from 'src/app/services/class.service'
import { CourseService } from 'src/app/services/course.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import { MasterService } from 'src/app/services/master.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-create-class',
  templateUrl: './create-class.component.html',
  styleUrls: ['./create-class.component.css']
})
export class CreateClassComponent implements OnInit {
  courseList: any = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  selectedArr: any = [];
  selectedAcademic: any = [];
  sessionData: any = [];
  orgData: any = [];
  showError:boolean = false;
  errorMessages:any = [];
  gradeItems:any = [];
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  userPermissionData:any;
  viewPage :boolean = false;
  isLoading:boolean = true;
  isSubmit: boolean = false;
  submitBtn:any;

  defOpOrg:any ='Select School';
  defOpGrade:any ='Select Grade';
  defOpCourse:any ='Select Course';
  gradLoader:boolean= false;
  courseLoader:boolean=false;
  constructor(private xj: externalJs, private serviceAcademic: AcademicService,
     private serviceOrganisation: OrganisationService, 
     public loginService: LoginServiceService , private service: ClassService,private courseService: CourseService ,
     private router :Router,private toastr: NotificationService, private masterService : MasterService) {

      var localData = JSON.parse(localStorage.getItem('userPermission'))
      const a = localData?.modules;

      a.forEach(element => {
        if (element.module === "Classes") {
          this.userPermissionData = element?.permission;
        }
      });

      this.userPermissionData?.forEach(element => {
        if (element?.permission === 'create class') {
          this.viewPage = true;
        } 
      });
    }

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'select_txt',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    
    this.viewSession();
    if(this.schoolId){
      this.getGradeItems(this.schoolId);
      this.getCourseList(this.schoolId);
    } else{
      this.viewOrganisation();
    }
  }

  // add class api
  addClassForm = new FormGroup({
    title : new FormControl('',),
    grades : new FormControl({value:'', disabled:true}),
    classCode : new FormControl('',),
    classType : new FormControl('',),
    location : new FormControl('',),
    organisation : new FormControl({value: this.schoolId, disabled:true}),
    academicSession : new FormControl(''),
    statusType : new FormControl('1',),
    course : new FormControl({value:'', disabled:true})
  })
  //  view session api
  viewSession() {
    this.serviceAcademic.getAllSession().subscribe((res:any) => {
      if(res.status){
        this.sessionData = res['data'];
        for(let item in this.sessionData){ 
          this.sessionData[item]['select_txt'] = this.sessionData[item]['title'] + ' - (' + this.sessionData[item]['schoolYear'] + ' - ' + this.sessionData[item]['type'] + ')';
        }  
      } else{
        this.sessionData = [];
      }
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/class-list']);
      this.isLoading = false;
    })
  }
   
 

  // view organisation
  viewOrganisation() {
    this.serviceOrganisation.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg='Select School';
        this.addClassForm.controls['organisation'].enable();
      } else{
        this.orgData = [];
        this.defOpOrg='No School Found';
      }
      this.isLoading = false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/class-list']);
      this.isLoading = false;
    })
  }


  selectSchool(e:any){
    this.gradLoader =true;
    this.courseLoader = true;
    this.addClassForm.controls['course'].disable();
    this.addClassForm.controls['grades'].disable();
    this.getGradeItems(e);
    this.getCourseList(e);
  }

  getGradeItems(schoolId:any){
    this.masterService.allGradeList(schoolId).subscribe((res:any)=>{
      if(res.status){
        this.gradeItems =  res.data;
        this.defOpGrade='Select Grade';
        this.addClassForm.controls['grades'].enable();
      } else{
        this.gradeItems = [];
        this.defOpGrade='No Grade Found';
      }
      this.gradLoader= false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/class-list']);
      this.isLoading = false;
    })
  }
  

  // add class api
  addClass(e:any){
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    this.showError = false;
    this.errorMessages = [];
    this.selectedAcademic= [];
    for(const data of this.addClassForm.value.academicSession){
      this.selectedAcademic.push(data.id) 
    }
    const data ={
      classCode : this.addClassForm.value.classCode,
      classType : this.addClassForm.value.classType,
      location : this.addClassForm.value.location,
      schoolSourcedId : this.addClassForm.value.organisation || this.schoolId ,
      termSourcedIds : this.selectedAcademic,
      isActive: this.addClassForm.value.statusType,
      title : this.addClassForm.value.title,
      grades: this.addClassForm.value.grades,
      courseSourcedId : this.addClassForm.value.course
    }
    this.service.createClass(data).then(res => {
      if (res['status']) {
        this.router.navigate(['/home/class-list'])
        this.toastr.showSuccess('Class created successfully')
      } else{
          if(res['message']){
            this.errorMessages.push(res['message']);
          }else{
            this.errorMessages.push('Something went wrong. Please try later');
          }         
          this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
        this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }

  // get course list
  getCourseList(schoolId:any= this.schoolId){  
    this.courseService.getAllCoursebyOrgid(schoolId).subscribe(res => {
      if(res['status']){
        this.courseList = res['data'];
        this.defOpCourse = 'Select Course';
        this.addClassForm.controls['course'].enable();
      } else{
        this.courseList= [];
        this.defOpCourse= 'No Course Found';
      }
      this.courseLoader = false;
      this.isLoading = false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/class-list']);
      this.isLoading = false;
    })
  }
}
