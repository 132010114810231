<router-outlet></router-outlet>

<div id="unauthModel" class="modal fade" aria-hidden="true" #delete>
    <div class="modal-dialog modal-confirm modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header flex-column">
                <div class="icon-box">
                    <i class="bi bi-exclamation-lg"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i
                        class="bi bi-x"></i></button>
            </div>
            <div class="modal-body">
                <p>You are unauthorized to access. Please Login Now</p>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>


<div id="settingPopup" class="modal fade" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header border-0 pb-0">
                <div class="modal-title1">Select District</div>
            </div>
            <form [formGroup]="setDistrictForm">
                <div class="modal-body">
                    <div class="form-group asyncData mb-0" [ngClass]="{'is-loading mr-4': districtList?.length < 1 }">
                        <select id="district" class="form-control" formControlName="district">
                            <option value="">{{defOpDistrict}}</option>
                            <option *ngFor ="let district of districtList;"  value="{{district.id}}">{{ district.name}}</option>
                        </select>
                        <div class="spinner-border spinner-border-sm "></div>
                    </div>
                    <ng-container *ngIf="showError">
                        <div class="alert alert-danger" role="alert">
                            {{errorMessages[0]}}
                        </div>
                    </ng-container>
                </div>
                <div class="modal-footer justify-content-end">
                    <button type="submit" class="btn btn-success btn-sm" (click)="saveDistrict($event)">Save District
                        <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isSubmit"></span>
                    </button>
                </div>
            </form>
            
        </div>
    </div>
</div>