import { Component, OnInit, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginServiceService } from '../../services/login-service.service';
import { externalJs } from 'src/app/services/external-js.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  showErrMsg: string;
  isLoading: boolean;
  @HostListener('window:focus') onFocus() {
    // console.log('window focus');
    if(localStorage.getItem('token')){
      window.location.reload();
    }
  }
  constructor(private service: LoginServiceService, private router: Router, private xj:externalJs) {
      const hasToken = localStorage.getItem('token');
      if(hasToken){
        this.router.navigate(['/home/dashboard']);
        
      }
   }

  ngOnInit(): void {
    this.xj.extraInit();
  }
  // form validation

  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  })

  //  login api
  loginApi(e:any) {
    const btn =  e.target;
    btn.disabled = true;
    this.isLoading = true;
    this.showErrMsg = ''
    const data = {
      
      username: this.loginForm.value.username,
      password: this.loginForm.value.password
    }
    
    this.service.login(data).subscribe(res => {     
      localStorage.clear();
      sessionStorage.clear();
      if(res['success'] === true){
        localStorage.setItem('token', res['token'])
        localStorage.setItem('username', res['data']['username'])
        localStorage.setItem('firstname', res['data']['givenName'])
        localStorage.setItem('lastname', res['data']['familyName'])
        localStorage.setItem('userid', res['data']['id'])
        localStorage.setItem('roleid', res['data']['role'])
        localStorage.setItem('userId', res['data']['userIds'])
        localStorage.setItem('orgName', res['data']['orgName'])
        localStorage.setItem('orgIds', res['data']['orgSourcedIds'])
        localStorage.setItem('logo', res['data']['schoolLogo']);
        // localStorage.setItem('district', res['data']['district']);
        localStorage.setItem('district_flag', res['data']['district_flag']);
        
        if(res['data'].modules){
          for(let i = 0; i<= res['data'].modules.length-1; i++) {
            var datas = res['data'].modules
            for(let j = 0;j<datas[i].permission.length;j++){
              localStorage.setItem(res['data'].modules[i].module,JSON.stringify(res['data'].modules[i].permission));
              localStorage.setItem('userPermission',JSON.stringify(res['data']));
            }
          }
        }       
        setTimeout(() => {
          btn.disabled = false;
          // if(parseInt(localStorage.getItem('district_flag')) === 0 && parseInt(localStorage.getItem('roleid')) === 1){
          //   this.xj.openModalPopup("#settingPopup");
          // }
         
          if(this.service.getCurrentUserRole().slug === 'student' ||  this.service.getCurrentUserRole().slug === 'parent'){
            this.router.navigate(['/home/document'], { queryParams: {approve: 'yes'}});
          } else{
            this.router.navigate(['/home/dashboard']);
          }
          this.isLoading = false;
        }, 1000);
       
      }
      if(res['success'] === false){
        this.showErrMsg = res['data']['error'];
        this.isLoading = false;
        btn.disabled = false;
      }
      else if(res['success'] == true) {
        
      }
      else if(res['status'] === false){
        this.showErrMsg = res['message'];
        this.isLoading = false;
        btn.disabled = false;
      }
      
    },  (error:any) => {
      this.showErrMsg = 'Oops! something went wrong. Please try again later';
      this.isLoading = false;
      btn.disabled = false;
    })
  }
}
