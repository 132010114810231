import { Component, OnInit, ViewChild } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { AcademicService } from 'src/app/services/academic.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { StudentService } from 'src/app/services/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService } from 'src/app/services/user-service.service';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.css']
})
export class StudentListComponent implements OnInit {
  @ViewChild('delete') delete;
  studentList: any = [];
  studentId: any;
  titleSearch = '';
  gradeSearch = '';
  schoolSearch =''
  isLoading = true;
  currentAddLnk :any;
  currAddLnkTxt: string = "Add Student";
  sessionData: any;
  orgData: any [];
  userPermissionData: any;
  editButton: boolean = false;
  deleteButton: boolean = false;
  viewPage: boolean = false;
  message: string='';
  lastPage:number = 1;
  pageList:any = [];

  // filter and paging
  // filter and paging
  currentSchool: any = parseInt(localStorage.getItem('orgIds')) || '';
  currentName: any;
  currentEmail: any;
  currentCourse: any
  currentPage: number = 1;
  totalPages: any = [];
  courseData: any = [];

  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  tableDataPerPage: number = 20;

  constructor(private courseSrv:CourseService,private route:ActivatedRoute,private xj: externalJs, private serviceAcademic: AcademicService, private toaster: NotificationService,
    private router:Router, private service: StudentService, private serviceOrganisation: OrganisationService, public loginService: LoginServiceService, private userSer:UserServiceService) {
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if(element.module === "Students"){
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {
      if(element?.permission === 'delete Student'){
       this.deleteButton = true;
      }else if(element?.permission === 'Edit Student'){
        this.editButton = true;
      }else if(element?.permission === 'Add Student'){
     this.currentAddLnk = "/home/student/add";
      } else if(element?.permission === "View Student"){
        this.viewPage = true;        
    }
    });
   }

  
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
   }
    this.getCourse();
    this.getSchool();
    // params
    this.route.queryParamMap
      .subscribe((params: any) => {
        let paramsObj = { ...params.keys, ...params };

        if (paramsObj.params.page) {
          this.currentPage = paramsObj.params.page;
        }
        if (paramsObj.params.school) {
          this.currentSchool = paramsObj.params.school;
        }
        if (paramsObj.params.course) {
          this.currentCourse = paramsObj.params.course;
        } else {
          this.currentCourse = '';
        } if (paramsObj.params.name) {
          this.currentName = paramsObj.params.name;
        } else {
          this.currentName = '';
        } if (paramsObj.params.email) {
          this.currentEmail = paramsObj.params.email;
        } else {
          this.currentEmail = '';
        }
        this.initComp();
      });

    
  }
  // get school
  getSchool(){
    this.serviceOrganisation.allSchool().subscribe((res:any) => {    
      if(res.status){
        this.orgData = res.data
      } else{
        this.orgData = []
      }
    }, (err:any)=>{
      this.toaster.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
    })
  }
  initComp(){
    this.xj.extraInit();
    this.getStudentList();
  }
  //  get student list
  getStudentList() {
    this.isLoading = true;
    if( this.currentSchool == null){
      this.currentSchool = '';
    }
    this.service.viewStudent(this.currentPage, this.currentSchool,  this.currentCourse, this.currentName, this.currentEmail).subscribe(res => {      
      if(res['status']){
        this.studentList = res['data'].data
        this.currentPage = res['data'].current_page;
        this.lastPage = res['data'].last_page;
        this.mapPages(res['data'].last_page);
        this.tableDataFrom = res['data']['from'];
        this.tableDataTo = res['data']['to'];
        this.tableDataTotal = res['data']['total'];
        this.tableDataPerPage = res['data']['per_page'];
        this.message = '';
        this.isLoading = false;       
      } else if(res['status_code'] === 404){
        this.message = 'No student data found';        
        this.studentList = [];
        this.isLoading = false;
      } else{
        this.studentList= [];
        this.message= "Something went wrong. Please try later";
        this.isLoading = false;       
      }       
    }, (err:any)=>{
      this.message= "Something went wrong. Please try later";
      this.isLoading = false;
    })
  }
  exportStudent(){
    this.userSer.exportCSV(7).then((res:any)=>{
      if(res.status){
        let requiredData = [];
        res.data.forEach((usr:any) => {
          requiredData.push({fname:usr.givenName, lname:usr.familyName, email:usr.email, pass:usr.password_view, school:usr.school})
        });
        let csv =  this.ConvertToCSV(requiredData);
        var uri = 'data:text/csv;charset=utf-8,' + escape(csv);
        
        var downloadLink = document.createElement("a");
        downloadLink.href = uri;
        downloadLink.download = "student.csv";
        document.body.appendChild(downloadLink);
       
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this.toaster.showSuccess('Student data export successfully');
      } else{
        this.toaster.showError('Unable to export data','');
      }
    }).catch(err=>{
      this.toaster.showError('Something went wrong. Please try later','');
    })
}

// JSON to CSV Converter
ConvertToCSV(objArray:any) {
var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;

var str = '';

var pre = 'First Name, Last Name, Email, Password, School' + '\r\n';
str += pre;

for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
        if (line != '') line += ','

        line += array[i][index];
    }

    str += line + '\r\n';
}

return str;
}
  // map pages
  mapPages(lastPage = 1) {
    let pageListArr = [];
    this.totalPages = [];
    if(lastPage <=1) return;
    if(lastPage <= 5){
      for(var i =1; i<= lastPage; i++){
          pageListArr.push(i);
      }
    }
    if(lastPage > 5){
      if(this.currentPage <=4){        
          for(var i =1; i<= 5; i++){
            pageListArr.push(i);
          }      
          pageListArr.push('...');
          pageListArr.push(lastPage);
      } else if(lastPage - this.currentPage <=3){
        pageListArr.push(1);
        pageListArr.push('...');
        for(var i =lastPage - 4; i<= lastPage; i++){
          pageListArr.push(i);
        } 
      }
      else {
        pageListArr.push(1);
        pageListArr.push('...');  

        for(var i = this.currentPage - 2; i<= this.currentPage + 2; i++){
          pageListArr.push(i);
        }

        pageListArr.push('...');
        pageListArr.push(lastPage);
      } 
    } 
    this.totalPages = pageListArr;
    
  }
   // pagination
   changeCurrentPage(page: any) {
     if(page != '...'){
      this.currentPage = page;
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { page: page },
          queryParamsHandling: 'merge'
        });
     }
    
  }

   // filter 
  // filter
  filterDocuments() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: 1, course: this.currentCourse, school: this.currentSchool, name: this.currentName, email: this.currentEmail },
        queryParamsHandling: 'merge'
      });
  }
  // on school change
  filterSchool(orgId){
    this.getCourse(orgId);
  }
  //getCourse
  getCourse(orgID: any = this.currentSchool) {
    this.courseData = [];
    this.courseSrv.getAllCoursebyOrgid(orgID).subscribe((res:any) => {
      if(res.status) {
        this.courseData = res.data;
      } else{
        this.courseData = [];
      }
    },(err:any)=>{
      this.toaster.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
    })
  }




  // get student id
  getStudentId(id) {
    this.studentId = id
  }
  // delete student
  deleteStudent() {
    this.service.deleteStudent(this.studentId).subscribe((res:any) => {
      if(res.status){
        this.initComp();       
        this.toaster.showSuccess('Student deleted successfully')
        this.xj.closeModalPopup("#deletePopup");
      } else{
        this.toaster.showError('Unable to delete student', '');
        this.initComp();
        this.xj.closeModalPopup("#deletePopup"); 
      }
    },(err:any)=>{
        this.toaster.showError('Something went wrong. Please try later', '');
        this.initComp();
        this.xj.closeModalPopup("#deletePopup");   
    })
  }
  // go to edit page
  goToEditPage(id) {
    this.router.navigate(['/home/student/edit'], { queryParams: { id: id } })
  }

  clearFilter() {
    this.currentSchool = parseInt(localStorage.getItem('orgIds')) || '';
    this.currentName = '';
    this.currentEmail = '';
    this.currentCourse = '';
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, course: '', email: '', school: '', name:'' },
        queryParamsHandling: 'merge'
      });
  }

  openPopup(e, items){
    var html = '';
    html+="<ul class='pl-4'>";
    for(var item of items ){
       html += `<li>${item?.title}</li>`;
    }
    html+="</ul>";
    this.xj.openPopup(e, html);
    
  }

}
