import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { AcademicService } from 'src/app/services/academic.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service'
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassService } from 'src/app/services/class.service';
import { CourseService } from 'src/app/services/course.service';
import { MasterService } from 'src/app/services/master.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.css']
})
export class AddStudentComponent implements OnInit {
  parentList:any= [];
  selParentItems = [];
  dropdownSettings = {};
  parentDropDownSettings = {};
  selectedClass: any = [];
  checked: any;
  classList: any = [];
  empForm: FormGroup;
  // showGrade:boolean= false;
  courseList: any;
  role: any = '';
  currentPath:any;
  showGrade: boolean = true;
  showGender: boolean = true;
  showDOB: boolean = true;
  showClass: boolean = true;
  showParent: boolean = false;
  userPermissionData:any;
  viewPage: boolean = false;
  isSubmit: boolean = false;
  submitBtn:any;
  isLoading:boolean = true;

  defOpOrg:any ='Select School';
  defOpGrade:any ='Select Grade';
  defOpClass:any = 'Select Class';
  defOpParent:any = 'Select Parent';
  gradLoader:boolean= false;
  courseLoader:boolean=false;
  parentLoader:boolean=false;
  constructor(private xj: externalJs, private fb: FormBuilder, private courseService: CourseService, private serviceAcademic: AcademicService,
     private classService: ClassService, private router: Router, private route: ActivatedRoute, private toastr: NotificationService, private service: StudentService, private serviceOrganisation: OrganisationService, public loginService: LoginServiceService, private masterService: MasterService,) {
    this.empForm = this.fb.group({
      employees: this.fb.array([]),
    })
    this.route.parent.url.subscribe((urlPath) => {
      this.currentPath = urlPath[urlPath.length - 1].path;
    })
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;
    if(this.currentPath == 'teacher'){
      this.role = 8;
      a.forEach(element => {
        if(element.module === "Teacher"){
          this.userPermissionData = element?.permission;
        }
      });  
      this.userPermissionData?.forEach(element => {      
        if(element?.permission === 'Add Teacher'){
          this.viewPage = true;
        }
      });
    } else if(this.currentPath == 'student'){
      this.role = 7;
      a.forEach(element => {
        if(element.module === "Students"){
          this.userPermissionData = element?.permission;
        }
      });  
      this.userPermissionData?.forEach(element => {      
        if(element?.permission === 'Add Student'){
          this.viewPage = true;
        }
      });
    } else if(this.currentPath == 'parent'){
      this.role = 14;
      a.forEach(element => {
        if(element.module === "Parents"){
          this.userPermissionData = element?.permission;
        }
      });
  
      this.userPermissionData?.forEach(element => {      
        if(element?.permission === 'Add Parent'){
          this.viewPage = true;
        }
      });
    } 
    this.changeRole();
  }
  sessionData: any;
  orgData: any = [];
  showError = false;
  errorMessages = [];
  gradeItems:any = [];
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'select_txt',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.parentDropDownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullName',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
 
    // this.viewSession();
    
    this.initialForm();
    if(this.schoolId){
      this.getGradeItems(this.schoolId);
      this.getAllParent(this.schoolId);
      this.getClass(this.schoolId);   
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);         
    } else{
      this.viewOrganisationApi();
    }
  }


  employees(): FormArray {
    return this.empForm.get("employees") as FormArray
  }
  newEmployee(): FormGroup {
    return this.fb.group({
      course: '',
      class: '',
    })
  }
  addEmployee() {
    this.employees().push(this.newEmployee());
  }

  removeEmployee(empIndex: number) {
    this.employees().removeAt(empIndex);
  }

  initialForm(){
    this.addEmployee();
  }
  // view session api
  // viewSession() {
  //   this.serviceAcademic.viewSession().subscribe(res => {
  //     this.sessionData = res['data']['data'];
  //   })
  // }

  // view organisationApi
  viewOrganisationApi() {
    this.serviceOrganisation.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg='Select School';
        this.addStudentForm.controls['school'].enable();
      } else{
        this.orgData = [];
        this.defOpOrg='No School Found';
      }
      this.isLoading = false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      if(this.role === 8){
        this.router.navigate(['/home/teacher']);
      } else if(this.role === 7){
        this.router.navigate(['/home/student'])
      } else if(this.role === 14){
        this.router.navigate(['/home/parent']);
      } else{
        this.router.navigate(['/home/dashboard']);
      }
      this.isLoading = false;
    })
  }

  dob(date: any) {
    setTimeout(() => {
      // console.log(date.target.value);
      this.addStudentForm.patchValue({
        dob: date.target.value
      })
      
    }, 300);
  }
  // getRoleId(id){
  //   this.role = id
  //   if(id == 7){
  //    this.showGrade = true;
  //   }
  // }

  changeRole(){
    if(this.role  == 14){
      this.showGrade = false;
      this.showClass = false;
      this.showParent = false;
      this.showGender = false;
      this.showDOB = false;
    } else if(this.role  == 7){
      this.showGrade = true;
      this.showClass = true;
      this.showParent = true;
      this.showGender = true;
      this.showDOB = true;
    } else {
      this.showGrade = true;
      this.showClass = true;
      this.showParent = false;
      this.showGender = false;
      this.showDOB = false;
    }

  }

  // add student form
  addStudentForm = new FormGroup({
    firstName: new FormControl('',),
    middleName: new FormControl('',),
    lastName: new FormControl('',),
    dob: new FormControl('',),
    genderType: new FormControl('',),
    grade: new FormControl({value: '', disabled:true}),
    native: new FormControl('',),
    asian: new FormControl('',),
    nativeHawaiin1: new FormControl('',),
    isWhite: new FormControl('',),
    demographic: new FormControl('',),
    hispanic1: new FormControl('',),
    countryCode: new FormControl('',),
    number: new FormControl('',),
    email: new FormControl('',),
    userName: new FormControl('',),
    password: new FormControl('',),
    state: new FormControl('',),
    city: new FormControl('',),
    zipCode: new FormControl('',),
    address: new FormControl('',),
    parents: new FormControl('',),
    school: new FormControl({value: this.schoolId, disabled: true}),
    course: new FormControl('',),
    class: new FormControl('',),
    startDate: new FormControl('',),
    endDate: new FormControl('',),
    statusType: new FormControl('1',),
    role: new FormControl('')
  })
   

  // add student api
  addStudent(e:any){
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    if(this.addStudentForm.value.asian == true){
      var asianValue = '1'
    }else{
      var asianValue = '0'
    }
    if(this.addStudentForm.value.americanIndianOrAlaskaNative == true){
      var americanIndianOrAlaskaNativeValue = '1'
    }else{
      var americanIndianOrAlaskaNativeValue = '0'
    }
    if(this.addStudentForm.value.asian == true){
      var asianValue = '1'
    }else{
      var asianValue = '0'
    }
    if(this.addStudentForm.value.nativeHawaiin1 == true){
      var nativeHawaiianOrOtherPacificIslanderValue = '1'
    }else{
      var nativeHawaiianOrOtherPacificIslanderValue = '0'
    }
    if(this.addStudentForm.value.isWhite == true){
      var isWhiteValue = '1'
    }else{
      var isWhiteValue = '0'
    }
    if(this.addStudentForm.value.hispanic1 == true){
      var hispanic1Value = '1'
    }else{
      var hispanic1Value = '0'
    }
    if(this.addStudentForm.value.demographic == true){
      var demographicValue = '1'
    }else{
      var demographicValue = '0'
    }
    this.selectedClass = [];
    for(const data of this.addStudentForm.value.course){
      this.selectedClass.push(data.id) 
    }
    this.selParentItems = [];
    for(const data of this.addStudentForm.value.parents){
      this.selParentItems.push(data.id) 
    }
    this.showError = false;
    this.errorMessages = [];
    var classData = this.employees().getRawValue();
    var orgId = localStorage.getItem('orgIds');
    const data = {
      givenName: this.addStudentForm.value.firstName,
      middleName: this.addStudentForm.value.middleName,
      familyName: this.addStudentForm.value.lastName,
      birthdate: this.addStudentForm.value.dob,
      sex: this.addStudentForm.value.genderType,
      grades: this.addStudentForm.value.grade,
      americanIndianOrAlaskaNative: americanIndianOrAlaskaNativeValue,
      asian:  asianValue,
      nativeHawaiianOrOtherPacificIslander: nativeHawaiianOrOtherPacificIslanderValue,
      white: isWhiteValue,
      demographicRaceTwoOrMoreRaces: demographicValue,
      hispanicOrLatinoEthnicity:hispanic1Value,
      countryOfBirthCode: this.addStudentForm.value.countryCode,
      phone: this.addStudentForm.value.number,
      email: this.addStudentForm.value.email,
      username: this.addStudentForm.value.userName,
      password: this.addStudentForm.value.password,
      state: this.addStudentForm.value.state,
      city: this.addStudentForm.value.city,
      zipCode: this.addStudentForm.value.zipCode,
      // parents: this.addStudentForm.value.parents,
      schoolSourcedId:  this.addStudentForm.value.school ? this.addStudentForm.value.school : this.schoolId,
      courseSourcedId: this.selectedClass,
      classSourcedId: this.addStudentForm.value.class,
      role: this.role,
      // beginDate: this.addStudentForm.value.startDate,
      // endDate: this.addStudentForm.value.endDate,
      beginDate: '',
      endDate: '',
      // role: this.addStudentForm.value.role,
      enabledUser: this.addStudentForm.value.statusType,
      sms:this.addStudentForm.value.address,
      agentSourcedIds: this.selParentItems,
    }

    const teacherData = {
      givenName: this.addStudentForm.value.firstName,
      middleName: this.addStudentForm.value.middleName,
      familyName: this.addStudentForm.value.lastName,  
      phone: this.addStudentForm.value.number,
      email: this.addStudentForm.value.email,   
      password: this.addStudentForm.value.password,
      role: this.role,
      sms:this.addStudentForm.value.address,
      state: this.addStudentForm.value.state,
      city: this.addStudentForm.value.city,
      zipCode: this.addStudentForm.value.zipCode,
      schoolSourcedId:  this.addStudentForm.value.school ? this.addStudentForm.value.school : this.schoolId,
      grades: this.addStudentForm.value.grade,
      courseSourcedId: this.selectedClass,
      enabledUser: this.addStudentForm.value.statusType
    }

    const parentData = {
      givenName: this.addStudentForm.value.firstName,
      middleName: this.addStudentForm.value.middleName,
      familyName: this.addStudentForm.value.lastName,
      email: this.addStudentForm.value.email,
      phone: this.addStudentForm.value.number,    
      password: this.addStudentForm.value.password,
      role: this.role,
      schoolSourcedId:  this.addStudentForm.value.school ? this.addStudentForm.value.school : this.schoolId,
      sms: this.addStudentForm.value.address,
      country: this.addStudentForm.value.countryCode,
      state: this.addStudentForm.value.state,
      city: this.addStudentForm.value.city,
      zipCode: this.addStudentForm.value.zipCode,
      enabledUser: this.addStudentForm.value.statusType,
    }
    // console.log(teacherData);
    // return;   
    // add teacher
    if(this.role === 8){
      this.service.createTeacher(teacherData).then(res => {
        if (res['status']) {
          this.router.navigate(['/home/teacher']);
          this.toastr.showSuccess('Teacher created successfully');
        } else{
          if(res['message']){
            this.errorMessages.push(res['message']);
          }else{
            this.errorMessages.push('Something went wrong. Please try later');
          }         
          this.showError = true;
        }
        this.isSubmit = false;
        this.submitBtn.disabled = false;
      }).catch((err: any) => {
        let errData = err.error.errors;
        for (let key in errData) {
          this.errorMessages.push(errData[key][0]);
        }        
        if(errData == undefined || errData == null){
          this.errorMessages.push('Something went wrong. Please try later');
        }
        this.showError = true;
        this.submitBtn.disabled = false;
        this.isSubmit = false;
      })
    }
    // add student
    if(this.role === 7){
      this.service.createStudent(data).then(res => {
        if (res['status']) {         
          this.router.navigate(['/home/student'])
          this.toastr.showSuccess('Student created successfully');  
        } else{
          if(res['message']){
            this.errorMessages.push(res['message']);
          } else{
            this.errorMessages.push('Something went wrong. Please try later');
          }         
          this.showError = true;
        }
        this.isSubmit = false;
        this.submitBtn.disabled = false;
      }).catch((err: any) => {
        let errData = err.error.errors;
        for (let key in errData) {
          this.errorMessages.push(errData[key][0]);
        }
        if(errData == undefined || errData == null){
          this.errorMessages.push('Something went wrong. Please try later');
        }
        this.showError = true;
        this.submitBtn.disabled = false;
        this.isSubmit = false;
      })
    }
    // add parent
    if(this.role === 14){
      this.service.createParent(parentData).then(res => {
        if (res['status']) {          
          this.router.navigate(['/home/parent']);
          this.toastr.showSuccess('Parent created successfully'); 
        } else{
          if(res['message']){
            this.errorMessages.push(res['message']);
          } else{
            this.errorMessages.push('Something went wrong. Please try later');
          }         
          this.showError = true;
        }
        this.isSubmit = false;
        this.submitBtn.disabled = false;
      }).catch((err: any) => {
        let errData = err.error.errors;
        for (let key in errData) {
          this.errorMessages.push(errData[key][0]);
        }
        if(errData == undefined || errData == null){
          this.errorMessages.push('Something went wrong. Please try later');
        }
        this.showError = true;
        this.submitBtn.disabled = false;
        this.isSubmit = false;
      })
    }
    
  }
  // get course list

  getCourseList() {
    this.courseService.getAllCoursebyOrgid(this.schoolId).subscribe(res => {
      this.courseList = res['data']
    })
  }

  // 
  schoolChange(e){
    this.gradLoader = true;
    this.courseLoader = true;
    this.parentLoader = true;
    this.addStudentForm.controls['grade'].disable();
    this.classList = [];
    this.parentList= [];
    this.getGradeItems(e);
    this.getAllParent(e)
    this.getClass(e);
  }
  getGradeItems(schoolId:any){
    this.gradLoader= true;
    this.masterService.allGradeList(schoolId).subscribe((res:any)=>{
      if(res.status){
        this.gradeItems =  res.data;
        this.defOpGrade='Select Grade';
        this.addStudentForm.controls['grade'].enable();
      } else{
        this.gradeItems = [];
        this.defOpGrade='No Grade Found';
      }
      this.gradLoader= false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      if(this.role === 8){
        this.router.navigate(['/home/teacher']);
      } else if(this.role === 7){
        this.router.navigate(['/home/student'])
      } else if(this.role === 14){
        this.router.navigate(['/home/parent']);
      } else{
        this.router.navigate(['/home/dashboard']);
      }
      this.isLoading = false;
    })
  }

  getAllParent(schoolId:any){
    this.parentLoader= true;  
    this.service.getAllParentList(schoolId).subscribe((res:any)=>{
      if(res.status){
        this.parentList =  res.data;
        for(let item in this.parentList){ 
          let fullName = this.parentList[item]['givenName'];
        if(this.parentList[item]['middleName']){
          fullName += ' '+this.parentList[item]['middleName']
        }
        if(this.parentList[item]['familyName']){
          fullName += ' '+this.parentList[item]['familyName']
        }
          this.parentList[item]['fullName'] = fullName + ' - ' + this.parentList[item]['email'];
        } 
        this.defOpParent = 'Select Parent';
      } else{
        this.parentList = [];
        this.defOpParent= 'No Parent Found';
      }
      this.parentLoader= false;     
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      if(this.role === 8){
        this.router.navigate(['/home/teacher']);
      } else if(this.role === 7){
        this.router.navigate(['/home/student'])
      } else if(this.role === 14){
        this.router.navigate(['/home/parent']);
      } else{
        this.router.navigate(['/home/dashboard']);
      }
      this.isLoading = false;
    })
  }



  // get class by course id
  getClass(schoolId = this.schoolId) {
    this.classList = [];
    this.courseLoader = true;
    this.addStudentForm.get('course').setValue('');
    this.classService.getAllClasses(schoolId).subscribe(res => {
      if(res['status']){
        this.classList = res['data']
        for(let item in this.classList){
          this.classList[item]['select_txt'] = this.classList[item]['title'] + ' - ['+ this.classList[item]['classCode'] +'] - (' + this.classList[item]['course'] + ')';          
        }
        this.defOpClass = 'Select Class';
      } else{
        this.classList = [];
        this.defOpClass = 'No Class Found';
      }
      this.courseLoader = false;
      this.isLoading = false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      if(this.role === 8){
        this.router.navigate(['/home/teacher']);
      } else if(this.role === 7){
        this.router.navigate(['/home/student'])
      } else if(this.role === 14){
        this.router.navigate(['/home/parent']);
      } else{
        this.router.navigate(['/home/dashboard']);
      }
      this.isLoading = false;
    })
  }
}
