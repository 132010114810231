import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { LoginServiceService } from './login-service.service';


@Injectable({
  providedIn: 'root'
})
export class AcademicService {




  constructor(private http: HttpClient, private loginSrv:LoginServiceService) {

  }

  getHeader = () =>{
    return {
      headers : new HttpHeaders({
        "Authorization" :'Bearer ' + this.loginSrv.getUserToken()
      })
    }
  }


  // create Session api
  createSession(data) {
    return this.http.post(env.apiurl + 'academic-session', data, this.getHeader()).toPromise();
  }

  // view Session api
  viewSession(page:number = 1, year='', type='') {
    return this.http.get(env.apiurl + 'academic-session?page='+page+'&year='+ year + '&type=' + type, this.getHeader())
  }
  getAllSession() {
    return this.http.get(env.apiurl + 'academic-session?allrecords=yes', this.getHeader())
  }
  // delete Session

  deleteSession(data) {
    return this.http.delete(env.apiurl + 'academic-session/' + data, this.getHeader())
  }

  // edit Session api

  editSession(data) {
    return this.http.get(env.apiurl + 'academic-session/' + data + '/edit', this.getHeader())
  }
  // update Session api

  updateSession(id, data) {
    return this.http.put(env.apiurl + 'academic-session/' + id, data, this.getHeader()).toPromise();
  }
}
