import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CourseService } from 'src/app/services/course.service';
import { Router, ActivatedRoute  } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';
import { BulkImportCsvService } from 'src/app/services/bulk-import-csv.service';
import { NotificationService } from 'src/app/services/notification.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


import { ClassService } from 'src/app/services/class.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { OrganisationService } from 'src/app/services/organisation.service';

@Component({
  selector: 'app-bulk-view-upload',
  templateUrl: './bulk-view-upload.component.html',
  styleUrls: ['./bulk-view-upload.component.css']
})


export class BulkViewUploadComponent implements OnInit {

  userPermissionData:any;
  viewPage:boolean = false;
  currentAddLnk:any;
  constructor(public serviceSchool: OrganisationService , public loginService : LoginServiceService  , private classSrv : ClassService, private xj: externalJs, private serviceCourse: CourseService, private router: Router, private route: ActivatedRoute, private serviceBulkUpload: BulkImportCsvService, private FB : FormBuilder, private toastr: NotificationService) {
    this.route.queryParamMap.subscribe(queryParams => {
      if(queryParams.get("schoolId")){
        this.schoolId = queryParams.get("schoolId")
      } else if(loginService.getCurrentUserRole().slug == "principal" || loginService.getCurrentUserRole().slug == "teacher"){
        this.router.navigate(['/home//bulk/upload-user']);
      }
      else {
        this.schoolId= '';
      }
    }) 
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;
    a.forEach(element => {
      if (element.module === "Bulk Upload") {
        this.userPermissionData = element?.permission;
      }
    });
    this.userPermissionData?.forEach(element => {
      if(element?.permission === 'Bulk user upload'){
        this.currentAddLnk = "/home//bulk/upload-user"; 
        this.viewPage = true;
      }
    });
  }
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  isLoading: boolean = false;
  masterSelected:boolean;
  checkedList:any;
  courseData: any;
  classData: any = [];
  editLists: any;
  currentItemIndex:number = 0;
  dataLength: number = 0;
  userDatas : any = [];
  courseIds: any;

  isSubmit: boolean = false;
  isBulkActive: boolean = false;
  
  dropdownSettings: IDropdownSettings = {};

 
  currAddLnkTxt: string = "Upload User CSV";
  // filters
  allSchools:any ;
  allRoles:any = [
    {id:8, title:'Teacher'},
    {id:7, title:'Student'},
    {id:14, title:'Parent'},
  ];
  currentSchool:any = parseInt(localStorage.getItem('orgIds')) || '';
  currentRole:any = '';
  currentName:any = '';
  currentEmail:any = '';
  currentPage = 1;
  totalPages: any = [];

  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  message:any = '';
  errorMessages:any = []
  showError:boolean = false;
  defOpClass:any ='Select Class';
  classLoader:boolean= false;
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    
 

    this.route.queryParamMap
      .subscribe((params:any) => {
        let paramsObj = { ...params.keys, ...params };
        
        if(paramsObj.params.page){
          this.currentPage = paramsObj.params.page;
        }
        if(paramsObj.params.role){
          this.currentRole = paramsObj.params.role;
        }else {
          this.currentRole = '';
        }
        if(paramsObj.params.name){
          this.currentName = paramsObj.params.name;
        }else {
          this.currentName = '';
        }
        if(paramsObj.params.schoolId){
          this.currentSchool = paramsObj.params.schoolId;
        }else {
          this.currentSchool = this.currentSchool;
        }if(paramsObj.params.email){
          this.currentEmail = paramsObj.params.email;
        }else {
          this.currentEmail = '';
        }
        this.initComp();
      });
       
      if(this.schoolId){
        this.getClassesFromSchool(this.schoolId);
      } else{
        this.getSchool();
      }   
    
  }
  getSchool(){
    this.serviceSchool.allSchool().subscribe((res:any) =>{
      if(res.status){
        this.allSchools = res.data
      } else{
        this.allSchools = [];
      }
    })
  }
  initComp(){
    this.getUploadedUser(this.currentSchool);
    
    
    this.dropdownSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: "id",
      textField: "full_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
    };
  }

  filterUsers(){
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, schoolId: this.currentSchool, role: this.currentRole, name: this.currentName, email: this.currentEmail },
        queryParamsHandling: 'merge'
      });
  }
  mapPages(lastPage = 1) {
    let pageListArr = [];
    this.totalPages = [];
    if(lastPage <=1) return;
    if(lastPage <= 5){
      for(var i =1; i<= lastPage; i++){
          pageListArr.push(i);
      }
    }
    if(lastPage > 5){
      if(this.currentPage <=4){        
          for(var i =1; i<= 5; i++){
            pageListArr.push(i);
          }      
          pageListArr.push('...');
          pageListArr.push(lastPage);
      } else if(lastPage - this.currentPage <=3){
        pageListArr.push(1);
        pageListArr.push('...');
        for(var i =lastPage - 4; i<= lastPage; i++){
          pageListArr.push(i);
        } 
      }
      else {
        pageListArr.push(1);
        pageListArr.push('...');  

        for(var i = this.currentPage - 2; i<= this.currentPage + 2; i++){
          pageListArr.push(i);
        }

        pageListArr.push('...');
        pageListArr.push(lastPage);
      } 
    } 
    this.totalPages = pageListArr;
  }

   // pagination
   changeCurrentPage(page: any) {
     
    if(page != '...'){
    this.currentPage = page;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: page },
        queryParamsHandling: 'merge'
      });
    }
  }

  getUploadedUser(id: any = this.schoolId){
    this.isLoading = true;    
    //this.serviceBulkUpload.getUploadedUser(id).subscribe(res =>{
    this.serviceBulkUpload.getUploadedUser(this.currentPage, id, this.currentRole, this.currentName, this.currentEmail).subscribe(res =>{ 
      //console.log(res);
      this.userDatas = [];
      if(res['status'] && res['status_code'] === 200){
        this.userDatas = res['data']['data'];
        this.mapPages(res['data']['last_page']);
        this.tableDataFrom = res['data']['from'];
        this.tableDataTo = res['data']['to'];
        this.tableDataTotal = res['data']['total'];
        for(let item in this.userDatas){
          this.userDatas[item]['isSelected'] = false;
        }       
        this.message = '';
        this.isLoading = false;       
      } else if(res['status_code'] === 404){
        this.userDatas = [];
        this.message= "No CSV User Data Found";
        this.isLoading = false;
      } else {
        this.message= "Something went wrong. Please try later";
        this.isLoading = false;    
      }
     
    }, (err:any)=>{
        this.message= "Something went wrong. Please try later";
        this.isLoading = false;    
    })
   
  }

  getClassesFromSchool(id: any = this.schoolId){
    this.classLoader = true;
    this.serviceBulkUpload.getAllClassesFromSchoolId(id).subscribe(res => {   
      if(res['status'])  {
        this.classData = res['data'];
        for(let item in this.classData){
          this.classData[item]['full_text'] = this.classData[item]['title'] + ' - ['+ this.classData[item]['classCode'] +'] - (' + this.classData[item]['course'] + ')';
        } 
          this.defOpClass = 'Select Class';
      } else{
        this.classData = [];
        this.defOpClass= 'No Class Found';
      }
      this.classLoader = false;
    },(err:any)=>{
      this.toastr.showError('Unable to load course. Please try later', '');
      this.xj.closeModalPopup("#editpopup"); 
    })
  }

  

 
  getRoleName(roleId: any){
    let id = parseInt(roleId);
    if(id === 7){
      return 'Student';
    } else if(id === 8){
      return 'Teacher';
    } else{
      return 'NA';
    }

  }

  selectSchool(e:any){
    this.classData = [];
    this.classLoader= true;
    this.getClassesFromSchool(e);
  }
  
  // popup form 
  editBulkUser = new FormGroup(
    { 
      schoolId: new  FormControl(this.schoolId),
      user_ids: new FormControl(''),
      class_ids: new FormControl('',),
      userRole: new FormControl('',),
    }
  );



  bulkEdit(userArr: any = "", ) {    
    this.editLists = [];   
    this.editLists = userArr;  
    this.isBulkActive = true;
    this.editBulkUser.controls['class_ids'].disable();
    let arr = [];
    this.editLists.forEach(el => {
      arr.push(el.id);
    });
    this.editBulkUser.get('user_ids').setValue(arr);
    this.xj.openModalPopup("#editpopup");
  }

  updateBulkUser(event:any) {
    const btn =  event.target;
    btn.disabled = true;
    this.isSubmit = true;
    // this.isLoading = true;
    this.errorMessages = [];
    this.showError = false;
    let formData = new FormData;
    // console.log(this.editBulkUser.get('user_ids').value);
    // console.log(this.editBulkUser.get('class_ids').value);
    this.editBulkUser.get('user_ids').value.forEach(id => {
      formData.append('userId[]', id);
    });
    if(this.editBulkUser.get('class_ids').value){
      this.editBulkUser.get('class_ids').value.forEach(data => {
        formData.append('classId[]', data.id);
      });
    } else{
      formData.append('classId[]', '');  
    }
    
    formData.append('role', this.editBulkUser.get('userRole').value);
    formData.append('schoolId', this.editBulkUser.get('schoolId').value);
    
    this.classSrv.assignClassToUser(formData).then((res:any)=>{
      if(res?.status){
        this.isSubmit = false;
        btn.disabled = false;
        // this.getUploadedUser();
        this.cancelBulkEdit();
        this.toastr.showSuccess(res['message']);
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }         
        this.showError = true;
      }
      this.isSubmit = false;
      btn.disabled = false;
    }).catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
        this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      btn.disabled = false;
    })
  }

  // 

  get m(){
    return this.editBulkUser.controls;
  }
  // 
  isAllSelected() {
    this.masterSelected = this.userDatas.every(function(item:any) {
        return item.isSelected == true;
    })
    this.getCheckedItemList();
  }
  // 
  checkUncheckAll() {
    for (var i = 0; i < this.userDatas.length; i++) {
      this.userDatas[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  // 
  getCheckedItemList(){
    this.checkedList = [];
    for (var i = 0; i < this.userDatas.length; i++) {
      if(this.userDatas[i].isSelected){
        this.checkedList.push(this.userDatas[i]);
      }
    } 
    //console.log(this.checkedList);
  }

  

 

  // updateData(e: any){
  //   const btn =  e.target;
  //   btn.setAttribute('disabled', true);
  //   this.isSubmit = true;
  //   this.courseIds = [];
  //   this.courseIds = this.editBulkForm.value.courseId;
  //   let course = [];
  //   for(let item of this.courseIds){
  //     course.push(item.id)
  //   }
  //   const userId = this.editBulkForm.value.userId;
  //   const formData = {
  //     schoolId: this.schoolId,
  //     givenName: this.editBulkForm.value.givenName,
  //     familyName: this.editBulkForm.value.familyName,
  //     middleName: this.editBulkForm.value.middleName, 
  //     role: this.editBulkForm.value.role,
  //     courseId:  course,
  //   }
  //   //console.log((this.currentItemIndex + 1) +"==>"+ this.dataLength);
    
  //   this.serviceBulkUpload.updateUser(userId, formData).then(res => {
  //     if(res['status'] && res['status_code'] === 200){
  //       this.toastr.showSuccess(res['message']);
  //       setTimeout(() => {
  //         this.isSubmit = false;
  //         // if(this.isBulkActive){
  //         //   this.nextItem(); 
  //         // } else{
  //         //   this.cancelBulkEdit();
  //         // }  
  //         this.cancelBulkEdit();      
  //       }, 1000);
       
  //     } else{
  //       this.isSubmit = false;
  //       btn.removeAttribute('disabled');
  //       this.toastr.showError('Unable to upload. Please try after sometime', '');
  //     }
  //     console.log(res);
  //   }).catch((err:any)=>{
  //     console.log(err);
  //     btn.removeAttribute('disabled');
  //     this.toastr.showError('Unable to upload. Please try after sometime', '');
  //   })
  // }

  // clear bulk edit 
  cancelBulkEdit(){
    if(this.checkedList){
      for (var i = 0; i < this.checkedList.length; i++) {
        let index = this.userDatas.findIndex(item => item.id === this.checkedList[i].id);
        this.userDatas[index].isSelected = false;        
      }
      this.checkedList.splice(0,this.checkedList.length) 
      this.getCheckedItemList();  
      this.masterSelected = false; 
    }
    this.xj.closeModalPopup("#editpopup"); 
    this.editBulkUser.reset();
    this.initComp();
  }


  handleReset() {
    this.editBulkUser.get('class_ids').setValue([]);
  }

  clearFilter() {
    this.currentSchool = '';
    this.currentRole = '';
    this.currentName = '';
    this.currentEmail = '';
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, schoolId: '', name: '', role: '', email:'' },
        queryParamsHandling: 'merge'
      });
   


  }

}
