import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '../../environments/environment';
import { LoginServiceService } from './login-service.service';



@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient, private loginSrv:LoginServiceService) { }

  getHeader = () =>{
    return {
      headers : new HttpHeaders({
        "Authorization" :'Bearer ' + this.loginSrv.getUserToken()
      })
    }
  }

  // save document
  saveDoc(data:any) {
    return this.http.post(env.apiurl + 'assign-doc-to-student', data, this.getHeader()).toPromise()
  }

  // share document
  share(data:any) {
    return this.http.post(env.apiurl + 'documentshare', data, this.getHeader()).toPromise()
  }
  reject(data:any) {
    return this.http.post(env.apiurl + 'document/reject', data, this.getHeader()).toPromise()
  }
  // delete document
  delete(id, action='') {
    return this.http.delete(env.apiurl + 'document/'+id+'?action='+action, this.getHeader()).toPromise()
  }

  shareList(hsh:any){
    return this.http.get(env.apiurl + 'document-list?hsh='+hsh, this.getHeader()).toPromise()
  }
  // end class

  getForwardUserList(schoolId: any){
    return this.http.get(env.apiurl + 'forward-to-whom?schoolId='+schoolId, this.getHeader())
  }

  forwardedToWhom(formData:any){
    return this.http.post(env.apiurl + 'forward-document', formData, this.getHeader()).toPromise()
  }

  bulkDocForward(formData:any){
    return this.http.post(env.apiurl + 'update-document', formData, this.getHeader()).toPromise()
  }
}
