<div id="main-wrapper">
    <!--  -->
    <app-sidebar></app-sidebar>
    <!-- sidebar menu -->
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form"  [ngClass]="{'loading position-relative': isLoading}">
                <div class="apploader" [ngClass]="{'show': isLoading}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Getting your data...</span>
                    </div>
                </div>
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Upload CSV</h5>                        
                    </div>
                </div>
                <form [formGroup]="uploadBulkDocForm">
                    <div class="card-body">
                        <div class="row">                                
                            <div class="col-lg-12">
                                <div class="row align-items-md-center d-none" *ngIf='loginService.getCurrentUserRole().slug === "super-admin" || loginService.getCurrentUserRole().slug === "superident"'>
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="name" class="text-dark fw-500 label-text">School Name<sup class="text-danger">*</sup> :</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <select class="form-control" name="" id="organisation" formControlName="school" [ngClass]="(m.school.touched && m.school.invalid) ? 'is-invalid' : ''">
                                                <option value="">Select School</option>
                                                <option *ngFor="let i of orgData;" value="{{i.id}}">{{ i.name }}</option>
                                           </select>
                                           <div *ngIf="m.school.touched && m.school.invalid" class="invalid-feedback">
                                                Please Select School
                                          </div>
                                        </div>                                       
                                    </div>
                                </div>
                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="doc" class="text-dark fw-500 label-text mb-0">Upload School :</label>
                                            <small class="text-muted d-block">
                                                Upload Org.csv file
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-9">
                                        <div class="form-group">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="orgFile" (change)="onOrgFileChange($event)">
                                                <label class="custom-file-label" for="orgFile">Choose File</label>
                                            </div>                                              
                                        </div>                                       
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <button type="button" class="btn btn-success upload-btn" (click)="uploadOrgDoc($event)" [disabled]="!m.uploadOrg.value">Upload</button>
                                        </div>                                        
                                    </div>
                                </div>   

                                <div class="row align-items-md-center d-none">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="doc" class="text-dark fw-500 label-text mb-0">Upload Academic Session :</label>
                                            <small class="text-muted d-block">
                                                Upload Academicsessuib.csv file
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-9">
                                        <div class="form-group">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="customFile" (change)="onAcademicFileChange($event)">
                                                <label class="custom-file-label" for="customFile">Choose File</label>
                                              </div>                                              
                                        </div>                                       
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <button type="button" class="btn btn-success upload-btn" (click)="uploadAcDoc($event)" [disabled]="!m.uploadAcademic.value">Upload</button>
                                        </div>                                        
                                    </div>

                                </div>   

                               


                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="doc" class="text-dark fw-500 label-text mb-0">Upload Courses :</label>
                                            <small class="text-muted d-block">
                                                Upload Course.csv file
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-9">
                                        <div class="form-group">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="uploadCourse" (change)="onCourseFileChange($event)">
                                                <label class="custom-file-label" for="uploadCourse">Choose File</label>
                                              </div>                                              
                                        </div>                                       
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <button type="button" class="btn btn-success upload-btn" (click)="uploadCRDoc($event)" [disabled]="!m.uploadCourse.value">Upload</button>
                                        </div>                                        
                                    </div>
                                </div>   


                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="doc" class="text-dark fw-500 label-text mb-0">Upload Classes :</label>
                                            <small class="text-muted d-block">
                                                Upload Classes.csv file
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-9">
                                        <div class="form-group">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="uploadClass" (change)="onClassesFileChange($event)">
                                                <label class="custom-file-label" for="uploadClass">Choose File</label>
                                              </div>
                                        </div>                                       
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <button type="button" class="btn btn-success upload-btn" (click)="uploadCLDoc($event)" [disabled]="!m.uploadClass.value">Upload</button>
                                        </div>                                        
                                    </div>
                                </div>   


                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="doc" class="text-dark fw-500 label-text mb-0">Upload Users :</label>
                                            <small class="text-muted d-block">
                                                Upload User.csv file
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-9">
                                        <div class="form-group">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="uploadUser" (change)="onUserFileChange($event)">
                                                <label class="custom-file-label" for="uploadUser">Choose File</label>
                                            </div>
                                              
                                        </div>                                       
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <button type="button" class="btn btn-success upload-btn" (click)="uploadURDoc($event)"  [disabled]="!m.uploadUser.value">Upload</button>
                                        </div>                                        
                                    </div>
                                </div>   

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="doc" class="text-dark fw-500 label-text mb-0">Upload Enrollments :</label>
                                            <small class="text-muted d-block">
                                                Upload Enrollments.csv file
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-9">
                                        <div class="form-group">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="uploadEnroll" (change)="onEnrollFileChange($event)">
                                                <label class="custom-file-label" for="uploadEnroll">Choose File</label>
                                            </div>
                                              
                                        </div>                                       
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <button type="button" class="btn btn-success upload-btn" (click)="uploadENDoc($event)" [disabled]="!m.uploadEnroll.value">Upload</button>
                                        </div>                                        
                                    </div>
                                </div>   

                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="doc" class="text-dark fw-500 label-text mb-0">Upload Demographics :</label>
                                            <small class="text-muted d-block">
                                                Upload Demographics.csv file
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-9">
                                        <div class="form-group">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="uploadDemographic"  (change)="onDemographicFileChange($event)">
                                                <label class="custom-file-label" for="uploadDemographic">Choose File</label>
                                            </div>
                                        </div>                                       
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <button type="button" class="btn btn-success upload-btn" (click)="uploadDGDoc($event)" [disabled]="!m.uploadDemographic.value">Upload</button>
                                        </div>                                        
                                    </div>
                                </div>   


                            </div>
                           


                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>