import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { LoginServiceService } from 'src/app/services/login-service.service';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentComponent implements OnInit {

  constructor( private xj:externalJs,public loginService: LoginServiceService) { }

  ngOnInit(): void {
    this.xj.extraInit();
  }

}
