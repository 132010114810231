import { Component, OnInit, ViewChild } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { CourseService } from 'src/app/services/course.service'
import { ActivatedRoute, Router } from '@angular/router';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { MasterService } from 'src/app/services/master.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.css']
})
export class CoursesListComponent implements OnInit {
  @ViewChild( 'delete') delete;
  courseList: any = [];
  courseId: any;
  currentName = '';
  currentGrade = '';
  currentAddLnk :any ;
  currAddLnkTxt: string = "Add Course";
  getAllCourseList =[]
  orgData: any = [];
  message: string = '';
  userPermissionData: any;
  editButton: boolean = false;
  deleteButton: boolean = false;
  viewPage :boolean = false;
  currentPage:number = 1;
  lastPage:number = 1;
  pageList:any = [];
  
  gradeItems: any= [];
  isLoading = true;
  currentSchool: any = parseInt(localStorage.getItem('orgIds')) || '';
  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  tableDataPerPage: number = 20;
  constructor(private xj:externalJs,private service: CourseService , public loginService:LoginServiceService, private toaster: NotificationService,private router: Router, private route: ActivatedRoute,  private masterService: MasterService, private serviceOrganisation: OrganisationService) {
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if(element.module === "Course"){
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {
      if(element?.permission === 'Delete course'){
       this.deleteButton = true;
      }else if(element?.permission === 'update course'){
        this.editButton = true;
      }else if(element?.permission === 'create course'){
        this.currentAddLnk = '/home/course/add';  
      } else if(element?.permission === 'view courses'){
        this.viewPage = true;
      }
    });

     // params
     this.route.queryParamMap
     .subscribe((params: any) => {
       let paramsObj = { ...params.keys, ...params };

       if (paramsObj.params.page) {
         this.currentPage = paramsObj.params.page;
       }
       if (paramsObj.params.name) {
         this.currentName = paramsObj.params.name;
       } else {
         this.currentName = '';
       } if (paramsObj.params.school) {
          this.currentSchool = paramsObj.params.school;
        } 
       if (paramsObj.params.grade) {
         this.currentGrade = paramsObj.params.grade;
       } else {
         this.currentGrade = '';
       } 
       this.initComp();
     });
    
   }
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();       
    this.getAllSchool();    
    this.getGradeItems(this.currentSchool);
  }
  initComp(){
    this.getCourseList();
  }

  // get all school
  getAllSchool(){   
    this.serviceOrganisation.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data
      } else{
        this.orgData = []
      }
    }, (err:any)=>{
        this.toaster.showError('Something went wrong. Please try later','');
        this.router.navigate(['/home/dashboard']);
    })
  }
  // get course list

  getCourseList(){
    this.isLoading = true;
    this.service.viewCourse(this.currentPage,this.currentName, this.currentSchool, this.currentGrade).subscribe(res => {
      if(res['status']){
        this.courseList = res['data'].data
        this.currentPage = res['data'].current_page;
        this.lastPage = res['data'].last_page;
        this.mapPageList( res['data'].last_page);
        this.tableDataFrom = res['data']['from'];
        this.tableDataTo = res['data']['to'];
        this.tableDataTotal = res['data']['total'];
        this.tableDataPerPage = res['data']['per_page'];       
        this.message = '';
        this.isLoading = false;
      } else if(res['status_code'] === 404){
        this.message = 'No Course Data Found';        
        this.courseList = [];
        this.isLoading = false;
      } else{
        this.message = 'Somenthing went wrong. Please try later';  
        this.courseList = [];
        this.isLoading = false;       
      }      
    }, (err:any)=>{
      this.message= "Something went wrong. Please try later";
      this.isLoading = false;
    })
  }
  // for mapping page list
  mapPageList(lastPage){
    this.pageList = [];
    let pageListArr = [];
    if(lastPage <=1) return;
    if(lastPage <= 5){
      for(var i =1; i<= lastPage; i++){
          pageListArr.push(i);
      }
    }
    if(lastPage > 5){
      if(this.currentPage <=4){        
          for(var i =1; i<= 5; i++){
            pageListArr.push(i);
          }      
          pageListArr.push('...');
          pageListArr.push(lastPage);
      } else if(lastPage - this.currentPage <=3){
        pageListArr.push(1);
        pageListArr.push('...');
        for(var i =lastPage - 4; i<= lastPage; i++){
          pageListArr.push(i);
        } 
      }
      else {
        pageListArr.push(1);
        pageListArr.push('...');  

        for(var i = this.currentPage - 2; i<= this.currentPage + 2; i++){
          pageListArr.push(i);
        }

        pageListArr.push('...');
        pageListArr.push(lastPage);
      } 
    } 
    this.pageList = pageListArr;
  }
    // change page
  changePage(page){
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: page },
        queryParamsHandling: 'merge'
      });
    
  }
  // get course id
  getCourseId(id){    
    this.courseId= id
  }

  // delete course

  deleteCourse(){
    this.service.deleteCourse(this.courseId).subscribe((res:any) => {
      if(res.status){
        this.initComp();
        this.toaster.showSuccess("Course deleted successfully !!");
        this.xj.closeModalPopup("#deletePopup");        
      } else{
        this.toaster.showError('Unable to delete course', '');
        this.initComp();
        this.xj.closeModalPopup("#deletePopup");   
      }      
    }, (err:any)=>{
        this.toaster.showError('Something went wrong. Please try later', '');
        this.initComp();
        this.xj.closeModalPopup("#deletePopup");   
    })
  }

    // go to edit page
    goToEditPage(id) {
      this.router.navigate(['/home/course/edit'], { queryParams: { id: id } })
    }

     // filter
  filterData() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: 1, name: this.currentName, school: this.currentSchool, grade: this.currentGrade},
        queryParamsHandling: 'merge'
      });
  }
  clearFilter() {
    this.currentName = '';
    this.currentGrade = '';
    this.currentSchool = parseInt(localStorage.getItem('orgIds')) || '';
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, name: '', school:'',  grade: ''},
        queryParamsHandling: 'merge'
      });

  }

   // on school change
  filterSchool(orgId){
    this.currentGrade = '';
    this.getGradeItems(orgId);
  }
  getGradeItems(schoolId:any){
    this.masterService.allGradeList(schoolId).subscribe((res:any)=>{
      if(res.status){
        this.gradeItems =  res.data;
      } else{
        this.gradeItems = []
      }
    }, (err:any)=>{
      this.toaster.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
    })
  }

}
