import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { externalJs } from 'src/app/services/external-js.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AcademicService } from 'src/app/services/academic.service';

@Component({
  selector: 'app-add-session',
  templateUrl: './add-session.component.html',
  styleUrls: ['./add-session.component.css']
})
export class AddSessionComponent implements OnInit {

  showErrMsg: string;
  showError:boolean = false;
  errorMessages:any = [];
  startDateData: any = '';
  endDateData: any = '';
  userPermissionData:any;
  viewPage: boolean = false;
  isSubmit: boolean = false;
  submitBtn:any;
  isLoading:boolean = true;
  constructor(private xj: externalJs, private service: AcademicService, private router: Router, private toastr: NotificationService) { 

    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if (element.module === "academic session") {
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {
      if (element?.permission === 'create session') {
        this.viewPage = true;
      } 
    });
  }

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }
  //  add form validation
  addSessionForm = new FormGroup({
    title: new FormControl('',),
    term: new FormControl('',),
    schoolYear: new FormControl('',),
    endDate: new FormControl('',),
    startDate: new FormControl('',),
  })

  // add session api
  addSession(e:any) {
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.errorMessages = []
    this.showError = false;
    this.isSubmit = true;
    let r = (Math.random() + 1).toString(36).substring(7); //for generating random string
    const data = {
        title: this.addSessionForm.value.title,
        type : this.addSessionForm.value.term,
        // startDate : this.addSessionForm.value.startDate,
        // endDate : this.addSessionForm.value.endDate,
        startDate : this.startDateData,
        endDate : this.endDateData,
        parentSourcedId: r,
        schoolYear : this.addSessionForm.value.schoolYear
    }
    // console.warn(data);
    this.service.createSession(data).then(res => {
      if(res['status']){     
        this.toastr.showSuccess("Session added successfully !!")
        this.router.navigate(['/home/academic-session']);
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }         
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
    .catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }

//  handle
updateStartDate(date: any) {
  setTimeout(() => {
    // console.log(date.target.value);
    this.startDateData = date.target.value
  }, 300);
}

updateEndDate(date: any) {
  setTimeout(() => {
    // console.log(date.target.value);
    this.endDateData = date.target.value
  }, 300);
}

}
