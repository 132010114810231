import { Injectable } from '@angular/core';
import {environment as env} from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginServiceService } from './login-service.service';


@Injectable({
  providedIn: 'root'
})
export class StudentService {
  token: string;
 

  constructor(private http: HttpClient, private loginSrv:LoginServiceService) {
 
  }

  getHeader = () =>{
    return {
      headers : new HttpHeaders({
        "Authorization" :'Bearer ' + this.loginSrv.getUserToken()
      })
    }
  }

  // create student api
  createStudent(data){
    return this.http.post(env.apiurl +'student', data,this.getHeader()).toPromise();
  }

  // create student api
  createTeacher(data){
    return this.http.post(env.apiurl +'teacher', data,this.getHeader()).toPromise();
  }

  // create student api
  createParent(data){
    return this.http.post(env.apiurl +'parents', data,this.getHeader()).toPromise();
  }

    // view student api
  viewStudent(page:number = 1, school = '', course = '', name ='', email='', class_id = ''){
    return this.http.get(env.apiurl + 'student?page='+page+'&orgId='+school+'&name='+name+'&classId='+class_id+'&courseId='+course+'&email='+email,this.getHeader())
  }

  // delete student

  deleteStudent(data){
    return this.http.delete(env.apiurl + 'users/'+ data,this.getHeader())
  }

  // edit student api

  editStudent(data){
    return this.http.get( env.apiurl + 'student/'+ data +'/edit',this.getHeader())
  }
  // update student api

  updateStudent(id,data){
    return this.http.put( env.apiurl + 'student/'+ id,data,this.getHeader()).toPromise();
  }
  // search api
  getAllStudentApi(name, grade,school) {
    return this.http.get(env.apiurl + 'student?' + name + '&' + grade+ '&' + school, this.getHeader())
  }

  // search api
  searchStudents(name:any,school_id:any) {
    return this.http.get(env.apiurl + 'student?name='+name+'&classId&courseId=&username&schoolId='+school_id+'&search=yes', this.getHeader()).toPromise();
  }

  getAllStudent(school){
    return this.http.get(env.apiurl + 'student?schoolId=' + school, this.getHeader())
  }

  getParentList(page:number = 1, school = '', name ='', email=''){
    return this.http.get(env.apiurl + 'parents?page='+page+'&schoolId='+school+'&name='+name+'&email='+email,this.getHeader())
  }

  getAllParentList(school){
    return this.http.get(env.apiurl + 'parents?schoolId=' + school+'&allrecords=yes', this.getHeader())
  }

  viewParentItem(id:any){
    return this.http.get(env.apiurl + 'users/' + id+'/edit', this.getHeader())
  }
  updateParent(id:any,formData:any){
    return this.http.put( env.apiurl + 'parents/'+ id, formData, this.getHeader()).toPromise();
  }
  deleteParent(id:any){
    return this.http.delete(env.apiurl + 'users/'+ id,this.getHeader()).toPromise();
  }


  getAllStudents(school:any='', grade:any='', course:any=''){
    return this.http.get(env.apiurl + 'student?schoolId='+school+'&grade='+grade+'&courseId='+course+'&allrecords=yes', this.getHeader())
  }
  searchAllStudents(school:any='', grade:any='', course:any=''){
    return this.http.get(env.apiurl + 'student?schoolId='+school+'&grade='+grade+'&courseId='+course+'&search=yes', this.getHeader())
  }
}
