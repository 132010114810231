import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { LoginServiceService } from './login-service.service';


@Injectable({
  providedIn: 'root'
})
export class CourseService {
  token: string;


  constructor(private http: HttpClient,private loginSrv:LoginServiceService) {

    this.token = localStorage.getItem('token')
  }


  getHeader = () =>{
    return {
      headers : new HttpHeaders({
        "Authorization" :'Bearer ' + this.loginSrv.getUserToken()
      })
    }
  }

  // create course api
  createCourse(data) {
    return this.http.post(env.apiurl + 'course', data, this.getHeader()).toPromise()
  }

  // view course api
  viewCourse(page:number = 1,title ='',orgId='',grades='') {
    return this.http.get(env.apiurl + 'course?page='+page+'&title='+ title + '&org='+orgId+'&grades=' + grades, this.getHeader())
  }

  // delete course

  deleteCourse(data) {
    return this.http.delete(env.apiurl + 'course/' + data, this.getHeader())
  }

  // edit course api

  editCourse(data) {
    return this.http.get(env.apiurl + 'course/' + data + '/edit', this.getHeader())
  }
  // update course api

  updateCourse(id, data) {
    return this.http.put(env.apiurl + 'course/' + id, data, this.getHeader()).toPromise()
  }

  // search api
  getAllCourseApi(title, grades) {
    return this.http.get(env.apiurl + 'course?' + title + '&' + grades, this.getHeader())
  }

  // get course by orgId
  getAllCoursebyOrgid(orgId) {
    return this.http.get(env.apiurl + 'course?org=' + orgId+'&allrecords=yes', this.getHeader())
  }

  // get students by courseId
  getAllStudetnsbyCourseid(courseId) {
    return this.http.get(env.apiurl + 'student?courseId=' + courseId, this.getHeader())
  }

  getAllStudetnsbyParent(school:any='', grade:any='', course:any=''){
    return this.http.get(env.apiurl + 'get-child?schoolId='+school+'&grade='+grade+'&courseId='+course+'&allrecords=yes', this.getHeader())
   
  }

  // get doucment type api
  getdocTypes() {
    return this.http.get(env.apiurl + 'document-type?allrecords=yes', this.getHeader())
  }

  getAllStudents(school:any='', grade:any='', course:any=''){
    return this.http.get(env.apiurl + 'student?schoolId='+school+'&grade='+grade+'&courseId='+course+'&allrecords=yes', this.getHeader())
  }


}
