import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoginServiceService } from 'src/app/services/login-service.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {

  rqsFailSts :boolean;
  errmsg:string = '';
  sucsmsg:string = '';
  isLoading:boolean = false;
  forgetpass:number;
  isForgot:boolean;
  submitBtn:boolean = false;

  constructor(private router:Router,private route:ActivatedRoute, private service:LoginServiceService) { }

  ngOnInit(): void {


    this.route.params.subscribe((params:Params)=>{
      if (params['isForgotpas'] ){
        this.isForgot = true;
        this.forgetpass = 1;
      }
      else {
        this.router.navigate(['/login']);
      }
    })

   

  } 
  
  otpFormSubmit(frm:NgForm){
    this.isLoading = true;    
    this.submitBtn = true;
    this.sucsmsg='';
    this.errmsg='';
    var email = localStorage.getItem('res_email');
    this.service.verifyOtp(frm.value.otp,this.isForgot,email,).subscribe(sbsData=>{
      this.isLoading = false;
      if(sbsData['status'] === true){        
        if(this.forgetpass == 1){          
          this.router.navigate(['/reset-password']);
        }else{
          this.router.navigate(['/login']);
        }        
      }else{
        this.rqsFailSts = true;
        this.sucsmsg='';
        this.errmsg = sbsData['message'];
      }
      this.isLoading = false;    
      this.submitBtn = false;
    },(err:any)=>{
      this.sucsmsg='';
      this.errmsg = 'Something went wrong. Please try later';
      this.isLoading = false;    
      this.submitBtn = false;
    })
  }

  resendOTP(){
    if(this.isForgot){
      var email = localStorage.getItem('res_email');
    }else{
      var email = localStorage.getItem('r_email');
    }
    this.sucsmsg='';
    this.errmsg='';
    this.service.resendOtp(email,this.isForgot).subscribe(sbsData=>{
      if(sbsData['status']){
        this.errmsg="";
        this.sucsmsg = "OTP sent successfully";
        setTimeout(() => {
          this.sucsmsg = '';
        }, 2000);
      }else{
        this.sucsmsg='';
        this.errmsg = "Something went wrong. Please try later";
      }
    },(err:any)=>{
      this.sucsmsg='';
      this.errmsg = "Something went wrong. Please try later";
    });
  }

}
