import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { LoginServiceService } from 'src/app/services/login-service.service';

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.css']
})
export class TeacherComponent implements OnInit {

  constructor( private xj:externalJs,public loginService: LoginServiceService) { }

  ngOnInit(): void {
    this.xj.extraInit();
  }

}
