<div class="login-container h-100">
    <div class="login-inner h-100">
        <div class="login-two-pane">
            <div>
                <div class="login-form">
                    <div class="mb-10 login-logo">
                        <img src="./assets/images/logo.png" alt="logo" class="img-fluid">
                    </div>
                    <div>
                        <h4>Reset Password</h4>
                    </div>

                    <div class="form-group">
                        <div>
                            <div class="position-relative">
                                <input type="password" name="new_pass" class="form-control" placeholder="New Password"
                                    required #newpass>
                                <span class="show-password"><i class="icon bi bi-eye"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div>
                            <div class="position-relative">
                                <input type="password" name="cnf_pass" class="form-control" placeholder="Confirm  Password"
                                    required #cnfpass>
                                <span class="show-password"><i class="icon bi bi-eye"></i></span>
                            </div>

                        </div>
                    </div>
                    <p class="text-danger my-3" *ngIf="errMsg !=''">{{errMsg}}</p>
                    <p class="text-success my-3" *ngIf="sucsMsg !=''">
                        Password updated successfully.<br>
                        <a href="/login">Click here to login</a>
                    </p>

                    <!-- email end -->

                    <!-- password end -->

                    <div class="text-center">
                        <button type="submit" class="btn btn-primary btn-block" (click)="reResetPass(newpass.value, cnfpass.value, $event)">Submit
                            <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isLoading"></span>
                        </button>
                    </div>


                </div>
            </div>
            <div class="right-pane">
                <div class="text-center login-right">
                    <div class="mb-10 login-logo">
                        <img src="./assets/images/logo.png" alt="logo" class="img-fluid">
                    </div>
                    <div class="mt-10">
                        <h4 class="mb-4">Welcome to Clinton County R-3.</h4>
                        <p>Clinton County is a place where kids can experience a wide range of activities and
                            experiences. A place where students, staff and community work together towards a common goal
                            of success in education.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>