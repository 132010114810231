<div id="main-wrapper">
    <!--  -->
    <app-sidebar></app-sidebar>
    <!-- sidebar menu -->
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form" [ngClass]="{'loading position-relative': isLoading}">
                <div class="apploader" [ngClass]="{'show': isLoading}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Getting your data...</span>
                    </div>
                </div>
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Upload User</h5>                        
                    </div>
                </div>
                <form class="position-relative {{ (isLoading)? 'loading' : ''}}" [formGroup]="uploadBulkUser">
                    <div class="apploader {{ (isLoading)? 'show' : ''}}">
                        <div class="loader">
                            <div class="spinner-border" role="status"></div>
                            <span class="d-block mt-3">Loading...</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">                                
                            <div class="col-lg-12">                                
                                <div class="row align-items-md-center"  *ngIf='loginService.getCurrentUserRole().slug === "super-admin" || loginService.getCurrentUserRole().slug === "superident"'>
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="name" class="text-dark fw-500 label-text">School Name<sup class="text-danger">*</sup> : </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <select class="form-control" name="organisation" id="organisation" formControlName="school" [ngClass]="(m.school.touched && m.school.invalid) ? 'is-invalid' : ''" >
                                                <option value="" selected="true" disabled="true">Select School</option>
                                                <option *ngFor="let i of orgData;" [value]="i.id">{{ i.name }}</option>
                                           </select>
                                           <div *ngIf="m.school.touched && m.school.invalid" class="invalid-feedback">
                                                Please Select School
                                            </div>
                                        </div>                                       
                                    </div>
                                </div>


                                <div class="row align-items-md-center">
                                    <div class="col-md-3">
                                        <div class="form-group col-label">
                                            <label for="doc" class="text-dark fw-500 label-text mb-0">Upload Document<sup class="text-danger">*</sup> :</label>
                                            <small class="text-muted d-block">
                                                Support only formats .csv
                                              </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="custom-file">
                                                <input type="file" name="doc" class="custom-file-input" id="customFile" (change)="onChnageUser($event)">
                                                <label class="custom-file-label" for="customFile">Choose File</label>
                                            </div>
                                        </div>                                       
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <a href="assets/custom-users.csv" download="">Click to download .csv format</a>
                                        </div>
                                    </div>
                                </div>
                                

                            </div>
                        </div>
                        <ng-container *ngIf="showError">
                            <div class="alert alert-danger" role="alert">
                                {{errorMessages[0]}}
                            </div>
                        </ng-container>
                    </div>

                    <div class="card-footer">
                        <div class="form-group mb-0">
                            <button type="submit" class="btn btn-success"  (click)="uploadDocCSV($event)">Upload
                                <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isSubmit"></span>
                            </button>
                            <button type="reset" [routerLink]="['/home//bulk/view-upload-user']"  [queryParams]="schoolId ? { schoolId: schoolId} : {} " class="btn btn-secondary mr-3">Cancel</button>
                        </div>
                    </div>
                    
                   
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>
