import { Component, OnInit } from '@angular/core';

import { externalJs } from 'src/app/services/external-js.service';
import { LoginServiceService } from 'src/app/services/login-service.service';

@Component({
  selector: 'app-auth-document',
  templateUrl: './auth-document.component.html',
  styleUrls: ['./auth-document.component.css']
})
export class AuthDocumentComponent implements OnInit {

  constructor( private xj:externalJs,public loginService: LoginServiceService ) { }

  isLoading = true;

  ngOnInit(): void {
    this.xj.extraInit();
    setTimeout(() => {
      this.isLoading = false;
    }, 2000)
  }

}
