<div class="login-container h-100">
    <div class="login-inner h-100">
        <div class="login-two-pane">
            <div>
                <div class="login-form">
                    <div class="mb-10 login-logo">
                        <img src="./assets/images/logo.png" alt="logo" class="img-fluid">
                    </div>
                    <div>
                        <h4>Login into account</h4>
                        <p class="mb-10">Use your credentials to access your account.</p>
                    </div>
                    <form [formGroup]="loginForm">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                            <g transform="translate(0)">
                                                <path d="M23.983,101.792a1.3,1.3,0,0,0-1.229-1.347h0l-21.525.032a1.169,1.169,0,0,0-.869.4,1.41,1.41,0,0,0-.359.954L.017,115.1a1.408,1.408,0,0,0,.361.953,1.169,1.169,0,0,0,.868.394h0l21.525-.032A1.3,1.3,0,0,0,24,115.062Zm-2.58,0L12,108.967,2.58,101.824Zm-5.427,8.525,5.577,4.745-19.124.029,5.611-4.774a.719.719,0,0,0,.109-.946.579.579,0,0,0-.862-.12L1.245,114.4,1.23,102.44l10.422,7.9a.57.57,0,0,0,.7,0l10.4-7.934.016,11.986-6.04-5.139a.579.579,0,0,0-.862.12A.719.719,0,0,0,15.977,110.321Z" transform="translate(0 -100.445)"></path>
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                                <input required="" formControlName="username" class="form-control" name="email" type="email" placeholder="Email Address">
                            </div>
                        </div>
                        <!-- email end -->
                        <div class="form-group password-field">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24">
                                            <g transform="translate(0)">
                                                <g transform="translate(5.457 12.224)">
                                                    <path d="M207.734,276.673a2.543,2.543,0,0,0-1.749,4.389v2.3a1.749,1.749,0,1,0,3.5,0v-2.3a2.543,2.543,0,0,0-1.749-4.389Zm.9,3.5a1.212,1.212,0,0,0-.382.877v2.31a.518.518,0,1,1-1.035,0v-2.31a1.212,1.212,0,0,0-.382-.877,1.3,1.3,0,0,1-.412-.955,1.311,1.311,0,1,1,2.622,0A1.3,1.3,0,0,1,208.633,280.17Z" transform="translate(-205.191 -276.673)"></path>
                                                </g>
                                                <path d="M84.521,9.838H82.933V5.253a4.841,4.841,0,1,0-9.646,0V9.838H71.7a1.666,1.666,0,0,0-1.589,1.73v10.7A1.666,1.666,0,0,0,71.7,24H84.521a1.666,1.666,0,0,0,1.589-1.73v-10.7A1.666,1.666,0,0,0,84.521,9.838ZM74.346,5.253a3.778,3.778,0,1,1,7.528,0V9.838H74.346ZM85.051,22.27h0a.555.555,0,0,1-.53.577H71.7a.555.555,0,0,1-.53-.577v-10.7a.555.555,0,0,1,.53-.577H84.521a.555.555,0,0,1,.53.577Z" transform="translate(-70.11)"></path>
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                                <input required="" class="form-control" formControlName="password" type="password" placeholder="Password">
                                <div class="input-group-append cursor-pointer show-password">
                                    <span class="input-group-text">
                                       <i class="icon bi bi-eye"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- password end -->
                        <div class="row mt-6 mb-6">
                            <div class="col-6 d-flex align-items-center">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="cb1">
                                    <label class="custom-control-label" for="cb1">Remember me</label>
                                </div>
                            </div>
                            <div class="col-6 text-right">
                                <a [routerLink]="['/forgot-password']">Forgot password?</a>
                            </div>
                        </div>
                        <div class="text-center">
                            <p *ngIf="showErrMsg" class="text-center text-danger">{{showErrMsg}}</p>
                            <button type="submit" class="btn btn-primary btn-block" (click)="loginApi($event)"  [disabled]="!loginForm.valid">
                                Sign Me In
                                <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="isLoading"></span>
                            </button>
                        </div>

                    </form>
                </div>
            </div>
            <div class="right-pane">
                <div class="text-center login-right">
                    <div class="mb-10 login-logo">
                        <img src="./assets/images/logo.png" alt="logo" class="img-fluid">
                    </div>
                    <div class="mt-10">
                        <h4 class="mb-4">Welcome to Clinton County R-3.</h4>
                        <p>Clinton County is a place where kids can experience a wide range of activities and experiences. A place where students, staff and community work together towards a common goal of success in education.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

