import { Component, OnInit } from '@angular/core';
import { externalJs} from 'src/app/services/external-js.service';
import { BulkImportCsvService } from 'src/app/services/bulk-import-csv.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-failed-user',
  templateUrl: './failed-user.component.html',
  styleUrls: ['./failed-user.component.css']
})
export class FailedUserComponent implements OnInit {
  isLoading:boolean = true;
  userPermissionData:any;
  viewPage:boolean = false;
  constructor(private xj: externalJs, private route: ActivatedRoute, private router: Router, public loginService : LoginServiceService, private serviceBulkUpload: BulkImportCsvService, private toaster: NotificationService) {
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if (element.module === "Failed User") {
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {
      if(element?.permission === "View Failed User"){
        this.viewPage = true;        
      }
    });
    this.route.queryParamMap
      .subscribe((params: any) => {
        let paramsObj = { ...params.keys, ...params };
        if (paramsObj.params.page) {
          this.currentPage = paramsObj.params.page;
        }
        this.getFailedList(this.currentPage);
      });
   }
  faileduser:any = [];
  currentPage: number = 1;
  lastPage: number = 1;
  totalPages: any = [];
  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  tableDataPerPage: number = 20;
  message: string = '';
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
   }
    this.xj.extraInit();    
  }

  getFailedList(page){
    this.serviceBulkUpload.getFailedUser(page).subscribe((res:any) =>{
      if(res.status){
        this.faileduser= res.data.data;
        this.currentPage = res['data'].current_page;
        this.lastPage = res['data'].last_page;
        this.mapPages(res['data'].last_page);
        this.tableDataFrom = res['data']['from'];
        this.tableDataTo = res['data']['to'];
        this.tableDataTotal = res['data']['total'];
        this.tableDataPerPage = res['data']['per_page'];
        this.message = '';
        this.isLoading = false;
      } else if(res.status_code === 404){
        this.faileduser = [];
        this.message = "No Failed User Found";
        this.isLoading = false;
      } else{      
        this.faileduser = [];
        this.message = "Something went wrong. Please try later";
        this.isLoading = false;
      }
    }, (err:any) => {        
        this.faileduser = [];
        this.message = "Something went wrong. Please try later";
        this.isLoading = false;
    })
  }

  mapPages(lastPage = 1) {
    let pageListArr = [];
    this.totalPages = [];
    if(lastPage <=1) return;
    if(lastPage <= 5){
      for(var i =1; i<= lastPage; i++){
          pageListArr.push(i);
      }
    }
    if(lastPage > 5){
      if(this.currentPage <=4){        
          for(var i =1; i<= 5; i++){
            pageListArr.push(i);
          }      
          pageListArr.push('...');
          pageListArr.push(lastPage);
      } else if(lastPage - this.currentPage <=3){
        pageListArr.push(1);
        pageListArr.push('...');
        for(var i =lastPage - 4; i<= lastPage; i++){
          pageListArr.push(i);
        } 
      }
      else {
        pageListArr.push(1);
        pageListArr.push('...');  

        for(var i = this.currentPage - 2; i<= this.currentPage + 2; i++){
          pageListArr.push(i);
        }

        pageListArr.push('...');
        pageListArr.push(lastPage);
      } 
    } 
    this.totalPages = pageListArr;
  }

  changeCurrentPage(page: any) {
    if(page != '...'){
    this.currentPage = page;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: page },
        queryParamsHandling: 'merge'
      });
    }
  }

  

  exportUser(){
    this.serviceBulkUpload.exportFailedUser().subscribe((res:any)=>{
      if(res.status){
        let requiredData = [];
        res.data.forEach((usr:any) => {
          requiredData.push({sourcedId:usr.sourcedId, fname:usr.givenName, lname:usr.familyName, mname:usr.middleName, role:usr.roleName, email:usr.email, grades:(usr?.grades)? usr?.grades:''})
        });
       
        let csv =  this.ConvertToCSV(requiredData);
        var uri = 'data:text/csv;charset=utf-8,' + escape(csv);
        
        var downloadLink = document.createElement("a");
        downloadLink.href = uri;
        downloadLink.download = "failed-user.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this.toaster.showSuccess('Failed User data export successfully');
      } else{
        this.toaster.showError('Unable to export data','');
      }
    },(err:any)=>{
      this.toaster.showError('Something went wrong. Please try later','');
    })
  }

  ConvertToCSV(objArray: any) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;

    var str = '';

    var pre = 'sourcedId,givenName,familyName,middleName,role,email,grades' + '\r\n';
    str += pre;

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','

        line += array[i][index];
      }

      str += line + '\r\n';
    }

    return str;
  }


}
