import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentService } from 'src/app/services/student.service';
import { UserServiceService } from 'src/app/services/user-service.service';
import { OrganisationService } from 'src/app/services/organisation.service';
@Component({
  selector: 'app-parent-list',
  templateUrl: './parent-list.component.html',
  styleUrls: ['./parent-list.component.css']
})
export class ParentListComponent implements OnInit {

  userPermissionData: any;
  editButton: boolean = false;
  deleteButton: boolean = false;
  viewPage: boolean = false;

  constructor(private xj:externalJs, private serviceSchool: OrganisationService,  public loginService: LoginServiceService, private route:ActivatedRoute,  private router:Router, private toastr: NotificationService, private stdSer:StudentService, private userSer:UserServiceService) { 
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if(element.module === "Parents"){
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {      
      if(element?.permission === 'Delete Parent'){
       this.deleteButton = true;
      }else if(element?.permission === 'Edit Parent'){
        this.editButton = true;
      }else if(element?.permission === 'Add Parent'){
          this.currentAddLnk = '/home/parent/add';  
      } else if(element?.permission === "View Parent"){
          this.viewPage = true;        
      }

     

    });

  }

  isLoading = true;
  currentAddLnk = ""; 
  currAddLnkTxt: string = "Add Parent";
  message: string = '';
  parentLists: any = [];
  deleteId: any = ''; 

  orgData: any = [];

  // table data
  currentSchool: any = parseInt(localStorage.getItem('orgIds')) || '';
  currentName: any = '';
  currentEmail:any = '';
  currentPage: number = 1;
  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  tableDataPerPage: number = 20;
  totalPages: any = [];
  lastPage: any = 1;

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
   }
    this.route.queryParamMap
      .subscribe((params:any) => {
        let paramsObj = { ...params.keys, ...params };
        
        if(paramsObj.params.page){
          this.currentPage = paramsObj.params.page;
        }
        if(paramsObj.params.school){
          this.currentSchool = paramsObj.params.school;
        }else {
          this.currentSchool = parseInt(localStorage.getItem('orgIds')) || '';
        }
        if(paramsObj.params.name){
          this.currentName = paramsObj.params.name;
        }else {
          this.currentName = '';
        }
        if(paramsObj.params.email){
          this.currentEmail = paramsObj.params.email;
        }else {
          this.currentEmail = '';
        }
        this.initComp();
      });
     this.getSchool(); 
  }

  // getgetSchool
  
  getSchool(){
    this.serviceSchool.allSchool().subscribe((res:any) => {    
      if(res.status){
        this.orgData = res.data
      } else{
        this.orgData = []
      }
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
    })
  }
  
  initComp(){    
    this.xj.extraInit();   
    this.getParentList(this.currentPage, this.currentSchool, this.currentName, this.currentEmail);   
  }

  getParentList(page = this.currentPage, schoolId:any= '', name:any='', email:any= ''){
    this.isLoading = true;
    this.stdSer.getParentList(page, schoolId, name, email).subscribe((res:any) => {      
      if(res.status){
        this.parentLists = res.data.data;        
        this.currentPage = res.data.current_page;
        this.tableDataFrom = res.data.from;
        this.tableDataTo = res.data.to;
        this.tableDataTotal = res.data.total;
        this.tableDataPerPage = res.data.per_page;
        this.lastPage = res.data.last_page;
        this.mapPages(res.data.last_page);
        this.message = '';
        this.isLoading = false;
      } else if(res.status_code === 404){
        this.message = 'No Parent Data Found';        
        this.parentLists = [];
        this.isLoading = false;
      }  else {
        this.message= "Something went wrong. Please try later";
        this.parentLists = [];
        this.isLoading = false;
      }
    }, (err:any)=>{
      this.message= "Something went wrong. Please try later";
      this.isLoading=false;
    })

  }

  // map pages
  mapPages(lastPage = 1) {
    let pageListArr = [];
    this.totalPages = [];
    if(lastPage <=1) return;
    if(lastPage <= 5){
      for(var i =1; i<= lastPage; i++){
          pageListArr.push(i);
      }
    }
    if(lastPage > 5){
      if(this.currentPage <=4){        
          for(var i =1; i<= 5; i++){
            pageListArr.push(i);
          }      
          pageListArr.push('...');
          pageListArr.push(lastPage);
      } else if(lastPage - this.currentPage <=3){
        pageListArr.push(1);
        pageListArr.push('...');
        for(var i =lastPage - 4; i<= lastPage; i++){
          pageListArr.push(i);
        } 
      }
      else {
        pageListArr.push(1);
        pageListArr.push('...');  

        for(var i = this.currentPage - 2; i<= this.currentPage + 2; i++){
          pageListArr.push(i);
        }

        pageListArr.push('...');
        pageListArr.push(lastPage);
      } 
    } 
    this.totalPages = pageListArr;
    console.log(this.totalPages);
  }

  changeCurrentPage(page) {
    
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: page },
        queryParamsHandling: 'merge'
      });
  }

  filterDocuments() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: 1, school: this.currentSchool, name: this.currentName, email: this.currentEmail },
        queryParamsHandling: 'merge'
      });
  }

  clearFilter() {
    this.currentSchool = parseInt(localStorage.getItem('orgIds')) || '';
    this.currentName = '';
    this.currentEmail = '';
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, school: this.currentSchool, name:this.currentName, email: this.currentEmail },
        queryParamsHandling: 'merge'
      });
  }

  deletePopup(id:any){
    this.xj.openModalPopup("#deletePopup");
    this.deleteId = id;
    // console.log(this.deleteGrade)
  }
  
  deleteGrade(){
    this.stdSer.deleteParent(this.deleteId).then((res:any) =>{
      if(res.status){
        this.initComp();       
        this.toastr.showSuccess('Parent deleted successfully')
        this.xj.closeModalPopup("#deletePopup");
      } else{
        this.toastr.showError('Unable to delete parent', '');
        this.initComp();
        this.xj.closeModalPopup("#deletePopup"); 
      }
    }).catch((err:any)=>{
        this.toastr.showError('Something went wrong. Please try later', '');
        this.initComp();
        this.xj.closeModalPopup("#deletePopup");   
    })

  }

  exportParent(){
    this.userSer.exportCSV(14).then((res:any)=>{
      if(res.status){
        let requiredData = [];
        res.data.forEach((usr:any) => {
          requiredData.push({fname:usr.givenName,mname:usr.middleName, lname:usr.familyName, email:usr.email, pass:usr.password_view, school:usr.organisaitonName})
        });       
        let csv =  this.ConvertToCSV(requiredData);
        var uri = 'data:text/csv;charset=utf-8,' + escape(csv);        
        var downloadLink = document.createElement("a");
        downloadLink.href = uri;
        downloadLink.download = "parent.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this.toastr.showSuccess('Parent data export successfully');
      } else{
        this.toastr.showError('Unable to export data','');
      }
    }).catch(err=>{
        this.toastr.showError('Something went wrong. Please try later','');
    })
}

// JSON to CSV Converter
ConvertToCSV(objArray:any) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  
  var str = '';
  
  var pre = 'First Name, Middle Name, Last Name, Email, Password, School' + '\r\n';
  str += pre;
  
  for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
          if (line != '') line += ','
  
          line += array[i][index];
      }
  
      str += line + '\r\n';
  }
  
  return str;
  }


  openPopup(e, items){
    var html = '';
    html+="<ul class='pl-4'>";
    for(var item of items ){
       html += `<li>${item?.givenName}`;
       if(item?.middleName){
        html += ' ' + item?.middleName;
       }
       if(item?.familyName){
        html +=  ' ' + item?.familyName;
       }
       html += '</li>';
       
    }
    html+="</ul>";
    this.xj.openPopup(e, html);
    
  }

}
