   <!-- main-wrapper -->
   <div id="main-wrapper">
    <!-- sidebar menu  -->
  <app-sidebar [addLink]="currentAddLnk" [addLinkTxt]="currAddLnkTxt" (exportEvent)="exportTeacher()" [exportText]="'Export Teacher Credentials'"></app-sidebar>
    <!-- sidebar menu -->
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-table {{ (isLoading)? 'loading' : ''}}">
                <div class="apploader {{ (isLoading)? 'show' : ''}}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Loading...</span>
                    </div>
                </div>
                <div class="table-head">
                    <form class="row align-items-md-end" method="post">
                        <div class="col align-self-md-center">
                            <div class="form-inline span-5">
                                <div class="form-group mb-1" *ngIf="loginService.getCurrentUserRole().slug != 'principal' && loginService.getCurrentUserRole().slug != 'student' && loginService.getCurrentUserRole().slug != 'teacher'">
                                    <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">School : </label>
                                    <select class="form-input" name="currentSchool" [(ngModel)]="currentSchool" (change)="filterSchool($event.target.value)">
                                        <option value="" selected>All</option>
                                        <option value="{{ school.id }}" *ngFor="let school of orgData">{{ school.name }}</option>
                                   </select>                                                
                                </div>
                                <div class="form-group mb-1" >
                                    <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">Course : </label>
                                    <select class="form-input" name="currentCourse" [(ngModel)]="currentCourse">
                                        <option value="" selected>All</option>
                                        <option value="{{ course.id }}" *ngFor="let course of courseData">{{ course.title }}</option>
                                   </select>                                                
                                </div>

                                <div class="form-group mb-1" >
                                    <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">Name : </label>
                                    <input type="text"  class="form-input" name="name" [(ngModel)]="currentName">                                            
                                </div>
                                <div class="form-group mb-1" >
                                    <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">Email : </label>
                                    <input type="text"  class="form-input" name="email" [(ngModel)]="currentEmail">                                            
                                </div>
                                
                               
                            </div>
                        </div>
                        <div class="col-auto text-md-right">                                
                            <div class="addMore d-md-inline-block">
                                <a class="btn-add" (click)="filterDocuments()">Filter</a>
                                <button class="btn-reset" type="reset" (click)="clearFilter()">
                                    Clear
                                </button>
                            </div>
                            
                        </div>
                    </form>
                </div>
                
                <div class="table-responsive">
                    <p *ngIf="message && !isLoading" class="text-center not-found">
                        <i class="bi bi-person-circle"></i>
                        {{ message }}
                    </p>
                    <table class="table table-sm table-bordered sortable datatable nowraptable" *ngIf="!message && !isLoading">
                        <thead>
                            <tr class="table-header">
                                <th class="text-dark">S.No.</th>
                                <th class="text-dark">Name</th>
                                <th class="text-dark" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>School</th>
                                <th class="text-dark">Email</th>
                                <!-- <th class="text-dark phone-col">Phone</th> -->
                                <th class="text-dark">Courses</th>
                                <!-- <th class="text-dark">Classes</th> -->
                                <th class="text-dark">Status</th>
                                <th class="text-dark no-sort"  *ngIf="editButton || deleteButton">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor ="let data of teacherList;index as i">
                                <td>{{(i + 1) + (currentPage - 1) * tableDataPerPage}}</td>
                                <td>{{data.givenName }} {{ data.middleName}} {{ data.familyName}}</td>
                                <td *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>{{data.organisaitonName}}</td>
                                <td>{{data.email}}</td>
                                <!-- <td>{{data.phone}}</td> -->
                                <td >
                                    <ng-container *ngIf="data.course?.length <= 2">
                                        <ul class="single">
                                            <li *ngIf="data.course[0]?.title">{{data.course[0]?.title}}</li>
                                            <li *ngIf="data.course[1]?.title">{{data.course[1]?.title}}</li>
                                        </ul>  
                                    </ng-container>
                                    <ng-container *ngIf="data.course?.length > 2">
                                        <ul class="single">
                                            <li *ngIf="data.course[0]?.title">{{data.course[0]?.title}}</li>
                                            <li *ngIf="data.course[1]?.title">{{data.course[1]?.title}}</li>                                            
                                            <!-- <li *ngFor ="let data1 of data.course">{{data1.title}}</li> -->                                            
                                        </ul>  
                                        <a tabindex="1" data-trigger="focus" class="fw-500 small text-primary" (click)="openPopup($event.target, data.course)" title="Courses">View More</a>
                                    </ng-container>
                                   
                                </td>
                                <!-- <td>{{data.phone}}</td> -->
                                <!-- <td ><ul *ngFor ="let data1 of data.course"><li>{{data1.title}}</li></ul></td> -->
                                <!-- <td ><ul *ngFor ="let data1 of data.class"><li>{{data1.className}}</li></ul></td> -->
                                <td *ngIf="data.enabledUser == '1'"><span class="badge light badge-success" >Active</span></td>
                                <td *ngIf="data.enabledUser == '0'"><span class="badge light badge-danger" >In-Active</span></td>
                                <td  *ngIf="editButton || deleteButton">                                    
                                    <a  (click)="goToEditPage(data.id)" title="Edit" class="btn btn-sm btn-success light app-tooltip">
                                        <i class="bi bi-pencil-fill"></i>
                                    </a>
                                    <a href="#deletePopup" (click)="getTeacherId(data.id)" data-toggle="modal" title="Delete" class="btn btn-sm btn-danger light app-tooltip">
                                        <i class="bi bi-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    
                    </table>
                </div>
                <div class="table-footer" *ngIf="!message && !isLoading">
                    <div class="row">
                        <div class="col-md-auto align-self-center ml-auto">
                            <div class="justify-content-md-end">
                               
                            </div>
                        </div>
                        <div class="col-md-auto col-12 align-self-center ml-auto">
                            <div class="justify-content-md-end">
                                <div class="text-md-right">
                                    <div class="dropdown-select">
                                        <!-- <div class="dataTables_length" id="sortable_length">
                                            <label>
                                                <span class="mb-0">Rows per page:</span>
                                                <select name="sortable_length">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </label>
                                        </div> -->
                                        <div>
                                            <div class="dataTables_info" id="sortable_info" role="status" aria-live="polite">{{ tableDataFrom }} - {{ tableDataTo }} of
                                                {{tableDataTotal}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-auto">                            
                            <div class="pagination dataTables_paginate" id="sortable_paginate" *ngIf="totalPages.length > 1">
                                <a class="paginate_button previous" [ngClass]="1 == currentPage ? 'disabled':''"  (click)="changeCurrentPage(currentPage-1)">
                                    <i class="bi bi-chevron-left"></i>
                                </a>
                                <span>
                                    <ng-container *ngFor="let page of totalPages; let i = index;">
                                        <a *ngIf="page !== '...'" (click)="changeCurrentPage(page)" [ngClass]="page == currentPage ? 'current paginate_button':'paginate_button'"   >{{ page }}</a>
                                        <span *ngIf="page === '...'"   class="paginate_button"   >{{ page }}</span>
                                    </ng-container>                                    
                                </span>
                                <a class="paginate_button next" [ngClass]="totalPages.at(-1) == currentPage ? 'disabled':''"  (click)="changeCurrentPage(currentPage+1)">
                                    <i class="bi bi-chevron-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- content-body -->
</div>
<!-- main-wrapper end -->
<!-- delete popup start -->
<div id="deletePopup" class="modal fade" aria-hidden="true" style="display: none;" #delete>
    <div class="modal-dialog modal-confirm modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header flex-column">
                <div class="icon-box">
                    <i class="bi bi-x"></i>
                </div>						
                <h4 class="modal-title w-100">Are you sure?</h4>	
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="bi bi-x"></i></button>
            </div>
            <div class="modal-body">
                <p>Do you really want to delete these record?</p>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" (click)="deleteTeacher()">Delete</button>
            </div>
        </div>
    </div>
</div>
<!-- delete popup end -->