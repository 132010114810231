<div id="main-wrapper">
    <app-sidebar></app-sidebar>
        <!-- content-body -->
        <div class="content-body">
            <div class="container-fluid">
                <div class="card-table {{ (isLoading)? 'loading' : ''}}">
                    <div class="apploader {{ (isLoading)? 'show' : ''}}">
                        <div class="loader">
                            <div class="spinner-border" role="status"></div>
                            <span class="d-block mt-3">Loading...</span>
                        </div>
                    </div>
                    <div class="table-head">
                        <div class="row">
                            <div class="col align-self-md-center">
                                <div class="form-inline">
                                    <div class="form-group">
                                    <label for="" class="mr-2 fw-500 text-muted">Search : </label>
                                    <input type="search" id="table_search" class="form-input">                                                  
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto text-md-right">                                
                                <div class="addMore d-md-inline-block">
                                    <a class="btn-add" href="add-document.html">
                                        <i class="hi hi-create-lg mr-1"></i>
                                        Add More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-sm table-bordered sortable datatable">
                            <thead>
                            <tr class="table-header">
                                    <th class="text-dark">S.No.</th>
                                    <th class="text-dark no-sort">Document</th>
                                    <th class="text-dark">Document Type</th>
                                    <th class="text-dark">Student Name</th>
                                    <th class="text-dark" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident"'>Organization</th>
                                    <th class="text-dark">Grade</th>
                                    <th class="text-dark">Session(Date)</th>                                
                                    <th class="text-dark">Status</th>
                                    <th class="text-dark">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01</td>
                                    <td><img src="https://i.pinimg.com/736x/65/54/78/65547833c4b785fdc4a9e0b8e01a1451.jpg" height="60px" width="60px"></td>
                                    <td>University Degree</td>
                                    <td><span class="badge light badge-success">Active</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>02</td>
                                    <td><img src="https://i.pinimg.com/736x/65/54/78/65547833c4b785fdc4a9e0b8e01a1451.jpg" height="60px" width="60px"></td>
                                    <td>University Degree</td>
                                    <td><span class="badge light badge-success">Active</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>03</td>
                                    <td><img src="https://i.pinimg.com/736x/65/54/78/65547833c4b785fdc4a9e0b8e01a1451.jpg" height="60px" width="60px"></td>
                                    <td>University Degree</td>
                                    <td><span class="badge light badge-success">Active</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>04</td>
                                    <td><img src="https://i.pinimg.com/736x/65/54/78/65547833c4b785fdc4a9e0b8e01a1451.jpg" height="60px" width="60px"></td>
                                    <td>University Degree</td>
                                    <td><span class="badge light badge-danger">UnActive</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>05</td>
                                    <td><img src="https://i.pinimg.com/736x/65/54/78/65547833c4b785fdc4a9e0b8e01a1451.jpg" height="60px" width="60px"></td>
                                    <td>University Degree</td>
                                    <td><span class="badge light badge-success">Active</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>06</td>
                                    <td><img src="https://i.pinimg.com/736x/65/54/78/65547833c4b785fdc4a9e0b8e01a1451.jpg" height="60px" width="60px"></td>
                                    <td>University Degree</td>
                                    <td><span class="badge light badge-warning">Pending</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>07</td>
                                    <td><img src="https://i.pinimg.com/736x/65/54/78/65547833c4b785fdc4a9e0b8e01a1451.jpg" height="60px" width="60px"></td>
                                    <td>University Degree</td>
                                    <td><span class="badge light badge-success">Active</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>08</td>
                                    <td><img src="https://i.pinimg.com/736x/65/54/78/65547833c4b785fdc4a9e0b8e01a1451.jpg" height="60px" width="60px"></td>
                                    <td>University Degree</td>
                                    <td><span class="badge light badge-success">Active</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>09</td>
                                    <td><img src="https://i.pinimg.com/736x/65/54/78/65547833c4b785fdc4a9e0b8e01a1451.jpg" height="60px" width="60px"></td>
                                    <td>University Degree</td>
                                    <td><span class="badge light badge-success">Active</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td><img src="https://i.pinimg.com/736x/65/54/78/65547833c4b785fdc4a9e0b8e01a1451.jpg" height="60px" width="60px"></td>
                                    <td>University Degree</td>
                                    <td><span class="badge light badge-success">Active</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>                                
                            </tbody>
                        
                        </table>
                    </div>
                    <div class="table-footer">
                        <div class="row">
                            <div class="col-auto align-self-center ml-auto">
                                <div class="justify-content-end">
                                    <div class="text-md-right">
                                        <div class="dropdown-select">
                                            <div class="dataTables_length" id="sortable_length">
                                                <label>
                                                    <span class="mb-0">Rows per page:</span>
                                                    <select name="sortable_length">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </label>
                                            </div>
                                            <div>
                                                <div class="dataTables_info" id="sortable_info" role="status" aria-live="polite">1 - 10 of
                                                    14</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <div class="pagination dataTables_paginate" id="sortable_paginate">
                                    <a class="paginate_button previous disabled">
                                        <i class="bi bi-chevron-left"></i>
                                    </a>
                                    <span>
                                        <a class="paginate_button current">1</a>
                                        <a class="paginate_button">2</a>
                                    </span>
                                    <a class="paginate_button next">
                                        <i class="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- content-body -->
    </div>