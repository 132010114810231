import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { LoginServiceService } from './login-service.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http:HttpClient, private loginSrv:LoginServiceService) { }

  getHeader = () =>{
    return {
      headers : new HttpHeaders({
        "Authorization" :'Bearer ' + this.loginSrv.getUserToken()
      })
    }
  }

  getData() {
    return this.http.get(env.apiurl + 'dashboard', this.getHeader()).toPromise()
  }

}
