import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { MasterService } from 'src/app/services/master.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from 'src/app/services/organisation.service';

@Component({
  selector: 'app-document-type-list',
  templateUrl: './document-type-list.component.html',
  styleUrls: ['./document-type-list.component.css']
})
export class DocumentTypeListComponent implements OnInit {
  userPermissionData: any;
  editButton: boolean = false;
  deleteButton: boolean = false;
  viewPage:boolean = false;
  isLoading = true;
  currentAddLnk:any; 
  currAddLnkTxt: string = "Add Document Type";
  constructor(private xj:externalJs, public loginService: LoginServiceService, private route:ActivatedRoute,  private router:Router, private masterService: MasterService, private toastr: NotificationService, private serviceOrganisation: OrganisationService) { 
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;
    a.forEach(element => {
      if (element.module === "DocType Management") {
        this.userPermissionData = element?.permission;
      }
    });
    this.userPermissionData?.forEach(element => {
      if (element?.permission === 'Delete DocType') {
        this.deleteButton = true;
      } else if (element?.permission === 'Update DocType') {
        this.editButton = true;
      } else if (element?.permission === 'Add DocType') {
        this.currentAddLnk = '/home/master/add-doc-type';
      } else if(element?.permission === 'View DocType'){
        this.viewPage = true;
      }
    });
    this.route.queryParamMap.subscribe((params:any) => {
      let paramsObj = { ...params.keys, ...params };      
      if(paramsObj.params.page){
        this.currentPage = paramsObj.params.page;
      }      
      if(paramsObj.params.school){
        this.currentSchool = paramsObj.params.school;
      }else {
        this.currentSchool =parseInt(localStorage.getItem('orgIds')) || '';
      }
      if(paramsObj.params.name){
        this.currentName = paramsObj.params.name;
      }else {
        this.currentName = '';
      }
      this.initComp();
    });
    
  }
 
  
  docTypeItems: any = [];
  deleteId: any = ''; 
  orgData: any = [];

  // table data
  currentSchool: any = parseInt(localStorage.getItem('orgIds')) || '';
  currentName: any = '';
  currentPage: number = 1;
  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  tableDataPerPage: number = 20;
  totalPages: any = [];
  message:any='';

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    
      this.getSchool()
      this.xj.extraInit();
  }
  getSchool(){
    this.serviceOrganisation.allSchool().subscribe((res:any) => {      
      if(res.status){
        this.orgData = res.data
      } else{
        this.orgData = []
      }
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
    })
  }
  initComp(){       
    this.getDocTypeList(this.currentPage, this.currentSchool, this.currentName);   
  }

  getDocTypeList(page = this.currentPage, schoolId:any= '', name:any=''){
    this.isLoading = true;
    this.masterService.getDocTypeList(page, schoolId, name).subscribe((res:any) => {      
      if(res.status){
        this.docTypeItems = res.data.data;        
        this.currentPage = res.data.current_page;
        this.tableDataFrom = res.data.from;
        this.tableDataTo = res.data.to;
        this.tableDataTotal = res.data.total;
        this.tableDataPerPage = res.data.per_page;
        this.mapPages(res.data.last_page);
        this.message='';
        this.isLoading = false;
      } else if(res.status_code === 404){
        this.message = 'No Document Type Data Found';        
        this.docTypeItems = [];
        this.isLoading = false;
      } else{
        this.docTypeItems = []; 
        this.message='Something went wrong. Please try later';
        this.isLoading = false;
      }
    }, (err:any)=>{
      this.message= "Something went wrong. Please try later";
      this.isLoading = false;
    })
  }

    // map pages
    mapPages(lastPage = 1) {
      this.totalPages = [];
    let pageListArr = [];
    if(lastPage <=1) return;
    if(lastPage <= 5){
      for(var i =1; i<= lastPage; i++){
          pageListArr.push(i);
      }
    }
    if(lastPage > 5){
      if(this.currentPage <=4){        
          for(var i =1; i<= 5; i++){
            pageListArr.push(i);
          }      
          pageListArr.push('...');
          pageListArr.push(lastPage);
      } else if(lastPage - this.currentPage <=3){
        pageListArr.push(1);
        pageListArr.push('...');
        for(var i =lastPage - 4; i<= lastPage; i++){
          pageListArr.push(i);
        } 
      }
      else {
        pageListArr.push(1);
        pageListArr.push('...');  

        for(var i = this.currentPage - 2; i<= this.currentPage + 2; i++){
          pageListArr.push(i);
        }

        pageListArr.push('...');
        pageListArr.push(lastPage);
      } 
    } 
      this.totalPages = pageListArr;
      
    }

  changeCurrentPage(page) {
    this.currentPage = page;
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: page },
        queryParamsHandling: 'merge'
      });
  }

  filterDocuments() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: 1, school: this.currentSchool, name: this.currentName },
        queryParamsHandling: 'merge'
      });
  }

  clearFilter() {
    this.currentSchool = parseInt(localStorage.getItem('orgIds')) || '';
    this.currentName = '';
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, school: '', name:'' },
        queryParamsHandling: 'merge'
      });
  }

  deletePopup(id:any){
    this.xj.openModalPopup("#deletePopup");
    this.deleteId = id;
  }
  
  deleteGrade(){
    this.masterService.deleteDocType(this.deleteId).then((res:any) =>{
      if(res.status){        
        this.toastr.showSuccess('Document Type deleted successfully');
        this.xj.closeModalPopup("#deletePopup");
        this.initComp();
      }  else{
        this.toastr.showError('Unable to delete document type', '');
        this.xj.closeModalPopup("#deletePopup");
        this.initComp();
      }  
    }).catch((err:any)=>{
        this.toastr.showError('Something went wrong. Please try later', '');
        this.xj.closeModalPopup("#deletePopup");
        this.initComp();
    })

  }

}
