   
     <!-- main-wrapper -->
     <div id="main-wrapper">
        <app-sidebar></app-sidebar>
        <!-- content-body -->
        <div class="content-body">
            <div class="container-fluid">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" *ngIf="isParents">
                    <li class="nav-item">
                      <a class="nav-link active" data-toggle="pill" href="#my-students" role="tab"  aria-selected="true">My Students</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="pill" href="#my-children" role="tab"  aria-selected="false">My Children</a>
                    </li>
                    
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="my-students" role="tabpanel" aria-labelledby="my-students-tab">
                        <div class="row">
                            <!-- <div class="col-lg-4 col-md-6 col-12" *ngIf='loginService.getCurrentUserRole().slug == "super-admin"'>
                                <div class="card-bx stacked card">
                                    <img src="assets/images/card1.jpg" alt="">
                                    <div class="card-info">
                                        <p class="mb-1 text-white fs-14">Total Districts</p>
                                        <div class="d-flex justify-content-between">
                                            <h2 class="num-text text-white mb-5 font-w600">673,412</h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="white" class="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                                                <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4 text-white">
                                                <a href="" class="text-white"><p class="fs-12 mb-1 op6">View More</p></a>
                                            </div>                              
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-lg-4 col-md-6 col-12" *ngIf='loginService.getCurrentUserRole().slug == "super-admin"'>
                                <div class="card-bx stacked card">
                                    <img src="assets/images/card2.jpg" alt="">
                                    <div class="card-info">
                                        <p class="fs-14 mb-1 text-white">Total Superintendents</p>
                                        <div class="d-flex justify-content-between">
                                            <h2 class="num-text text-white mb-5 font-w600">673,412</h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="white" class="bi bi-person-workspace" viewBox="0 0 16 16">
                                                <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                                                <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4 text-white">
                                                <a href="" class="text-white"><p class="fs-12 mb-1 op6">View More</p></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-4 col-md-6 col-12" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident"'>
                                <div class="card-bx stacked card">
                                    <img src="assets/images/card3.jpg" alt="">
                                    <div class="card-info">
                                        <p class="mb-1 text-white fs-14">Total Schools</p>
                                        <div class="d-flex justify-content-between">
                                            <h2 class="num-text text-white mb-5 font-w600"><span *ngIf="dashboardData">{{ (dashboardData?.totalSchools) ? dashboardData?.totalSchools : 0 }}</span></h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="white" class="bi bi-building" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                                                <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4 text-white">
                                                <a [routerLink]="['/home/school']" class="text-white"><p class="fs-12 mb-1 op6">View More</p></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" || loginService.getCurrentUserRole().slug == "principal" || loginService.getCurrentUserRole().slug == "teacher"'>
                                <div class="card-bx stacked card">
                                    <img src="assets/images/card4.jpg" alt="">
                                    <div class="card-info">
                                        <p class="mb-1 text-white fs-14">Total Students</p>
                                        <div class="d-flex justify-content-between">
                                            <h2 class="num-text text-white mb-5 font-w600"><span *ngIf="dashboardData">{{ (dashboardData?.totalStudent) ? dashboardData?.totalStudent : 0}}</span></h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="white" class="bi bi-people" viewBox="0 0 16 16">
                                                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"/>
                                              </svg>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4 text-white">
                                                <a [routerLink]="['/home/student']" routerLinkActive="router-link-active"  class="text-white"><p class="fs-12 mb-1 op6">View More</p></a>
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" || loginService.getCurrentUserRole().slug == "principal"'>
                                <div class="card-bx stacked card">
                                    <img src="assets/images/card5.jpg" alt="">
                                    <div class="card-info">
                                        <p class="mb-1 text-white fs-14">Total Teachers</p>
                                        <div class="d-flex justify-content-between">
                                            <h2 class="num-text text-white mb-5 font-w600"><span *ngIf="dashboardData">{{ (dashboardData?.totalTeacher) ? dashboardData?.totalTeacher: 0 }}</span></h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="white" class="bi bi-person-rolodex" viewBox="0 0 16 16">
                                                <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                                                <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4 text-white">
                                                <a [routerLink]="['/home/teacher']" routerLinkActive="router-link-active"  class="text-white"><p class="fs-12 mb-1 op6">View More</p></a>
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <!-- <div class="col-lg-4 col-md-6 col-12" *ngIf='loginService.getCurrentUserRole().slug == "super-admin"'>
                                <div class="card-bx stacked card">
                                    <img src="assets/images/card6.jpg" alt="">
                                    <div class="card-info">
                                        <p class="mb-1 text-white fs-14">Total Documents</p>
                                        <div class="d-flex justify-content-between">
                                            <h2 class="num-text text-white mb-5 font-w600"><span *ngIf="dashboardData">{{ (dashboardData?.totalDocument) ? dashboardData?.totalDocument : 0 }}</span></h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="white" class="bi bi-file-earmark-post" viewBox="0 0 16 16">
                                                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                                <path d="M4 6.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7zm0-3a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5z"/>
                                              </svg>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4 text-white" *ngIf='loginService.getCurrentUserRole().slug == "student"'>
                                                <a [routerLink]="['/home/document']" routerLinkActive="router-link-active"  class="text-white"><p class="fs-12 mb-1 op6">View More</p></a>
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                            </div>    -->
                            <div class="col-lg-4 col-md-6 col-12" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "parent" || loginService.getCurrentUserRole().slug == "student"'>
                                <div class="card-bx stacked card">
                                    <img src="assets/images/card7.jpg" alt="">
                                    <div class="card-info">
                                        <p class="mb-1 text-white fs-14">Verified Documents</p>
                                        <div class="d-flex justify-content-between">
                                            
                                            <h2 class="num-text text-white mb-5 font-w600"><span *ngIf="dashboardData">{{ (dashboardData?.verifiedCer) ? dashboardData?.verifiedCer : 0 }}</span></h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="white" class="bi bi-file-check" viewBox="0 0 16 16">
                                                <path d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4 text-white">
                                                <a [routerLink]="['/home/document']" [queryParams]="{approve: 'yes'}" routerLinkActive="router-link-active"  class="text-white"><p class="fs-12 mb-1 op6">View More</p></a>
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div class="col-lg-4 col-md-6 col-12" *ngIf='loginService.getCurrentUserRole().slug == "super-admin"  || loginService.getCurrentUserRole().slug == "parent" || loginService.getCurrentUserRole().slug == "student" || loginService.getCurrentUserRole().slug == "teacher"'>
                                <div class="card-bx stacked card">
                                    <img src="assets/images/card8.jpg" alt="">
                                    <div class="card-info">
                                        <p class="mb-1 text-white fs-14">Unverified Documents</p>
                                        <div class="d-flex justify-content-between">
                                            <h2 class="num-text text-white mb-5 font-w600"><span *ngIf="dashboardData">{{ (dashboardData?.unverifiedCer) ? dashboardData?.unverifiedCer : 0 }}</span></h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="white" class="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                                            <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4 text-white">
                                                <a [routerLink]="['/home/document']" [queryParams]="{approve: 'no'}" routerLinkActive="router-link-active"  class="text-white"><p class="fs-12 mb-1 op6">View More</p></a>
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                            </div> 
                          
                            <div class="col-lg-4 col-md-6 col-12" *ngIf='loginService.getCurrentUserRole().slug == "superident" || loginService.getCurrentUserRole().slug == "principal" || loginService.getCurrentUserRole().slug == "secreatry" ' >
                                <div class="card-bx stacked card">
                                    <img src="assets/images/card4.jpg" alt="">
                                    <div class="card-info">
                                        <p class="mb-1 text-white fs-14">Assigned Document</p>
                                        <div class="d-flex justify-content-between">
                                            <h2 class="num-text text-white mb-5 font-w600"><span *ngIf="dashboardData">{{ (dashboardData?.assignedCer) ? dashboardData?.assignedCer : 0 }}</span></h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="white" class="bi bi-cloud-check-fill" viewBox="0 0 16 16">
                                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 4.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
                                              </svg>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4 text-white">
                                                <a [routerLink]="['/home/document']" class="text-white"><p class="fs-12 mb-1 op6">View More</p></a>
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                            </div>             
                        </div>
                    </div>
                    <div class="tab-pane fade" id="my-children" role="tabpanel" aria-labelledby="my-children-tab" *ngIf="isParents">
                        <div class="row">
                            <!-- <div class="col-lg-4 col-md-6 col-12">
                                <div class="card-bx stacked card">
                                    <img src="assets/images/card6.jpg" alt="">
                                    <div class="card-info">
                                        <p class="mb-1 text-white fs-14">Total Documents</p>
                                        <div class="d-flex justify-content-between">
                                            <h2 class="num-text text-white mb-5 font-w600"><span *ngIf="dashboardData">{{ (dashboardData?.child?.totalDocument) ? dashboardData?.child?.totalDocument : 0 }}</span></h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="white" class="bi bi-file-earmark-post" viewBox="0 0 16 16">
                                                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                                <path d="M4 6.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7zm0-3a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5z"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4 text-white">
                                                <a [routerLink]="['/home/document']" routerLinkActive="router-link-active"  class="text-white"><p class="fs-12 mb-1 op6">View More</p></a>
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                            </div>    -->
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="card-bx stacked card">
                                    <img src="assets/images/card7.jpg" alt="">
                                    <div class="card-info">
                                        <p class="mb-1 text-white fs-14">Verified Documents</p>
                                        <div class="d-flex justify-content-between">
                                            
                                            <h2 class="num-text text-white mb-5 font-w600"><span *ngIf="dashboardData">{{ (dashboardData?.child?.verifiedCer) ? dashboardData?.child?.verifiedCer : 0 }}</span></h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="white" class="bi bi-file-check" viewBox="0 0 16 16">
                                                <path d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4 text-white">
                                                <a [routerLink]="['/home/mychild']" [queryParams]="{approve: 'yes'}" routerLinkActive="router-link-active"  class="text-white"><p class="fs-12 mb-1 op6">View More</p></a>
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="card-bx stacked card">
                                    <img src="assets/images/card8.jpg" alt="">
                                    <div class="card-info">
                                        <p class="mb-1 text-white fs-14">Unverified Documents</p>
                                        <div class="d-flex justify-content-between">
                                            <h2 class="num-text text-white mb-5 font-w600"><span *ngIf="dashboardData">{{ (dashboardData?.child?.unverifiedCer) ? dashboardData?.child?.unverifiedCer : 0 }}</span></h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="white" class="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                                            <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                            </svg>
                                        </div>
                                        <div class="d-flex">
                                            <div class="mr-4 text-white">
                                                <a [routerLink]="['/home/mychild']" [queryParams]="{approve: 'no'}" routerLinkActive="router-link-active"  class="text-white"><p class="fs-12 mb-1 op6">View More</p></a>
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
               
            </div>
        </div>
        <!-- content-body -->
     </div>
     <!-- main-wrapper end -->