<div id="main-wrapper">
    <!--  -->
    <app-sidebar></app-sidebar>
    <!-- sidebar menu -->
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form">
                <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Role Permission</h5>
                        <div class="addMore d-md-inline-block border-0">
                            <a class="btn-add" href="add-role.html">
                                <i class="hi hi-create-lg mr-1"></i>
                                Add Module
                            </a>
                        </div>
                    </div>
                </div>
                <form>
                    <div class="card-body">
                        <div class="row user_role">  
                            <div class="col-md-12">
                                <div class="card-table">
                                    <div class="apploader">
                                        <div class="loader">
                                            <div class="spinner-border" role="status"></div>
                                            <span class="d-block mt-3">Loading...</span>
                                        </div>
                                    </div>
                                    <form [formGroup]="editRoleForm">
                                    <div class="row align-items-md-center">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="role_name" class="text-dark fw-500 label-text">Role Name :</label>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <div class="form-group">
                                                <input type="text" formControlName="roleName" id="role_name" readonly class="form-control" placeholder="Role Name">
                                            </div>                                           
                                        </div>
                                    </div>
                                </form>
                                     <table class="table table-sm table-bordered">
                                        <thead>
                                            <tr class="table-header">
                                                <!-- <th class="fx-w-30">Status</th> -->
                                                <th class="w-30">Module</th>
                                                <th class="text-center" colspan="4"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor = "let data of moduleList, let i = index">
                                                <!-- <td>
                                                    <div class="ui slider checkbox chEnable">
                                                        <input type="checkbox" name="modEnable" id="modEnable1" checked="" class="hidden">
                                                        <label for="modEnable1">Enable</label>
                                                    </div>
                                                </td> -->
                                                <td>{{data.moduleName}}</td>
                                                <td>
                                                    <span *ngFor = "let data1 of data.permission" class="permission-tag"> 
                                                       
                                                        <div class="ui checkbox">
                                                            <input type="checkbox" name="md_1_create" [id]="data1.id" [checked]="data1.checked" [(ngModel)]="data1.checked" [ngModelOptions]="{standalone: true}" (click)="getData()" class="hidden">
                                                            <label [for]="data1.id">
                                                                <span>{{data1.displayName}}</span>
                                                            </label>
                                                        </div>
                                                    </span>
                                                </td>
                                                <!-- <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_1_read" id="md_1_read" checked="" class="hidden">
                                                        <label for="md_1_read"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_1_update" id="md_1_update" checked="" class="hidden">
                                                        <label for="md_1_update"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_1_delete" id="md_1_delete" checked="" class="hidden">
                                                        <label for="md_1_delete"></label>
                                                    </div>
                                                </td> -->
                                            </tr>
                                            <!-- <tr>
                                                <td>
                                                    <div class="ui slider checkbox chEnable">
                                                        <input type="checkbox" name="modEnable" id="modEnable2" checked="" class="hidden">
                                                        <label for="modEnable2">Enable</label>
                                                    </div>
                                                </td>
                                                <td>Student Profile</td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_2_create" id="md_2_create" checked="" class="hidden">
                                                        <label for="md_2_create"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_2_read" id="md_2_read" checked="" class="hidden">
                                                        <label for="md_2_read"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_2_update" id="md_2_update" checked="" class="hidden">
                                                        <label for="md_2_update"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_1_delete" id="md_2_delete" checked="" class="hidden">
                                                        <label for="md_2_delete"></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="ui slider checkbox chEnable">
                                                        <input type="checkbox" name="modEnable" id="modEnable3" checked="" class="hidden">
                                                        <label for="modEnable3">Enable</label>
                                                    </div>
                                                </td>
                                                <td>Teacher Profile</td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_3_create" id="md_3_create" checked="" class="hidden">
                                                        <label for="md_3_create"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_3_read" id="md_3_read" checked="" class="hidden">
                                                        <label for="md_3_read"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_3_update" id="md_3_update" checked="" class="hidden">
                                                        <label for="md_3_update"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_3_delete" id="md_3_delete" checked="" class="hidden">
                                                        <label for="md_3_delete"></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="ui slider checkbox chEnable">
                                                        <input type="checkbox" name="modEnable" id="modEnable4" checked="" class="hidden">
                                                        <label for="modEnable4">Enable</label>
                                                    </div>
                                                </td>
                                                <td>School</td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_4_create" id="md_4_create" checked="" class="hidden">
                                                        <label for="md_4_create"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_4_read" id="md_4_read" checked="" class="hidden">
                                                        <label for="md_4_read"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_4_update" id="md_4_update" checked="" class="hidden">
                                                        <label for="md_4_update"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_4_delete" id="md_4_delete" checked="" class="hidden">
                                                        <label for="md_4_delete"></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="ui slider checkbox chEnable">
                                                        <input type="checkbox" name="modEnable" id="modEnable5" checked="" class="hidden">
                                                        <label for="modEnable5">Enable</label>
                                                    </div>
                                                </td>
                                                <td>Document</td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_5_create" id="md_5_create" checked="" class="hidden">
                                                        <label for="md_5_create"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_5_read" id="md_5_read" checked="" class="hidden">
                                                        <label for="md_5_read"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_5_update" id="md_5_update" checked="" class="hidden">
                                                        <label for="md_5_update"></label>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="ui checkbox">
                                                        <input type="checkbox" name="md_5_delete" id="md_5_delete" checked="" class="hidden">
                                                        <label for="md_5_delete"></label>
                                                    </div>
                                                </td>
                                            </tr> -->
                                        </tbody>
                                     </table>
                                     <!-- <div class="table-footer">
                                        <div class="text-md-right">
                                            <button type="submit" class="btn btn-success user-btn" (click)="updateRole()">Save</button>
                                            <button type="reset" class="btn btn-secondary user-btn" routerLink="/user-role">Cancel</button>
                                        </div>
                                     </div> -->
                                </div>
                            </div>   
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="form-group mb-0">
                            <button type="submit" class="btn btn-success" (click)="updateRole()">Save</button>
                            <button type="reset" class="btn btn-secondary" routerLink="/home/user-role">Cancel</button>
                        </div>
                    </div>
                </form>  
                
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>
<!-- main-wrapper end -->
<!-- add/edit role start -->

<!-- end add/edit role -->
    <!-- add/edit role start -->
    <div class="modal fade addEditRole" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                    <div class="modal-header modal-heading" id="modal-header"></div>
                    <div class="modal-body">
                        <div class="form-group mb-0">
                            <label>
                                Role
                            </label>
                            <input type="text" name="roleName" class="form-control" id="roleName">
                        </div>
                    </div>
                    <div class="modal-footer">                          
                        <button type="reset" class="btn btn-secondary user-btn" data-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-success user-btn" id="submitRole">Save</button>
                    </div>
                
            </div>
        </div>
    </div>
    <!-- end add/edit role -->
    <!-- add Module start -->
    <div class="modal fade" id="addModule" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                    <div class="modal-header modal-heading">Add Module</div>
                    <div class="modal-body">
                        <div class="form-group mb-0">
                            <label>
                                Module
                            </label>
                            <input type="text" name="roleName" class="form-control" id="roleName">
                        </div>
                    </div>
                    <div class="modal-footer">                          
                        <button type="reset" class="btn btn-secondary user-btn" data-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-success user-btn" id="submitRole">Save</button>
                    </div>
                
            </div>
        </div>
    </div>
    <!-- end add Module -->
    <!-- delete popup start -->
    <div id="deletePopup" class="modal fade" aria-hidden="true" style="display: none;">
        <div class="modal-dialog modal-confirm">
            <div class="modal-content">
                <div class="modal-header flex-column">
                    <div class="icon-box">
                        <i class="bi bi-x"></i>
                    </div>						
                    <h4 class="modal-title w-100">Are you sure?</h4>	
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="bi bi-x"></i></button>
                </div>
                <div class="modal-body">
                    <p>Do you really want to delete these records? This process cannot be undone.</p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" id="roleDelete">Delete</button>
                </div>
            </div>
        </div>
    </div>