import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { externalJs } from 'src/app/services/external-js.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserServiceService } from 'src/app/services/user-service.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { OrganisationService } from 'src/app/services/organisation.service';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  userData: any = [];
  roleList:any = [];
  userId: any;
  isLoading:boolean = true;
  message: any='';
  userPermissionData: any;
  editButton: boolean = false;
  deleteButton: boolean = false;
  viewPage: boolean = false;
  currentAddLnk :any;
  currAddLnkTxt: string = 'Add User';
  // add pagination data 
  currentSchool:any = parseInt(localStorage.getItem('orgIds')) || '';
  currentName: any ='';
  currentRole:any = '';
  currentEmail:any = ''
  currentPage:number = 1;
  lastPage:number = 1;
  pageList:any = [];
  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  tableDataPerPage: number = 20;


  constructor(private xj: externalJs, private service: UserServiceService, private serviceOrganisation: OrganisationService, private router: Router, private route: ActivatedRoute, private toastr: NotificationService, public loginService: LoginServiceService) { 
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if(element.module === "User Management"){
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {      
      if(element?.permission === 'delete'){
       this.deleteButton = true;
      }else if(element?.permission === 'update'){
        this.editButton = true;
      }else if(element?.permission === 'create'){
          this.currentAddLnk = '/home/add-user';  
      } else if(element?.permission === "read"){
          this.viewPage = true;        
      }
    });

    // params
    this.route.queryParamMap
      .subscribe((params: any) => {
        let paramsObj = { ...params.keys, ...params };
        if (paramsObj.params.page) {
          this.currentPage = paramsObj.params.page;
        }
        if (paramsObj.params.role) {
          this.currentRole = paramsObj.params.role;
        } else {
          this.currentRole = '';
        }
        if (paramsObj.params.name) {
          this.currentName = paramsObj.params.name;
        } else {
          this.currentName = '';
        } 
        if (paramsObj.params.email) {
          this.currentEmail = paramsObj.params.email;
        } else {
          this.currentEmail = '';
        } 
        this.initComp();
      });

  }
  ngOnInit(): void {
    if(!this.viewPage){
       this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    this.getRoles();
  }


  initComp(){    
    this.userList();
  }

  // get user list

  userList() {
    this.isLoading = true;
    this.service.users(this.currentPage,this.currentRole,this.currentName, this.currentEmail).subscribe((res:any) => {
      if(res.status){
        this.userData = res.data?.data;
        this.currentPage = res.data?.current_page;
        this.lastPage = res.data?.last_page;
        this.mapPageList( res.data?.last_page);
        this.tableDataFrom = res.data?.from;
        this.tableDataTo = res.data?.to;
        this.tableDataTotal = res.data?.total;
        this.tableDataPerPage = res.data?.per_page;
        this.message = '';
        this.isLoading = false;
      } else if(res.status_code === 404){
        this.message = 'No User Data Found';       
        this.userData = [];
        this.isLoading = false;
      } else{
        this.message = "Something went wrong. Please try later";     
        this.userData = [];
        this.isLoading = false;
      }
      
    }, (err:any)=>{
      this.userData = [];
      this.message = "Something went wrong. Please try later";
      this.isLoading = false;
      
    })
  }
    // for mapping page list
  mapPageList(lastPage){
    let pageListArr = [];
    this.pageList = [];
    if(lastPage <=1) return;
    if(lastPage <= 5){
      for(var i =1; i<= lastPage; i++){
          pageListArr.push(i);
      }
    }
    if(lastPage > 5){
      if(this.currentPage <=4){        
          for(var i =1; i<= 5; i++){
            pageListArr.push(i);
          }      
          pageListArr.push('...');
          pageListArr.push(lastPage);
      } else if(lastPage - this.currentPage <=3){
        pageListArr.push(1);
        pageListArr.push('...');
        for(var i =lastPage - 4; i<= lastPage; i++){
          pageListArr.push(i);
        } 
      }
      else {
        pageListArr.push(1);
        pageListArr.push('...');  

        for(var i = this.currentPage - 2; i<= this.currentPage + 2; i++){
          pageListArr.push(i);
        }

        pageListArr.push('...');
        pageListArr.push(lastPage);
      } 
    } 
    this.pageList = pageListArr;
  }
  // change page
  changePage(page){
    
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: page },
        queryParamsHandling: 'merge'
      });
    // console.log(page)
  }
  // go to edit page
  goToEditPage(id, roleid) {
    this.router.navigate(['/home/edit-user'], { queryParams: { id: id, roleid: roleid } })
  }

  // get delete data id
  deleteOrgId(id) {
    this.userId = id
  }

  // delete api
  deleteUser() {
    this.service.deleteUsers(this.userId).subscribe((res:any) => {
      if(res.status){
        this.initComp();
        this.toastr.showSuccess("User deleted successfully !!");
      }else{
        this.toastr.showError('Unable to delete User', '');
        this.initComp();
      }      
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later', '');
      this.initComp();
    })
  }

  // get roles
  getRoles(){
    this.service.fetchRole().subscribe((res:any)=>{
      if(res.status){
        this.roleList = res.data
      } else{
        this.roleList = [];
      }
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/dashboard']);
    })
  }

  //  search
 

  filterData() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: 1, role: this.currentRole, name: this.currentName, email:this.currentEmail },
        queryParamsHandling: 'merge'
      });
  }

  clearFilter(){
  this.currentSchool= parseInt(localStorage.getItem('orgIds')) || '';
  this.currentName = '';
  this.currentRole = '';
  this.currentEmail = '';
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: { page: 1, role: this.currentRole, name: this.currentName, email:this.currentEmail},
      queryParamsHandling: 'merge'
    });
  }

}
