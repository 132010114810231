import { Component, OnInit} from '@angular/core';
import { externalJs} from 'src/app/services/external-js.service';
import { Router } from '@angular/router';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CourseService } from 'src/app/services/course.service';
import { ClassService } from 'src/app/services/class.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AcademicService } from 'src/app/services/academic.service';
import { MasterService } from 'src/app/services/master.service';
import { data } from 'jquery';
import { HttpEventType, HttpResponse } from '@angular/common/http';


@Component({
  selector: 'app-uploadbulkdocument',
  templateUrl: './uploadbulkdocument.component.html',
  styleUrls: ['./uploadbulkdocument.component.css']
})
export class UploadbulkdocumentComponent implements OnInit {
  bulkUploadDocFrm: FormGroup;
  submitted = false;
  userPermissionData:any;
  viewPage:boolean = false;
  isSubmit: boolean = false;
  docTypesL : boolean  = true;   
  constructor(private xj: externalJs, private router: Router, private serviceOrganisation : OrganisationService,  private formBuilder: FormBuilder,
    public loginService : LoginServiceService,private classServ:ClassService, private serviceCourse: CourseService, private serviceAcademic : AcademicService,private toastr: NotificationService,private masterService: MasterService,) {
      var localData = JSON.parse(localStorage.getItem('userPermission'))
      const a = localData?.modules;
      a.forEach(element => {
        if (element.module === "Bulk Upload") {
          this.userPermissionData = element?.permission;
        }
      });
      this.userPermissionData?.forEach(element => {
        if(element?.permission === 'Bulk upload document'){
          this.viewPage = true;
        }
      });
    }
  isLoading = true;
  isUploading= false;
  step: number = 0;
  progress: number = 0;
  orgData: any= [];
  courseData: any = [];
  sessionData: any = [];
  docTypes = [];
  myDocs:string [] = [];
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || 0;
  files: any[] = [];
  gradeItems:any = [];
  fileUpload = {status: '', message: ''};
  errorMessages:any = [];
  defOpCourse:any = 'Select Course';
  defOpGrade:any = 'Select Grade';
  CourseLoader:boolean = false;
  GradeLoader:boolean = false;
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    this.serviceOrganisation.allSchool().subscribe(res => {
      if(res['status']){
        this.orgData = res['data'];
        this.isLoading = false;
      }
    })
    this.serviceAcademic.getAllSession().subscribe(res =>{
      this.sessionData = res['data'];
    })
   
    if(this.schoolId){
      this.getCourse(this.schoolId);
      // this.getclassName(this.schoolId);
      this.getGradeItems(this.schoolId);
    }

    this.getDocumentType();

    //form validations
    this.bulkUploadDocFrm = this.formBuilder.group({
      organisation: ['',],
      course: [''],
      grade: [''],
      acaSession: [''],
      year: [''],
      doc_type: ['',],
      document: ['',],
    })

    this.bulkUploadDocFrm.get('year').setValue(new Date().getFullYear());
    this.bulkUploadDocFrm.get('grade').disable();
    this.bulkUploadDocFrm.get('course').disable();
    if(this.schoolId) {
      this.bulkUploadDocFrm.controls['organisation'].setValue(this.schoolId);
    }

  }
  get m(){
    return this.bulkUploadDocFrm.controls;
  }

 
  onschoolChange(e){ 
    this.CourseLoader=true;
    this.GradeLoader=true;
    this.bulkUploadDocFrm.get('course').setValue('');
    this.bulkUploadDocFrm.get('grade').setValue('');
    this.bulkUploadDocFrm.get('course').disable();
    this.bulkUploadDocFrm.get('grade').disable();
    this.getCourse(e);
    //this.getclassName(e);
    this.getGradeItems(e);
  }

  //get course
  getCourse(orgID){
    this.CourseLoader=true;
    this.serviceCourse.getAllCoursebyOrgid(orgID).subscribe(res => {
      
      if(res['status']){
        this.courseData = res['data'];
        this.defOpCourse = 'Select Course';
        this.bulkUploadDocFrm.get('course').enable();
      } else{
        if(!this.bulkUploadDocFrm.controls['organisation'].value){
          this.defOpCourse = 'Select Course';
        } else{
          this.defOpCourse = 'No Course Found';
        }
        this.courseData = [];
      }  
      this.CourseLoader = false;
      if(this.isLoading){
        this.isLoading = false;
      }  
    })   
  }

//get the class name by school id
  getclassName(orgId){
    this.classServ.getClassdetails(orgId).subscribe(res => {
      //this.courseData = res['data']['data'];
      //console.log(res);
    })
  }


// get document type
getDocumentType(){
  this.serviceCourse.getdocTypes().subscribe((res:any) => {
    if(res.status){
      this.docTypes = res.data;
      this.bulkUploadDocFrm.controls['doc_type'].enable();
    } else{
      this.docTypes = [];        
    }
    this.docTypesL = false;   
  })
}


getGradeItems(schoolId:any){
  this.masterService.allGradeList(schoolId).subscribe((res:any)=>{
    if(res.status){
      this.gradeItems =  res.data;
      this.defOpGrade= 'Select Grade';
      this.bulkUploadDocFrm.get('grade').enable();
    } else{
      this.gradeItems = [];
      if(!this.bulkUploadDocFrm.controls['organisation'].value){
        this.defOpGrade = 'Select Grade';
      } else{
        this.defOpGrade = 'No Grade Found';
      }
    }
    this.GradeLoader = false;
  })
}

// uploadItem
uploadDocCSV(){  
  const pr = setInterval(() => {
    if(this.progress === 100){      
      clearInterval(pr);
      this.isLoading = true; 
      setTimeout(() => {
        this.isLoading = false;
        //this.router.navigate(['/home//bulk/uploaded-document']);
      }, 300);  
    } else{
      this.progress +=5;
    }
  }, 200);
 
}
// nextStep

nextStep(){
  this.step = 1;
  this.errorMessages = [];
}

// preStep
preStep(){
  this.step = 0;
  this.errorMessages = [];
}

/**
   * on file drop handler
   */
 onFileDropped(event) {
  this.isUploading = true;
  this.prepareFilesList(event);
}

/**
 * handle file from browsing
 */
fileBrowseHandler(files) {
  this.isUploading = true;
  this.prepareFilesList(files);
 
}


/**
 * Convert Files list to normal array list
 * @param files (Files List)
 */
prepareFilesList(files: Array<any>) {
  for (const item of files) {
    item.progress = 0;
    this.files.push(item);
  }
  
}


// submit(){
//   this.errorMessages = [];
//   this.submitted = true;
//   this.isSubmit = true;
  

//   this.uploadDocCSV();
  // const formData = new FormData();
  // for (var i = 0; i < this.files.length; i++) {
  //   formData.append("document[]", this.files[i]);
  // }
  // //formData.append('userSourcedId', '1'); 
  // formData.append('orgSourcedId', this.bulkUploadDocFrm.get('organisation').value);
  // formData.append('courseSourcedId', this.bulkUploadDocFrm.get('course').value);
  // formData.append('documentType', this.bulkUploadDocFrm.get('doc_type').value);
  // formData.append('grade', this.bulkUploadDocFrm.get('grade').value);
  // formData.append('sessionSourcedId', this.bulkUploadDocFrm.get('acaSession').value);
  // formData.append('year', this.bulkUploadDocFrm.get('year').value);

  // localStorage.setItem('orgId', this.bulkUploadDocFrm.get('organisation').value);

//   // return;

//   this.serviceOrganisation.upbulkLoadDocument(formData).subscribe(res => { 
//     // this.fileUpload = res;  
//     console.log(res);
//     if(res['status'] && res['status_code'] === 200){
//       this.toastr.showSuccess("Document uploaded successfully !!")         
//     } else{
//       if(res['message'] && res['status_code']){
//         this.errorMessages.push(res['message']);
//       }  
//     }
//     this.isSubmit = false;
//     this.submitted = false;
//   }, (err:any)=> {      
//       let errData = err.error.errors;    
//       for(let key in errData){
//          this.errorMessages.push(errData[key][0]);
//       }
//       // if(errData == undefined || errData == null){
//       //   this.errorMessages.push('Something went wrong. Please try later');
//       // }
//       this.isSubmit = false;
//       this.submitted = false;
//   })
  
 
// }
progressInfos = { value: 0, fileStatus:false };
submit(){
  this.isSubmit = true;
  this.submitted = true;
  this.errorMessages =[];
  // if (this.files?.length > 1) {
    // for (let i = 0; i < this.files?.length; i++) {
      this.upload();
    // }
  // } else{
  //   this.errorMessages.push('Please uplaod document file');
  //   this.isSubmit = false;
  //   this.submitted = false;
  // }
}

upload(): void {
  // this.progressInfo = ;
  // if (file) {
    const formData = new FormData(); 
    for (let i = 0; i < this.files.length; i++) {
        formData.append("document[]", this.files[i]);
    } 
    //formData.append('userSourcedId', '1'); 
    formData.append('orgSourcedId', this.bulkUploadDocFrm.get('organisation').value);
    formData.append('courseSourcedId', this.bulkUploadDocFrm.get('course').value);
    formData.append('documentType', this.bulkUploadDocFrm.get('doc_type').value);
    formData.append('grade', this.bulkUploadDocFrm.get('grade').value);
    formData.append('sessionSourcedId', this.bulkUploadDocFrm.get('acaSession').value);
    formData.append('year', this.bulkUploadDocFrm.get('year').value);
    localStorage.setItem('orgId', this.bulkUploadDocFrm.get('organisation').value);
    this.serviceOrganisation.upbulkLoadDocument(formData).subscribe((event: any) => { 
      if(this.files?.length > 0){
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos.value = Math.round(
            (100 * event.loaded) / event.total
          );
          this.progressInfos.fileStatus = true;
        } else if (event instanceof HttpResponse) {  
          this.progressInfos.value = 0;
          this.progressInfos.fileStatus = false;  
          this.toastr.showSuccess('All document uploaded successfully');
          this.router.navigate(['/home//bulk/uploaded-document']);
          this.isSubmit = false;
          this.submitted = false;         
        }
      }
    },
    (err: any) => {
      this.progressInfos.value = 0;
      this.progressInfos.fileStatus = false;
      let errData = err.error.errors;    
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
        
      }
      this.bulkUploadDocFrm.get('document').setValue('');
      this.files= [];
      this.isSubmit = false;
      this.submitted = false;
    })
  // }
}

}
