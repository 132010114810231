import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { externalJs } from 'src/app/services/external-js.service';
import { LoginServiceService } from 'src/app/services/login-service.service';

@Component({
  selector: 'app-create-student',
  templateUrl: './create-student.component.html',
  styleUrls: ['./create-student.component.css']
})
export class CreateStudentComponent implements OnInit {

  dropdownList = [];
  dropdownSettings = {};
  constructor( private xj:externalJs,public loginService: LoginServiceService) { }

  ngOnInit(): void {
    this.xj.extraInit();

    

    this.dropdownList = [
      { item_id: 1, item_text: 'Pre School' },
      { item_id: 2, item_text: '1st Grade' },
      { item_id: 3, item_text: '2nd Grade' },
      { item_id: 4, item_text: '3rd Grade' },
      { item_id: 5, item_text: '4th Grade' }
    ];
    // this.selectedItems = [
    //   { item_id: 3, item_text: '2nd Grade' },
    //   { item_id: 4, item_text: '3rd Grade' },
    // ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: false,
    };

  }
  
  // create student form
  addStudentForm = new FormGroup({
    userName : new FormControl('',),
    firstName : new FormControl('',),
    middleName : new FormControl('',),
    lastName : new FormControl('',),
    enableUser : new FormControl('',),
    class : new FormControl('',),
    school : new FormControl('',),
    phoneNumber : new FormControl('',),
    email : new FormControl('',),
    startDate : new FormControl('',),
    endDate : new FormControl('',),
    sms : new FormControl('',),
  })
  addStudent(){
    
  }
}
