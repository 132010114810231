import {environment as env} from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType, HttpRequest} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { event } from 'jquery';
import { LoginServiceService } from './login-service.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class OrganisationService {
  token: string;
  constructor(private http: HttpClient, private loginSrv:LoginServiceService) {
 
  }

  getHeader = () =>{
    return {
      headers : new HttpHeaders({
        "Authorization" :'Bearer ' + this.loginSrv.getUserToken()
      })
    }
  }


  // create organisation api
  createOrganisation(data:FormData){
    
    return this.http.post(env.apiurl +'organisation', data, this.getHeader()).toPromise();
  }

  // view organisation api
  viewOrganisation(page:number = 1,name = '',type = '',district =''){ 
    return this.http.get(env.apiurl + 'organisation?page='+page+'&name='+ name + '&type=' + type+ '&district=' + district,this.getHeader())
  }

  allSchool(){
    return this.http.get(env.apiurl + 'organisation?allrecords=yes',this.getHeader())
  }
  // delete organisation

  deleteOrganisation(data){
    return this.http.delete(env.apiurl + 'organisation/'+ data,this.getHeader())
  }

  // edit organisation api

  editOrganisation(data){
  return this.http.get( env.apiurl + 'organisation/'+ data +'/edit',this.getHeader())
  }
  // update organisation api

  updateOrganisation(id,data:FormData){
  
  return this.http.post( env.apiurl + 'organisation/'+ id+'?_method=PUT',data,this.getHeader()).toPromise();
  }


  // upload document api
  upLoadDocument(data){
    return this.http.post(env.apiurl +'document', data,this.getHeader()).toPromise();
  }

  //upload bulk document api

  upbulkLoadDocument(formData): Observable<HttpEvent<any>>{
    //return this.http.post(env.apiurl +'import/csv/bulk-upload-document', data,this.getHeader()).toPromise();
    
    // return this.http.post<any>(`${env.apiurl +'import/csv/bulk-upload-document'}` ,data,{
    //   headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.loginSrv.getUserToken()),
    //   reportProgress: true,
    //   observe: 'events'
    // }).pipe(
    //  map(event => this.getEventMessage(event, data)),
    //     catchError(this.handleError)
    // );
    
    const req = new HttpRequest('POST', `${env.apiurl +'import/csv/bulk-upload-document'}`, formData, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.loginSrv.getUserToken()),
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
    
    
  }

  private getEventMessage(event: HttpEvent<any>, formData) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
		break;
      case HttpEventType.Response:
        return this.apiResponse(event);
		break;
      default:
        return `File  surprising upload event: ${event.type}.`;
    }
  }
  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }
  private apiResponse(event) {
    return event.body;
  }

  private handleError(error: HttpErrorResponse) {
    //console.log('errorsssss')
    // console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      return throwError(error);
    } 
    // return an observable with a user-facing error message
    // return throwError('Something bad happened. Please try again later.');
  }



 // get bulk uplaoded documents

 getBulkuploadedDocuments(schoolId, page=1, documentType = '', courseId = ''){
  return this.http.get(env.apiurl +'import/csv/get-uploaded-document?page='+page+'&schoolId='+schoolId+'&documentType='+documentType+'&courseId='+courseId, this.getHeader())
 }



  // search api
  getAllSchoolApi(name, type,district) {
    return this.http.get(env.apiurl + 'organisation?' + name + '&' + type+ '&' + district, this.getHeader())
  }

  //update bulk doument
  updatebulkDocument(id, data){
    return this.http.put(env.apiurl +'import/csv/update-document-info/'+id, data,this.getHeader()).toPromise();
  }


  // get approved document list
  fetchAppDocList(page=1, orgId = '', course = '', type = '', name = '', verified = '', forwarded='', archived=''){
    return this.http.get(env.apiurl + 'document?page='+page+'&orgId='+orgId+'&course='+course+'&type='+type+'&name='+name+'&verified='+verified+'&forwarded='+forwarded+'&archived='+archived, this.getHeader())
  }
  // fetch teacher's child doc list
  fetchTeachChildDocList(page=1, orgId = '', course = '', type = '', name = '', verified = '', forwarded='', archived=''){
    return this.http.get(env.apiurl + 'get-child-document?page='+page+'&orgId='+orgId+'&course='+course+'&type='+type+'&name='+name+'&verified='+verified+'&forwarded='+forwarded+'&archived='+archived, this.getHeader())
  }
  //approve all documetns
  approveAlldocs(data){
    return this.http.post(env.apiurl +'document/approve', data,this.getHeader()).toPromise();
  }


  // view all school
  getAllScool(){
    return this.http.get(env.apiurl + 'organisation?allrecords=yes', this.getHeader());
  }

  
  }
