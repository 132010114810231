import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { externalJs } from './external-js.service';
import { LoginServiceService } from './login-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private exj: externalJs, private loginSrv : LoginServiceService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if ( this.loginSrv.getUserToken()) {
      return true;
    }
    else {
      this.exj.openModalPopup('#unauthModel');
      // alert('You are unauthorized to access. Please Login Now')
      this.router.navigate(['/login'])
    }
  }

}
