import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { ConfirmPasswordValidator } from 'src/app/dashboard/change-password/confirm-password.validator';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  constructor(private xj:externalJs, private route: Router, private FB:  FormBuilder, private  serviceUser : UserServiceService, private toastr: NotificationService ) { }
  

  showError:boolean = false;
  errorMessages:any = [];
  isLoading: boolean = true;
  isSubmit: boolean = false;
  submitBtn: any;
  email: any = '';
  ngOnInit(): void {
    this.xj.extraInit();
    
  }

  formUpdatePassword = this.FB.group({
    currPwd: ['',],
    newPwd: ['', ],
    conPwd: ['',]
  })


  get m(){
    return this.formUpdatePassword.controls;
  }

  updatePassword(e:any){
    this.isSubmit = true;
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.showError = false;
    this.errorMessages = [];
    const formData = {
      current_password: this.formUpdatePassword.value.currPwd,
      password: this.formUpdatePassword.value.newPwd,
      password_confirmation: this.formUpdatePassword.value.conPwd,
    };   
    this.serviceUser.changePassword(formData).then(res => {
      if(res['status']){
        this.toastr.showSuccess("Password changed successfully!!")
        this.formUpdatePassword.reset();       
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }   
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
     
    }).catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    });
    // setTimeout(() => {
    //   this.isLoading = false; 
    //   this.toastr.showSuccess('Password has been updated');
    //   this.formUpdatePassword.reset();
    // }, 1000);
  }

  
 
}
