import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { OrganisationService } from 'src/app/services/organisation.service'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { NotificationService } from 'src/app/services/notification.service';
import { UserServiceService } from 'src/app/services/user-service.service';
import { timestamp } from 'rxjs/operators';
@Component({
  selector: 'app-edit-organisation',
  templateUrl: './edit-organisation.component.html',
  styleUrls: ['./edit-organisation.component.css']
})
export class EditOrganisationComponent implements OnInit {
  editOrgData: any;
  id: any;
  districtList: any = [];
  showError:boolean = false;
  errorMessages:any = [];
  currentLogo:any;


  userPermissionData:any;
  viewPage:boolean = false;
  isSubmit: boolean = false;
  submitBtn:any;
  isLoading: boolean = true;
  defOpDistrict:string='Select District';
  constructor(private xj: externalJs, private service: OrganisationService,   private userService: UserServiceService, private route: ActivatedRoute, private router: Router, private toastr: NotificationService) {
    this.route.queryParamMap.subscribe(queryParams => {
      this.id = queryParams.get("id")
    })
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;
    a.forEach(element => {
      if (element.module === "School") {
        this.userPermissionData = element?.permission;
      }
    });
    this.userPermissionData?.forEach(element => {
      if (element?.permission === 'Edit Organisation') {
        this.viewPage = true;
      } 
    });
  }

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    this.editData();
    // this.getDistrict();
  }
  getDistrict(){
    this.isLoading = true;
    this.userService.fetchDistrict().subscribe((res:any) => {
      if(res.status){
        this.editOrgForm.controls['district'].enable();
        this.districtList = res['data'];
        this.defOpDistrict= 'Select District';
      }else{
        this.defOpDistrict= 'No District Found';
        this.districtList = [];        
      }
      this.isLoading = false;
    }, (err:any)=>{
        this.toastr.showError('Something went wrong. Please try later','');
        this.router.navigate(['/home/school']);
        this.isLoading = false;
    })
  }

  changeLogo(event:any){
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); 
    reader.onload = (_event) => { 
      this.currentLogo = reader.result; 
    }
    this.editOrgForm.get('logo').setValue(event.target.files[0]);
  }
  //add organisation form
  editOrgForm = new FormGroup({
    name: new FormControl(''),
    type: new FormControl('school'),
    district: new FormControl(''),
    address: new FormControl(''),
    country: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl(''),
    zipcode: new FormControl(''),
    status: new FormControl(''),
    one_roaster_upload: new FormControl(''),
    upload_csv: new FormControl(''),
    logo: new FormControl(''),
    sourcedId: new FormControl(''),   
  })

  // edit organisation api
  editData() {
    this.isLoading=true;
    this.service.editOrganisation(this.id).subscribe((res:any) => {
      if(res.status){
        this.editOrgData = res['data'];
        this.editOrgForm.patchValue({
          name: (this.editOrgData.name === 'null') ? '' : (this.editOrgData.name || ''),
          type: (this.editOrgData.type === 'null') ? '' : (this.editOrgData.type || ''),
          district:  (this.editOrgData.district === 'null') ? '' : (this.editOrgData.district || ''),
          address: (this.editOrgData.address === 'null') ? '' : (this.editOrgData.address || ''),
          country: (this.editOrgData.country === 'null') ? '' : (this.editOrgData.country || ''),
          state: (this.editOrgData.state === 'null') ? '' : (this.editOrgData.state || ''),
          city:  (this.editOrgData.city === 'null') ? '' : (this.editOrgData.city || ''),
          zipcode:  (this.editOrgData.pinCode === 'null') ? '' : (this.editOrgData.pinCode || ''),
          sourcedId: (this.editOrgData?.sourcedId === 'null') ? '' : (this.editOrgData?.sourcedId || ''),
          status:  this.editOrgData.isActive,
          one_roaster_upload: this.editOrgData.one_roaster_upload,
          upload_csv: this.editOrgData.upload_csv,
        })
        this.currentLogo = this.editOrgData.logo;
        this.isLoading=false; 
      } else{
        this.router.navigate(['/home/school']);
        this.toastr.showError('Something went wrong. Please try later','');       
        this.isLoading=false; 
      } 
         
    }, (err:any)=>{
        this.router.navigate(['/home/school']);
        this.toastr.showError('Something went wrong. Please try later','');        
        this.isLoading=false;
    })
   
  }
  
  // update organisation 
  updateOrg(e:any) {
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    this.errorMessages = []
    this.showError = false;
    let r = (Math.random() + 1).toString(36).substring(7); //for generating random string
    const data = new FormData;
    data.append('name', this.editOrgForm.value.name);
    data.append('type', this.editOrgForm.value.type);
    data.append('identifier', r);
    data.append('district', this.editOrgForm.value.district);
    data.append('address', this.editOrgForm.value.address);
    data.append('country', this.editOrgForm.value.country);
    data.append('state', this.editOrgForm.value.state);
    data.append('city', this.editOrgForm.value.city);
    data.append('pinCode', this.editOrgForm.value.zipcode);
    data.append('status', this.editOrgForm.value.status);
    data.append('sourcedId', this.editOrgForm.value.sourcedId);
    data.append('parentSourcedId', '');
    if(this.editOrgForm.value.one_roaster_upload){
      data.append('one_roaster_upload','1');
    } else{
      data.append('one_roaster_upload', '0');
    }
    if(this.editOrgForm.value.upload_csv){
      data.append('upload_csv','1');
    } else{
      data.append('upload_csv', '0');     
    }   
    if(this.editOrgForm.value.logo){
      data.append('logo', this.editOrgForm.value.logo);
    }
   
    this.service.updateOrganisation(this.id,data).then(res => {
      if(res['status']){
        this.toastr.showSuccess("School updated successfully !!")
        this.router.navigate(['/home/school']);
      }else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }         
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }

}
