
// export const environment = {
//   production: false,
//   apiurl:'https://ig.showmeproject.com/admbackend/public/api/v1/',
// };

// live clinton url
export const environment = {
  production: false,
  apiurl:'https://www.mydocschain.com/admbackend/public/api/v1/',
};

// export const environment = {
//   production: false,
//   apiurl:'https://clinton-1420060987.us-east-2.elb.amazonaws.com/admbackend/public/api/v1/',
// };


// demo clinton url
// export const environment = {
//   production: false,
//   apiurl:'http://ec2-3-137-205-9.us-east-2.compute.amazonaws.com/admbackend/public/api/v1/',
// };
