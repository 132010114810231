import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { externalJs } from 'src/app/services/external-js.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css']
})
export class UserRoleComponent implements OnInit {
  @ViewChild('delete') delete;
  isLoading:boolean = true;
  message:string = '';
  moduleList: any;
  allModuleId = [];
  editRoleList: any;
  roleList: any;
  perId: any;
  ReadOnlyStyleGuideNotes: boolean;
  roleId: any;
  currentAddLnk :any;
  currAddLnkTxt: string = 'Add Role';
  edit = false;
  editId: any;
  editNewPermissionArray: any[];
  add: boolean = true;
  userPermissionData: any;
  editButton: boolean = false;
  deleteButton: boolean = false;
  viewPage: boolean = false;
  isSubmit: boolean = false;
  submitBtn:any;
  showError = false;
  errorMessages = [];
  // loader = true
  constructor(private xj: externalJs, private service: UserServiceService, private router: Router, private toastr: NotificationService) {
    this.ReadOnlyStyleGuideNotes = true;
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if(element.module === "Role Management"){
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {
      if(element?.permission === 'delete'){
        this.deleteButton = true;
      }else if(element?.permission === 'update'){
        this.editButton = true;
      }else if(element?.permission === 'create'){
        this.currentAddLnk = '/home/add-role';  
      }else if(element?.permission === 'read'){
        this.viewPage = true;
      }
    });

  }

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    this.fetchUserRole();
    this.getRoles();
  }


  getEditId(data) {
    this.edit = true
    this.add = false
    this.editId = data
    this.goToEdit();
  }


  // get role list
  getRoles() {
    this.edit = false
    this.add = true;
    this.service.fetchRoleAllRecords().subscribe((res:any) => {
      if(res.status){
        this.roleList = res.data;
      } else{
        this.roleList = []
      }
    }, (err:any)=>{
      this.router.navigate(['/home/dashboard']);
    })
  }
  getRolesByRoles(id) {
    // this.service.editRole(id).subscribe(res => {
    //   this.moduleList = res['data'];
    // })
  }
  // get delete data
  getDeleteData(id) {
    this.roleId = id
  }

  // delete role api
  deleteRole() {
    this.service.deleteRole(this.roleId).subscribe((res:any) => {      
      if(res.success){
          this.getRoles();
          this.toastr.showSuccess("Role deleted successfully !!");
          this.xj.closeModalPopup("#deletePopup");        
        } else{
          this.toastr.showError('Unable to delete Role', '');
          this.getRoles();
          this.xj.closeModalPopup("#deletePopup");   
        }      
    }, (err:any)=>{
        this.toastr.showError('Something went wrong. Please try later', '');
        this.getRoles();
        this.xj.closeModalPopup("#deletePopup");   
    })
  }
  goToEdit() {
    this.isLoading = true;
    this.editNewPermissionArray = []
    this.service.editRole(this.editId).subscribe(res => {
      if(res['success']){
        this.editRoleList = res['data'];
        this.fetchUserRole();
        this.editRoleForm.patchValue({
          roleName: this.editRoleList.roleName
        })
      } else{
        this.router.navigate(['/home/user-role']);
        this.toastr.showError('Something went wrong. Please try later',''); 
      }
      this.isLoading = false; 
    },(err:any)=>{
      this.router.navigate(['/home/user-role']);
      this.toastr.showError('Something went wrong. Please try later','');        
      this.isLoading=false;
    })
  }
  // add role form
  editRoleForm = new FormGroup({
    roleName: new FormControl('',)
  })

  // get module with permission data
  fetchUserRole() {
    this.service.getUserModule().subscribe((res:any) => {
      if(res.success){
        this.moduleList = res['data'];
        this.mapPermission();
        this.message ='';
      } else{
        this.moduleList = [];
        this.message= 'No Role Data Found';
      }
      this.isLoading = false;
    },(err:any)=>{
      this.message = 'Something went wrong. Please try later';
      this.isLoading = false;
    })
  }

  mapPermission() {
    this.moduleList.forEach((module: any) => {
      module?.permission.forEach((per: any) => {
        per.checked = false;
        this.editRoleList?.modules.forEach(element => {
          if (element.moduleId == module.id && element.id == per.id) {
            per.checked = true;
          }
        });

      })

    });

  }

  //  update role api
  updateRole(e:any) {
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    this.showError = false;
    this.errorMessages = [];
    this.getData();
    var finalData = []
    var permissionArr = []

    for (let i = 0; i < this.allModuleId.length; i++) {
      var permissionArr = []
      for (let j = 0; j < this.allModuleId.length; j++) {
        if (this.allModuleId[i].moduleId == this.allModuleId[j].moduleId) {
          permissionArr.push({ 'permissionId': this.allModuleId[j].permissionId })
        }
      }

      var request = {
        "moduleId": this.allModuleId[i].moduleId,
        "permissions": permissionArr
      }
      finalData.push(request);
    }
    var elementsArr = finalData.reduce(function (previous, current) {
      var object = previous.filter(object => object.moduleId === current.moduleId);
      if (object.length == 0) {
        previous.push(current);
      }
      return previous;
    }, []);
    const data = {
      "roleName": this.editRoleForm.value.roleName,
      "modules": elementsArr

    }
    this.service.updateRoleModule(this.editId, data).subscribe(res => {
      if(res['success']){
        this.toastr.showSuccess("Role updated successfully !!")
        this.getRoles();
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }         
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
      // this.route.navigate(['/home/user-role']);
    },(err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }

  // get id of module and permission
  getData() {
    this.allModuleId = [];
    this.moduleList.forEach(module => {
      module.permission.forEach(per => {
        if (per.checked) {
          this.allModuleId.push({ 'moduleId': module.id, 'permissionId': per.id });
        }
      });

    });

  }
}
