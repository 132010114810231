import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { LoginServiceService } from './login-service.service';


@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
  token: string;

  constructor(private http: HttpClient, private loginSrv:LoginServiceService) {
    this.token = localStorage.getItem('token');
  }

  getHeader = () =>{
    return {
      headers : new HttpHeaders({
        "Authorization" :'Bearer ' + this.loginSrv.getUserToken()
      })
    }
  }
  // create user api
  createUser(data) {
    return this.http.post(env.apiurl + 'users', data, this.getHeader()).toPromise();
  }

  // create user role
  createRole(data) {
    return this.http.post(env.apiurl + 'users', data, this.getHeader())
  }

  // create user list apu
  users(page:number = 1, role:any='', name:any='',email:any='') {
    return this.http.get(env.apiurl + 'users?page='+page+'&role='+role+'&name='+name+'&email='+email, this.getHeader())
  }

  // edit user list 
  editUsers(data) {
    return this.http.get(env.apiurl + 'user/profile/' + data , this.getHeader())
  }

  // update user list 
  updateUsers(id, data) {
    return this.http.put(env.apiurl + 'users/' + id, data, this.getHeader()).toPromise();
  }

  // delete user list 
  deleteUsers(id) {
    return this.http.delete(env.apiurl + 'users/' + id, this.getHeader())
  }

  // fetch role user list 
  getUserModule() {
    return this.http.get(env.apiurl + 'permission', this.getHeader())
  }

  // add role api
  addRoleModule(data) {
    return this.http.post(env.apiurl + 'role', data, this.getHeader())
  }
  // update role api
  updateRoleModule(id, data) {
    return this.http.put(env.apiurl + 'role/' + id, data, this.getHeader())
  }

  // get role api
  // fetchRole() {
  //   return this.http.get(env.apiurl + 'role',httpTokenOptions)
  // }

  fetchRole() {
    return this.http.get(env.apiurl + 'role?allrecords=yes', this.getHeader())
  }


  fetchRoleAllRecords() {
    return this.http.get(env.apiurl + 'role?allrecords=yes', this.getHeader())
  }

  // edit role api
  editRole(data) {
    return this.http.get(env.apiurl + 'role/' + data + '/edit', this.getHeader())
  }

  // delete role api
  deleteRole(data) {
    return this.http.delete(env.apiurl + 'role/' + data, this.getHeader())
  }

  // fetch district
  fetchDistrict() {
    return this.http.get(env.apiurl + 'district', this.getHeader())
  }

  // fetch district
  fetchAllDistrict() {
    return this.http.get(env.apiurl + 'district?allrecords=yes', this.getHeader())
  }

  // search api
  getAllUserApi(username, name, role) {
    return this.http.get(env.apiurl + 'users?' + username + '&' + name + '&' + role, this.getHeader())
  }

  //  changePassword 

  changePassword(formData: any){
    return this.http.post(env.apiurl + 'change-password', formData, this.getHeader()).toPromise();
  }

  exportCSV(role: any){
    return this.http.get(env.apiurl + 'csv/export/user/'+role, this.getHeader()).toPromise();
  }

  // get setting 
  getSetting(){
    return this.http.get(env.apiurl + 'setting', this.getHeader()).toPromise();
  }

  updateSetting(formData:any){
    return this.http.post(env.apiurl+ 'setting', formData, this.getHeader()).toPromise();
  }


  saveDistrict(formData:any){
    return this.http.post(env.apiurl + 'update-district', formData, this.getHeader()).toPromise();
  }
  
}
