import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AcademicService } from 'src/app/services/academic.service';
import { externalJs } from 'src/app/services/external-js.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-edit-session',
  templateUrl: './edit-session.component.html',
  styleUrls: ['./edit-session.component.css']
})
export class EditSessionComponent implements OnInit {
  showErrMsg: string;
  id: any;
  editSessionData: any;
  startDateData: any;
  endDateData : any;
  showError:boolean = false;
  isLoading: boolean = true;
  errorMessages:any = [];
  userPermissionData:any;
  viewPage:boolean = false;
  isSubmit: boolean = false;
  submitBtn:any;
  constructor(private xj: externalJs, private service: AcademicService, private router: Router, private toastr: NotificationService, private route: ActivatedRoute) {
    this.route.queryParamMap.subscribe(queryParams => {
      this.id = queryParams.get("id")
    })
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if (element.module === "academic session") {
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {
      if (element?.permission === 'Update Session') {
        this.viewPage = true;
      } 
    });
  }

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    this.editSession();
  }
  //  edit form validation
  updateSessionForm = new FormGroup({
    title: new FormControl('',),
    term: new FormControl('',),
    schoolYear: new FormControl('',),
    endDate: new FormControl('',),
    startDate: new FormControl('',),
  })

  // edit session api
  editSession() {
    this.showError = false;
    this.errorMessages = [];
    this.service.editSession(this.id).subscribe(res => {
      if(res['status']){
      this.editSessionData = res['message']
      this.updateSessionForm.patchValue({
        title: this.editSessionData.title,
        term: this.editSessionData.type,
        schoolYear: this.editSessionData.schoolYear,
        endDate: this.editSessionData.endDate,
        startDate: this.editSessionData.startDate,
       
      })
      this.startDateData = this.editSessionData.startDate;
      this.endDateData = this.editSessionData.endDate;
      this.isLoading = false;
    } else{
      this.router.navigate(['/home/academic-session'])
      this.toastr.showError('Something went wrong. Please try later','');       
      this.isLoading=false; 
    }
      
    },(err:any)=>{
      this.router.navigate(['/home/academic-session'])
      this.toastr.showError('Something went wrong. Please try later','');        
      this.isLoading=false;
    })
    
  }
  // update session api
  updateSession(e:any) {
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    this.errorMessages = []
    this.showError = false;
    let r = (Math.random() + 1).toString(36).substring(7); //for generating random string
    const data = {
      title: this.updateSessionForm.value.title,
      type: this.updateSessionForm.value.term,
      // startDate: this.updateSessionForm.value.startDate,
      // endDate: this.updateSessionForm.value.endDate,
      startDate : this.startDateData,
      endDate : this.endDateData,
      parentSourcedId: r,
      schoolYear: this.updateSessionForm.value.schoolYear
    }
    this.service.updateSession(this.id, data).then(res => {      
      if(res['status']){     
        this.toastr.showSuccess("Session Updated successfully !!")
        this.router.navigate(['/home/academic-session']);
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }         
        this.showError = true;
      }
      this.submitBtn.disabled = false;
      this.isSubmit = false;
    })
    .catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
        this.errorMessages.push(errData[key][0]);
     }
     if(errData == undefined || errData == null){
       this.errorMessages.push('Something went wrong. Please try later');
     }
     this.showError = true;
     this.isSubmit = false;
     this.submitBtn.disabled = false;
    })
  }

  //  handle
updateStartDate(date: any) {
  setTimeout(() => {
    // console.log(date.target.value);
    this.startDateData = date.target.value
  }, 300);
}

updateEndDate(date: any) {
  setTimeout(() => {
    // console.log(date.target.value);
    this.endDateData = date.target.value
  }, 300);
}
}
