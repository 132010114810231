import { Injectable } from '@angular/core';
import { data } from 'jquery';
declare var $: any;
declare var PerfectScrollbar:any;
interface Scripts {
  name: string;
  src: string;
}




@Injectable()
export class externalJs {

  private scripts: any = {};

  constructor() {
  }

  extraInit(){
  if($('.app-tooltip').length > 0){
    $('.app-tooltip').tooltip({
      trigger : 'hover'
    });
    $('.app-tooltip').on('click', function () {
      $(this).tooltip('dispose');
    });
  }

  

$(document).on("click", ".show-password", function(e){
  e.stopImmediatePropagation();
  var $input = $(this).siblings('input');
  var $icon  = $(this).find('.icon');
  $icon.toggleClass('bi-eye bi-eye-slash');
  ($input.attr('type') === 'password') ? $input.attr('type', 'text') : $input.attr('type', 'password');
});
//   



// datepicker

if($('.datepicker').length > 0){
  $('.datepicker').datepicker({
      dateFormat: "dd/mm/yy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1900:+3",
      onSelect: function(dateText, inst) {
        $('#'+inst.id).attr('value',dateText);
      }
  });
  
}

if($('.birthdate').length > 0){
  $('.birthdate').datepicker({
      dateFormat: "dd/mm/yy",
      changeMonth: true,
      changeYear: true,
      yearRange: "1920:+1",
      maxDate: '+0d',
      onSelect: function(dateText, inst) {
        $('#'+inst.id).attr('value',dateText);
      }
  });
  
}


$(function() {
  $('.custom-file-input').change(function(){
    var t = $(this).val();
    var labelText = t.substr(12, t.length);
    $(this).next('label').text(labelText);
  })
});

  }

  initAutoComplete(id:any) {
    $.widget( "custom.combobox", {
      _create: function() {
        this.wrapper = $( "<span>" )
          .addClass( "custom-combobox" )
          .insertAfter( this.element );
 
        this.element.hide();
        this._createAutocomplete();
      },
 
      _createAutocomplete: function() {
        var selected = this.element.children( ":selected" ),
          value = selected.val() ? selected.text() : "";
 
        this.input = $( "<input>" )
          .appendTo( this.wrapper )
          .val( value )
          .attr( "placeholder", selected.text() )
          .addClass( "form-control" )
          .autocomplete({
            delay: 0,
            minLength: 0,
            autoFocus: true,
            source: this._source.bind( this )
          }).focus(function(){            
            $(this).data("uiAutocomplete").search($(this).val());
        });
 
        this._on( this.input, {
          autocompleteselect: function( event, ui ) {
            ui.item.option.selected = true;
            this._trigger( "select", event, {
              item: ui.item.option
            });
          },
 
          autocompletechange: "_removeIfInvalid"
        });
      },
 
      
 
      _source: function( request, response ) {
        var matcher = new RegExp( $.ui.autocomplete.escapeRegex(request.term), "i" );
        response( this.element.children( "option" ).map(function() {
          var text = $( this ).text();
          if ( this.value && ( !request.term || matcher.test(text) ) )
            return {
              label: text,
              value: text,
              option: this
            };
        }) );
      },
 
      _removeIfInvalid: function( event: any, ui: any ) {
 
        // Selected an item, nothing to do
        if ( ui.item ) {
          return;
        }
 
        // Search for a match (case-insensitive)
        var value = this.input.val(),
          valueLowerCase = value.toLowerCase(),
          valid = false;
        this.element.children( "option" ).each(function() {
          if ( $( this ).text().toLowerCase() === valueLowerCase ) {
            this.selected = valid = true;
            return false;
          }
        });
 
        // Found a match, nothing to do
        if ( valid ) {
          return;
        }
 
        // Remove invalid value
        // this.input
        //   .val( "" )
        //   .attr( "title", value + " didn't match any item" )
        //   .tooltip( "open" );
        this.element.val( "" );
        // this._delay(function() {
        //   this.input.tooltip( "close" ).attr( "title", "" );
        // }, 2500 );
        this.input.autocomplete( "instance" ).term = "";
      },
 
      _destroy: function() {
        this.wrapper.remove();
        this.element.show();
      }
    });
    $(id).combobox();
    // $(id).autocomplete({
    //   source: data
     
    // });
  }


  // student autocomplete
  initStdAutocomplete(inputId, sources){
    let data = sources;
    // console.log(data);
    // debugger;
    $(inputId).autocomplete({
      source: data,
      minLength: 2,
      focus: function( event, ui ) {        
        $(inputId).val(ui.item.label);
        $(inputId).attr('data-value', ui.item.id);
        return false;
      },
      select: function( event, ui ) {       
        $(inputId).val(ui.item.label);
        $(inputId).attr('data-value', ui.item.id);
        return false;
      },
      
      response: function (event, ui) {
        $("#message").remove();
        //$(inputId).val('');
        if (!ui.content.length) {
         $(inputId).after('<sapn id="message" class="small text-danger">No Student found</span>');               
        }
        else {
          $("#message").remove();
        }
      }
    }).autocomplete( "instance" )._renderItem = function( ul, item ) {     
     
      return $( "<li>" )
        .append( "<div>" + item.label + "<br>" + item.username + "</div>" )
        .appendTo( ul );
    };
   
  }
  initStdAutocompleteFocus(inputId){
   $(inputId).autocomplete( "search", "" );
  }

  
  // document view
  initDocumentView(){
    $('#lightbox').modal({
      backdrop: 'static',
      focus: false,
      keyboard: false,
      show: true
    });
  }


  // close modal by id

  closeModalPopup(modalId: any){
    $(modalId).modal('hide');
  }

  // open modal by id
   openModalPopup(modalId: any){
      $(modalId).modal('show');
   }


//  single clear input

clearInput(inputId: any){
  $(inputId).val('');
  $(inputId).next('label').text('Choose File');
}
  
 openPopup(e:any, content){     
   $(e).popover({
    html: true,
    placement: 'auto',
    content: content
   });
   $(e).popover('show');
 }
}