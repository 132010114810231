import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginServiceService } from './login-service.service';


@Injectable({
  providedIn: 'root'
})
export class TeacherService {
  token: string;


  constructor(private http: HttpClient, private loginSrv:LoginServiceService) {

  }
  getHeader = () =>{
    return {
      headers : new HttpHeaders({
        "Authorization" :'Bearer ' + this.loginSrv.getUserToken()
      })
    }
  }
  // create teacher api
  createTeacher(data) {

    return this.http.post(env.apiurl + 'teacher', data, this.getHeader()).toPromise();
  }

  // view teacher api
  viewTeacher(page:number = 1,school:any='',name:any='',email:any='', course:any='', class_id:any='') {
    return this.http.get(env.apiurl + 'teacher?page='+page+'&name='+ name + '&orgId=' + school+ '&email=' + email+'&classId='+class_id+'&courseId='+course, this.getHeader())
  }

  // delete teacher

  deleteTeacher(data) {
    return this.http.delete(env.apiurl + 'users/' + data, this.getHeader())
  }

  // edit teacher api

  editTeacher(data) {
    return this.http.get(env.apiurl + 'teacher/' + data + '/edit', this.getHeader())
  }
  // update teacher api

  updateTeacher(id, data) {
    return this.http.put(env.apiurl + 'teacher/' + id, data, this.getHeader()).toPromise();
  }
  // search api
  getAllTeacherApi(name, grade, school) {
    return this.http.get(env.apiurl + 'teacher?' + name + '&' + grade + '&' + school, this.getHeader())
  }

  getAllTeacher(school) {
    return this.http.get(env.apiurl + 'teacher?'+ '&orgId=' + school + '&allrecords=yes', this.getHeader())
  }

  
}
