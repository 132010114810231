import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { externalJs } from 'src/app/services/external-js.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css']
})
export class EditRoleComponent implements OnInit {
  editRoleList: any;
  id: string;
  moduleList: any;
  allModuleId = [];
  editNewPermissionArray: any[];
  modulePermissionId: any;
  modulesId: any;
  permisionData : '';
  userPermissionData:any;
  viewPage:boolean=false;
  constructor(private xj: externalJs, private service: UserServiceService, private router: ActivatedRoute, private route: Router, private toastr: NotificationService) {
    this.router.queryParamMap.subscribe(queryParams => {
      this.id = queryParams.get("id")
    })
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if(element.module === "Role Management"){
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {
      if(element?.permission === 'update'){
        this.viewPage = true;
      }
    });
  }

  ngOnInit(): void {
    if(!this.viewPage){
      this.route.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    this.goToEdit();
  }
  goToEdit() {
    this.editNewPermissionArray = []
    this.service.editRole(this.id).subscribe(res => {
      this.editRoleList = res['data'];
      this.fetchUserRole();
      this.editRoleForm.patchValue({
        roleName: this.editRoleList.roleName
      })
    })
  }
  // add role form
  editRoleForm = new FormGroup({
    roleName: new FormControl('',)
  })

  // get module with permission data
  fetchUserRole() {
    this.service.getUserModule().subscribe(res => {
      this.moduleList = res['data']
      this.mapPermission();
    })
  }

  mapPermission(){
    this.moduleList.forEach((module:any) => {
      module.permission.forEach((per:any) => {
        per.checked = false;
        this.editRoleList.modules.forEach(element => {
              if(element.moduleId == module.id && element.id == per.id){
                per.checked = true;
              }
        });

      })
        
      });

  }

  //  update role api
  updateRole() {
    this.getData();
    var finalData = []
    var permissionArr = []

    for (let i = 0; i < this.allModuleId.length; i++) {
      var permissionArr = []
      for (let j = 0; j < this.allModuleId.length; j++) {
        if (this.allModuleId[i].moduleId == this.allModuleId[j].moduleId) {
          permissionArr.push({ 'permissionId': this.allModuleId[j].permissionId })
        }
      }

      var request = {
        "moduleId": this.allModuleId[i].moduleId,
        "permissions": permissionArr
      }
      finalData.push(request);
    }
    var elementsArr = finalData.reduce(function (previous, current) {
      var object = previous.filter(object => object.moduleId === current.moduleId);
      if (object.length == 0) {
        previous.push(current);
      }
      return previous;
    }, []);
    const data = {
      "roleName": this.editRoleForm.value.roleName,
      "modules": elementsArr

    }
    this.service.updateRoleModule(this.id, data).subscribe(res => {
      this.toastr.showSuccess("Role updated successfully !!")
      this.route.navigate(['/home/user-role']);
    })
  }

  // get id of module and permission
  getData() {
    this.allModuleId = [];
    this.moduleList.forEach(module => {
      module.permission.forEach(per => {
          if(per.checked){
            this.allModuleId.push({ 'moduleId': module.id, 'permissionId': per.id });
          }
      });
      
    });
    
  }
}
