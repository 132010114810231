import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { StudentService } from 'src/app/services/student.service'
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-edit-parent',
  templateUrl: './edit-parent.component.html',
  styleUrls: ['./edit-parent.component.css']
})
export class EditParentComponent implements OnInit {
  editId: number;
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  orgData:any = [];
  showError = false;
  errorMessages = [];
  userPermissionData: any;
  viewPage: boolean = false;
  isRedirectTo: any = '';
  redirectSchool: any = '';
  isSubmit: boolean = false;
  submitBtn:any;
  isLoading:boolean = true;

  defOpOrg:any ='Select School';
  constructor(private xj:externalJs,  private FB: FormBuilder, private route:ActivatedRoute, private router:  Router,  private serSchool:  OrganisationService, public loginService: LoginServiceService, private toastr: NotificationService,  private srvStd: StudentService) {
    this.editId = parseInt(this.route.snapshot.params.id);
    this.route.queryParamMap.subscribe(queryParams => {     
      if(queryParams.get("redirectTo")){
        this.isRedirectTo = '/home/bulk/view-upload-user'
      } else{
        this.isRedirectTo = '/home/parent';
      } 
      if(queryParams.get('schoolId')){
        this.redirectSchool = queryParams.get('schoolId');
      } else{
        this.redirectSchool= '';      }
     
    })
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if(element.module === "Parents"){
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {      
      if(element?.permission === 'Edit Parent'){
        this.viewPage = true;
      }
    });
   }
  editParentForm = this.FB.group({
    firstName : [''],
    middleName: [''],
    lastName: [''],
    // dob: [''],
    // genderType: [''],
    number: [''],
    email: [''],
    address: [''],
    state:[''],
    city: [''],
    zipCode: [''],
    school: [{value:this.schoolId, disabled:true}],
    statusType: [1]
  })
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
   }
   if(!this.schoolId){
      this.viewOrganisationApi();
   }
    if(this.editId){
      this.getEditData(this.editId);      
    }
   
  }

  viewOrganisationApi() {
    this.serSchool.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg='Select School';
        this.editParentForm.controls['school'].enable();
      } else{
        this.orgData = [];
        this.defOpOrg='No School Found';
      }
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/parent']);
      this.isLoading = false;
    })
  }
  
  getEditData(id:any){
    this.isLoading= true;
    this.srvStd.viewParentItem(id).subscribe((res:any)=> {
      if(res.success){
        this.editParentForm.patchValue({
          firstName: res.data.givenName,
          middleName: res.data.middleName,
          lastName: res.data.familyName,
          // dob: res.data.familyName,
          // genderType: res.data.familyName,
          number: res.data.phone,
          email: res.data.email,
          address: res.data.sms,
          state: res.data.state,
          city: res.data.city,
          zipCode: res.data.zipCode,
          school: res.data.orgSourcedIds,
          statusType: res.data.enabledUser
        })
        this.isLoading=false; 
      } else{
        this.router.navigate(['/home/parent']);
        this.toastr.showError('Something went wrong. Please try later','');       
        this.isLoading=false; 
      }
    },(err:any)=>{
      this.router.navigate(['/home/parent']);
      this.toastr.showError('Something went wrong. Please try later','');        
      this.isLoading=false;
    })

  }
  updateParent(e:any){
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    this.errorMessages = [];
    this.showError = false;
    const formData = {
      givenName: this.editParentForm.value.firstName,
      middleName: this.editParentForm.value.middleName,
      familyName: this.editParentForm.value.lastName,
      email: this.editParentForm.value.email,
      phone: this.editParentForm.value.number,    
      schoolSourcedId: this.editParentForm.value.school ? this.editParentForm.value.school: this.schoolId,
      sms: this.editParentForm.value.address,
      country: this.editParentForm.value.countryCode,
      state: this.editParentForm.value.state,
      city: this.editParentForm.value.city,
      zipCode: this.editParentForm.value.zipCode,
      // grades: '',
      // courseSourcedId: [],
      // agentSourcedIds: [],
      // birthdate: '',
      // sex: '',
      enabledUser: this.editParentForm.value.statusType,
    }
    this.srvStd.updateParent(this.editId, formData).then((res:any) => {
      if(res.status) {
        this.router.navigate(['/home/parent'])
        this.toastr.showSuccess('Parent updated successfully');
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }         
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err: any) => {
      let errData = err.error.errors;
      for (let key in errData) {
        this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }
}
