import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeControllerComponent} from './home-controller/home-controller.component';
import { LoginComponent } from './dashboard/login/login.component';
import { DashboardPageComponent } from './dashboard/dashboard-page/dashboard-page.component';
import { AuthDocumentComponent } from './dashboard/auth-document/auth-document.component';
import { UserComponent } from './dashboard/users/user/user.component';
import { AddUserComponent } from './dashboard/users/add-user/add-user.component';
import { UserRoleComponent } from './dashboard/users/user-role/user-role.component';
import { OrganisationViewComponent } from './dashboard/organisation/organisation-view/organisation-view.component';
import { AddOrganisationComponent } from './dashboard/organisation/add-organisation/add-organisation.component';
import { EditOrganisationComponent } from './dashboard/organisation/edit-organisation/edit-organisation.component';
import { EditUserComponent } from './dashboard/users/edit-user/edit-user.component';
import { ProfileSettingComponent } from './dashboard/profile-setting/profile-setting.component';
import { AddRoleComponent } from './dashboard/users/add-role/add-role.component';
import { TeacherComponent } from './dashboard/teacher/teacher.component';
import { StudentComponent } from './dashboard/student/student.component';

import { ChangePasswordComponent } from './dashboard/change-password/change-password.component';
import { ForgotPasswordComponent } from './dashboard/forgot-password/forgot-password.component';
import { VerifyOtpComponent } from './dashboard/verify-otp/verify-otp.component';
import { ResetPasswordComponent } from './dashboard/reset-password/reset-password.component';
import { EditRoleComponent } from './dashboard/users/edit-role/edit-role.component';
import { SessionListComponent } from './dashboard/acedmin-session/session-list/session-list.component';
import { AddSessionComponent } from './dashboard/acedmin-session/add-session/add-session.component';
import { EditSessionComponent } from './dashboard/acedmin-session/edit-session/edit-session.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './services/auth.guard';

import { DocumentsComponent } from './dashboard/documents/documents.component';
import { ListDocumentComponent } from './dashboard/documents/list-document/list-document.component';
import { CreateDocumentComponent } from './dashboard/documents/create-document/create-document.component';
import { StudentListComponent } from './dashboard/student/student-list/student-list.component';
import { AddStudentComponent } from './dashboard/student/add-student/add-student.component';
import { EditStudentComponent } from './dashboard/student/edit-student/edit-student.component';
import { TeacherListComponent } from './dashboard/teacher/teacher-list/teacher-list.component';
import { AddTeacherComponent } from './dashboard/teacher/add-teacher/add-teacher.component';

import { CoursesComponent } from './dashboard/courses/courses.component';
import { CoursesListComponent } from './dashboard/Courses/courses-list/courses-list.component';
import { CreateCourseComponent } from './dashboard/Courses/create-course/create-course.component';
import { ClassesComponent } from './dashboard/classes/classes.component';
import { ClassesListComponent } from './dashboard/Classes/classes-list/classes-list.component';
import { CreateClassComponent } from './dashboard/Classes/create-class/create-class.component';
import { EditCourseComponent } from './dashboard/courses/edit-course/edit-course.component';
import { EditClassComponent } from './dashboard/classes/edit-class/edit-class.component';
import { EditTeacherComponent } from './dashboard/teacher/edit-teacher/edit-teacher.component';

import { BulkuploadComponent } from './dashboard/bulkupload/bulkupload.component';
import { UploadbulkcsvComponent } from './dashboard/bulkupload/uploadbulkcsv/uploadbulkcsv.component';
import { UploadedDcoumentComponent } from './dashboard/bulkupload/uploaded-dcoument/uploaded-dcoument.component';
import { UploadbulkdocumentComponent } from './dashboard/bulkupload/uploadbulkdocument/uploadbulkdocument.component';
import { BulkUploadUserComponent } from './dashboard/bulkupload/bulk-upload-user/bulk-upload-user.component';
import { BulkViewUploadComponent } from './dashboard/bulkupload/bulk-view-upload/bulk-view-upload.component';
import { DocumentListComponent } from './document-list/document-list.component';

import { MasterComponent } from './dashboard/master/master.component';
import { GradeListComponent } from './dashboard/master/grade-list/grade-list.component';
import { AddGradeComponent } from './dashboard/master/add-grade/add-grade.component';
import { DocumentTypeListComponent } from './dashboard/master/document-type-list/document-type-list.component';
import { AddDocumentTypeComponent } from './dashboard/master/add-document-type/add-document-type.component';
import { FtpSettingListComponent } from './dashboard/master/ftp-setting-list/ftp-setting-list.component';
import { AddFtpSettingComponent } from './dashboard/master/add-ftp-setting/add-ftp-setting.component';

import { ParentsComponent } from './dashboard/parents/parents.component';
import { ParentListComponent } from './dashboard/parents/parent-list/parent-list.component';
import { EditParentComponent } from './dashboard/parents/edit-parent/edit-parent.component';

import { data } from 'jquery';
import { FailedUserComponent } from './dashboard/bulkupload/failed-user/failed-user.component';
import { TeacherChildDocComponent } from './dashboard/teacher-child-doc/teacher-child-doc.component';
import { DocListComponent } from './dashboard/teacher-child-doc/doc-list/doc-list.component';
import { CreateDocComponent } from './dashboard/teacher-child-doc/create-doc/create-doc.component';
import { SettingsComponent } from './dashboard/settings/settings.component';



const routes: Routes = [
  // {
  //   path: '', component:HomeControllerComponent
  // },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: '', redirectTo:'/login', pathMatch:'full'
  },
  // { path: '**', redirectTo: 'login' },

  {
    path: 'forgot-password', component: ForgotPasswordComponent
  },
  { path: 'verify-otp', component: VerifyOtpComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: 'document-list', component: DocumentListComponent
  },
  { path: 'home', component: DashboardComponent, canActivate : [AuthGuard],
  children: [
      {
        path: 'user', component: UserComponent, data: {title: 'User Management'}
      },
      {
        path: 'add-user', component: AddUserComponent, data: {title: 'Add User'}
      },
      {
        path: 'user-role', component: UserRoleComponent, data: {title: 'User Role'}
      },
      {
        path: 'add-role', component: AddRoleComponent, data: {title: 'Add Role'}
      },
      {
        path: 'edit-role', component: EditRoleComponent, data: {title: 'Edit Role'}
      },
      {
        path: 'edit-user', component: EditUserComponent, data: {title: 'Edit User'}
      },
      {
        path: 'dashboard', component: DashboardPageComponent, data: {title: 'Dashboard'}
      },
      {
        path: 'auth-document', component: AuthDocumentComponent, data: {title: 'Change Password'}
      },
      {
        path: 'school', component: OrganisationViewComponent, data: {title: 'School'}
      },
      {
        path: 'add-school', component: AddOrganisationComponent, data: {title: 'Add School'}
      },
      {
        path: 'edit-school', component: EditOrganisationComponent, data: {title: 'Edit School'}
      },
      {
        path: 'profile-setting', component: ProfileSettingComponent, data: {title: 'Profile'}
      },
      {
        path: 'teacher', component: TeacherComponent, 'children': [
          { path: '', component: TeacherListComponent, data: {title: 'Teacher List'} },
          { path: 'add', component: AddStudentComponent, data: {title: 'Add teacher'} },
          { path: 'edit', component: EditTeacherComponent, data: {title: 'Edit Teacher'} },
        ]
      },
      {
        path: 'student', component: StudentComponent, 'children': [
          { path: '', component: StudentListComponent, data: {title: 'Student List'} },
          { path: 'add', component: AddStudentComponent, data: {title: 'Add Student'} },
          { path: 'edit', component: EditStudentComponent, data: {title: 'Edit Student'} },
        ]
      },
      {
        path: 'change-password', component: ChangePasswordComponent, data: {title: 'Change Password'}
      },
      // {
      //   path: 'create_student', component: CreateStudentComponent
      // },
      // {
      //   path: 'edit_student', component: EditStudentComponent
      // },
      {
        path: 'change-password', component: ChangePasswordComponent
      },
      {
        path: 'academic-session', component: SessionListComponent, data: {title: 'Academic Session'}
      },
      {
        path: 'academic-session/:page', component: SessionListComponent, data: {title: 'Academic Session'}
      },
      {
        path: 'add-academic-session', component: AddSessionComponent, data: {title: 'Add Academic Session'}
      },
      {
        path: 'edit-academic-session', component: EditSessionComponent , data: {title: 'Edit Academic Session'}
      },
      {
        path: 'class-list', component: ClassesListComponent , data: {title: 'Classes List'}
      },
      {
        path: 'classes', component: ClassesComponent , data: {title: 'Classes'} 
      },
      {
        path: 'create-class', component: CreateClassComponent , data: {title: 'Create Class'}
      },
      {
        path: 'edit-class', component: EditClassComponent , data: {title: 'Edit Class'}
      },
      {
        path: 'document', component: DocumentsComponent, 'children': [
          { path: '', component: ListDocumentComponent, data: {title: 'Documents'} },
          { path: 'upload', component: CreateDocumentComponent, data: {title: 'Upload Document'} },
        ]
      },
      {
        path: 'course', component: CoursesComponent, 'children': [
          { path: '', component: CoursesListComponent, data: {title: 'Course List'} },
          { path: 'add', component: CreateCourseComponent, data: {title: 'Add Course'} },
          { path: 'edit', component: EditCourseComponent, data: {title: 'Edit Course'} },
        ]
      },
      {
        path: 'bulk', component: BulkuploadComponent, 'children': [
        { path: 'uploaddocument', component: UploadbulkdocumentComponent, data: {title: 'Bulk Upload Documents'} },
        { path: 'uploaded-document', component: UploadedDcoumentComponent, data: {title: 'Bulk Upload Document'} },
        { path: 'upload-user', component: BulkUploadUserComponent, data: {title: 'Bulk Upload User'} },
        { path: 'view-upload-user', component: BulkViewUploadComponent, data: {title: 'View Bulk Upload'} },
        { path: 'uploadcsv', component: UploadbulkcsvComponent, data: {title: 'Bulk Upload CSV'} },
      ]
    },

    {
      path:'master', component:MasterComponent, 'children': [
        {path: 'grade-list', component: GradeListComponent, data: {title: 'Grade List'} },
        {path: 'add-grade', component: AddGradeComponent, data: {title: 'Add Grade'} },
        {path: 'edit-grade/:id', component: AddGradeComponent, data: {title: 'Edit Grade'} },
        {path: 'doc-type-list', component: DocumentTypeListComponent, data: {title: 'Document Type List'} },
        {path: 'add-doc-type', component: AddDocumentTypeComponent, data: {title: 'Add Document Type'} },
        {path: 'edit-doc-type/:id', component: AddDocumentTypeComponent, data: {title: 'Edit Document Type'} },
        {path: 'ftp-list', component: FtpSettingListComponent, data: {title: 'FTP List'} },
        {path: 'add-ftp', component: AddFtpSettingComponent, data: {title: 'Add Ftp Setting'} },
        {path: 'edit-ftp/:id', component: AddFtpSettingComponent, data: {title: 'Edit Ftp Setting'} },

      ]
    },

    {
      path: 'parent', component:  ParentsComponent, 'children': [
        { path: '', component: ParentListComponent, data: {title: 'Parent List'} },
        { path: 'add', component: AddStudentComponent, data: {title: 'Add Parent'} },
        { path: 'edit/:id', component: EditParentComponent, data: {title: 'Edit Parent'} },
      ]
    },

    {
      path: 'failed-user', component: FailedUserComponent, data: { title: 'Failed User List'}
    },

    {
      path: 'settings', component: SettingsComponent, data: { title: 'Settings'}
    },
    // teacher child
    {
      path: 'mychild', component: TeacherChildDocComponent, 'children': [
        { path: '', component: DocListComponent, data: { title: 'My Child Document'} },
        { path: 'upload', component: CreateDocComponent, data: { title: 'Upload Child Document'} }
      ]
    }

  ]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
