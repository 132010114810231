<div id="main-wrapper">
    <!--  -->
    <app-sidebar [addLink]="currentAddLnk" [addLinkTxt]="currAddLnkTxt"></app-sidebar>
    <!-- sidebar menu -->
    <!-- content-body -->
    <div class="content-body">
        <div class="container-fluid">
            <div class="card-v-group card-form">
                
                    <!-- loader -->
                    
                    <!-- /loader -->
                <!-- <div class="card shadow-none">
                    <div class="card-header header-grid">
                        <h5 class="card-title text-dark">Uploaded Documents</h5>                     
                    </div>
                </div> -->
                <form>                   
                    <div class="card-table mb-0 {{ (showLoader)? 'loading' : ''}}">
                        <div class="apploader {{ (showLoader)? 'show' : ''}}">
                            <div class="loader">
                                <div class="spinner-border" role="status"></div>
                                <span class="d-block mt-3">Loading...</span>
                            </div>
                        </div>
                        <div class="table-head">
                            <form class="row align-items-md-end">
                                <div class="col align-self-md-center">
                                    <div class="form-inline span-5">
                                        <div class="form-group mb-1" *ngIf="loginService.getCurrentUserRole().slug != 'principal' && loginService.getCurrentUserRole().slug != 'student' && loginService.getCurrentUserRole().slug != 'teacher'">
                                            <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">School : </label>
                                            <select class="form-input" name="schoolId" [(ngModel)]="schoolId" (change)="filterSchool($event.target.value)">
                                                <option value="">All</option>
                                                <option value="{{ school.id }}" *ngFor="let school of orgData">{{ school.name }}</option>
                                           </select>                                                
                                        </div>
                                        <div class="form-group mb-1" >
                                            <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">Documents Type : </label>
                                            <select class="form-input" name="currentDocType" [(ngModel)]="currentDocType">
                                                <option value="">All</option>
                                                <option value="{{ doc.id }}" *ngFor="let doc of docTypes">{{ doc.name }}</option>
                                           </select>                                                
                                        </div>
                                        <div class="form-group mb-1" >
                                            <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">Course : </label>
                                            <select class="form-input" name="currentCourseId" [(ngModel)]="currentCourseId">
                                                <option value="">All</option>
                                                <option value="{{ course.id }}" *ngFor="let course of courseData">{{ course.title }}</option>
                                           </select>                                                
                                        </div>
                                        
                                        <!-- <div class="form-group mb-1" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident"'>
                                            <label for="table_organization" class="fw-500 text-muted small w-100 justify-content-start mb-1">School : </label>
                                            <select class="form-input" name="" id="organisation">
                                                <option value="">Select School</option>
                                                <option *ngFor="let i of orgData;" value="{{i.id}}">{{ i.name }}</option>
                                           </select>                                                
                                        </div>    
                                                                     -->
                                        <div class="form-group mb-1">
                                            <label class="fw-500 text-muted small w-100 justify-content-start mb-1"> <br> </label>
                                            <a class="btn-add" (click)="filterDocuments()">Filter</a>   
                                            <button class="btn-reset" type="reset" (click)="clearFilter()">
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                </form>
                        </div>
                        <div class="table-responsive"  *ngIf="message && !showLoader">
                            <p class="text-center not-found">
                                <i class="bi bi-cloud-upload"></i>
                                {{ message }}
                            </p>
                        </div>
                        <div class="table-responsive"  *ngIf="!message && !showLoader">
                            <table class="table table-sm table-bordered sortable datatable nowraptable">
                                <thead>
                                <tr class="table-header">                                       
                                        <th class="text-dark">S.No.</th>
                                        <th class="text-dark no-sort">Document</th>
                                        <th class="text-dark">Document Type</th>
                                        <th class="text-dark">Student Name</th>
                                        <th class="text-dark">School</th>
                                        <th class="text-dark">Course</th>
                                        <th class="text-dark">Grade</th>
                                        <th class="text-dark">School Year</th>    
                                        <th class="text-dark">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor ="let docItem of docItems; let index = index">                                       
                                        <td>{{(index + 1) + (currentPage - 1) * tableDataPerPage}}</td>
                                        <td>
                                            <button class="previewBtn"  (click)="openGallery(index, docItems)">
                                                <img [src]="(docItem.fileType === 'pdf') ? 'assets/images/pdf.png' : docItem.documentLocalPath" alt="Image {{ index + 1 }}"  height="60px" width="60px">
                                            </button>
                                        </td>
                                        <td>{{docItem.documentTypeName}}</td>
                                        <td style="position: relative;"> 
                                            {{docItem.studentName}}
                                            <ng-container *ngIf="!docItem.studentName">
                                            
                                            <!-- <input type="text" #searchStudent value="{{ docItem?.selectedStudent?.givenName }} {{ docItem?.selectedStudent?.middleName }} {{ docItem?.selectedStudent?.familyName }}" (click)="serchStudents($event, index)" (keyup)="serchStudents($event, index)" placeholder="Enter name" /> -->
                                            <input type="text"  id="studentDrop{{(index + 1) + (currentPage - 1) * tableDataPerPage}}" class="form-control form-control-sm autocomplete" (blur)="selectStudent($event.target)" data-value="" [attr.data-org]="docItem.orgSourcedId" [attr.data-course]="(docItem.courseSourcedId) ? docItem.courseSourcedId : ''" [attr.data-grade]="(docItem.grade) ? docItem.grade.id : ''" placeholder="Student">
                                            <!-- <span *ngIf="docItem.showStudentList" class="close-student-list" (click)="closeStudentList(index)" >X</span>
                                            <ul class="student-list" *ngIf="docItem.showStudentList">
                                                <li *ngFor="let student of docItem.serchStudentList" (click)="selectStudent(student, index)">
                                                   {{ student.givenName }} {{ student.middleName }} {{ student.familyName }}
                                                   <span>{{ student.username }}</span>
                                                </li>
                                            </ul> -->
                                           </ng-container>
                                        </td>
                                        <td>{{docItem.orgName}}</td>
                                        <td>{{docItem.courseName}}</td>
                                        <td>{{(docItem.grade) ? docItem.grade.grade : ''}}</td>
                                        <td>{{ docItem?.docYear }}</td>                                        
                                        <td>                                           
                                            <button *ngIf="!docItem.studentName" class="save-btn" (click)="saveDoc(index, $event)">Save <span class="spinner-border spinner-border-sm ml-2 d-none" role="status" aria-hidden="true"></span></button>
                                            <a *ngIf="loginService.getCurrentUserRole().slug != 'super-admin' && loginService.getCurrentUserRole().slug != 'superident'"  class="btn btn-sm btn-success ml-2" (click)="forwardPopup(docItem.id, docItem.orgSourcedId, (docItem.courseSourcedId) ? docItem.courseSourcedId : '', (docItem.grade) ? docItem.grade.id : '' )"><i class="bi bi-reply-all"></i></a>
                                        </td>
                                    </tr>                               
                                </tbody>
                            
                            </table>
                        </div>
                        <div class="table-footer" *ngIf="!message && !showLoader"> 
                            <div class="row">
                                <div class="col-md-auto col-12 align-self-center ml-auto">
                                    <div class="justify-content-md-end">
                                        <div class="text-md-right">
                                            <div class="dropdown-select">
                                                <!-- <div class="dataTables_length" id="sortable_length">
                                                    <label>
                                                        <span class="mb-0">Rows per page:</span>
                                                        <select name="sortable_length">
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                        </select>
                                                    </label>
                                                </div> -->
                                                <div>
                                                    <div class="dataTables_info" id="sortable_info" role="status" aria-live="polite">{{ tableDataFrom }} - {{ tableDataTo }} of
                                                        {{tableDataTotal}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div class="col-md-auto" *ngIf="totalPages?.length > 1">
                                    <div class="pagination dataTables_paginate" id="sortable_paginate">
                                        <a class="paginate_button previous " [ngClass]="{'disabled': currentPage == 1}" (click)="changeCurrentPage(currentPage - 1)">
                                            <i class="bi bi-chevron-left"></i>
                                        </a>
                                        <span>
                                            <ng-container *ngFor="let page of totalPages; let i = index;">
                                                <a *ngIf="page !== '...'" (click)="changeCurrentPage(page)" [ngClass]="page == currentPage ? 'current paginate_button':'paginate_button'"   >{{ page }}</a>
                                                <span *ngIf="page === '...'"   class="paginate_button"   >{{ page }}</span>
                                            </ng-container>                                        
                                        </span>
                                        <a class="paginate_button next" [ngClass]="{'disabled': currentPage == totalPages.at(-1)}" (click)="changeCurrentPage(currentPage + 1)">
                                            <i class="bi bi-chevron-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- content-body -->
</div>

 <!-- lightbox gallery start -->
 <div class="lg-container" [ngClass]="showLightBox ? 'lg-show' : ''" *ngIf="showLightBox">
    <div class="lg-backdrop"></div>
    <div class="lg-outer no-thumbnail">
        <div class="lg-content">
            <div class="lg-inner">
                <div class="lg-item">
                    <div class="d-flex justify-content-center previewLoader" *ngIf="previewLoading">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                    </div>                       
                    <div class="" [ngClass]="(previewSRCType === 'pdf') ? 'lg-pdf-wrap':'lg-img-wrap'">
                        <picture class="lg-img-wrap" *ngIf="previewSRCType !== 'pdf'"> 
                            <img class="lg-object lg-image image-lg-doc" [src]="imgSrc" default="assets/images/doc-icon-lg.png">
                        </picture>                             
                        <div class="embed-responsive embed-responsive-1by1" *ngIf="previewSRCType === 'pdf'">
                            <iframe [src]="imgSrc"  class="embed-responsive-item"></iframe>
                        </div>                    
                    </div>
                </div>
            </div>

            <button type="button" class="lg-prev lg-icon" *ngIf="docItems.length >1" (click)="prevImage()">
                <i class="bi bi-arrow-left"></i>
            </button>
            <button type="button" class="lg-next lg-icon" *ngIf="docItems.length >1" (click)="nextImage()">
                <i class="bi bi-arrow-right"></i>
            </button>
        </div>
        <div class="lg-toolbar">
            <div class="lg-heading">
                Clinton School Document Preview
            </div>            
            <button type="button" class="lg-close lg-icon" (click)="closeLightBox($event)">
                <i class="bi bi-x"></i>
            </button>
            <div class="lg-counter">
                <span class="lg-counter-current">{{ currentImageIndex + 1 }}</span> /
                <span class="lg-counter-all">{{ currentDocLength }}</span>
            </div>
            <div class="lg-counter">
                 
            </div>
        </div>
        <div class="lg-sub-html">
            <form [formGroup]="editBulkDoc" class="position-relative {{ (isLoading)? 'loading' : ''}}" (ngSubmit)="submit()">
                <div class="apploader {{ (isLoading)? 'show' : ''}}">
                    <div class="loader">
                        <div class="spinner-border" role="status"></div>
                        <span class="d-block mt-3">Loading...</span>
                    </div>
                </div>
                <input type="hidden" formControlName="docId">
                <div class="form-group">
                    <label for="docTYpe">Document Type</label>
                    <select class="form-control form-control-sm" readonly id="docType" formControlName="docType">
                        <option value="">Select Type</option>
                        <option *ngFor="let i of docTypes;" value="{{i.id}}">{{ i.name }}</option>
                    </select>
                    
                </div>
                
                <div class="form-group" *ngIf='loginService.getCurrentUserRole().slug == "super-admin" || loginService.getCurrentUserRole().slug == "superident" '>
                    <label for="school">School</label>
                    <select class="form-control form-control-sm" readonly formControlName="school" id="school" (change)="onschoolChange($event.target.value)">
                        <option value="">Select School</option>
                        <option *ngFor="let i of orgData;" [value]="i.id">{{ i.name }}</option>
                    </select>
                    <div *ngIf="submitted && f.school.errors" class="invalid-feedback">
                        <div *ngIf="f.school.errors.required">School is required</div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="grade">Grade</label>
                    <select id="grade" class="form-control form-control-sm" formControlName="grade" (change)="onGradeChange($event.target.value)">
                        <option value="">Select Grade</option>
                        <option *ngFor="let item of gradeItems" [ngValue]="item.id">{{ item.grade }}</option>
                    </select>
                    <div *ngIf="submitted && f.grade.errors" class="invalid-feedback">
                        <div *ngIf="f.grade.errors.required">Grade is required</div>
                    </div>
                </div>
               
                <div class="form-group">
                    <label for="course">Course</label>
                     <select class="form-control form-control-sm" formControlName="course" id="course" (change)="onCourseChange($event.target.value)">
                        <option value="">Select Course</option>
                        <option *ngFor="let i of courseData;" value="{{i.id}}">{{ i.title }}</option>
                   </select>
                   <div *ngIf="submitted && f.course.errors" class="invalid-feedback">
                    <div *ngIf="f.course.errors.required">Course is required</div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="studentName">Student Name</label>
                    <select class="form-control form-control-sm" formControlName="studentName" id="studentName">
                        <option value="">Select Student Name</option>
                        <option *ngFor="let i of studentData;" value="{{i.id}}">{{ i.givenName }} {{ i.middleName }} {{ i.familyName }} <br> <span class="extra-info-option">({{ i.email }})</span></option>
                   </select>
                   <div *ngIf="submitted && f.studentName.errors" class="invalid-feedback">
                        <div *ngIf="f.studentName.errors.required">Student is required
                    </div>
                </div>
                </div>

                <div class="form-group">
                    <label for="session">School Year</label>
                    <input type="text" class="form-control" formControlName="year" id="year" placeholder="YYYY">
                   
                </div>

                <!-- <div class="form-group">
                    <label for="session">Academic Session</label>
                    <select class="form-control form-control-sm" formControlName="session" id="session">
                        <option value="">Select Academic Session</option>
                        <option *ngFor="let i of sessionData;"  value="{{i.id}}">{{ i.title }} - ({{ i.schoolYear }})</option>
                   </select>
                   <div *ngIf="submitted && f.session.errors" class="invalid-feedback">
                        <div *ngIf="f.session.errors.required">Session is required</div>
                    </div>
                </div> -->

                <div class="form-group">
                    <label for="comment">Comment</label>
                    <textarea class="form-control" formControlName="comment"></textarea>
                    <div *ngIf="submitted && f.comment.errors" class="invalid-feedback">
                        <div *ngIf="f.comment.errors.required">Comment is required</div>
                    </div>
                </div>

                <div class="form-group" *ngIf="this.loginService.getCurrentUserRole().slug !== 'super-admin' &&  this.loginService.getCurrentUserRole().slug !== 'superident'">
                    <label for="grade">Forward To</label>
                    <select class="form-control form-control-sm" formControlName="forwardToId">
                        <option value="">Select User</option>
                        <option *ngFor="let forward of forwardToWhom;" [value]="forward.id">
                            {{ forward.givenName }} {{ forward.middleName }} {{ forward.familyName }} - {{ (forward.role === '10') ? 'Principal' : ((forward.role === '9') ? 'Superident': 'Secratery')  }}
                        </option>
                    </select>
                </div>

                <!-- <div class="form-group">
                    <label for="grade">isApproved</label>
                    <div>
                        <label class="toggle-btn toggle-sm" [ngClass]="{'active': docItems[currentImageIndex].isApproved === 1}" for="list-{{currentImageIndex + 1}}">
                            <input type="checkbox" class="cb-value" id="list-{{currentImageIndex + 1}}"  [attr.checked]="docItems[currentImageIndex].isApproved === 1 ? true : false" (change)="approveDoc(docItems[currentImageIndex].id)" formControlName="status"/>
                            <span class="round-btn"></span>
                        </label>
                    </div>
                </div> -->
                <div class="form-group">
                    <button type="submit" class="btn btn-sm btn-success" [disabled]="creating">Update Document 
                        <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true" *ngIf="creating"></span>
                    </button>
                </div>
            </form>
        </div>       
    </div>
</div>
<!-- end lightbox gallery end -->

<!-- document approve  start -->
<div class="modal fade addEditRole" id="approveModal" tabindex="-1" role="dialog" aria-hidden="true">    
    <div class="modal-dialog modal-dialog-centered">
        <form class="w-100" [formGroup]="forwardForm">
            <div class="modal-content">
                <div class="modal-header modal-heading">Forward Document</div>                    
                <div class="modal-body">
                    <div class="form-group">
                        <label>Student</label>
                        <input type="text" formControlName="forwardUser" id="student" class="form-control" (blur)="getValue($event.target.value)" placeholder="Student">
                        <!-- <select class="form-control" id="student" formControlName="forwardUserId">
                            <option value="">Select Student</option>
                            <option *ngFor="let item of dropStudent;" [value]="item.id">
                                {{ item.givenName }} {{ item.middleName }} {{ item.familyName }} - {{ item.email }}
                            </option>
                        </select> -->
                    </div>
                    <div class="form-group">
                        <label>Forward To</label>
                        <select class="form-control" formControlName="forwardToId">
                            <option value="">Select User</option>
                            <option *ngFor="let forward of forwardToWhom;" [value]="forward.id">
                                {{ forward.givenName }} {{ forward.middleName }} {{ forward.familyName }} - {{ (forward.role === '10') ? 'Principal' : ((forward.role === '9') ? 'Superident': 'Secratery')  }}
                            </option>
                        </select>
                    </div>
                    
                </div>
                <div class="modal-footer">                          
                    <button type="button" class="btn btn-secondary user-btn" (click)="forwardPopupClose()">Cancel</button>
                    <button type="submit" class="btn btn-success user-btn" (click)="forwardAction($event)" [disabled]="!forwardForm.valid">Submit  <span class="spinner-border spinner-border-sm d-none ml-2" role="status" aria-hidden="true"></span></button>
                </div>   
                            
            </div>
        </form>
    </div>
</div>
<!-- document approve end -->