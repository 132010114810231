import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { AcademicService } from 'src/app/services/academic.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { CourseService } from 'src/app/services/course.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClassService } from 'src/app/services/class.service';
import { NotificationService } from 'src/app/services/notification.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import { MasterService } from 'src/app/services/master.service';
import { ThrowStmt } from '@angular/compiler';
import { TeacherService } from 'src/app/services/teacher.service';
declare var $: any;
@Component({
  selector: 'app-create-document',
  templateUrl: './create-document.component.html',
  styleUrls: ['./create-document.component.css']
})
export class CreateDocumentComponent implements OnInit {
  createDocForm: FormGroup;
  submitted = false;

  constructor(private xj: externalJs, private formBuilder: FormBuilder, private serviceAcademic: AcademicService, private serviceOrganisation: OrganisationService,
    public loginService: LoginServiceService, private classServ: ClassService, private serviceCourse: CourseService, private router: Router, private toastr: NotificationService, private masterService: MasterService, private teacher: TeacherService) { }
  sessionData: any = [];
  orgData: any = [];
  course = [];
  students = [];
  dropdownList = [];
  studentData = [];
  cities = [];
  selectedItems = [];
  dropdownSettings = {};
  docTypes: any = [];
  //dropdownSettings:IDropdownSettings;
  closeDropdownSelection = false;
  disabled = false;

  gradeItems: any = [];


  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  currentGrade: any = '';
  currentCourse: any = '';

  creating: any = false;
  createAlert: any = { status: false, msg: '' }


  //  student condition
  studentId: any = '';

  isApproved: any;

  parentChild: any = [];
  showError: boolean = false;
  errorMessages: any = [];

  // loader is
  gradeLoad: boolean = false;
  courseLoad: boolean = false;
  studLoad: boolean = false;
  isLoading: boolean = true;
  defOpOrg: any = 'Select School';
  defOpType: any = 'Select Type';
  defOpSess: any = 'Select Session';
  defOpGrade: any = 'Select Grade';
  defOpCourse: any = 'Select Course';
  defOpStd: any = 'Select Student';
  defOpassignedTo: any = 'Select Teacher';
  assignedTo: any = [];
  assignedToLoading: boolean = false;
  isExp: boolean = false;

  ngOnInit(): void {
    this.xj.extraInit();
    // this.sessionList();
    if (this.schoolId) {
      this.courseLoad = true;
      this.gradeLoad = true;
      this.getCourse(this.schoolId);
      this.getGradeItems(this.schoolId);
    } else {
      this.getSchool();
    }

    // 
    this.getDocumentType();
    // 
    if (this.loginService.getCurrentUserRole().slug == "student") {
      this.isApproved = 0;
      this.studentId = parseInt(localStorage.getItem('userid'));
    } else if (this.loginService.getCurrentUserRole().slug == "parent") {
      this.isApproved = 0;
    } else {
      this.isApproved = 0;
    }
    //form validations

    this.createDocForm = this.formBuilder.group({
      name: [this.studentId],
      organisation: [this.schoolId],
      course: [''],
      session: [''],
      grade: [''],
      document_type: [''],
      comment: [''],
      certificate: [''],
      fileSource: [''],
      year: [''],
      assignedTo: [''],
      isApproved: [this.isApproved]

    })
    this.createDocForm.controls['organisation'].disable();
    this.createDocForm.controls['grade'].disable();
    this.createDocForm.controls['course'].disable();
    this.createDocForm.controls['name'].disable();
    this.createDocForm.controls['session'].disable();
    this.createDocForm.controls['document_type'].disable();
    this.createDocForm.get('year').setValue(new Date().getFullYear());


    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };




  }

  // get document type
  getDocumentType() {
    this.serviceCourse.getdocTypes().subscribe(res => {
      if (res['status']) {
        this.docTypes = res['data'];
        this.defOpType = 'Select Type';
        this.createDocForm.controls['document_type'].enable();
      } else {
        this.docTypes = [];
        this.defOpType = 'No Document Type Found';
      }
    }, (err: any) => {
      this.toastr.showError('Something went wrong. Please try later', '');
      this.router.navigate(['/home/document']);
    })
  }

  getSchool() {
    this.orgData = [];
    this.serviceOrganisation.allSchool().subscribe((res: any) => {
      if (res.status) {
        this.orgData = res.data;
        this.defOpOrg = 'Select School';
        this.createDocForm.controls['organisation'].enable();
      } else {
        this.orgData = [];
        this.defOpOrg = 'No School Found';
      }
      this.isLoading = false;
    }, (err: any) => {
      this.toastr.showError('Something went wrong. Please try later', '');
      this.router.navigate(['/home/document']);
      this.isLoading = false;
    })
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }


  //school change

  onschoolChange(orgid) {
    this.schoolId = orgid;
    this.currentGrade = '';
    this.currentCourse = '';
    if (orgid) {
      this.gradeLoad = true;
      this.courseLoad = true;
    }
    this.createDocForm.get('grade').setValue('');
    this.createDocForm.get('course').setValue('');
    this.createDocForm.get('name').setValue('');
    this.createDocForm.controls['grade'].disable();
    this.createDocForm.controls['course'].disable();
    this.createDocForm.controls['name'].disable();
    this.getCourse(orgid);
    this.getGradeItems(orgid);
    this.studentData = [];
  }

  //get courses based on the org id
  getCourse(orgId) {
    this.course = [];
    this.serviceCourse.getAllCoursebyOrgid(orgId).subscribe(res => {
      if (res['status']) {
        this.course = res['data'];
        this.defOpCourse = 'Select Course';
        this.createDocForm.controls['course'].enable();
      } else {
        this.course = [];
        if (!this.createDocForm.controls['organisation'].value) {
          this.defOpCourse = 'Select Course';
        } else {
          this.defOpCourse = 'No Course Found';
        }
      }
      this.courseLoad = false;
      if (this.isLoading) {
        this.isLoading = false;
      }
    }, (err: any) => {
      this.toastr.showError('Something went wrong. Please try later', '');
      this.router.navigate(['/home/document']);
      this.isLoading = false;
      this.courseLoad = false;
    })
  }

  //get courses based on the org id
  getAssignedTo(orgId) {
    this.assignedToLoading = true;
    this.assignedTo = [];
    this.teacher.getAllTeacher(orgId).subscribe((res: any) => {
      if (res.status) {
        this.assignedTo = res.data;
        this.defOpassignedTo = 'Select Teacher';
        this.createDocForm.controls['assignedTo'].enable();
      } else {
        this.assignedTo = [];
        this.defOpassignedTo = 'No Teacher Found';
      }
      this.assignedToLoading = false;
      if (this.isLoading) {
        this.isLoading = false;
      }
    }, (err: any) => {
      this.toastr.showError('Something went wrong. Please try later', '');
      this.router.navigate(['/home/document']);
      this.isLoading = false;
      this.assignedToLoading = false;
    })
  }

  getGradeItems(schoolId: any) {
    this.gradeItems = [];
    this.masterService.allGradeList(schoolId).subscribe((res: any) => {
      if (res.status) {
        this.gradeItems = res.data;
        this.defOpGrade = 'Select Grade';
        this.createDocForm.controls['grade'].enable();
      } else {
        this.gradeItems = [];
        if (!this.createDocForm.controls['organisation'].value) {
          this.defOpGrade = 'Select Grade';
        } else {
          this.defOpGrade = 'No Grade Found';
        }
      }
      this.gradeLoad = false;
    }, (err: any) => {
      this.toastr.showError('Something went wrong. Please try later', '');
      this.router.navigate(['/home/document']);
      this.isLoading = false;
    })
  }

  //get the class name by school id
  getclassName(orgId) {
    this.classServ.getClassdetails(orgId).subscribe(res => {
      if (res['status']) {
        this.course = res['data']['data'];
      }
    })
  }

  // onGradeChange
  onGradeChange(GradeId) {
    this.currentGrade = GradeId;
    if (this.studentId === null || this.studentId === undefined || !this.studentId) {
      this.studLoad = true;
      this.createDocForm.controls['name'].disable();
      this.getStudents(this.schoolId, this.currentGrade, this.currentCourse);
      this.getParentChild(this.schoolId, this.currentGrade, this.currentCourse);
    }
  }
  //course change
  oncourseChange(courseId) {
    if (courseId) {
      this.currentCourse = courseId;
      if (this.studentId === null || this.studentId === undefined || !this.studentId) {
        this.studLoad = true;
        this.createDocForm.controls['name'].disable();

        this.getStudents(this.schoolId, this.currentGrade, this.currentCourse);
        this.getParentChild(this.schoolId, this.currentGrade, this.currentCourse);
      }
      const index = this.course.findIndex(p => p.id == courseId);
      if (this.course[index].isExp) {
        this.isExp = true;
        this.getAssignedTo(this.schoolId);
      } else {
        this.isExp = false;
        this.createDocForm.controls['assignedTo'].setValue('');
      }
    } else{
      this.isExp = false;
      this.createDocForm.controls['assignedTo'].setValue('');
    }
    // console.log(courseId);
  }

  //get students based on course id
  getStudents(school: any, grade: any, courseId: any) {
    if (this.loginService.getCurrentUserRole().slug !== 'parent') {
      if (courseId !== '') {
        grade = '';
      }
      this.studentData = [];
      this.serviceCourse.getAllStudents(school, grade, courseId).subscribe(res => {
        if (res['status']) {
          this.studentData = res['data'];
          this.defOpStd = 'Select Student';
          this.createDocForm.controls['name'].enable();
        } else {
          this.studentData = [];
          this.defOpStd = 'No Student Found';
        }
        this.studLoad = false;
      }, (err: any) => {
        this.toastr.showError('Something went wrong. Please try later', '');
        this.router.navigate(['/home/document']);
        this.isLoading = false;
      })
    }
  }


  // get parents child 
  getParentChild(school: any, grade: any, courseId: any) {
    if (this.loginService.getCurrentUserRole().slug === 'parent') {
      if (courseId !== '') {
        grade = '';
      }
      this.parentChild = [];
      this.serviceCourse.getAllStudetnsbyParent(school, grade, courseId).subscribe((res: any) => {
        if (res.status) {
          this.parentChild = res.data;
          this.defOpStd = 'Select Student';
          this.createDocForm.controls['name'].enable();
        } else {
          this.parentChild = [];
          this.defOpStd = 'No Student Found';
        }
        this.studLoad = false;
      }, (err: any) => {
        this.toastr.showError('Something went wrong. Please try later', '');
        this.router.navigate(['/home/document']);
        this.isLoading = false;
      })
    }
  }



  // get session list

  sessionList() {
    this.serviceAcademic.getAllSession().subscribe(res => {
      if (res['status']) {
        this.sessionData = res['data'];
        this.defOpSess = 'Select Session';
        this.createDocForm.controls['session'].enable();
      } else {
        this.sessionData = [];
        this.defOpSess = "No Session Found";
      }
    }, (err: any) => {
      this.toastr.showError('Something went wrong. Please try later', '');
      this.router.navigate(['/home/document']);
      this.isLoading = false;
    })
  }


  get f() {
    return this.createDocForm.controls;
  }


  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 2) {
        this.toastr.showWarning('You have attached a large file so it will take time to upload. please be patient', 'Large File');
      }
      this.createDocForm.patchValue({
        fileSource: file
      });

    }
  }
  
  submit() {
    this.submitted = true;
    this.creating = true;
    this.showError = false;
    this.errorMessages = [];

    const formData = new FormData();
    formData.append('certificate', this.createDocForm.get('fileSource').value);
    formData.append('userSourcedId', this.createDocForm.get('name').value);
    formData.append('orgSourcedId', this.createDocForm.get('organisation').value || this.schoolId);
    formData.append('courseSourcedId', this.createDocForm.get('course').value);
    formData.append('sessionSourcedId', this.createDocForm.get('session').value);
    formData.append('documentType', this.createDocForm.get('document_type').value);
    if (this.loginService.getCurrentUserRole().slug === "student") {
      formData.append('grade', JSON.parse(localStorage.getItem('userPermission'))?.grades);
    } else {
      formData.append('grade', this.createDocForm.get('grade').value);
    }
    if(this.isExp){
      formData.append('isApproved', '0');
    } else{
      formData.append('isApproved', this.createDocForm.get('isApproved').value);
    }
    formData.append('year', this.createDocForm.get('year').value);
    formData.append('remarks', this.createDocForm.get('comment').value);
    formData.append('assignedTo', this.createDocForm.get('assignedTo').value);

    this.serviceOrganisation.upLoadDocument(formData).then(res => {
      if (res['status']) {
        this.toastr.showSuccess("Document uploaded successfully !!");
        this.router.navigate(['/home/document']);
      } else {
        if (res['message']) {
          this.errorMessages.push(res['message']);
        } else {
          this.errorMessages.push('Something went wrong. Please try later');
        }
        this.showError = true;
      }
      this.creating = false;
    }).catch((err: any) => {
      let errData = err.error.errors;

      for (let key in errData) {
        this.errorMessages.push(errData[key][0]);
      }
      if (errData == undefined || errData == null) {
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.creating = false;
      //  this.createAlert = {status:true, msg:'Something went wrong !!'}
    })



  }

  onReset() {
    this.submitted = false;
    this.createDocForm.reset();
  }




}
