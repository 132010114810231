import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment as env} from '../../environments/environment';
import { externalJs } from './external-js.service';
const httpOptions ={
  headers : new HttpHeaders({
    'Accept' : "application/json"
  })
}

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  roles:any = {
    9 : {slug:'superident', title:'Superident'},
    14 :  {slug:'parent', title:'Parent'},
    15 :  {slug:'parent', title:'Guardian'},
    13 :  {slug:'secreatry', title:'Secreatry'},
    10 : {slug:'principal', title:'Principal'},
    8 : {slug:'teacher', title:'Teacher'},
    7 : {slug:'student', title:'Student'},
    1 : {slug:'super-admin', title:'SuperAdmin'},
  }

  constructor(private http: HttpClient, private xj: externalJs) { }

 

  // login api
  login(data){
    return this.http.post(env.apiurl +'login', data,httpOptions)
  }

  // get current user role
  getCurrentUserRole(){
    let roleId =  localStorage.getItem('roleid');
    let role:any = '';
    if(roleId && this.roles[roleId]){
     role = this.roles[roleId];
    }
    return role;
  }
  

    verifyOtp(otp:string,isforgot,email='',){
    if(isforgot){
      var API_URL = 'reset-password';
    }

    return this.http.post(env.apiurl+API_URL, {
      otp : otp,
      email : email,
      verify_otp : "1",
      send_otp : "0",
	    update_password : "0",
	      
    }).pipe(rawData => {return rawData;});
  }

  resendOtp(email, isforgot) {
    console.log(isforgot);
    if (isforgot) {
      var forget = 1;
      var API_URL = 'reset-password';
    } else {
      var forget = 0;
      var API_URL = 'reset-password';
    }

    return this.http.post(env.apiurl + API_URL, {
      email: email,
      verify_otp: "0",
      send_otp: "1",
      update_password: "0",
      forget: forget
    }).pipe(rawData => { return rawData; });
  }

  // get user token
  getUserToken(){
    let token =  localStorage.getItem('token');
    if(token){
      return token;
    }
    // return 'token not found';
    return '';
  }

  getDistrictFlag(){
    let flag = localStorage.getItem('district_flag');
    if(flag){
      return parseInt(flag);
    }
    // return 'token not found';
    return '';
  }


  // end class
  getHeader = () =>{
    return {
      headers : new HttpHeaders({
        "Authorization" :'Bearer ' + this.getUserToken()
      })
    }
  }
  isCompleteProfile(){
    // console.warn(this.getDistrictFlag() , this.getCurrentUserRole().slug === "super-admin")
    if(this.getDistrictFlag() === 0 && this.getCurrentUserRole().slug === "super-admin"){
      this.xj.openModalPopup("#settingPopup");      
    } 
  }
  logout(){
    return this.http.post(env.apiurl +'logout', '', this.getHeader());
  }
}
