import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { MasterService } from 'src/app/services/master.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-add-document-type',
  templateUrl: './add-document-type.component.html',
  styleUrls: ['./add-document-type.component.css']
})
export class AddDocumentTypeComponent implements OnInit {
  editId: number;
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  orgData: any = [];
  docTypeForm: any;
  showError:boolean = false;
  errorMessages:any = [];
  isSubmit: boolean = false;
  submitBtn: any;
  userPermissionData:any;
  viewPage:boolean = false;
  defOpOrg:any= 'Select School';
  isLoading:boolean= true;
  constructor(private xj:externalJs,  private FB: FormBuilder, private route:ActivatedRoute, private router:  Router, private serSchool:  OrganisationService, public loginService: LoginServiceService, private masterService: MasterService, private toastr: NotificationService,) { 
    this.editId = parseInt(this.route.snapshot.params.id);
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if (element.module === "DocType Management") {
        this.userPermissionData = element?.permission;
      }
    });
    if(this.editId){
      this.userPermissionData?.forEach(element => {
        if(element?.permission === 'Update DocType'){
          this.viewPage = true;
        }
      });
    } else{
      this.userPermissionData?.forEach(element => {
        if(element?.permission === 'Add DocType'){
          this.viewPage = true;
        }
      });
    }
    
  }
  
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    // console.log("edit Id=>"+this.editId);
    this.getSchool();
    this.docTypeForm = this.FB.group({
      school: [{value:this.schoolId, disabled: true}],
      name: ['',],
    })
    if(this.editId){
      this.getDocTypetem(this.editId);
    }
  }

  getSchool(){
    this.isLoading=true;
    this.serSchool.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg= 'Select School';
        this.docTypeForm.controls['school'].enable();
      } else{
        this.orgData = [];
        this.defOpOrg= 'No School Found';
      }
      this.isLoading = false;
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/master/doc-type-list']);
      this.isLoading = false;
    })
  }

  getDocTypetem(id:any){
    this.isLoading = true;
    this.masterService.viewDocTypeItem(id).subscribe((res:any)=> {
      if(res.status){
        this.docTypeForm.patchValue({
          school: res.data.schoolId,
          name: res.data.name
        })
        this.isLoading = false;
      } else{
        this.router.navigate(['/home/master/doc-type-list']);
        this.toastr.showError('Something went wrong. Please try later','')
        this.isLoading = false;
      } 
    }, (err:any)=>{     
      this.router.navigate(['/home/master/doc-type-list']);
      this.toastr.showError('Something went wrong. Please try later','');      
      this.isLoading = false;   
      
    })
  }

  
  submitForm(e:any){
    this.isSubmit = true;
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.showError = false;
    this.errorMessages = [];
    if(this.editId){
      this.updateDocType()
    } else{
      this.addDocType();
    }
  }


  addDocType(){
    const formData = {
      schoolId: this.docTypeForm.value.school,
      name: this.docTypeForm.value.name
    }
    this.masterService.addDocType(formData).then((res: any)=> {
      if(res.status){
        this.router.navigate(['/home/master/doc-type-list']);
        this.toastr.showSuccess(res.message);       
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }   
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any) =>{
      let errData = err.error.errors;
      for (let key in errData) {
        this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError= true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  } 

  updateDocType(){
    const formData = {
      schoolId: this.docTypeForm.value.school,
      name: this.docTypeForm.value.name
    }
    this.masterService.editDoctype(this.editId, formData).then((res: any)=> {
      if(res.status){
        this.router.navigate(['/home/master/doc-type-list']);
        this.toastr.showSuccess(res.message);
        this.isSubmit = false;
        this.submitBtn.disabled = false;
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }   
        this.showError = true;
      }
    }).catch((err:any) =>{
      let errData = err.error.errors;
      for (let key in errData) {
        this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })

  }

}
