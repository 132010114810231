import { Component } from '@angular/core';
import { externalJs } from './services/external-js.service';

import { FormBuilder, Validators } from '@angular/forms';
import { UserServiceService } from './services/user-service.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoginServiceService } from './services/login-service.service';
@Component({  
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'clinton';
  url: string;
  district_flag: any = parseInt(localStorage.getItem('district_flag'));
  roles = parseInt(localStorage.getItem('roleid')) || '';
  setDistrictForm : any;
  defOpDistrict:string='Select District';
  districtList: any [];
  isSubmit: boolean = false;
  submitBtn:any;
  showError:boolean = false;
  errorMessages:any = [];
  constructor( private xj: externalJs, private FB: FormBuilder, private userService:  UserServiceService, private toastr: NotificationService, private loginServe: LoginServiceService){
  
  }
  ngOnInit(){
    this.url = './login';
    if(this.loginServe.getUserToken() && this.loginServe.getDistrictFlag() === 0){
      this.getDistrict();
    }
    this.setDistrictForm = this.FB.group({
      district : ['', Validators.required]
    })
    // return;
    // if(this.loginSrv.getDistrictFlag() === 0 && this.loginSrv.getCurrentUserRole().slug === "super-admin"){
    //   this.xj.openModalPopup("#settingPopup");
      
    // } 
  }

  getDistrict(){
    this.userService.fetchDistrict().subscribe((res:any) => {
      if(res.status){
        this.setDistrictForm.controls['district'].enable();
        this.districtList = res['data'];
        this.defOpDistrict= 'Select District';
      } else{
        this.defOpDistrict= 'No District Found';
        this.districtList = [];        
      }
    }, (err:any)=>{
        this.toastr.showError('Something went wrong. Please try later','');       
    })
  }

  saveDistrict(event:any){
    this.submitBtn = event.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    this.errorMessages = [];
    this.showError = false;
    const data = this.setDistrictForm.value;
    this.userService.saveDistrict(data).then(res => {
      if(res['status']){
          this.toastr.showSuccess("District added successfully !!");
          localStorage.setItem('district', this.setDistrictForm.value.district);
          localStorage.setItem('district_flag', this.setDistrictForm.value.district);
          this.xj.closeModalPopup("#settingPopup");
      } else{
          if(res['message']){
            this.errorMessages.push(res['message']);
          }else{
            this.errorMessages.push('Something went wrong. Please try later');
          }         
          this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }
}
