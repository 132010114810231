import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { externalJs } from 'src/app/services/external-js.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { UserServiceService } from '../.././../services/user-service.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  showErrMsg: string;
  roleList: any = [];
  orgData: any = [];
  roleId: any;
  orgId: any;
  orgHide: boolean = false;
  disHide: boolean = false;
  viewPage: boolean = false;
  userPermissionData: any;
  districtList: any = [];
  showError:boolean = false;
  isLoading: boolean = true;
  errorMessages:any = [];
  isreviewed: boolean;
  hasReviewed: boolean = false;
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  isSubmit: boolean = false;
  submitBtn:any;
  defOpRole:string='Select Role';
  defOpOrg:string='Select School';
  defOpDistrict:string='Select District';
  currentDistrict:any = parseInt(localStorage.getItem('district_flag')) || '';
  constructor(private xj: externalJs, private service: UserServiceService, private orgService: OrganisationService, private router: Router, private toastr: NotificationService, public loginService: LoginServiceService) { 
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if(element.module === "User Management"){
        this.userPermissionData = element?.permission;
      }
    });
    this.userPermissionData?.forEach(element => {  
     if(element?.permission === 'create'){
          this.viewPage = true;  
      }
    });
   
  }

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    this.getOrgList();
    this.getRoles();
    this.getDistrict();
  }
  //  add form validation
  addUserForm = new FormGroup({   
    firstname: new FormControl('',),
    middleName: new FormControl('',),
    lastName: new FormControl('',),   
    email: new FormControl('',),
    phoneNumber: new FormControl('',),
    username: new FormControl(''),
    password: new FormControl('',),
    role: new FormControl({value: '', disabled: true}),
    organisation: new FormControl({value: this.schoolId, disabled: true}),
    district : new FormControl({value: this.currentDistrict, disabled: true}),
    status: new FormControl('1',)
  })

  // getting role id
  getRoleId(evt){
    this.roleId = evt
    if(this.roleId == '11' || this.roleId == '10' || this.roleId == '13'){
     this.orgHide = true
     this.disHide = false;
    }else if(this.roleId === "9" ){
      this.orgHide = false;
      this.disHide = false;
    }else if(this.roleId == '11' || this.roleId == '1'){
      this.disHide = false;
      this.orgHide = false;
    }else{
      this.disHide = true;
      this.orgHide = true;
    }
  }

  // getting organisation id
  getOrgId(evt){
    this.orgId = evt
  }
  // add user api
  addUser(e:any) {
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;
    this.errorMessages = []
    this.showError = false;
    let r = (Math.random() + 1).toString(36).substring(7); //for generating random string
    const data = {
      roleId: this.roleId,
      userId: r,
      username: this.addUserForm.value.username,
      givenName: this.addUserForm.value.firstname,
      middleName: this.addUserForm.value.middleName,
      familyName: this.addUserForm.value.lastName,
      phone: this.addUserForm.value.phoneNumber,
      email: this.addUserForm.value.email,
      password: this.addUserForm.value.password,
      orgSourceId: this.addUserForm.value.organisation,
      district : this.currentDistrict ? this.currentDistrict : this.addUserForm.value.district,
      enabledUser : this.addUserForm.value.status
    }
    this.service.createUser(data).then(res => {
      if(res['success']){
        this.toastr.showSuccess("User added successfully !!")
        this.router.navigate(['/home/user']);
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }   
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err:any)=>{
      let errData = err.error.errors;
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }

  // get role list
  getRoles() {
    this.isLoading = true;
    this.service.fetchRole().subscribe((res:any) => {
      if(res.status){
        this.defOpRole= 'Select Role';
        this.roleList = res.data;
        this.addUserForm.controls['role'].enable();
      } else{
        this.roleList = [];
        this.defOpRole= 'No Role Found';
      }
      this.isLoading = false;
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/user']);
      this.isLoading = false;
    })
  }

  // get organisation api

  getOrgList() {
    this.isLoading = true;
    this.orgService.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg= 'Select School';
        this.addUserForm.controls['organisation'].enable();
      } else{
        this.orgData = [];
        this.defOpOrg= 'No School Found';
      }
      this.isLoading = false;
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/user']);
      this.isLoading = false;
    })
  }

    // fetch district
    getDistrict(){
      this.isLoading = true;
      this.service.fetchAllDistrict().subscribe((res:any) => {
       if(res.status){
        this.districtList = res.data;
        this.defOpDistrict= 'Select District';
        this.addUserForm.controls['district'].enable();
       } else{
        this.orgData = [];
        this.defOpDistrict= 'No District Found';
      }
      this.isLoading = false;
       
      }, (err:any)=>{
        this.toastr.showError('Something went wrong. Please try later','');
        this.router.navigate(['/home/user']);
        this.isLoading = false;
      })
    }
}
