import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { externalJs } from 'src/app/services/external-js.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})
export class AddRoleComponent implements OnInit {
  isLoading:boolean =true;
  moduleList: any;
  allModuleId = [];
  viewPage: boolean = false;
  userPermissionData:any;
  isSubmit: boolean = false;
  submitBtn:any;
  showError:boolean = false;
  errorMessages:any = [];
  constructor(private xj: externalJs, private service: UserServiceService, private toastr: NotificationService,private router:Router) {
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if(element.module === "Role Management"){
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {
      if(element?.permission === 'create'){
        this.viewPage = true;
      }
    });
  }

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    this.xj.extraInit();
    this.fetchUserRole();
  }
  // add role form
  addRoleForm = new FormGroup({
    roleName: new FormControl('',)
  })

  // get module with permission data
  fetchUserRole() {
    this.service.getUserModule().subscribe((res:any) => {
      if(res.success){
        this.moduleList = res['data'];
        this.isLoading = false;
      } else{
        this.toastr.showError('Something went wrong. Please try later','');
        this.router.navigate(['/home/user-role']);
        this.isLoading = false;
      }     
    }, (err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/user-role']);
      this.isLoading = false;
    })
  }

    //  add role api
    addRole(e:any) {
      this.submitBtn = e.target;
      this.submitBtn.disabled = true;
      this.isSubmit = true;
      this.showError = false;
      this.errorMessages = [];
      var finalData = []
      var permissionArr = []
  
      for (let i = 0; i < this.allModuleId.length; i++) {
        var permissionArr = []
        for (let j = 0; j < this.allModuleId.length; j++) {
          if (this.allModuleId[i].moduleId == this.allModuleId[j].moduleId) {
            permissionArr.push({ 'permissionId': this.allModuleId[j].permissionId })
          }
        }
  
        var request = {
          "moduleId": this.allModuleId[i].moduleId,
          "permissions": permissionArr
        }
        finalData.push(request);
      }
      var elementsArr = finalData.reduce(function (previous, current) {
        var object = previous.filter(object => object.moduleId === current.moduleId);
        if (object.length == 0) {
          previous.push(current);
        }
        return previous;
      }, []);
  
      console.log('finalArr', elementsArr)
      const data = {
        "roleName": this.addRoleForm.value.roleName,
        "modules": elementsArr
  
      }
      this.service.addRoleModule(data).subscribe(res => {
        if(res['success']){
          this.toastr.showSuccess("Role Added successfully !!")
          this.router.navigate(['/home/user-role']);
        } else{
          if(res['message']){
            this.errorMessages.push(res['message']);
          }else{
            this.errorMessages.push('Something went wrong. Please try later');
          }         
          this.showError = true;
        }
       
        this.isSubmit = false;
        this.submitBtn.disabled = false;
      },(err:any)=>{
        let errData = err.error.errors;
        for(let key in errData){
          this.errorMessages.push(errData[key][0]);
        }
        if(errData == undefined || errData == null){
          this.errorMessages.push('Something went wrong. Please try later');
        }
        this.showError = true;
        this.isSubmit = false;
        this.submitBtn.disabled = false;
      })
    }
  
    // get id of module and permission
    getData(moduleId, permissionId) {
      this.allModuleId.push({ 'moduleId': moduleId, 'permissionId': permissionId });  
    }
}
