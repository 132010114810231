import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { OrganisationService } from 'src/app/services/organisation.service';
import { CourseService } from 'src/app/services/course.service';
import { AcademicService } from 'src/app/services/academic.service';
import { StudentService } from 'src/app/services/student.service';
import { NotificationService } from 'src/app/services/notification.service';

import { DocumentService } from 'src/app/services/document.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-uploaded-dcoument',
  templateUrl: './uploaded-dcoument.component.html',
  styleUrls: ['./uploaded-dcoument.component.css']
})
export class UploadedDcoumentComponent implements OnInit {
  //editBulkDoc: FormGroup;
  submitted = false;
  userPermissionData:any;
  viewPage:boolean = false;
  currentAddLnk: any;
  constructor(private route: ActivatedRoute, public loginService: LoginServiceService, private xj: externalJs, private docServ: DocumentService, private formBuilder: FormBuilder, private router: Router, private toastr: NotificationService, private sanitizer: DomSanitizer, private serviceOrganisation: OrganisationService, private serviceCourse: CourseService, private serviceAcademic: AcademicService, private serviceStd: StudentService, private masterService: MasterService,) { 
    var localData = JSON.parse(localStorage.getItem('userPermission'))
      const a = localData?.modules;
      a.forEach(element => {
        if (element.module === "Bulk Upload") {
          this.userPermissionData = element?.permission;
        }
      });
      this.userPermissionData?.forEach(element => {
        if(element?.permission === 'Bulk upload document'){
          this.currentAddLnk = "/home/bulk/uploaddocument"; 
          this.viewPage = true;
        }
      });
  }
  isLoading = false;
  showLightBox: boolean = false;
  creating: boolean = false;
  previewLoading: boolean = false;
  currentImageIndex: number = 0;
  currentDocLength: number = 0;
  previewSRCType: string = '';
  previewIsActive: any = '';
  imgurl: string;
  docItems = [];
  message:any = '';
  assignStudent:any;
  docTypes = [];
  orgData: any = [];
  courseData: any;
  sessionData: any;
  studentData: any;
  imgSrc: any;
  approveStatus: any;
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  totalPages:any;
  currentPage = 1;
  currentDocType:any;
  currentCourseId:any;
  showLoader = false;

  showTable: boolean = false;

  preiewLists: any;

  disApproveBtn: boolean = false;

 
  currAddLnkTxt: string = "Upload Documents";


  gradeItems:any = [];
  forwardToWhom: any= [];
  isapprove: boolean = true;
  dropStudent: any = [];
  //imgSrc: any = this.imgSanitizer(this.docItems[0].src);
  forwardUserId:any= '';
  forwardDocId:any= '';
  forwardToId:any= '';


  tableDataFrom: number = 0;
  tableDataTo: number = 0;
  tableDataTotal: number = 0;
  tableDataPerPage: number = 10;
  errorMessages=[];

  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
    }
    // console.log(this.loginService.getCurrentUserRole())
    this.approveStatus = 0;
    this.xj.extraInit();
    this.getDocumentType();
    this.serviceOrganisation.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data
      } else{
        this.orgData = []
      }
    })
    this.serviceAcademic.getAllSession().subscribe(res => {
      if(res['status']){
        this.sessionData = res['data'];
      }
    })

    

    if (this.schoolId) {
      this.getCourse(this.schoolId);
      this.getGradeItems(this.schoolId);
    }

    this.route.queryParamMap
      .subscribe((params:any) => {
        let paramsObj = { ...params.keys, ...params };
        
        if(paramsObj.params.page){
          this.currentPage = paramsObj.params.page;
        }
        if(paramsObj.params.course){
          this.currentCourseId = paramsObj.params.course;
        }else {
          this.currentCourseId = '';
        }
        if(paramsObj.params.doc){
          this.currentDocType = paramsObj.params.doc;
        }else {
          this.currentDocType = '';
        }
        if(paramsObj.params.school){
          this.schoolId = paramsObj.params.school;
        }else {
          this.schoolId = parseInt(localStorage.getItem('orgIds')) || '';
        }
        this.initComp();
      });
     
  }
  ngAfterViewInit(): void{
    
  }
  initComp() {
    this.getBulkupldDocs(this.schoolId, this.currentPage, this.currentDocType, this.currentCourseId);
  }

  // serch Student
  serchStudents(e: any, index: any) {
    this.docItems[index].showStudentList = true;

    this.docItems[index].selectedStudent = '';
    this.serviceStd.searchStudents(e.target.value, this.docItems[index].orgSourcedId).then((res: any) => {
      if (res?.data && res?.data?.length) {
        this.docItems[index].serchStudentList = res.data;
        if (this.docItems[index].serchStudentList && this.docItems[index].serchStudentList.length) {
          this.docItems[index].showStudentList = true;
        }
      }
    });
  }

  selectStudent(e:any) {
    this.assignStudent = '';
    this.assignStudent = e.getAttribute('data-value');
    // this.docItems[index].selectedStudent = student;
    // this.docItems[index].showStudentList = false;
  }

  closeStudentList(index) {
    this.docItems[index].showStudentList = false;
  }
 
  // save the doc
  saveDoc(index: any, e:any) {
    const btn = e.currentTarget;
    btn.disabled = true;
    const btnSpinner = btn.childNodes[1];
    btnSpinner.classList.remove('d-none');
    let documentId = this.docItems[index].id;
    let studentId = this.assignStudent;
    // let stdIndex = this.dropStudent.findIndex(rank => rank.label ===  this.assignStudent);
    // if(stdIndex !== -1){
    //   studentId = this.dropStudent[stdIndex]['id'];
    // }
    let postData = {
      "documentId": documentId,
      "studentId": studentId
    };
    // console.log(studentId);
    // return;
    if (documentId && studentId) {
      this.docServ.saveDoc(postData).then((res: any) => {
        if(res.status){
          this.docItems = [];
          var orgID = localStorage.getItem('orgId');
          this.toastr.showSuccess('Student assigned successfully');
          this.getBulkupldDocs(orgID);
        } else{
          this.toastr.showError('Something went wrong. Please try later', '');
        }
        
        btn.disabled =false
      }).catch((err: any) => {
        btnSpinner.classList.add('d-none');
        btn.disabled =false
        this.toastr.showError('Something went wrong. Please try later', '')
      })
    }
    else {
      this.toastr.showError('Please Select Student First', '')
      btnSpinner.classList.add('d-none');
      btn.disabled =false
    }
  }


  // get document type
  getDocumentType() {
    this.isLoading = true;
    this.serviceCourse.getdocTypes().subscribe(res => {
      this.docTypes = res['data'];
      this.isLoading = false;
    })
  }



  editBulkDoc = new FormGroup({

    docType: new FormControl('', Validators.required),
    studentName: new FormControl('', Validators.required),
    school: new FormControl('', Validators.required),
    course: new FormControl('', Validators.required),
    grade: new FormControl(''),
    session: new FormControl('', Validators.required),
    status: new FormControl('', Validators.required),
    comment: new FormControl(''),
    //organisation: new FormControl('', Validators.required),
    docId: new FormControl('', Validators.required),
    year: new FormControl(''),
    forwardToId: new FormControl(''),
  })





  // onschoolChange
  onschoolChange(e) {
    this.isLoading =true;
    this.getCourse(e);
    this.getGradeItems(e);
  }
  onGradeChange(e){
    this.isLoading =true;
    this.getStudent(this.schoolId,e,'');
  }
  // onCourseChange
  onCourseChange(e) {
    this.isLoading =true;
    this.getStudent(this.schoolId,'',e);
  }


  // get bulk uploaded documents

  getBulkupldDocs(schoolId = this.schoolId, page = 1, documentType = '', courseId = '') {
    this.showLoader = true;
    this.serviceOrganisation.getBulkuploadedDocuments(schoolId, page, documentType, courseId).subscribe((res:any) => {
      if (res['status']) {
        this.docItems = res['data']['data'];
        this.mapPages(res.data.last_page);       
        this.tableDataFrom= res.data.from;
        this.tableDataTo = res.data.to;
        this.tableDataTotal = res.data.total;
        this.tableDataPerPage = res.data.per_page;
        this.message='';
        setTimeout(() => {
          this.removeElementsByClass(".autocomplete");
        }, 500);
        this.showLoader = false;
      } else if(res['status_code'] === 404){
        this.docItems = [];
        this.message= 'No uploaded document data found';
        this.showLoader = false;
      } else {
        this.docItems = []
        this.showLoader = false;
        this.message= "Something went wrong. Please try later";
      }
    }, (err:any)=>{
      this.message= "Something went wrong. Please try later";
      this.showLoader = false;
    })

  }

  // map pages
  mapPages(lastPage = 1){
    let pageListArr = [];
    this.totalPages = [];
    if(lastPage <=1) return;
    if(lastPage <= 5){
      for(var i =1; i<= lastPage; i++){
          pageListArr.push(i);
      }
    }
    if(lastPage > 5){
      if(this.currentPage <=4){        
          for(var i =1; i<= 5; i++){
            pageListArr.push(i);
          }      
          pageListArr.push('...');
          pageListArr.push(lastPage);
      } else if(lastPage - this.currentPage <=3){
        pageListArr.push(1);
        pageListArr.push('...');
        for(var i =lastPage - 4; i<= lastPage; i++){
          pageListArr.push(i);
        } 
      }
      else {
        pageListArr.push(1);
        pageListArr.push('...');  

        for(var i = this.currentPage - 2; i<= this.currentPage + 2; i++){
          pageListArr.push(i);
        }

        pageListArr.push('...');
        pageListArr.push(lastPage);
      } 
    } 
    
     this.totalPages = pageListArr;
  }

  // pagination
  changeCurrentPage(page:any){
    if(page != '...'){
      this.currentPage = page;
      this.router.navigate(
        [], 
        {
          relativeTo: this.route,
          queryParams: { page: page },
          queryParamsHandling: 'merge'
        });
      }
  }

  // filter
  filterDocuments(){
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, course: this.currentCourseId, doc: this.currentDocType, school: this.schoolId },
        queryParamsHandling: 'merge'
      });

  }

  // on school change
  filterSchool(orgId){
    this.getCourse(orgId);
  }

  //getCourse
  getCourse(orgID: any = this.schoolId) {
    this.courseData = [];
    this.serviceCourse.getAllCoursebyOrgid(orgID).subscribe(res => {
      if (res['status']) {
        this.courseData = res['data'];        
      } else{
        this.courseData =  []
      }
      this.isLoading=false;
    })
  }
  // getStudent
  getStudent(school:any, grade:any, courseId: any) {
    this.isLoading = true;
    if(courseId !== ''){
      grade = '';
    }
    this.studentData = [];
   
      this.serviceStd.getAllStudents(school, grade, courseId).subscribe(res => {
        if (res['status'] && res['status_code'] === 200) {
          //console.warn(res);
          this.studentData = res['data'];
          this.isLoading = false;
        } else{
          this.isLoading = false;
        }
      })
   
  }


  getGradeItems(schoolId:any){
    this.masterService.allGradeList(schoolId).subscribe((res:any)=>{
      if(res.status){
        this.gradeItems =  res.data;
      } else{
        this.gradeItems = []
      }
      this.isLoading=false;
    })
  }


  get f() {
    return this.editBulkDoc.controls;
  }

  imgSanitizer(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  pdfSanitizer(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openLightBox(event: any = '') {
    this.showLightBox = true;
    document.getElementsByTagName('body')[0].style.overflow = "hidden";
  }

  closeLightBox(event: any) {
    this.showLightBox = false;
    document.getElementsByTagName('body')[0].style.overflow = "auto";
    this.initComp();
  }
  prevImage() {
    this.previewLoading = true;
    this.currentImageIndex--;
    if (this.currentImageIndex < 0) {
      this.currentImageIndex = this.preiewLists.length - 1;
    }
    this.openGallery(this.currentImageIndex, this.preiewLists);
  }

  nextImage() {
    this.previewLoading = true;
    this.currentImageIndex++;
    if (this.preiewLists.length === this.currentImageIndex) {
      this.currentImageIndex = 0;
      // if (this.currentImageIndex === 0) {
      //   this.router.navigate(['/home/document']);
      // }
    }
    this.openGallery(this.currentImageIndex, this.preiewLists);

  }
  // open gallery
  openGallery(index, docArr: any = "") {
    
    this.preiewLists = [];
    this.previewSRCType = '';
    this.imgSrc= '';
    if (!this.showLightBox) {
      this.openLightBox();
    }
    if (!index) {
      this.currentImageIndex = 1;
    }
    this.preiewLists = docArr;
    this.currentImageIndex = index;
    this.currentDocLength = docArr.length;
    this.previewLoading = true;

    //console.log(this.preiewLists);

    for (var i = 0; i < docArr.length; i++) {
      if (i === this.currentImageIndex) {
        // console.log(this.preiewLists[i].grade.id);
        this.previewSRCType =docArr[i].fileType;
        if (docArr[i].fileType === "pdf") {
          this.imgSrc = this.pdfSanitizer(this.preiewLists[i].documentLocalPath+'#toolbar=0');
        } else {
          this.imgSrc = this.imgSanitizer(this.preiewLists[i].documentLocalPath);
        }

        // this.previewIsActive = this.preiewLists[i].isActive;
        this.getCourse(this.preiewLists[i].orgSourcedId);
        // this.getStudent(this.preiewLists[i].courseSourcedId);

        
        this.getGradeItems(this.preiewLists[i].orgSourcedId);
        this.getStudent(this.preiewLists[i].orgSourcedId, this.preiewLists[i].grade?.id, this.preiewLists[i]?.courseSourcedId );
        
        this.getForwardUserList(this.preiewLists[i].orgSourcedId);
        this.editBulkDoc.patchValue({
          docId: this.preiewLists[i].id,
          docType: this.preiewLists[i].documentType,
          studentName: this.preiewLists[i].userSourcedId || '',
          school: this.preiewLists[i].orgSourcedId,
          course: this.preiewLists[i].courseSourcedId || '',
          grade: this.preiewLists[i].grade?.id || '',
          session: this.preiewLists[i].sessionSourcedId || '',
          status: this.preiewLists[i].isActive,
          year: this.preiewLists[i]?.docYear || '',
          comment: this.preiewLists[i].remarks || '',
        })
        setTimeout(() => {
          this.previewLoading = false;
        }, 100);
        break;
      }
    }
  }


  // all approve document

  allApproveDoc(data) {
    var isApprove = 0;
    let docIds = [];
    if (data == ' Approve All ') {
      isApprove = 1;
    }

    for (var i = 0; i < this.docItems.length; i++) {
      docIds.push(this.docItems[i].id);
      if (isApprove == 1) {
        this.docItems[i].isApproved = 1;
      } else {
        this.docItems[i].isApproved = 0;
      }


    }

    var newArr = {
      'documentId': docIds,
      'isApproved': isApprove
    }

    this.serviceOrganisation.approveAlldocs(newArr).then(res => {
      if (res['status']) {
        //this.disApproveBtn = this.docItems.every(item => item.isApproved === 1);
        //console.log(isApprove);
        if (isApprove == 1) {
          //this.disApproveBtn = true;
          this.disApproveBtn = this.docItems.every(item => item.isActive === 1);
        } else {
          //this.disApproveBtn = false;
          this.disApproveBtn = this.docItems.every(item => item.isActive === 0);
        }

      }
    })


  }


  // aprrove single doc

  approveDoc(dataId: any) {
    let index = this.docItems.findIndex(rank => rank.id === dataId);
    if (this.docItems[index].isApproved === 0) {
      this.docItems[index].isApproved = 1;
    } else {
      this.docItems[index].isApproved = 0;
    }
  }

 

  submit() {
    this.submitted = true;
    this.creating =true;
    //console.log(this.editBulkDoc);
    // stop here if form is invalid
    if (this.editBulkDoc.invalid) {
      this.creating =false;
      return;
      
    }
    if(this.editBulkDoc.get('forwardToId').value){
      const formData = {
        'document_id':  this.editBulkDoc.get('docId').value,
        'student_id': parseInt(this.editBulkDoc.get('studentName').value),
        'Forwardto': parseInt(this.editBulkDoc.get('forwardToId').value)
      }
      var frdocId = this.editBulkDoc.get('docId').value;
      // console.log(formData);
      // return;
      this.docServ.bulkDocForward(formData).then((res:any)=> {     
        if(res.status){
          this.toastr.showSuccess("Document forwarded successfully !!");
            //this.router.navigate(['/home/bulk/uploaded-document']);
            this.initComp();
            let PrIndex = this.preiewLists.findIndex(rank => rank.id === frdocId);        
            this.preiewLists.splice(PrIndex, 1);
            if(this.preiewLists?.length > 0){
              this.openGallery(0, this.preiewLists);  
            } else{
              this.closeLightBox('');
            }
            // this.editBulkDoc.reset();
            // this.nextImage();
        } else {
          this.toastr.showError('Something went wrong. Please try later', '')
        }
      }).catch((err:any)=>{
        let errData = err.error.errors; 
        for(let key in errData){
          this.errorMessages.push(errData[key][0]);
        }
        if(errData == undefined || errData == null){
          this.errorMessages.push('Something went wrong. Please try later');
          
        }
        this.toastr.showError(this.errorMessages[0], '')
      })
    } else{
    const formData = new FormData();

    const data = {
      'orgSourcedId': this.editBulkDoc.get('school').value,
      'courseSourcedId': this.editBulkDoc.get('course').value,
      'documentType': this.editBulkDoc.get('docType').value,
      'grade': this.editBulkDoc.get('grade').value,
      'sessionSourcedId': this.editBulkDoc.get('session').value,
      'userSourcedId': this.editBulkDoc.get('studentName').value,
      'remarks':  this.editBulkDoc.get('comment').value,
      'isApproved': 1,
      // 'userSourcedId': '1',      
      // 'isActive': '1'      
    }
    //formData.append('userSourcedId', '1');

    var docId = this.editBulkDoc.get('docId').value;
    let index = this.docItems.findIndex(rank => rank.id === docId);
    // console.log(this.editBulkDoc.value);
   
   
    // return;
    this.serviceOrganisation.updatebulkDocument(docId, data).then(res => {
      if (res['status_code'] === 200 && res['status'] === true) {
        this.toastr.showSuccess("Document updated successfully !!");
        this.initComp();
        let PrIndex = this.preiewLists.findIndex(rank => rank.id === docId);        
        this.preiewLists.splice(PrIndex, 1);
        if(this.preiewLists?.length > 0){
          this.openGallery(0, this.preiewLists);  
        } else{
          this.closeLightBox('');
        }
        // this.editBulkDoc.reset();
        // this.nextImage();

      } else {
        this.toastr.showError('Someting went wrong. Please try later', '')
      }
      this.submitted = false;
      this.creating =false;
    }).catch((err: any) => {
      let errData = err.error.errors; 
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
        
      }
      this.toastr.showError(this.errorMessages[0], '');
      this.submitted = false;
      this.creating =false;
    })

  }


  }

  clearFilter() {
    this.schoolId='';
    this.currentDocType='';
    this.currentCourseId='';
    this.getBulkupldDocs();
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { page: 1, course: '', doc: '', school: '' },
        queryParamsHandling: 'merge'
      });
  }


  // forward
  forwardForm = this.formBuilder.group({
    forwardUserId : [''],
    forwardUser: ['', Validators.required],
    forwardToId : ['', Validators.required],
  })  

  getValue(e:any){
    this.forwardForm.get('forwardUser').setValue(e);    
    // console.log(e);    
    let index = this.dropStudent.findIndex(rank => rank.label === e);
    if(index !== -1){
      this.forwardForm.get('forwardUserId').setValue(this.dropStudent[index]['id']);
    }
  }
  // forwardPopup
  forwardPopup(dcoId:any, orgSourcedId:any, courseId:any, grade:any ){
    this.forwardDocId = dcoId;
    this.getForwardUserList(orgSourcedId);
    this.getStudenList('#student',orgSourcedId,courseId,grade);
    setTimeout(()=>{
      this.xj.openModalPopup("#approveModal");     
    },500);
    
  }

  getForwardUserList(orgSourcedId){
    this.docServ.getForwardUserList(orgSourcedId).subscribe(res=>{
      if(res['status']){
        this.forwardToWhom = res['data'];
      }
    })
  }

  getStudenList(id:any, orgid:any, courseId:any, grade:any){
    // console.log(id);
    if(courseId !== ''){
        grade = ''
    } 
    this.dropStudent = [];
    this.serviceStd.searchAllStudents(orgid,grade,courseId).subscribe((res: any) => {
      if(res.status){
        this.dropStudent = res.data;       
        for(let item in this.dropStudent){ 
          let fullName = this.dropStudent[item]['givenName'];
        if(this.dropStudent[item]['middleName']){
          fullName += ' '+this.dropStudent[item]['middleName']
        }
        if(this.dropStudent[item]['familyName']){
          fullName += ' '+this.dropStudent[item]['familyName']
        }
          this.dropStudent[item]['label'] = fullName;
        }  
        this.xj.initStdAutocomplete(id, this.dropStudent)
        
      } else{
        this.dropStudent = [];
        this.xj.initStdAutocomplete(id, this.dropStudent);       
      }
    })
  }
  
 
  forwardAction(e:any){
    const btn = e.target;
    btn.childNodes[1].classList.remove('d-none');
    btn.disabled = true;
    this.errorMessages = [];
    const formData = {
      'document_id':  this.forwardDocId,
      'student_id': this.forwardForm.value.forwardUserId,
      'Forwardto': parseInt(this.forwardForm.value.forwardToId)
    }
    // console.log(formData);
    // return;
    this.docServ.bulkDocForward(formData).then((res:any)=> {     
      if(res.status){
        btn.childNodes[1].classList.add('d-none');
        this.toastr.showSuccess("Document successfully forwarded !!");
        this.initComp();
        btn.disabled = false;
        this.forwardPopupClose();
      } else{
        this.toastr.showError('Someting went wrong. Please try later', '')
      }
    }).catch((err:any)=>{
      let errData = err.error.errors; 
      for(let key in errData){
         this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
        
      }
      this.toastr.showError(this.errorMessages[0], '');
      btn.childNodes[1].classList.add('d-none');
      btn.disabled = false;
    })
    // setTimeout(() => {
    //   btn.classList.add('d-none');
    //   this.forwardPopupClose();
    //   e.target.disabled = false;
    // }, 500);
  }

  forwardPopupClose(){
    this.forwardForm.reset();
    this.forwardForm.get('forwardToId').setValue('');
    this.xj.closeModalPopup("#approveModal");
  }

  loadInitStd(){
    
  }

  removeElementsByClass(className){
    const removeElements = (elms) => elms.forEach(el => this.getStudenList(el, el.dataset.org,el.dataset.course,el.dataset.grade));
    removeElements( document.querySelectorAll(className) );                           
  }
}
