import { Component, OnInit } from '@angular/core';
import { externalJs } from 'src/app/services/external-js.service';
import { AcademicService } from 'src/app/services/academic.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassService } from 'src/app/services/class.service';
import { CourseService } from 'src/app/services/course.service';
import { MasterService } from 'src/app/services/master.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-edit-student',
  templateUrl: './edit-student.component.html',
  styleUrls: ['./edit-student.component.css']
})
export class EditStudentComponent implements OnInit { 
  selParentArr:any = [];
  parentList:any= [];
  selParentItems:any = [];
  dropdownSettings = {};
  parentDropDownSettings = {};
  selectedArr: any = [];
  selectedAcademic: any = [];
  checked: any;
  classList: any;
  empForm: FormGroup;
  courseList: any;
  id: any;
  studentEditData: any;
  studentInfo: any;
  classId: any;
  roleId:any;
  showGrade:boolean=false;
  editCourseId = [];
  editCourseIds = [];
  userPermissionData: any;
  viewPage:boolean = false;
  isRedirectTo: any = '';
  redirectSchool: any = '';
  isSubmit: boolean = false;
  submitBtn:any;
  isLoading:boolean = true;

  defOpOrg:any ='Select School';
  defOpGrade:any ='Select Grade';
  defOpClass:any = 'Select Class';
  defOpParent:any = 'Select Parent';
  gradLoader:boolean= false;
  courseLoader:boolean=false;
  parentLoader:boolean=false;
  constructor(private xj: externalJs, private fb: FormBuilder, private courseService: CourseService, private serviceAcademic: AcademicService,
    private classService: ClassService, private route: ActivatedRoute, private router: Router, private toastr: NotificationService, private service: StudentService, private serviceOrganisation: OrganisationService, public loginService: LoginServiceService, private masterService: MasterService,) {
    this.empForm = this.fb.group({
      employees: this.fb.array([]),
    })
    this.route.queryParamMap.subscribe(queryParams => {
      this.id = queryParams.get("id");
      if(queryParams.get("redirectTo")){
        this.isRedirectTo = '/home/bulk/view-upload-user'
      } else{
        this.isRedirectTo = '/home/student';
      } 
      if(queryParams.get('schoolId')){
        this.redirectSchool = queryParams.get('schoolId');
      } else{
        this.redirectSchool= '';
      }
    })
    var localData = JSON.parse(localStorage.getItem('userPermission'))
    const a = localData?.modules;

    a.forEach(element => {
      if(element.module === "Students"){
        this.userPermissionData = element?.permission;
      }
    });

    this.userPermissionData?.forEach(element => {      
      if(element?.permission === 'Edit Student'){
        this.viewPage = true;
      }
    });
  }
  sessionData: any;
  orgData: any;
  showError = false;
  errorMessages = [];
  gradeItems:any = [];
  selectedClasses: any = [];
  schoolId: any = parseInt(localStorage.getItem('orgIds')) || '';
  ngOnInit(): void {
    if(!this.viewPage){
      this.router.navigate(['/home/dashboard']);
   }
  
    //console.log(this.roleId);
   this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'select_txt',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.parentDropDownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullName',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    this.xj.extraInit();
     
    // this.getClass(); 
    if(this.schoolId){
      this.getGradeItems(this.schoolId);
      this.getClass(this.schoolId);
      this.getAllParent(this.schoolId);      
    } else{
      this.viewOrganisationApi(); 
    }
    // this.initialForm();
    this.editStudent();
  }


  getRoleId(id){
    if(id == 7){
     this.showGrade = true; 
    }else{
      this.showGrade = false;
    }
  }


  employees(): FormArray {
    return this.empForm.get("employees") as FormArray
  }
  newEmployee(): FormGroup {
    return this.fb.group({
      course: '',
      class: '',
    })
  }
  addEmployee() {
    this.employees().push(this.newEmployee());
  }

  removeEmployee(empIndex: number) {
    this.employees().removeAt(empIndex);
  }

  initialForm() {
    this.addEmployee();
  }
  // view session api
  viewSession() {
    this.serviceAcademic.viewSession().subscribe(res => {
      this.sessionData = res['data']['data'];
    })
  }

  // view organisationApi
  viewOrganisationApi() {
    this.serviceOrganisation.allSchool().subscribe((res:any) => {
      if(res.status){
        this.orgData = res.data;
        this.defOpOrg='Select School';
        this.addStudentForm.controls['school'].enable();
      } else{
        this.orgData = [];
        this.defOpOrg='No School Found';
      }      
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/student']);
      this.isLoading = false;
    })
  }

  // add student form
  addStudentForm = new FormGroup({
    firstName: new FormControl('',),
    middleName: new FormControl('',),
    lastName: new FormControl('',),
    dob: new FormControl('',),
    genderType: new FormControl('',),
    grade: new FormControl({value:'', disabled:true}),
    native: new FormControl('',),
    asian: new FormControl('',),
    nativeHawaiin1: new FormControl('',),
    isWhite: new FormControl('',),
    demographic: new FormControl('',),
    hispanic1: new FormControl('',),
    countryCode: new FormControl('',),
    number: new FormControl('',),
    email: new FormControl('',),
    userName: new FormControl('',),
    password: new FormControl('',),
    state: new FormControl('',),
    city: new FormControl('',),
    zipCode: new FormControl('',),
    address: new FormControl('',),
    parents: new FormControl('',),
    school: new FormControl({value: this.schoolId, disabled:true}),
    course: new FormControl('',),
    class: new FormControl('',),
    academicSession: new FormControl('',),
    startDate: new FormControl('',),
    role: new FormControl('',),
    endDate: new FormControl('',),
    statusType: new FormControl('',),
  })
  
  editStudent() {
    this.employees().clear();
    this.service.editStudent(this.id).subscribe((res:any) => {
      if(res.status){
        this.studentEditData = res['data'];
        if (this.studentInfo != undefined ||  this.studentInfo != null || this.studentInfo != '') {
            if(res['data']['demographics'] != null){
              this.studentInfo = res['data']['demographics'];
            }else{
              this.studentInfo = '';
            }
            
        }
        this.roleId = res['data']['role'];
        if(this.roleId == 7){
          this.showGrade = true;
        }
      
        // if(this.studentInfo != undefined){
        //   this.studentInfo = res['data']['demographics'];
        // }
        // for(let i = 0; i< this.studentEditData.class.length;i++){
        //   var titleData = this.studentEditData.class[i]
        //   this.addEmployee();
        //   this.employees().at(i).patchValue({
        //     course: titleData.courseId,
        //     class:titleData.classId,
        //   })
        // }
        //   if(titleData.classId != undefined || titleData.classId != null){
        //     this.getClass(this.classId);
        // }
        // this.studentEditData.class.forEach(element => {
        //   this.editCourseId.push(element.id)
        // });
        this.studentEditData.class.forEach(element => {
          this.editCourseIds.push(element.title)
        });
        
        
        var dob = ''; 
        var sex = '';
        if (this.studentInfo != undefined ||  this.studentInfo != null || this.studentInfo != '') { 
          if( this.studentInfo['birthdate'] != null){
            dob = this.studentInfo['birthdate'];
          } 
        
          if( this.studentInfo['sex'] != null){
            sex =  this.studentInfo['sex'].toLowerCase();
          } 
          // var dob = this.studentInfo.birthdate;
        
          // var sex = this.studentInfo.sex;
        }
        for(let item of this.studentEditData.class){
          this.selectedClasses.push({
            'id':  item.id,
            'select_txt':  item.title + ' - ['+ item.classCode +']'
          })
        }
        if(!this.schoolId){
          this.gradLoader= true;
          this.courseLoader= true;
          this.parentLoader =true;
          this.addStudentForm.controls['grade'].disable();
          this.getClass(this.studentEditData.orgSourcedIds);
          this.getGradeItems(this.studentEditData.orgSourcedIds);
          this.getAllParent(this.studentEditData.orgSourcedIds);        
        } 
        for(let item of this.studentEditData.parents){
          let fullName = item.givenName;
          if(item.middleName){
            fullName += ' '+item.middleName
          }
          if(item.familyName){
            fullName += ' '+item.familyName
          }
          this.selParentArr.push({
            'id':  item.id,
            'fullName':  fullName
          })
          // console.log(this.classList[item]['title'] + ' - (' + this.classList[item]['course'] + ')')
        }
        
        //  debugger;
        this.addStudentForm.patchValue({
          firstName: this.studentEditData.givenName,
          middleName: this.studentEditData.middleName,
          lastName: this.studentEditData.familyName,
          dob: dob,
          genderType: sex,
          grade: this.studentEditData.grades || '',
          // countryCode: this.studentInfo.countryOfBirthCode,
          number: this.studentEditData.phone,
          email: this.studentEditData.email,
          userName: this.studentEditData.username,
          // password: this.studentEditData.password,
          state: this.studentEditData.state,
          city: this.studentEditData.city,
          zipCode: this.studentEditData.zipCode,
          address: this.studentEditData.sms,
          parents: this.selParentArr,
          school: this.studentEditData.orgSourcedIds,
          course: this.selectedClasses,
          //class: this.studentEditData.givenName,
          startDate: '',
          role: this.studentEditData.role,
          // startDate: this.studentInfo.beginDate,
          // endDate: this.studentInfo.endDate,
          //  startDate: '',
          // endDate: '',
          statusType: this.studentEditData.enabledUser,
        })
        this.isLoading=false; 
      } else{
        this.router.navigate(['/home/student']);
        this.toastr.showError('Something went wrong. Please try later','');       
        this.isLoading=false; 
      }
    },(err:any)=>{
      this.router.navigate(['/home/student']);
      this.toastr.showError('Something went wrong. Please try later','');        
      this.isLoading=false;
    })
  }

   // 
   schoolChange(e){
    this.gradLoader= true;
    this.courseLoader= true;
    this.parentLoader = true;
    this.addStudentForm.controls['grade'].disable();
    this.addStudentForm.get('grade').setValue('');
    this.getGradeItems(e);
    this.getClass(e);
    this.getAllParent(e);
  }
  getGradeItems(schoolId:any){
    this.gradeItems = [];
    this.masterService.allGradeList(schoolId).subscribe((res:any)=>{
      if(res.status){
        this.gradeItems =  res.data;
        this.defOpGrade='Select Grade';
        this.addStudentForm.controls['grade'].enable();
      } else{
        this.gradeItems = [];
        this.defOpGrade='No Grade Found';
      }
      this.gradLoader= false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/student']);
      this.isLoading = false;
    })
  }
  // get class by course id
  getClass(schoolId = this.schoolId) {
    this.courseLoader = true;
    this.classList = [];
    this.addStudentForm.get('course').setValue('');
    this.classService.getAllClasses(schoolId).subscribe(res => {
      if(res['status']){        
        this.classList = res['data'];
        for(let item in this.classList){
          this.classList[item]['select_txt'] = this.classList[item]['title'] + ' - ['+ this.classList[item]['classCode'] +'] - (' + this.classList[item]['course'] + ')';
          // console.log(this.classList[item]['title'] + ' - (' + this.classList[item]['course'] + ')')
        }
        this.defOpClass = 'Select Class';
      } else{
        this.classList = [];
        this.defOpClass = 'No Class Found';
      }
      this.courseLoader = false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/student']);
      this.isLoading = false;
      this.courseLoader = false;
    })
  }
  getAllParent(schoolId:any){
    this.parentLoader= true;
    this.selParentArr = [];
    this.parentList= [];
    this.service.getAllParentList(schoolId).subscribe((res:any)=>{
      if(res.status){
        this.parentList =  res.data;
        for(let item in this.parentList){ 
          let fullName = this.parentList[item]['givenName'];
        if(this.parentList[item]['middleName']){
          fullName += ' '+this.parentList[item]['middleName']
        }
        if(this.parentList[item]['familyName']){
          fullName += ' '+this.parentList[item]['familyName']
        }
          this.parentList[item]['fullName'] = fullName + ' - ' + this.parentList[item]['email'];;
        } 
        this.defOpParent = 'Select Parent';
      } else{
        this.parentList = [];
        this.defOpParent= 'No Parent Found';
      }
      this.parentLoader= false;
    },(err:any)=>{
      this.toastr.showError('Something went wrong. Please try later','');
      this.router.navigate(['/home/student']);
      this.isLoading = false;
      this.parentLoader= false;
    })
  }
  

  dob(date: any) {
    setTimeout(() => {
      console.log(date.target.value);
      this.addStudentForm.patchValue({
        dob: date.target.value
      })
      
    }, 300);
  }

  // update student api
  updateStudent(e:any){
    this.submitBtn = e.target;
    this.submitBtn.disabled = true;
    this.isSubmit = true;

    if (this.addStudentForm.value.asian == true) {
      var asianValue = '1'
    } else {
      var asianValue = '0'
    }
    if (this.addStudentForm.value.americanIndianOrAlaskaNative == true) {
      var americanIndianOrAlaskaNativeValue = '1'
    } else {
      var americanIndianOrAlaskaNativeValue = '0'
    }
    if (this.addStudentForm.value.asian == true) {
      var asianValue = '1'
    } else {
      var asianValue = '0'
    }
    if (this.addStudentForm.value.nativeHawaiin1 == true) {
      var nativeHawaiianOrOtherPacificIslanderValue = '1'
    } else {
      var nativeHawaiianOrOtherPacificIslanderValue = '0'
    }
    if (this.addStudentForm.value.isWhite == true) {
      var isWhiteValue = '1'
    } else {
      var isWhiteValue = '0'
    }
    if (this.addStudentForm.value.hispanic1 == true) {
      var hispanic1Value = '1'
    } else {
      var hispanic1Value = '0'
    }
    if (this.addStudentForm.value.demographic == true) {
      var demographicValue = '1'
    } else {
      var demographicValue = '0'
    }
    for (const data of this.selectedArr) {
      this.selectedAcademic.push(data.id)
    }
    this.showError = false;
    this.errorMessages = [];
    // var classData = this.employees().getRawValue();
    // var course = this.editCourseId.concat(this.selectedAcademic)
    var course = [];
    this.selParentItems = [];
    for(const data of this.addStudentForm.value.course){
      course.push(data.id) 
    }
    var orgId = localStorage.getItem('orgIds');
    for(const data of this.addStudentForm.value.parents){
      this.selParentItems.push(data.id) 
    }
    const data = {
      givenName: this.addStudentForm.value.firstName,
      middleName: this.addStudentForm.value.middleName,
      familyName: this.addStudentForm.value.lastName,
      birthdate: this.addStudentForm.value.dob,
      sex: this.addStudentForm.value.genderType,
      grades: this.addStudentForm.value.grade,
      americanIndianOrAlaskaNative: americanIndianOrAlaskaNativeValue,
      asian: asianValue,
      nativeHawaiianOrOtherPacificIslander: nativeHawaiianOrOtherPacificIslanderValue,
      white: isWhiteValue,
      demographicRaceTwoOrMoreRaces: demographicValue,
      hispanicOrLatinoEthnicity: hispanic1Value,
      countryOfBirthCode: this.addStudentForm.value.countryCode,
      phone: this.addStudentForm.value.number,
      email: this.addStudentForm.value.email,
      username: this.addStudentForm.value.userName,
      state: this.addStudentForm.value.state,
      city: this.addStudentForm.value.city,
      zipCode: this.addStudentForm.value.zipCode,
      parents: this.addStudentForm.value.parents,
      schoolSourcedId: this.addStudentForm.value.school ? this.addStudentForm.value.school : orgId,
      courseSourcedId: course,
      beginDate: '',
      endDate: '',
      enabledUser: this.addStudentForm.value.statusType,
      sms: this.addStudentForm.value.address,
      agentSourcedIds: this.selParentItems,
    }

    // console.log(data);
    // return;
    this.service.updateStudent(this.id, data).then(res => {
      if (res['status']) {
        this.router.navigate(['/home/student'])
        this.toastr.showSuccess('Student updated successfully');
      } else{
        if(res['message']){
          this.errorMessages.push(res['message']);
        }else{
          this.errorMessages.push('Something went wrong. Please try later');
        }         
        this.showError = true;
      }
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    }).catch((err: any) => {
      let errData = err.error.errors;
      for (let key in errData) {
        this.errorMessages.push(errData[key][0]);
      }
      if(errData == undefined || errData == null){
        this.errorMessages.push('Something went wrong. Please try later');
      }
      this.showError = true;
      this.isSubmit = false;
      this.submitBtn.disabled = false;
    })
  }
  // get course list

  getCourseList() {
    this.courseService.viewCourse().subscribe(res => {
      this.courseList = res['data']['data']
    })
  }

  

}
